import React, { ReactElement, useContext, useEffect, useRef } from 'react';
import { IInitialValue } from '../entities/types';
import { CellContext } from '@tanstack/react-table';
import Input, { useInput } from '~/components/Input/InputWrapper';
import { State } from '~/store';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import toast from 'react-hot-toast';
import { StatusCodes } from 'http-status-codes';
import logger from '~/utils/logger';
import { IAPIResponse } from '~/utils/types';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { fetchSpecificPositions } from '~/pages/Headcount/utils/fetchSpecificPositions';
import { z } from 'zod';

const inputValidation: Record<string, RegExp> = {
  employeeName: /^.*$/,
  title: /.+/,
};

const CellInput = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { positionActiveStateDict, positionDataDict, setPositionDataDict } = useContext(HeadcountContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const parsedColumnId = z.enum(['employeeName', 'title']).parse(cellContext.column.id);
  const [value, setValue] = useInput({
    value: positionDataDict[initialValue.positionUuid][parsedColumnId],
    validation: inputValidation[parsedColumnId],
    disabled: cellContext.row.original.isActive.value === 'false',
  });
  const onBlur = async (): Promise<void> => {
    if (value.value !== positionDataDict[initialValue.positionUuid][parsedColumnId] && value.valid) {
      try {
        const updatedValueResponse = (await request({
          method: 'PATCH',
          url: `/organizations/${organizationUuid}/positions/${initialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            [parsedColumnId]: value.value,
          },
        })) as IAPIResponse<IPositionDetails>;
        if (updatedValueResponse.status === StatusCodes.CREATED) {
          const [updatedPosition] = await fetchSpecificPositions({
            positionUuids: [initialValue.positionUuid],
            organizationUuid,
            scenarioUuid: activeScenarioUuid ?? undefined,
          });
          setPositionDataDict((prev) => ({
            ...prev,
            [initialValue.positionUuid]: {
              employeeName: updatedPosition.employeeName ?? '',
              title: updatedPosition.title,
              department: updatedPosition.currentDepartment,
              hireDate: updatedPosition.hireDate,
              termDate: updatedPosition.termDate,
              fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
            },
          }));
        } else {
          toast.error('Failed to update value');
          setValue((prev) => ({
            ...prev,
            value: initialValue.value?.toString() ?? '',
          }));
        }
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        setValue((prev) => ({
          ...prev,
          value: initialValue.value?.toString() ?? '',
        }));
      }
    }
  };

  useEffect(() => {
    setValue((prev) => ({
      ...prev,
      disabled: !positionActiveStateDict[initialValue.positionUuid] && Boolean(activeScenarioUuid),
    }));
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  return (
    <div className={cellContext.column.id === 'title' ? 'min-w-36' : 'min-w-32'}>
      <Input
        id={`${cellContext.column.id}-${cellContext.row.index}`}
        type="text"
        onBlur={onBlur}
        state={value}
        setState={setValue}
        textRef={inputRef}
        className="!border-transparent hover:!border hover:!border-neutral-100 !shadow-none"
      />
    </div>
  );
};

export default CellInput;

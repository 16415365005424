import { IFormulaSegment } from '~/pages/FinancialModelDeprecated/entity/types';

export const handleRemoveSegmentToDelete = ({
  segmentToDelete,
  setSegmentToDelete,
  updatedFormula,
  inputRef,
}: {
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: React.RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  updatedFormula: IFormulaSegment[];
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  if (segmentToDelete) {
    updatedFormula[segmentToDelete.segmentIndex].ref.current?.classList.remove(
      '!bg-green-25',
      '!border-green-400',
      'text-green-500',
    );
    setSegmentToDelete(undefined);
  }

  if (inputRef.current) {
    inputRef.current.focus();
  }
};

import React, { State } from '~/store';
import { settingsSlice } from '~/store/settingsSlice';
import { useDispatch, useSelector } from 'react-redux';

const MobileNav = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { showSidebar } = useSelector((state: State) => state.settings);

  const toggleSidebarVisibility = (): void => {
    dispatch(settingsSlice.actions.update({ showSidebar: !showSidebar }));
  };

  return (
    <svg
      onClick={toggleSidebarVisibility}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6 cursor-pointer transform transition duration-300 hover:scale-125 hover:font-bold"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12H12m-8.25 5.25h16.5" />
    </svg>
  );
};

export default MobileNav;

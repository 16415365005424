import React, { useContext } from 'react';
import { RatiosContext } from '../context/RatiosContext';
import Typography from '~/components/Typography';

const RatiosPageTooltip = (): React.ReactNode => {
  const { emptyTableState } = useContext(RatiosContext);
  return (
    <div className="w-[469px] h-[388px] rounded-xl shadow-md flex flex-col gap-4 items-center bg-white text-center px-6 py-10">
      {emptyTableState}
      <div className="flex flex-row gap-4">
        <div className="flex items-center gap-1">
          <div className="size-2 rounded-full bg-purple" />
          <Typography color="secondary">Over-staffed</Typography>
        </div>
        <div className="flex items-center gap-1">
          <div className="size-2 rounded-full bg-red-500" />
          <Typography color="secondary">Under-staffed</Typography>
        </div>
      </div>
    </div>
  );
};

export default RatiosPageTooltip;

import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';

const UpcomingChangePopover = ({ id }: { id: string }): React.ReactNode => {
  return (
    <div data-testid={id}>
      <HoverPopover
        buttonContent={
          <div className="w-fit bg-green-400 rounded-full p-1 cursor-pointer">
            <ClockIcon className="size-4 text-white" />
          </div>
        }
        panelContent={
          <Typography color="white" size="xs">
            Upcoming change
          </Typography>
        }
        panelClassName="bg-black w-fit text-nowrap !no-underline !hover:no-underline text-black text-xs border-0 shadow-lg px-4 py-3 rounded-lg"
      />
    </div>
  );
};

export default UpcomingChangePopover;

import React, { Fragment, useEffect } from 'react';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { buttonFillStyles } from '~/components/Button';

interface IProps {
  fill?: 'clear' | 'outline' | 'solid' | 'destructive' | 'default';
  children: React.ReactNode;
  className?: string;
  options: {
    id: string;
    label: string;
    onClick: () => void;
    disabled?: boolean;
    className?: string;
  }[];
  direction?: 'below' | 'above';
  maxPanelHeight?: string;
  onOpenChange?: (open: boolean) => void;
}
const PopoverComponent = ({
  fill = 'solid',
  children,
  className = '',
  options,
  direction,
  maxPanelHeight,
  onOpenChange = (): void => {},
}: IProps): React.ReactNode => {
  const PopoverContent = ({
    open,
    close,
    onOpenChange,
  }: {
    open: boolean;
    close: () => void;
    onOpenChange: (open: boolean) => void;
  }): React.ReactNode => {
    useEffect(() => {
      onOpenChange(open);
    }, [open, onOpenChange]);

    return (
      <>
        <PopoverButton className={`py-2 px-5 rounded ${buttonFillStyles[fill]} ${className}`}>{children}</PopoverButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel
            className={`absolute z-10 transform mt-1 shadow-lg rounded
        ${direction === 'above' ? 'bottom-full' : ''} ${maxPanelHeight ? maxPanelHeight : ''}
        ${options.length > 4 ? 'overflow-y-scroll' : ''}`}
          >
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="relative bg-white">
                {options.map((option) => (
                  <button
                    type="button"
                    disabled={option.disabled ?? false}
                    key={option.id}
                    className={`flex justify-between items-center px-5 py-4 cursor-pointer hover:bg-gray-100 disabled:bg-transparent disabled:hover:bg-transparent disabled:cursor-default disabled:text-neutral-200 ${option.className}`}
                    onClick={() => {
                      close();
                      option.onClick();
                    }}
                  >
                    {option.label}
                  </button>
                ))}
              </div>
            </div>
          </PopoverPanel>
        </Transition>
      </>
    );
  };

  return (
    <Popover>{({ open, close }) => <PopoverContent open={open} close={close} onOpenChange={onOpenChange} />}</Popover>
  );
};

export default PopoverComponent;

import React from 'react';
import ShareLinkFormulasTable from './ShareLinkFormulasTable';

const ShareLinkFormulasContainer = ({
  permissionedFormulaUuids,
  startDate,
  endDate,
}: {
  permissionedFormulaUuids: string[];
  startDate: Date;
  endDate: Date;
}): React.ReactElement => {
  return (
    <div className="w-full mt-4 px-12">
      <ShareLinkFormulasTable
        permissionedFormulaUuids={permissionedFormulaUuids}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
};

export default ShareLinkFormulasContainer;

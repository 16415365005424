import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { format, toZonedTime } from 'date-fns-tz';
import React, { ReactElement, useEffect, useRef } from 'react';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { IConversation } from '~/pages/Dashboard/entity/types';
import ChatBox from './ChatBox';
import LoadingSpinner from '~/components/Loader';
import emptyConversationChatIcon from '~/assets/emptyConversationChatIcon.svg';

const ConversationLineItem = ({
  conversation,
  onClick,
}: {
  conversation: IConversation;
  onClick: () => void;
}): React.ReactNode => {
  return (
    <div
      data-testid={`line-item-conversation-${conversation.uuid}`}
      className="px-6 hover:bg-neutral-50 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row border-b border-neutral-50 px-4 justify-between items-center">
        <div className="flex flex-col justify-center flex-grow h-20 min-w-0">
          <Typography
            size="sm"
            weight={conversation.isRead ? 'normal' : 'semibold'}
            className="whitespace-nowrap overflow-hidden text-ellipsis"
          >
            {conversation.title}
          </Typography>
          <Typography size="sm" color="empty">
            {format(toZonedTime(conversation.updatedAt, 'UTC'), 'yyyy-MM-dd')}
          </Typography>
        </div>
        {conversation.isRead ? (
          <ChevronRightIcon className="size-6 text-neutral-100 flex-shrink-0 ml-2" />
        ) : (
          <div
            data-testid={`unread-conversation-${conversation.uuid}`}
            className="bg-red-400 rounded-full h-3 w-3 flex-shrink-0 ml-2"
          />
        )}
      </div>
    </div>
  );
};

const ConversationList = ({
  conversation,
  isLoading,
}: {
  conversation: ReactElement[];
  isLoading?: boolean;
}): React.ReactNode => {
  const listRef = useRef(null);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [conversation]); // This dependency ensures that the scroll adjusts every time the conversation list updates.

  return (
    <div
      ref={listRef}
      data-testid="conversation-message-list"
      className={`flex-grow overflow-auto px-4 hide-scrollbar ${isLoading ? 'p-6' : 'flex flex-col gap-2 pt-2 pb-20'}`}
    >
      {isLoading ? <LoadingSpinner /> : conversation}
    </div>
  );
};

const EmptyConversationState = (): React.ReactNode => {
  return (
    <div
      data-testid="conversation-list-empty-state"
      className="w-full h-full flex flex-col text-center items-center pt-[35%] py-4 px-9 "
    >
      <img src={emptyConversationChatIcon} alt="logo" className="w-32 h-auto mb-6" />
      <Typography weight="semibold" className="mb-6">
        Welcome to Parallel Chat
      </Typography>
      <Typography className="mb-8">
        Feel free to ask us about your model, create scenarios, update your information, or for any other
        finance-related inquiries.
      </Typography>
      <Typography color="secondary">
        {`Additionally, please don't hesitate to report any bugs or request
        assistance from our support team.`}
      </Typography>
    </div>
  );
};

const MessageBox = ({
  conversations,
  isLoading,
  messageMode,
  conversation,
  enterChatMode,
  showHistory,
  message,
  setMessage,
  submitMessage,
  isLoadingConversation,
  isSendingMessage,
  chatBoxRef,
  isChatBoxFocused,
}: {
  conversations?: IConversation[];
  isLoading?: boolean;
  messageMode: { mode: 'chat' | 'history'; conversationUuid?: string | null };
  conversation: ReactElement[];
  enterChatMode: ({ conversationUuid }: { conversationUuid?: string }) => void;
  showHistory: () => void;
  message: Types.InputState;
  setMessage: React.Dispatch<React.SetStateAction<Types.InputState>>;
  submitMessage: () => void;
  isLoadingConversation?: boolean;
  isSendingMessage?: boolean;
  chatBoxRef?: React.RefObject<HTMLTextAreaElement>;
  isChatBoxFocused: boolean;
}): React.ReactNode => {
  return (
    <>
      {messageMode.mode === 'chat' || isLoadingConversation ? (
        <div
          className={`relative my-4 w-[500px] border border-neutral-50 rounded-xl shadow-xl flex flex-col h-[87dvh] bg-white max-sm:w-[90dvw] 
          ${isChatBoxFocused && 'max-sm:h-[54dvh]'}`}
          data-testid="conversation-message-box"
        >
          <div className="flex flex-row w-full bg-green-15 h-16 items-center justify-between px-6">
            <Button
              onClick={showHistory}
              id="back-to-conversation-list"
              fill="clear"
              className="flex items-center justify-center flex-shrink-0 h-16 !w-fit !px-0"
              disabled={Boolean(isLoadingConversation) || isSendingMessage}
            >
              <ChevronLeftIcon className="size-6" /> <span>Messages</span>
            </Button>
          </div>
          <ConversationList conversation={conversation} isLoading={isLoadingConversation} />
          <div className="absolute bg-white rounded-b-xl bottom-0 w-full px-6 pb-4">
            <ChatBox
              message={message}
              setMessage={setMessage}
              submitMessage={submitMessage}
              isDisabled={Boolean(isSendingMessage) || isLoadingConversation}
              isLoading={isSendingMessage}
              chatBoxRef={chatBoxRef}
            />
          </div>
        </div>
      ) : (
        <div
          data-testid="conversation-list-box"
          className="my-4 w-[500px] border border-neutral-50 rounded-xl shadow-xl flex flex-col h-[87dvh] bg-white max-sm:w-[90dvw]"
        >
          <div className="flex flex-row w-full bg-green-15 h-16 items-center justify-between px-6">
            <Typography>Messages</Typography>
            <Button
              id="new-chat-button"
              onClick={() => enterChatMode({ conversationUuid: undefined })}
              fill="clear"
              className="!w-fit !px-0"
            >
              + New Chat
            </Button>
          </div>
          <div className="flex flex-col flex-grow overflow-auto hide-scrollbar">
            {isLoading ? null : conversations?.length ? (
              conversations.map((conversation) => (
                <ConversationLineItem
                  key={conversation.uuid}
                  conversation={conversation}
                  onClick={() => enterChatMode({ conversationUuid: conversation.uuid })}
                />
              ))
            ) : (
              <EmptyConversationState />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default MessageBox;

import { IFormula } from '~/services/parallel/formulas.types';
import { ZContract } from './schema';
import { z } from 'zod';

export interface IContractTableRow {
  id: string;
  customer: string;
  billingSchedule: string;
  annualValue: string;
  setupFee: string;
  invoiceDate: string;
  startDate: string;
  endDate: string;
  uuid: string;
  contractUuid: string;
}

export interface IContractTablularTableRow {
  id: string;
}

export type IColumnId =
  | 'uuid'
  | 'startDate'
  | 'endDate'
  | 'id'
  | 'customer'
  | 'billingSchedule'
  | 'annualValue'
  | 'setupFee'
  | 'invoiceDate'
  | 'contractUuid';

export interface ICellData {
  contractTitle: string;
  formula: IFormula;
}

export type TableRow = Record<IColumnId, ICellData>;

export enum IBillingScheduleEnum {
  Monthly = 'monthly',
  Quarterly = 'quarterly',
  Annual = 'annual',
}

export type IContract = z.infer<typeof ZContract>;

export enum IColumnIdEnum {
  Customer = 'customer',
  BillingSchedule = 'billingSchedule',
  AnnualValue = 'annualValue',
  SetupFee = 'setupFee',
  InvoiceDate = 'invoiceDate',
  StartDate = 'startDate',
  EndDate = 'endDate',
}

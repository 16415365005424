import React from 'react';

export interface IPeriodPickerState {
  startDate: Date | null;
  endDate: Date | null;
  mode: 'month' | 'quarter' | 'year';
  valid?: boolean;
  errorMessage?: string;
}

const usePeriodPicker = ({
  startDate,
  endDate,
  mode,
  valid = true,
  errorMessage,
}: IPeriodPickerState): [IPeriodPickerState, React.Dispatch<React.SetStateAction<IPeriodPickerState>>] =>
  React.useState<IPeriodPickerState>({
    startDate,
    endDate,
    mode,
    valid: startDate && endDate ? true : valid,
    errorMessage,
  });

export default usePeriodPicker;

import React from 'react';
import { Switch as SwitchComponent } from '@headlessui/react';

interface IProps {
  id?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const Switch = ({ id, checked, onChange }: IProps): React.ReactElement => {
  return (
    <SwitchComponent
      data-testid={id}
      checked={checked}
      onChange={onChange}
      className="group inline-flex h-6 w-11 items-center rounded-full bg-neutral-100 data-[checked]:bg-green data-[disabled]:cursor-not-allowed data-[disabled]:opacity-50"
    >
      <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
    </SwitchComponent>
  );
};

export default Switch;

import isEqual from 'lodash.isequal';
import { IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import { IFormula } from '~/services/parallel/formulas.types';

const validateFormula = ({
  formulaList,
  expression,
  recipeVariables,
  formulaUuid,
}: {
  formulaList: IFormula[];
  expression: string;
  recipeVariables: IVariables;
  formulaUuid?: string;
}): { validated: boolean; errorMessage: string } => {
  const disallowedCombinations = [
    '++',
    '+/',
    '+)',
    '+*',
    '*/',
    '**',
    '*)',
    '*+',
    '/+',
    '/*',
    '/)',
    '//',
    '(+',
    '(*',
    '(/',
    '()',
    '-+',
    '-*',
    '-/',
    '-)',
  ];

  for (const combination of disallowedCombinations) {
    if (expression.includes(combination)) {
      return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
    }
  }

  if (!/^[-($]/.test(expression)) {
    return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
  }

  if (/[-+*/]{3,}/.test(expression)) {
    return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
  }

  if (!expression.length || !Object.keys(recipeVariables).length) {
    return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
  }

  if (/[+\-*/(]$/.test(expression)) {
    return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
  }

  const hasCircularDependency = (
    currentFormulaUuid: string | null,
    targetUuid?: string,
    visited: Set<string> = new Set(),
  ): boolean => {
    if (!currentFormulaUuid || visited.has(currentFormulaUuid)) {
      return false;
    }
    visited.add(currentFormulaUuid);

    const formula = formulaList.find((f) => f.formulaUuid === currentFormulaUuid);
    if (!formula) return false;

    const formulaVariables = formula.recipe.variables;
    const variableValues = Object.values(formulaVariables);

    for (const variable of variableValues) {
      if (variable.formulaUuid === targetUuid && isEqual(variable.timeModifier, {})) {
        return true;
      }

      if (
        variable.formulaUuid &&
        isEqual(variable.timeModifier, {}) &&
        hasCircularDependency(variable.formulaUuid, targetUuid, visited)
      ) {
        return true;
      }
    }

    return false;
  };

  for (const variable of Object.values(recipeVariables)) {
    if (variable.type === 'formula') {
      if (hasCircularDependency(variable.formulaUuid, formulaUuid) && isEqual(variable.timeModifier, {})) {
        return {
          validated: false,
          errorMessage: 'This creates a circular dependency, formula will not be saved',
        };
      }
    } else if (variable.type === 'self') {
      if (isEqual(variable.timeModifier, {})) {
        return {
          validated: false,
          errorMessage: 'This creates a circular dependency, formula will not be saved',
        };
      }
    }
  }

  const isParenthesesBalanced = (expression: string): boolean => {
    let balance = 0;

    for (const char of expression) {
      if (char === '(') {
        balance += 1;
      } else if (char === ')') {
        balance -= 1;

        if (balance < 0) {
          return false;
        }
      }
    }

    return balance === 0;
  };

  if (!isParenthesesBalanced(expression)) {
    return { validated: false, errorMessage: 'This is not a valid formula, formula will not be saved' };
  }

  const noConsecutiveVariables = ({ expression }: { expression: string }): boolean => {
    // checks that no $ is preceeded by an integer
    const regex = /^(?!.*(?<=\d)\$)/;
    if (regex.test(expression.replace(/\s/g, ''))) {
      return true;
    }

    return false;
  };

  if (!noConsecutiveVariables({ expression })) {
    return {
      validated: false,
      errorMessage: 'All variables must be separated by an operator, formula will not be saved',
    };
  }

  // Formula cannot contain letters
  if (/[a-zA-Z]/.test(expression)) {
    return { validated: false, errorMessage: 'This formula contains invalid characters, formula will not be saved' };
  }

  return { validated: true, errorMessage: '' };
};

export default validateFormula;

import { useContext } from 'react';
import { IModelBuilderContext, ModelBuilderContext } from './ModelBuilderContext';

export enum IContextTypeEnum {
  ModelBuilder = 'model-builder',
}

const useFormulaContext = (): IModelBuilderContext => {
  const modelBuilderContext = useContext(ModelBuilderContext);

  if (modelBuilderContext) {
    return modelBuilderContext;
  } else {
    throw new Error('No table context available. Make sure to wrap the component in a context provider.');
  }
};

export default useFormulaContext;

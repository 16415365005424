import { z } from 'zod';
import request, { IRequest } from '~/utils/request';
import {
  IFormattingEnum,
  IFormula,
  IFormulaSort,
  IFormulaTypeEnum,
  IRecipe,
  IUpdateSortOrderParams,
  ZFormula,
  ZFormulaSort,
} from './formulas.types';
import { IAPIResponse } from '~/utils/types';

export const list = async ({
  organizationUuid,
  startDate,
  endDate,
  scenarioUuid,
  types,
}: {
  organizationUuid: string;
  startDate: Date;
  endDate: Date;
  scenarioUuid?: string;
  types?: IFormulaTypeEnum[];
}): Promise<IFormula[]> => {
  const formulas = (await request({
    url: `/formulas`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
    params: {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      includes: ['calculations'],
      scenarioUuid,
      types,
    },
  })) as IAPIResponse;

  return z.array(ZFormula).parse(formulas.data.data);
};

export const getSortOrder = async ({ organizationUuid }: { organizationUuid: string }): Promise<IFormulaSort> => {
  const sortOrder = (await request({
    url: `/formulas/sorting`,
    method: 'GET',
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse;

  return ZFormulaSort.parse(sortOrder.data.data);
};

export const updateSortOrder = async ({
  organizationUuid,
  scenarioUuid,
  groups,
}: IUpdateSortOrderParams): Promise<IFormulaSort> => {
  const data = {
    url: `/formulas/sorting`,
    method: 'PATCH',
    headers: { 'Organization-Uuid': organizationUuid },
    body: {
      groups,
    },
    params: {},
  };

  if (scenarioUuid) {
    data.params = { scenarioUuid };
  }

  const updateSortOrder = (await request({
    ...data,
    method: 'PATCH' as const,
  })) as IAPIResponse;

  return ZFormulaSort.parse(updateSortOrder.data.data);
};

export interface IUpdateMonthValueParams {
  organizationUuid: string;
  formulaUuid: string;
  recipe?: IRecipe;
  actuals?: { date: string | Date; value: number | null }[];
  overrides?: { date: string | Date; value: number | null }[];
  latestNeededDate?: Date;
  idempotencyKey?: string;
  formatting?: IFormattingEnum;
  dataSourceUuids?: string[];
}

export const update = async ({
  organizationUuid,
  formulaUuid,
  actuals,
  overrides,
  recipe,
  latestNeededDate,
  idempotencyKey,
  formatting,
  dataSourceUuids,
}: IUpdateMonthValueParams): Promise<void> => {
  const data: IRequest = {
    url: `/formulas/${formulaUuid}`,
    method: 'PATCH' as const,
    headers: { 'Organization-Uuid': organizationUuid },
  };

  if (actuals) data.body = { ...data.body, actuals };
  if (overrides) data.body = { ...data.body, overrides };
  if (recipe) data.body = { ...data.body, recipe };
  if (dataSourceUuids) data.body = { ...data.body, dataSourceUuids };
  if (formatting) data.body = { ...data.body, formatting };

  if (latestNeededDate) data.params = { ...data.params, latestNeededDate: latestNeededDate.toISOString() };

  if (idempotencyKey) {
    data.headers = {
      ...data.headers,
      'Idempotency-Key': idempotencyKey,
    };
  }

  await request(data);
};

export const createAttribute = async ({
  organizationUuid,
  groupIndex,
  name,
  currentSortOrder,
}: {
  organizationUuid: string;
  groupIndex: number;
  name: string;
  currentSortOrder: { name: string; sortOrder: string[] }[];
}): Promise<IFormula> => {
  const response = (await request({
    url: `/formulas`,
    method: 'POST',
    headers: { 'Organization-Uuid': organizationUuid },
    body: {
      recipe: {
        name,
        expression: '$1',
        variables: {
          $1: { type: 'constant', formulaUuid: null, constantValue: 0, timeModifier: {}, calculationType: null },
        },
        roundingInstructions: null,
      },
      dataSourceUuids: [],
      formatting: 'number',
    },
  })) as IAPIResponse;

  const createdAttribute = ZFormula.parse(response.data.data);

  /**
   * Update the sort order to include the new attribute
   */
  const newSortOrder = currentSortOrder.map((group, index) => {
    if (groupIndex === index) {
      return { ...group, sortOrder: [createdAttribute.formulaUuid, ...group.sortOrder] };
    }
    return group;
  });

  await updateSortOrder({ organizationUuid, groups: newSortOrder });

  return createdAttribute;
};

export const remove = async ({
  organizationUuid,
  formulaUuid,
}: {
  organizationUuid: string;
  formulaUuid: string;
}): Promise<void> => {
  await request({
    url: `/formulas/${formulaUuid}`,
    method: 'DELETE',
    headers: { 'Organization-Uuid': organizationUuid },
  });
};

import React from 'react';

const useKeyPress = (key, cb, options = {}) => {
  const { target = window ?? null, eventOptions } = options;
  const eventOptionsRef = React.useRef(eventOptions);

  React.useEffect(() => {
    const onEvent = (event) => {
      if (event.key === key) {
        cb(event);
      }
    };

    target.addEventListener('keydown', onEvent, eventOptionsRef.current);
    target.addEventListener('keyup', onEvent, eventOptionsRef.current);

    return () => {
      target.removeEventListener('keydown', onEvent);
      target.removeEventListener('keyup', onEvent);
    };
  }, [target, cb]);
};

export default useKeyPress;

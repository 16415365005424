import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { flexRender, Table } from '@tanstack/react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { getCommonPinningStyles } from './getCommonPinningStyles';
import { TableRow } from './TableRow';
import { moveArrayElement } from './moveArrayElement';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import { useSelector } from 'react-redux';
import { State } from '~/store';

interface IProps {
  table: Table<Record<string, unknown>>;
  emptyState: string | ReactElement;
  styles?: {
    table?: string;
    tHead?: string;
    tBody?: string;
    tHeadTr?: string;
    tRow?: string;
    th?: string;
    td?: string;
  };
  id?: string;
  deleteGroup: (groupName: string) => void;
  updateGroup: (groupName: string) => void;
  sortingState: { name: string; sortOrder: string[] }[];
  setSortingState: React.Dispatch<React.SetStateAction<{ name: string; sortOrder: string[] }[]>>;
}

/**
 * ExapandableTable component
 *
 * Requires expandable custom feature
 */
const ExpandableTable = ({
  table,
  emptyState = 'No Data',
  styles,
  id,
  deleteGroup,
  updateGroup,
  sortingState,
  setSortingState,
}: IProps): ReactElement => {
  const { setSelectedMonthCell, setParsedFormulas, dragMode, setDragMode, search } = useContext(FinancialModelContext);
  const {
    settings: { financialModelExpand },
    scenario: { activeScenarioUuid },
  } = useSelector((state: State) => state);
  const [tableData, setTableData] = useState(
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    table.getRowModel().rows.filter((row) => row.original),
  );
  const [dragDisabled, setDragDisabled] = useState(false);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 150, // Delay in milliseconds before the drag operation is initiated
        tolerance: 5, // Optional: Movement tolerance in pixels before the drag operation is initiated
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  // Update tableData when rows change
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- tanstack returns rows with undefined
    setTableData(table.getRowModel().rows.filter((row) => row.original));
  }, [table.getRowModel().rows]);

  useEffect(() => {
    if (activeScenarioUuid || search.value) {
      setDragDisabled(true);
    }
  }, [activeScenarioUuid, search.value]);

  const handleDragStart = (event: DragEndEvent): void => {
    const groupIds = sortingState.map((group) => `group_${group.name}`);

    const isGroup = groupIds.includes(event.active.id as string);

    setDragMode({
      isDragging: true,
      isGroup,
    });
  };

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (!over) return;

    const activeIndex = tableData.findIndex((item) => item.id === active.id);
    let overIndex = tableData.findIndex((item) => item.id === over.id);

    if (activeIndex !== 0 && overIndex === 0) {
      overIndex += 1;
    }

    // Prevent groups from being moved into the middle of subrows
    const activeRow = tableData[activeIndex];
    const overRow = tableData[overIndex];

    if (activeRow.depth === 0 && overRow.depth !== 0) {
      // Find the next group index to place the active group before it
      const nextGroupIndex = tableData.slice(overIndex).findIndex((row) => row.depth === 0);
      const newIndex = nextGroupIndex !== -1 ? overIndex + nextGroupIndex : tableData.length;
      setTableData(arrayMove(tableData, activeIndex, newIndex));
    } else {
      setTableData(arrayMove(tableData, activeIndex, overIndex));
    }

    let toIndex = over.data.current.sortable.index;
    if (!dragMode.isGroup && activeIndex !== 0 && toIndex === 0) {
      toIndex += 1;
    }

    const updatedSortOrder = moveArrayElement({
      groups: sortingState,
      fromIndex: active.data.current.sortable.index,
      toIndex,
      groupMode: dragMode.isGroup,
    });

    const unflattenedSortOrder = updatedSortOrder.reduce(
      (output, element) => {
        const isGroup = sortingState.find(({ name }) => name === element);
        if (isGroup) {
          output.push({ name: element, sortOrder: [] });
        } else {
          output[output.length - 1].sortOrder.push(element);
        }
        return output;
      },
      [] as { name: string; sortOrder: string[] }[],
    );

    setDragMode((prevState) => ({
      ...prevState,
      isDragging: false,
    }));
    setSortingState(unflattenedSortOrder);
    setParsedFormulas((prev) => ({ ...prev, sorting: unflattenedSortOrder }));
  };

  useEffect(() => {
    if (financialModelExpand) {
      if (!dragMode.isDragging) {
        // TODO - figure out how to update store with the new changes.
        table.setExpanded(
          financialModelExpand.reduce(
            (output, { expanded, name }) => {
              output[`group_${name}`] = Boolean(expanded);
              return output;
            },
            {} as Record<string, boolean>,
          ),
        );
      } else {
        let allExpanded = true;
        if (dragMode.isGroup) allExpanded = false;

        table.setExpanded(
          financialModelExpand.reduce(
            (output, group) => {
              output[`group_${group.name}`] = allExpanded;
              return output;
            },
            {} as Record<string, boolean>,
          ),
        );

        setSelectedMonthCell((prev) => {
          return {
            ...prev,
            colIndex: undefined,
            rowIndex: undefined,
          };
        });
      }
    }
  }, [dragMode]);

  const columnCount = table.getHeaderGroups().reduce((acc, headerGroup) => headerGroup.headers.length, 0);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext items={tableData} strategy={verticalListSortingStrategy} disabled={dragDisabled}>
        <table
          className={`table-auto border-collapse${styles?.table ? ` ${styles.table}` : ''}`}
          data-testid={id ?? ''}
        >
          <thead className={`${styles?.tHead ? ` ${styles.tHead}` : ''}`}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={`${styles?.tHeadTr ? ` ${styles.tHeadTr}` : ''}`}>
                {headerGroup.headers
                  .filter((header, index) => index !== 1)
                  .map((header, index) => (
                    <th
                      key={header.id}
                      className={`${styles?.th ? ` ${styles.th}` : ''} relative`}
                      colSpan={index === 0 ? 2 : 1}
                      style={
                        header.column.getCanResize()
                          ? {
                              ...getCommonPinningStyles({
                                column: header.column,
                                type: 'header',
                              }),
                              width: header.getSize(),
                              maxWidth: header.getSize(),
                              minWidth: header.getSize(),
                            }
                          : undefined
                      }
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          className={`${
                            header.column.getCanSort() ? 'cursor-pointer select-none' : ''
                          } flex flex-row gap-4`}
                          onClick={header.column.getToggleSortingHandler()}
                          title={
                            header.column.getCanSort()
                              ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                  ? 'Sort descending'
                                  : 'Clear sort'
                              : undefined
                          }
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.getCanSort() && (
                            <div className="flex flex-col">
                              <ChevronUpIcon
                                className={`h-3 w-3 ${header.column.getIsSorted() === 'asc' ? 'text-black' : ''}`}
                              />
                              <ChevronDownIcon
                                className={`h-3 w-3 -mt-1 ${header.column.getIsSorted() === 'desc' ? 'text-black' : ''}`}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </th>
                  ))}
              </tr>
            ))}
          </thead>
          <tbody className={`${styles?.tBody ? ` ${styles.tBody}` : ''}`}>
            {tableData.length ? (
              tableData.map((row, index) => {
                return row.depth === 0 ? (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row.original.groupName}-${index}`}
                    table={table}
                    row={row}
                    styles={styles}
                    updateGroup={updateGroup}
                    deleteGroup={deleteGroup}
                    setDragDisabled={setDragDisabled}
                    dragMode={dragMode}
                  />
                ) : (
                  <TableRow
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${row.original.groupName}-${index}`}
                    table={table}
                    row={row}
                    styles={styles}
                    setDragDisabled={setDragDisabled}
                    dragMode={dragMode}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={columnCount} className="text-center">
                  {emptyState}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </SortableContext>
    </DndContext>
  );
};

export default ExpandableTable;

import React, { useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import { formatYValue } from '../utils/formatYValue';
import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';

const SideLabels = ({
  yMax,
  yMin,
  formatting,
  consolidatedGraphData,
  graphHasDragged,
}: {
  yMax: number;
  yMin: number;
  formatting: IFormattingEnum;
  consolidatedGraphData: IConsolidatedGraphBody;
  graphHasDragged: boolean;
}): React.ReactElement => {
  const [yMaxGreaterThanZero, setYMaxGreaterThanZero] = useState<boolean>(true);
  const [yMinLessThanZero, setYMinLessThanZero] = useState<boolean>(true);

  useEffect(() => {
    const { minGraphValue, maxGraphValue } = consolidatedGraphData.data.reduce(
      (acc: { minGraphValue: number; maxGraphValue: number }, item: Record<string, number>) => {
        Object.keys(item).forEach((key) => {
          if (key !== 'date') {
            const value = item[key];
            if (typeof value === 'number') {
              if (value < acc.minGraphValue) {
                acc.minGraphValue = value;
              }
              if (value > acc.maxGraphValue) {
                acc.maxGraphValue = value;
              }
            }
          }
        });
        return acc;
      },
      {
        minGraphValue: 0,
        maxGraphValue: 0,
      },
    );
    if (maxGraphValue !== 0 || minGraphValue !== 0) {
      setYMaxGreaterThanZero(maxGraphValue > 0);
      setYMinLessThanZero(minGraphValue < 0);
    }
  }, [consolidatedGraphData]);

  return (
    <div className="h-[115%] flex flex-col items-end justify-between text-right">
      <Typography size="xs" color="empty" className="text-right">
        {formatYValue({
          value: yMaxGreaterThanZero || graphHasDragged || consolidatedGraphData.roundingInstructions ? yMax : 0,
          formatType: formatting,
          yMax: yMax,
          yMin: yMin,
        })}
      </Typography>
      <Typography size="xs" color="empty">
        {formatYValue({
          value: (yMax + yMin) / 2,
          formatType: formatting,
          yMax: yMax,
          yMin: yMin,
        })}
      </Typography>
      <Typography size="xs" color="empty">
        {formatYValue({
          value: yMinLessThanZero || graphHasDragged || consolidatedGraphData.roundingInstructions ? yMin : 0,
          formatType: formatting,
          yMax: yMax,
          yMin: yMin,
        })}
      </Typography>
    </div>
  );
};

export default SideLabels;

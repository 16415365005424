import React, { useMemo, useState } from 'react';
import Typography from '~/components/Typography';
import { TrashIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import HoverPopover from '~/components/HoverPopover';
import ScenarioItemDeleteButton from './ScenarioItemDeleteButton/ScenarioItemDeleteButton';
import Button from '~/components/Button';

const DeleteButton = ({
  scenario,
  onDelete,
  anchorDirection = 'bottom',
}: {
  scenario: IScenario;
  onDelete: (scenario: IScenario) => void;
  anchorDirection?: 'top' | 'bottom';
}): React.ReactNode => {
  const [showConfirmPopover, setShowConfirmPopover] = useState(false);
  return (
    <ScenarioItemDeleteButton
      clickPanelOpen={showConfirmPopover}
      tooltip={{
        content: 'Delete',
        anchor: anchorDirection,
      }}
      onOpenChange={(isOpen) => setShowConfirmPopover(isOpen)}
      buttonContent={
        <TrashIcon
          data-testid={`${scenario.uuid}-scenario-delete`}
          className="text-neutral-200 cursor-pointer size-5"
        />
      }
      popoverContent={
        <div className="flex flex-row gap-2 bg-white py-2 px-3 shadow-md rounded-lg">
          <button
            className="text-sm text-neutral-500 hover:text-neutral-700 focus:outline-none"
            onClick={() => {
              setShowConfirmPopover(false);
            }}
          >
            <Typography>Cancel</Typography>
          </button>
          <div className="w-px h-6 bg-neutral-200"></div>
          <button
            className="text-sm text-red-500 hover:text-red-700 focus:outline-none"
            onClick={() => {
              setShowConfirmPopover(false);
              onDelete(scenario);
            }}
          >
            <Typography color="warning">Confirm Delete</Typography>
          </button>
        </div>
      }
    />
  );
};

const EditButton = ({
  scenario,
  onEdit,
}: {
  scenario: IScenario;
  onEdit: (scenario: IScenario) => void;
}): React.ReactNode => {
  return (
    <Button
      id={`${scenario.uuid}-scenario-edit`}
      fill="blueOutline"
      onClick={() => onEdit(scenario)}
      className="!py-1 !px-3 mr-1"
    >
      Edit
    </Button>
  );
};

const CopyButton = ({
  scenario,
  onCopy,
  anchorDirection,
}: {
  scenario: IScenario;
  onCopy: (scenario: IScenario) => void;
  anchorDirection?: 'top' | 'bottom';
}): React.ReactNode => {
  return (
    <HoverPopover
      buttonContent={
        <DocumentDuplicateIcon
          data-testid={`${scenario.uuid}-scenario-copy`}
          className="text-neutral-200 cursor-pointer size-5"
          onClick={() => onCopy(scenario)}
        />
      }
      panelContent={<div className="bg-black px-2 py-1 rounded text-white">Copy</div>}
      anchor={anchorDirection}
      panelClassName="z-20"
    />
  );
};

const ScenarioItem = ({
  scenario,
  onSelect,
  onEdit,
  onDelete,
  onCopy,
  anchorDirection = 'bottom',
}: {
  scenario: IScenario;
  onSelect: (scenario: IScenario, close?: () => void) => void;
  onEdit: (scenario: IScenario) => void;
  onDelete: (scenario: IScenario) => void;
  onCopy: (scenario: IScenario) => void;
  anchorDirection?: 'top' | 'bottom';
}): React.ReactNode => {
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);

  const [isHovered, setIsHovered] = useState(false);

  const shouldBeDisabled = useMemo((): boolean => {
    if (activeScenarioUuid === scenario.uuid) {
      return true;
    } else if (selectedScenarioUuids.length < 3) {
      return false;
    } else if (selectedScenarioUuids.includes(scenario.uuid)) {
      return false;
    } else {
      return true;
    }
  }, [activeScenarioUuid, selectedScenarioUuids, scenario.uuid]);

  const scenarioIsStatic = scenario.type === 'static';

  return (
    <div
      className={`w-full flex px-4 justify-between items-center py-1 group rounded-md ${
        shouldBeDisabled ? '' : isHovered ? 'bg-neutral-25' : 'hover:bg-neutral-25'
      } ${(selectedScenarioUuids.includes(scenario.uuid) || activeScenarioUuid === scenario.uuid) && 'bg-neutral-25'}`}
      key={scenario.uuid}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`flex justify-start align-middle items-center gap-[8px] ${shouldBeDisabled ? 'cursor-default' : ' cursor-pointer '} flex-grow`}
      >
        <label className="inline-flex items-center cursor-pointer">
          <input
            data-testid={`scenario-input-${scenario.uuid}`}
            value={`scenario-input-${scenario.uuid}`}
            id={`scenario-input-${scenario.uuid}`}
            type="checkbox"
            name="role"
            checked={selectedScenarioUuids.includes(scenario.uuid) || activeScenarioUuid === scenario.uuid}
            className="sr-only peer"
            disabled={shouldBeDisabled}
            onChange={() => {
              if (!shouldBeDisabled) {
                onSelect(scenario);
              }
            }}
          />
          <div
            className={`relative w-9 h-5 bg-neutral-100 rounded-full peer  peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-white after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-400 ${shouldBeDisabled ? 'opacity-75 after:bg-green-25 after:border-green-25' : ''}`}
          ></div>
        </label>
        <Typography
          color={shouldBeDisabled ? 'empty' : 'primary'}
          size="sm"
          className={`sm:group-hover:truncate max-w-[250px] h-[34px] flex items-center sm:group-hover:max-w-[150px] sm:truncate ${activeScenarioUuid === scenario.uuid && 'truncate !max-w-[162px]'}`}
        >
          {scenario.changeDescription}
        </Typography>
      </div>
      {activeScenarioUuid === scenario.uuid ? (
        <div className="flex justify-center items-center text-nowrap">
          <Typography color="disabled">{`(In Progress)`}</Typography>
        </div>
      ) : (
        <div className="flex justify-end items-center gap-1.5 opacity-0 group-hover:opacity-100 max-sm:hidden">
          {!shouldBeDisabled && !scenarioIsStatic && <EditButton scenario={scenario} onEdit={onEdit} />}
          {!shouldBeDisabled && !scenarioIsStatic && (
            <CopyButton scenario={scenario} onCopy={onCopy} anchorDirection={anchorDirection} />
          )}
          {!shouldBeDisabled && (
            <DeleteButton scenario={scenario} onDelete={onDelete} anchorDirection={anchorDirection} />
          )}
        </div>
      )}
    </div>
  );
};

export default ScenarioItem;

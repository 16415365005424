import React, { useContext } from 'react';
import { ICellData, TableRow } from '../../entity/types';
import { CellContext } from '@tanstack/react-table';
import Typography from '~/components/Typography';
import numberWithCommas from '~/utils/numberWithCommas';
import { RatiosContext } from '../../context/RatiosContext';

const DriverCell = ({ info }: { info: CellContext<TableRow, ICellData> }): React.ReactNode => {
  const { drivingValue, drivingElementTitle, ratioUuid } = info.getValue();
  const { setEditRatioUuid, setIsCreateRatioModalOpen } = useContext(RatiosContext);
  return (
    <div
      id={ratioUuid}
      className="flex flex-row px-4 !h-full gap-2 items-center relative"
      onClick={() => {
        setIsCreateRatioModalOpen(true);
        setEditRatioUuid(ratioUuid);
      }}
    >
      <Typography>{numberWithCommas(drivingValue)}</Typography>
      <Typography color="disabled">of</Typography>
      <div className="border rounded-full px-3 py-1 border-neutral-100 bg-neutral-15">
        <Typography>{drivingElementTitle}</Typography>
      </div>
      <div className="absolute h-full top-0 right-[-1px] w-px bg-gray-200" />
    </div>
  );
};

export default DriverCell;

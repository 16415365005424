import React from 'react';
import { RadioInputState } from '../RadioInput/RadioInput.types';
import Card from '../Card';
import Typography from '../Typography';

interface Props {
  id: string;
  state: RadioInputState;
  setState: React.Dispatch<React.SetStateAction<RadioInputState>>;
  disabled?: boolean;
}

const RadioInputCard = ({ id, state, setState, disabled }: Props): React.ReactElement => {
  const showError = state.touched && !state.pristine && !state.valid;
  return (
    <div className="flex flex-col gap-2 w-full">
      {state.options.map((option) => {
        const isSelected = state.selected?.value === option.value;
        return (
          <Card
            key={option.value}
            className={`${isSelected && '!bg-green-15 !border-green-100'} !flex !flex-row !items-center !gap-4 !cursor-pointer !w-full !py-2 !px-4`}
            onClick={() =>
              setState({
                ...state,
                selected: option,
                pristine: false,
                touched: true,
                valid: true,
              })
            }
          >
            <div className="flex items-center justify-center">
              <input
                data-testid={option.value}
                value={option.value}
                id={option.value}
                type="radio"
                name={id}
                checked={isSelected}
                onChange={() =>
                  setState({
                    ...state,
                    selected: option,
                    pristine: false,
                    touched: true,
                    valid: true,
                  })
                }
                className="mt-1 hover:bg-neutral-15 hover:border-green-500 
              checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-400 checked:focus:hover:bg-green-500
              focus:ring-green-400 focus:hover:ring-green-500"
                disabled={disabled}
              />
            </div>
            <div className="w-full">{option.icon}</div>
          </Card>
        );
      })}
      {showError && (
        <Typography tag="span" size="xs" color="warning">
          {state.errorMessage}
        </Typography>
      )}
    </div>
  );
};

export default RadioInputCard;

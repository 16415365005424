import { IChangeLogElement } from '../entity/types';

export const filterChangeLog = ({
  changeLog,
  changeTypeFilter,
  entityTypeFilter,
  searchTitle,
}: {
  changeLog: IChangeLogElement[];
  changeTypeFilter: string;
  entityTypeFilter: string;
  searchTitle: string;
}): IChangeLogElement[] => {
  const filteredChangeLog = changeLog.filter((change) => {
    return Boolean(
      (changeTypeFilter === 'all' || change.action === changeTypeFilter) &&
        (entityTypeFilter === 'all' || change.type === entityTypeFilter) &&
        change.title?.toLowerCase().includes(searchTitle.toLowerCase()),
    );
  });

  return filteredChangeLog;
};

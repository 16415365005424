import React from 'react';
import { format, isAfter } from 'date-fns';
import Typography from '~/components/Typography';

const ExpirationCell = ({ expiration }: { expiration: Date }): React.ReactElement => {
  const isExpired = isAfter(new Date(), expiration);

  if (isExpired) {
    return (
      <Typography size="sm" color="warning">
        Expired
      </Typography>
    );
  }

  return (
    <div>
      <Typography size="sm">{format(expiration, 'MM/dd/yyyy')}</Typography>
    </div>
  );
};

export default ExpirationCell;

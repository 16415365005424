import React, { useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';

interface TooltipProps {
  content: string;
  children: React.ReactNode;
  anchor?: 'top' | 'bottom';
}

const ScenarioItemDeleteTooltip: React.FC<TooltipProps> = ({ content, children, anchor = 'top' }) => {
  const [isVisible, setIsVisible] = useState(false);
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible && triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      let top: number;
      if (anchor === 'top') {
        top = triggerRect.top - tooltipRect.height;
      } else {
        top = triggerRect.bottom;
      }
      const left = triggerRect.left + triggerRect.width / 2 - tooltipRect.width / 2;

      tooltipRef.current.style.top = `${Math.max(0, top)}px`;
      tooltipRef.current.style.left = `${Math.max(0, left)}px`;
    }
  }, [isVisible]);

  return (
    <div ref={triggerRef} onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)}>
      {children}
      {isVisible &&
        createPortal(
          <div ref={tooltipRef} className="fixed z-50 bg-black px-2 py-1 rounded text-white text-sm">
            {content}
          </div>,
          document.body,
        )}
    </div>
  );
};

export default ScenarioItemDeleteTooltip;

import request from '~/utils/request';
import toast from 'react-hot-toast';

interface CreateInvitationProps {
  organizationUuid: string;
  name: string;
  email: string;
}

export const createInvitation = async ({ organizationUuid, name, email }: CreateInvitationProps) => {
  const response = await request({
    url: `/organizations/${organizationUuid}/invitations`,
    method: 'POST',
    body: {
      name,
      email,
      role: 'admin',
    },
  });

  if (response.status !== 201) {
    throw new Error('Failed to create invitation');
  }

  toast.success('Invitation created successfully');

  return response.data.data;
};

interface RevokeAccessProps {
  organizationUuid: string;
  uuid: string;
  type: string;
}

export const revokeAccess = async ({ organizationUuid, uuid, type }: RevokeAccessProps) => {
  const url =
    type === 'invitation'
      ? `/organizations/${organizationUuid}/invitations/${uuid}`
      : `/organizations/${organizationUuid}/users/${uuid}/remove-access`;
  const method = type === 'invitation' ? 'DELETE' : 'PATCH';
  const response = await request({
    url,
    method,
  });

  if (response.status !== 200) {
    throw new Error('Failed to revoke access');
  }

  return response.data.data;
};

interface EditPermissionsProps {
  organizationUuid: string;
  type: string;
  uuid: string | null;
  name: string;
  email: string;
  isActive: boolean;
}

export const editPermissions = async ({
  organizationUuid,
  type,
  uuid,
  name,
  email,
  isActive,
}: EditPermissionsProps) => {
  const response = await request({
    url: `/organizations/${organizationUuid}/${type}/${uuid}`,
    method: 'PATCH',
    body: {
      name,
      email,
      isActive,
    },
  });

  if (response.status !== 200) {
    throw new Error('Failed to edit permissions');
  }

  return response.data.data;
};

import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import Card from '~/components/Card';
import Divider from '~/components/Divider';
import Switch from '~/components/Switch';
import Typography from '~/components/Typography';
import { State } from '~/store';
import { update } from '~/store/organizationSlice';
import logger from '~/utils/logger';
import request from '~/utils/request';

const OrganizationSettings = (): React.ReactNode => {
  const dispatch = useDispatch();
  const organizationSlice = useSelector((state: State) => state.organization);
  const [isContractsEnabled, setIsContractsEnabled] = useState(organizationSlice.configuration.contractsEnabled);
  const [isRatiosEnabled, setIsRatiosEnabled] = useState(organizationSlice.configuration.ratiosEnabled);

  const enableContracts = async (): Promise<void> => {
    try {
      const response = await request({
        url: `/organizations/${organizationSlice.uuid}/settings`,
        method: 'PATCH',
        body: {
          contractsEnabled: true,
        },
      });

      if (response.status === StatusCodes.OK) {
        dispatch(
          update({
            ...organizationSlice,
            configuration: { ...organizationSlice.configuration, contractsEnabled: true },
          }),
        );
      } else {
        toast.error('Failed to enable contracts');
        logger.error(response.data);
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to enable contracts');
    }
  };

  const toggleRatiosEnabled = async (enabled: boolean): Promise<void> => {
    try {
      const response = await request({
        url: `/organizations/${organizationSlice.uuid}/settings`,
        method: 'PATCH',
        body: {
          ratiosEnabled: enabled,
        },
      });

      if (response.status === StatusCodes.OK) {
        dispatch(
          update({
            ...organizationSlice,
            configuration: { ...organizationSlice.configuration, ratiosEnabled: enabled },
          }),
        );
      } else {
        toast.error('Failed to enable ratios');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to enable ratios');
    }
  };

  const handleContractsEnabledChange = (checked: boolean): void => {
    if (checked) {
      setIsContractsEnabled(true);
    }
  };

  useEffect(() => {
    if (isContractsEnabled && !organizationSlice.configuration.contractsEnabled) {
      enableContracts();
    }
  }, [isContractsEnabled]);

  useEffect(() => {
    if (isRatiosEnabled !== organizationSlice.configuration.ratiosEnabled) {
      toggleRatiosEnabled(isRatiosEnabled);
    }
  }, [isRatiosEnabled]);

  return (
    <Card className="w-full flex flex-col gap-4">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col w-full">
          <Typography size="md" weight="bold">
            Organization Settings
          </Typography>
        </div>
      </div>
      <Divider className="w-full" />
      <div className="flex flex-col w-full">
        <div className="flex flex-row justify-start items-start w-full gap-2">
          <Switch
            checked={organizationSlice.configuration.contractsEnabled}
            onChange={handleContractsEnabledChange}
            id="contracts-switch"
          />
          <div className="flex flex-col">
            <Typography size="sm">Track Contract & Billing Schedule</Typography>
            <Typography size="sm" color="secondary">
              Enables the ability to manage customer details and billing timelines within the platform
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-start items-start w-full gap-2">
        <Switch checked={isRatiosEnabled} onChange={setIsRatiosEnabled} />
        <div className="flex flex-col">
          <Typography size="sm">Headcount Ratios</Typography>
          <Typography size="sm" color="secondary">
            Adjust staffing forecasts based on drivers, such as customer count or employee count
          </Typography>
        </div>
      </div>
    </Card>
  );
};

export default OrganizationSettings;

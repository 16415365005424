import React, { useContext } from 'react';
import Button from '~/components/Button';
import { useInput } from '~/components/Input';
import Input from '~/components/Input/InputWrapper';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import { ContractsContext } from '../context/ContractsContext';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import request from '~/utils/request';
import { HttpStatusCode } from 'axios';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const DeleteContractModal = (): React.ReactNode => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { showDeleteContractModal, setShowDeleteContractModal, setContracts } = useContext(ContractsContext);
  const [contractName, setContractName, resetContractName] = useInput({});

  const handleDeleteContract = async (): Promise<void> => {
    try {
      if (
        showDeleteContractModal &&
        contractName.value.trim() === showDeleteContractModal.contractTitle.toUpperCase()
      ) {
        const response = await request({
          url: `/contracts/${showDeleteContractModal.contractUuid}`,
          method: 'DELETE',
          headers: {
            'Organization-Uuid': organizationUuid,
          },
          params: {
            scenarioUuid: activeScenarioUuid,
          },
        });
        if (response.status === HttpStatusCode.NoContent) {
          setContracts((prevState) =>
            prevState.filter((contract) => contract.contractUuid !== showDeleteContractModal.contractUuid),
          );
          closeModal();
        }
      } else {
        setContractName((prevState) => ({
          ...prevState,
          valid: false,
          touched: true,
          errorMessage: 'Name entered does not match contract name',
        }));
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Error deleting contract');
    }
  };

  const closeModal = (): void => {
    resetContractName();
    setShowDeleteContractModal(null);
  };

  return (
    <Modal
      isOpen={!!showDeleteContractModal}
      title={`Delete ${showDeleteContractModal?.contractTitle ?? ''} Contract`}
      size="xs"
      onClose={closeModal}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-8">
          <Typography size="sm" color="secondary">
            Deleting will remove this contract entirely, in the{' '}
            <Typography size="sm" color="secondary" weight="bold">
              past and in the forecast.
            </Typography>
          </Typography>
          <Typography size="sm" color="secondary">
            Type the contracts name{' '}
            <Typography size="sm" color="secondary" weight="bold">
              {showDeleteContractModal?.contractTitle.toUpperCase()}
            </Typography>{' '}
            to delete.
          </Typography>
        </div>
        <Input id="contract-name" state={contractName} setState={setContractName} label="Contract Name" />
        <div className="flex w-full items-center justify-between">
          <Button fill="clear" className="!w-fit !px-0" onClick={closeModal}>
            Cancel
          </Button>
          <Button fill="destructive" className="!w-fit" onClick={handleDeleteContract}>
            Delete Contract
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteContractModal;

import React, { useEffect } from 'react';

const useClickOutside = (ref: React.Ref<HTMLElement>, onOutsideClick: () => void): void => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref && 'current' in ref && ref.current && !ref.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onOutsideClick]);
};

export default useClickOutside;

import { StatusCodes } from 'http-status-codes';
import React from 'react';
import { toast } from 'react-hot-toast';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import { IShareLink } from '~/pages/ShareLink/models/types';
import logger from '~/utils/logger';
import request from '~/utils/request';

const OptionsCell = ({
  shareLink,
  setShareLinks,
  setShareLinkToEdit,
  setIsCreateModalOpen,
}: {
  shareLink: IShareLink;
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
  setShareLinkToEdit: React.Dispatch<React.SetStateAction<IShareLink | undefined>>;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const handleDelete = async (): Promise<void> => {
    try {
      const response = await request({
        url: `/share-link/${shareLink.uuid}`,
        method: 'DELETE',
      });
      if (response.status === StatusCodes.NO_CONTENT) {
        toast.success('Share link deleted');
        setShareLinks((prev) => prev.filter((link) => link.uuid !== shareLink.uuid));
      } else {
        toast.error('Failed to delete share link');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to delete share link');
    }
  };

  return (
    <EllipsisDropdown
      options={[
        {
          label: 'View/Edit',
          onClick: (): void => {
            setShareLinkToEdit(shareLink);
            setIsCreateModalOpen(true);
          },
        },
        {
          label: 'Delete',
          className: 'text-red-500',
          onClick: handleDelete,
        },
      ]}
    />
  );
};

export default OptionsCell;

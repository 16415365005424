import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { ICompanyGoal } from '../../Dashboard/entity/types';

export const createGoal = async ({
  formulaUuid,
  targetValue,
  targetDate,
  organizationUuid,
}: {
  formulaUuid: string | null;
  targetValue: number;
  targetDate: string | null;
  organizationUuid: string;
}): Promise<IAPIResponse<ICompanyGoal>> => {
  const response = await request({
    method: 'POST',
    url: '/goals',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
    body: {
      createData: {
        formulaUuid,
        targetValue,
        targetDate,
      },
    },
  });
  return response as IAPIResponse<ICompanyGoal>;
};

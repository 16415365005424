import React, { useEffect, useRef, useState, ReactNode } from 'react';

interface Props {
  name: string;
  segments: {
    value: string;
    label: ReactNode | string;
    ref?: unknown;
    segmentClassName?: string;
    labelClassName?: string;
  }[];
  value: string;
  setValue: (value: string) => void;
  thin?: boolean;
  disabled?: boolean;
  backgroundColor?: 'green' | 'gray';
}

const Segment = ({
  name,
  segments,
  setValue,
  value,
  thin = false,
  disabled = false,
  backgroundColor = 'green',
}: Props): React.ReactElement => {
  const foundIndex = segments.findIndex((segment) => segment.value === value) || 0;
  const activeIndex = foundIndex > -1 ? foundIndex : 0;
  const [enableTransitioning, setEnableTransitioning] = useState(false);
  const transitionClasses =
    'before:transition-transform before:duration-300 before:ease-in-out before:transition-width before:duration-300 before:ease-in-out';
  const controlRef = useRef<HTMLDivElement | null>(null);

  const setHighlighterPosition = (segment: HTMLElement): void => {
    if (!controlRef.current?.firstChild) return;
    const { style } = controlRef.current;
    style.setProperty('--highlight-width', `${segment.offsetWidth}px`);
    style.setProperty('--highlight-x-pos', `${segment.offsetLeft}px`);
  };

  const onInputChange = (val: string, index: number, segment: HTMLDivElement): void => {
    setHighlighterPosition(segment);
    setValue(val);
  };

  useEffect(() => {
    setHighlighterPosition(controlRef.current?.children[activeIndex] as HTMLDivElement);
    setEnableTransitioning(true);
  }, [value]);

  const getTextColorClass = (active: boolean): string => {
    if (active) return 'text-neutral-900';
    return backgroundColor === 'green' ? 'text-green-500' : 'text-neutral-300';
  };

  return (
    <div className={`controls-container w-full flex my-100 mt-0 mb-25${disabled ? ' opacity-50 cursor-default' : ''}`}>
      <div
        className={`controls w-full before:absolute before:top-1 before:bottom-1 before:left-0 before:z-0 before inline-flex justify-between relative border 
          rounded-lg overflow-hidden ${backgroundColor === 'green' ? 'bg-green-50' : 'bg-neutral-25'} m-auto p-1 before:bg-white before:rounded idle ${
            enableTransitioning ? transitionClasses : 'idle'
          }`}
        ref={controlRef}
      >
        {segments.map((item, i) => {
          const active = i === activeIndex;
          return (
            <div
              key={item.value}
              className={`segment z-10 w-1/2 text-center relative px-1 ${active ? 'active' : 'inactive'} ${item.segmentClassName}`}
            >
              <input
                data-testid={`segment-${item.value.toLowerCase()}`}
                className={`absolute opacity-0 w-full h-full${
                  disabled ? ' cursor-default' : ' cursor-pointer'
                } m-0 inset-0`}
                type="radio"
                value={item.value}
                id={item.value}
                name={name}
                checked={i === activeIndex}
                onChange={(e) => {
                  if (!disabled) {
                    onInputChange(item.value, i, e.currentTarget.parentElement as HTMLDivElement);
                  }
                }}
              />
              <label
                className={`${getTextColorClass(active)} cursor-pointer items-center block text-bold transition-color duration-500 ease-in-out ${item.labelClassName} ${
                  thin ? 'px-1' : 'p-1'
                }`}
                htmlFor={item.value}
              >
                {item.label}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Segment;

import { createContext, useContext, CSSProperties } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { DraggableAttributes } from '@dnd-kit/core';

interface DraggableContextType {
  isDragging: boolean;
  isOver: boolean;
  attributes: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  style: CSSProperties;
  iconAlwaysVisible: boolean;
}

export const DraggableContext = createContext<DraggableContextType | null>(null);

export const useDraggableContext = (): DraggableContextType => {
  const context = useContext(DraggableContext);
  if (!context) {
    throw new Error('useDraggableContext must be used within a DraggableContext.Provider');
  }
  return context;
};

export const useDraggableProvider = (
  id: string,
  liveUpdate = false,
): DraggableContextType & { setNodeRef: (node: HTMLElement | null) => void } => {
  const { attributes, listeners, isOver, isDragging, transform, transition, setNodeRef } = useSortable({
    id: id.toString(),
    transition: liveUpdate ? null : undefined,
  });

  const defaultStyle: CSSProperties = {
    borderBottomColor: isOver ? 'green' : '',
    opacity: isDragging ? 0.5 : 1,
  };

  const liveUpdateStyle: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 0,
  };

  return {
    attributes,
    listeners,
    isOver,
    setNodeRef,
    isDragging,
    iconAlwaysVisible: false,
    style: liveUpdate ? liveUpdateStyle : defaultStyle,
  };
};

import React from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}

const Index = ({ children, className = '', onClick }: Props): React.ReactElement => (
  <div
    className={`bg-white py-6 px-8 md:py-6 md:px-8 border border-green-50 rounded-2xl flex flex-col justify-start items-start ${className}`}
    onClick={onClick}
  >
    {children}
  </div>
);

export default Index;

export default (
  text: string,
  font: string, // must include font size and family ex: 12px Inter
): { width: number; height: number } => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (context) {
    context.font = font;
    const metrics = context.measureText(text);
    return {
      width: metrics.width,
      height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
    };
  }
  return { width: 0, height: 0 };
};

import React, { SetStateAction, useState } from 'react';

const TimeboundGoalDot = ({
  cx,
  cy,
  payload,
  setTimeboundGoalHovered,
}: {
  cx?: number;
  cy?: number;
  payload?: { date: number; targetValue: number };
  setTimeboundGoalHovered: React.Dispatch<
    SetStateAction<{
      x: number;
      y: number;
      goal: number;
    } | null>
  >;
}): React.ReactNode => {
  const [hovered, setHovered] = useState<boolean>(false);
  if (!cx || !cy) {
    return null;
  }

  const handleMouseEnter = (): void => {
    if (payload) {
      setHovered(true);
      setTimeboundGoalHovered({
        x: cx,
        y: cy,
        goal: payload.targetValue,
      });
    }
  };

  const handleMouseLeave = (): void => {
    setHovered(false);
    setTimeboundGoalHovered(null);
  };

  if (hovered) {
    return (
      <g>
        <svg
          x={cx - 17} // offset width to center along x
          y={cy - 34} // offset height so pointer on icon is at the point on y axis
          width="34"
          height="34"
          viewBox="0 0 34 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <path
            d="M8.81861 25.1225C4.31642 20.6203 4.31642 13.3208 8.81861 8.81863C13.3208 4.31644 20.6203 4.31644 25.1225 8.81863C29.6247 13.3208 29.6247 20.6203 25.1225 25.1225L16.9706 33.2745L8.81861 25.1225Z"
            fill="white"
          />
          <path
            d="M8.81861 25.1225C4.31642 20.6203 4.31642 13.3208 8.81861 8.81863C13.3208 4.31644 20.6203 4.31644 25.1225 8.81863C29.6247 13.3208 29.6247 20.6203 25.1225 25.1225L16.9706 33.2745L8.81861 25.1225Z"
            stroke="#7F9376"
            strokeWidth="0.94281"
          />
          <path
            d="M16.5146 12.1911C16.6187 11.9408 16.9732 11.9408 17.0773 12.1911L18.2288 14.9598C18.2727 15.0653 18.3719 15.1373 18.4858 15.1465L21.4749 15.3861C21.745 15.4078 21.8545 15.7449 21.6487 15.9212L19.3714 17.872C19.2846 17.9463 19.2467 18.063 19.2732 18.1741L19.969 21.0909C20.0319 21.3545 19.7451 21.5629 19.5138 21.4216L16.9548 19.8586C16.8572 19.799 16.7346 19.799 16.6371 19.8586L14.0781 21.4216C13.8468 21.5629 13.56 21.3545 13.6229 21.0909L14.3186 18.1741C14.3452 18.063 14.3073 17.9463 14.2205 17.872L11.9431 15.9212C11.7373 15.7449 11.8469 15.4078 12.117 15.3861L15.1061 15.1465C15.22 15.1373 15.3192 15.0653 15.363 14.9598L16.5146 12.1911Z"
            fill="#7F9376"
          />
        </svg>
      </g>
    );
  } else {
    return (
      <svg
        x={cx - 17} // offset width to center along x
        y={cy - 34} // offset height so pointer on icon is at the point on y axis
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <path
          d="M8.84986 25.1519C4.34767 20.6498 4.34767 13.3502 8.84986 8.84805C13.3521 4.34586 20.6516 4.34586 25.1538 8.84805C29.656 13.3502 29.656 20.6498 25.1538 25.1519L17.0018 33.3039L8.84986 25.1519Z"
          fill="white"
        />
        <path
          d="M8.84986 25.1519C4.34767 20.6498 4.34767 13.3502 8.84986 8.84805C13.3521 4.34586 20.6516 4.34586 25.1538 8.84805C29.656 13.3502 29.656 20.6498 25.1538 25.1519L17.0018 33.3039L8.84986 25.1519Z"
          stroke="#BFC9BA"
          strokeWidth="0.94281"
        />
        <path
          d="M16.5459 12.2205C16.6499 11.9702 17.0044 11.9702 17.1085 12.2205L18.2601 14.9892C18.304 15.0947 18.4032 15.1668 18.5171 15.1759L21.5061 15.4155C21.7763 15.4372 21.8858 15.7743 21.68 15.9506L19.4026 17.9014C19.3159 17.9757 19.278 18.0924 19.3045 18.2035L20.0002 21.1203C20.0631 21.3839 19.7763 21.5923 19.545 21.451L16.986 19.888C16.8885 19.8284 16.7659 19.8284 16.6684 19.888L14.1093 21.451C13.878 21.5923 13.5912 21.3839 13.6541 21.1203L14.3499 18.2035C14.3764 18.0924 14.3385 17.9757 14.2517 17.9014L11.9744 15.9506C11.7686 15.7743 11.8781 15.4372 12.1483 15.4155L15.1373 15.1759C15.2512 15.1668 15.3504 15.0947 15.3943 14.9892L16.5459 12.2205Z"
          fill="#AAB7A4"
        />
      </svg>
    );
  }
};

export default TimeboundGoalDot;

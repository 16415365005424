import { IContract, IColumnIdEnum } from '../entity/types';

export const sortContracts = ({
  contracts,
  sortingState,
}: {
  contracts: IContract[];
  sortingState: { columnId: IColumnIdEnum; desc: boolean };
}): IContract[] => {
  const { columnId, desc } = sortingState;

  const sortOrder = desc ? -1 : 1;

  switch (columnId) {
    case IColumnIdEnum.Customer:
      return contracts.sort((a, b) => {
        return sortOrder * a.contractTitle.localeCompare(b.contractTitle);
      });

    case IColumnIdEnum.BillingSchedule:
      return contracts.sort((a, b) => {
        return sortOrder * a.billingSchedule.localeCompare(b.billingSchedule);
      });

    case IColumnIdEnum.AnnualValue:
      return contracts.sort((a, b) => {
        return sortOrder * (a.annualValue - b.annualValue);
      });

    case IColumnIdEnum.SetupFee:
      return contracts.sort((a, b) => {
        const aSetupFee = a.setupFee ?? 0;
        const bSetupFee = b.setupFee ?? 0;
        return sortOrder * (aSetupFee - bSetupFee);
      });

    case IColumnIdEnum.InvoiceDate:
      return contracts.sort((a, b) => {
        return sortOrder * (a.invoiceDate.getTime() - b.invoiceDate.getTime());
      });

    case IColumnIdEnum.StartDate:
      return contracts.sort((a, b) => {
        return sortOrder * (a.startDate.getTime() - b.startDate.getTime());
      });

    case IColumnIdEnum.EndDate:
      return contracts.sort((a, b) => {
        const aTime = a.endDate ? a.endDate.getTime() : 0;
        const bTime = b.endDate ? b.endDate.getTime() : 0;
        return sortOrder * (aTime - bTime);
      });

    default:
      return contracts;
  }
};

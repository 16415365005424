import React, { RefObject, useRef, useState } from 'react';
import { IFormulaSegment, IVariables } from '../../entity/types';

interface IFormulaBuilderInputContext {
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  segmentToDelete?: {
    segmentRef: RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  inputRef: RefObject<HTMLDivElement>;
  enteredConstantFrom: 'left' | 'right' | undefined;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  updatedFormula: IFormulaSegment[];
  variables: IVariables;
}

export const FormulaBuilderInputContext = React.createContext({} as IFormulaBuilderInputContext);

export const FormulaBuilderInputProvider = ({
  children,
  value,
  setValue,
  updatedFormula,
  variables,
}: {
  children: React.ReactNode;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  updatedFormula: IFormulaSegment[];
  variables: IVariables;
}): React.ReactNode => {
  const [inputPosition, setInputPosition] = useState(0);
  const [segmentToDelete, setSegmentToDelete] = useState<{
    segmentRef: RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  }>();
  const [enteredConstantFrom, setEnteredConstantFrom] = useState<'left' | 'right' | undefined>();

  const inputRef = useRef<HTMLDivElement>(null);

  return (
    <FormulaBuilderInputContext.Provider
      value={{
        inputPosition,
        setInputPosition,
        segmentToDelete,
        setSegmentToDelete,
        inputRef,
        enteredConstantFrom,
        setEnteredConstantFrom,
        value,
        setValue,
        updatedFormula,
        variables,
      }}
    >
      {children}
    </FormulaBuilderInputContext.Provider>
  );
};

export default FormulaBuilderInputProvider;

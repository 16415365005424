import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { IExpense } from '../types';
import { grayOutByDate } from './grayOutByDate';

export const filterExpenses = ({
  expenses,
  search,
  lockedDate,
  tagParam,
  frequencyParam,
  positions,
}: {
  expenses: IExpense[];
  search: string;
  lockedDate?: Date;
  tagParam: string | null;
  frequencyParam: string | null;
  positions: IPositionDetails[];
}): IExpense[] => {
  let filteredExpenses = expenses.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  if (tagParam && tagParam !== 'all') {
    filteredExpenses = filteredExpenses.filter(({ context }) => context.tag === tagParam);
  }

  if (frequencyParam && frequencyParam !== 'all') {
    filteredExpenses = filteredExpenses.filter(({ context }) => context.frequency === frequencyParam);
  }

  if (lockedDate) {
    filteredExpenses = grayOutByDate({
      expenses: filteredExpenses,
      lockedDate,
      positions,
    });
  }

  return filteredExpenses;
};

import React from 'react';
import useFormulaContext from '../useFormulaContext';
import Button from '~/components/Button';
import useTableContext from '~/components/FormulasTable/hooks/useTableContext';

const ManageGroupsFooter = (): React.ReactElement | null => {
  const { filteredFormulasData, setManageGroupsModalOpen } = useFormulaContext();
  const { columnWidths } = useTableContext();
  const isLoading = Object.keys(filteredFormulasData).length === 0;
  if (isLoading) return null;

  const fullWidth = columnWidths.reduce((acc, curr) => acc + curr, 0);
  return (
    <div className="px-5 pt-2" style={{ width: fullWidth }}>
      <Button fill="clear" className="!w-auto !px-2 sticky left-0" onClick={() => setManageGroupsModalOpen(true)}>
        Manage Groups
      </Button>
    </div>
  );
};

export default ManageGroupsFooter;

import { useState, useMemo, KeyboardEvent } from 'react';
import { IFormula } from '~/pages/FinancialModelDeprecated/entity/types';

interface UseFormulaInputProps {
  formulaList: IFormula[];
  inputValue: string;
  onSelectAttribute: (attribute: IFormula) => void;
  showFormulaOnEquals?: boolean;
}

export function useFormulaInput({
  formulaList,
  inputValue,
  onSelectAttribute,
  showFormulaOnEquals = false,
}: UseFormulaInputProps): {
  filteredFormulaList: IFormula[];
  highlightedFormula: {
    formula?: IFormula;
    index: number;
  };
  setHighlightedFormula: React.Dispatch<
    React.SetStateAction<{
      formula?: IFormula;
      index: number;
    }>
  >;
  handleKeyDown: (e: KeyboardEvent<Element>) => void;
  onSelectAttribute: (attribute: IFormula) => void;
} {
  const [highlightedFormula, setHighlightedFormula] = useState<{
    formula?: IFormula;
    index: number;
  }>({ formula: undefined, index: 0 });

  const filteredFormulaList = useMemo(() => {
    if (!inputValue.length) return [];

    const trimmedInput = inputValue.trim().toLowerCase();
    const exactMatch = formulaList.find((formula) => formula.recipe.name.toLowerCase() === trimmedInput);

    if (exactMatch && !showFormulaOnEquals) {
      return [];
    }

    return formulaList.filter((formula) => formula.recipe.name.toLowerCase().includes(trimmedInput));
  }, [inputValue, formulaList, showFormulaOnEquals]);

  const traverseFormulaListDown = (e: KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          inputValue.length && prev.index < filteredFormulaList.length - 1
            ? filteredFormulaList[prev.index + 1]
            : filteredFormulaList[0],
        index: inputValue.length && prev.index < filteredFormulaList.length - 1 ? prev.index + 1 : 0,
      }));
    }
  };

  const traverseFormulaListUp = (e: KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList.length) {
      setHighlightedFormula((prev) => ({
        formula:
          inputValue.length && prev.index > 0
            ? filteredFormulaList[prev.index - 1]
            : filteredFormulaList[filteredFormulaList.length - 1],
        index: inputValue.length && prev.index > 0 ? prev.index - 1 : filteredFormulaList.length - 1,
      }));
    }
  };

  const chooseFormula = (e: KeyboardEvent<Element>): void => {
    e.preventDefault();
    if (filteredFormulaList[highlightedFormula.index]) {
      onSelectAttribute(filteredFormulaList[highlightedFormula.index]);
      setHighlightedFormula({
        formula: filteredFormulaList[0],
        index: 0,
      });
    }
  };

  const handleKeyDown = (e: KeyboardEvent<Element>): void => {
    if (e.key === 'ArrowDown') {
      traverseFormulaListDown(e);
    } else if (e.key === 'ArrowUp') {
      traverseFormulaListUp(e);
    } else if (e.key === 'Enter') {
      chooseFormula(e);
    }
  };

  return {
    filteredFormulaList,
    highlightedFormula,
    setHighlightedFormula,
    handleKeyDown,
    onSelectAttribute,
  };
}

import React, { ForwardedRef } from 'react';
import CurrencyInput from 'react-currency-input-field';

interface Props {
  id: string;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  disabled?: boolean;
  className: string;
  onKeyDown?: (event: React.KeyboardEvent) => void;
  required?: boolean;
  showError?: boolean;
  ref?: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>;
}

const BaseCurrencyInput = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      placeholder = 'Please enter an amount',
      value,
      onChange,
      onBlur,
      disabled = false,
      className,
      onKeyDown,
      required = false,
      showError = false,
    },
    ref,
  ): React.ReactElement => {
    return (
      <CurrencyInput
        autoComplete="off"
        id={id}
        data-testid={`${id}-input`}
        name={id}
        disabled={disabled}
        required={required}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        ref={ref}
        className={`${className} w-full p-[0.57rem] h-[42px] border border-solid border-gray-300 disabled:bg-neutral-25 disabled:text-neutral-75 
        rounded shadow-sm focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white 
        focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300${
          showError ? ' border-red-300' : ''
        }`}
        placeholder={placeholder}
        defaultValue={value}
        value={value}
        decimalsLimit={2}
        onValueChange={(val) => onChange(val ?? '')}
      />
    );
  },
);

BaseCurrencyInput.displayName = 'BaseCurrencyInput';

export default BaseCurrencyInput;

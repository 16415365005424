import React from 'react';

const TooltipImg = (): React.ReactElement => {
  return (
    <svg width="247" height="172" viewBox="0 0 247 172" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="126.918" cy="85.9999" r="79.1591" fill="#F8F9F6" />
      <path
        d="M59.8049 39.443C59.8049 34.9848 63.419 31.3707 67.8772 31.3707H188.941C193.399 31.3707 197.013 34.9848 197.013 39.443V106.923C197.013 111.382 193.399 114.996 188.941 114.996H67.8772C63.419 114.996 59.8049 111.382 59.8049 106.923V39.443Z"
        fill="#DFE5DC"
      />
      <path
        d="M56.2726 36.0722C56.2726 31.8927 59.6608 28.5045 63.8403 28.5045H185.958C190.138 28.5045 193.526 31.8927 193.526 36.0722V104.195C193.526 108.375 190.138 111.763 185.958 111.763H63.8403C59.6608 111.763 56.2726 108.375 56.2726 104.195V36.0722Z"
        fill="white"
        stroke="#C6C6C6"
        strokeWidth="1.00903"
      />
      <line x1="71.1042" y1="45.5616" x2="177.843" y2="45.5616" stroke="#F3F3F3" strokeWidth="0.830966" />
      <line x1="71.1042" y1="69.1565" x2="177.843" y2="69.1565" stroke="#F3F3F3" strokeWidth="0.830966" />
      <line x1="71.1042" y1="94.9986" x2="177.843" y2="94.9986" stroke="#F3F3F3" strokeWidth="0.830966" />
      <path
        d="M78.7916 92.7679C102.358 92.7679 101.803 78.9175 133.356 76.6523C162.322 74.5729 160.301 61.0566 179.836 59.4971"
        stroke="#DFE5DC"
        strokeWidth="1.66193"
        strokeLinecap="round"
      />
      <path d="M93.5756 89.7961L93.5756 120.132" stroke="#64755C" />
      <path d="M127.283 120.132L127.283 70.6955" stroke="#64755C" />
      <path d="M160.99 57.2128L160.99 121.256" stroke="#64755C" />
      <path
        d="M78.7916 92.7673C102.358 92.7673 101.803 73.2742 133.356 70.0862C162.322 67.1596 160.301 48.1367 179.836 45.9418"
        stroke="#AAB7A4"
        strokeWidth="1.66193"
        strokeLinecap="round"
      />
      <circle cx="160.99" cy="121.509" r="2" fill="#64755C" stroke="#64755C" />
      <circle cx="127.386" cy="121.509" r="2" fill="#64755C" stroke="#64755C" />
      <circle cx="93.5756" cy="121.509" r="2" fill="#64755C" stroke="#64755C" />
      <rect
        x="81.2365"
        y="130.434"
        width="92.299"
        height="31.9038"
        rx="3.56612"
        fill="white"
        stroke="#C6C6C6"
        strokeWidth="0.867767"
      />
      <rect x="88.8026" y="138" width="10.9899" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="103.232" y="138" width="17.5865" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="124.259" y="138" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="133.33" y="138" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="142.402" y="138" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="151.473" y="138" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="160.545" y="138" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="88.8026" y="145" width="10.9899" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="103.232" y="145" width="17.5865" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="124.259" y="145" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="133.33" y="145" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="142.402" y="145" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="151.473" y="145" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="160.545" y="145" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="88.8026" y="152" width="10.9899" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="103.232" y="152" width="17.5865" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="124.259" y="152" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="133.33" y="152" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="142.402" y="152" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="151.473" y="152" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
      <rect x="160.545" y="152" width="5.63144" height="3" rx="1.5" fill="#AAB7A4" />
    </svg>
  );
};

export default TooltipImg;

import React from 'react';
import { IColumn } from './types';
import { IFormulaGroupData } from '../../pages/FinancialModel/ModelBuilderContext';
import useTableContext from './hooks/useTableContext';
import DraggableItem from './DraggableItem';
import { DndContext, DragOverlay } from '@dnd-kit/core';

interface IProps {
  tableData: IFormulaGroupData[];
  columns: IColumn[];
  groupComponent: React.ComponentType<{
    uuid: string;
    name: string;
    isCollapsed: boolean;
    count: number;
    index: number;
  }>;
}

export enum IRecordTypeEnum {
  Group = 'group',
  Formula = 'formula',
}

const TableBody = ({ columns, tableData, groupComponent: Group }: IProps): React.ReactElement => {
  const { columnWidths, draggingId, handleDragStart, handleDragOver, handleDragEnd } = useTableContext();

  // Calculate total width for the container
  const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);

  return (
    <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart} onDragOver={handleDragOver}>
      {tableData.map((data, rowIndex): React.ReactNode => {
        return (
          <div key={data.name} style={{ width: `${totalWidth}px`, minWidth: '100%' }}>
            <Group
              key={data.name}
              uuid={data.uuid}
              name={data.name}
              isCollapsed={data.isCollapsed}
              count={data.formulas.length}
              index={rowIndex}
            />
            {!data.isCollapsed &&
              data.formulas.map((formula) => {
                return (
                  <DraggableItem
                    key={formula.formulaUuid}
                    id={formula.formulaUuid}
                    className="relative group border-b border-neutral-50"
                  >
                    {columns.map(({ key, Cell }, columnIndex) => {
                      const columnWidth = columnWidths[columnIndex] ?? 0;
                      return (
                        <Cell
                          key={key}
                          rowIndex={rowIndex}
                          columnIndex={columnIndex}
                          columnWidth={columnWidth}
                          data={formula}
                        />
                      );
                    })}
                  </DraggableItem>
                );
              })}
          </div>
        );
      })}
      <DragOverlay dropAnimation={null}>
        {draggingId ? (
          <div className="h-[44px] w-[300px] bg-gradient-to-r from-white to-transparent flex items-center cursor-grab">
            {tableData.reduce((result: string | null, group) => {
              if (result) return result;
              const formula = group.formulas.find((formula) => formula.formulaUuid === draggingId);
              return formula?.label.name ?? null;
            }, null)}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default TableBody;

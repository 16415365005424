import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import InputWrapper from '~/components/Input/InputWrapper';

interface Props {
  buttonVal?: string;
  jobTitle?: Types.InputState;
  setJobTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  updateCalculationModifier: () => void;
}

const HeadcountOptions = ({
  buttonVal,
  jobTitle,
  setJobTitle,
  updateCalculationModifier,
}: Props): React.ReactElement => {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            as="div"
            className="flex flex-row gap-x-1 items-center cursor-pointer !w-fit text-neutral-200 hover:text-neutral-400"
          >
            {buttonVal}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? 'block' : 'hidden'
            } absolute bg-white shadow-md rounded-md z-10 border-none outline-none`}
          >
            <div
              className="py-3 px-4"
              onClick={(e) => {
                e.stopPropagation();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
            >
              <InputWrapper
                id="jobTitle"
                label="Job Title Includes"
                state={jobTitle as Types.InputState}
                setState={setJobTitle}
                onKeyDown={(e) => {
                  e.stopPropagation();
                  if (e.key === 'Enter') {
                    updateCalculationModifier();
                    close();
                  }
                }}
                onBlur={() => {
                  updateCalculationModifier();
                }}
              />
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default HeadcountOptions;

import React, { useEffect, useState } from 'react';
import Modal from '../Modal';
import { IChangeLogActionEnum, IChangeLogElement, IChangeLogElementTypeEnum } from './entity/types';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { StatusCodes } from 'http-status-codes';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import ChangeLogElement from './components/ChangeLogElement';
import Skeleton from 'react-loading-skeleton';
import { IFormula } from '~/services/parallel/formulas.types';
import Select, { useSelect } from '../Select';
import Input, { useInput } from '../Input/InputWrapper';
import { filterChangeLog } from './utils/filterChangeLog';

const ChangeLogModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const { ratiosEnabled, contractsEnabled } = useSelector((state: State) => state.organization.configuration);
  const scenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [companyChangeLog, setCompanyChangeLog] = useState<IChangeLogElement[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formulaDictionary, setFormulaDictionary] = useState<Record<string, IFormula>>({});
  const [filteredChangeLog, setFilteredChangeLog] = useState<IChangeLogElement[]>([]);
  const [searchTitle, setSearchTitle] = useInput({
    validation: /.*/,
  });
  const [changeTypeFilter, setChangeTypeFilter] = useSelect({
    options: [
      { label: 'All', value: 'all' },
      { label: 'Created', value: IChangeLogActionEnum.Created },
      { label: 'Updated', value: IChangeLogActionEnum.Updated },
      { label: 'Deleted', value: IChangeLogActionEnum.Deleted },
    ],
  });
  const [entityTypeFilter, setEntityTypeFilter] = useSelect({
    options: [
      { label: 'All', value: 'all' },
      { label: 'Formula', value: IChangeLogElementTypeEnum.Formula },
      { label: 'Expense', value: IChangeLogElementTypeEnum.Expense },
      { label: 'Department', value: IChangeLogElementTypeEnum.Department },
      { label: 'Position', value: IChangeLogElementTypeEnum.Position },
      ...(ratiosEnabled ? [{ label: 'Ratio', value: IChangeLogElementTypeEnum.Ratio }] : []),
      ...(contractsEnabled ? [{ label: 'Contract', value: IChangeLogElementTypeEnum.Contract }] : []),
    ],
  });

  const fetchCompanyChangeLog = async ({
    organizationUuid,
    scenarioUuid,
  }: {
    organizationUuid: string;
    scenarioUuid: string | null;
  }): Promise<void> => {
    try {
      setLoading(true);
      const response = (await request({
        url: `/change-log`,
        method: 'GET',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
        params: {
          scenarioUuid,
        },
      })) as IAPIResponse<{ changeLog: IChangeLogElement[]; formulasDict: Record<string, IFormula> }>;

      if (response.status === StatusCodes.OK) {
        setCompanyChangeLog(response.data.data.changeLog);
        setFormulaDictionary(response.data.data.formulasDict);
      } else {
        toast.error('Failed to fetch company change log');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch company change log');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && organizationUuid) {
      fetchCompanyChangeLog({ organizationUuid, scenarioUuid });
    }
  }, [isOpen, organizationUuid, scenarioUuid]);

  useEffect(() => {
    setFilteredChangeLog(
      filterChangeLog({
        changeLog: companyChangeLog,
        changeTypeFilter: changeTypeFilter.selected?.value ?? 'all',
        entityTypeFilter: entityTypeFilter.selected?.value ?? 'all',
        searchTitle: searchTitle.value,
      }),
    );
  }, [companyChangeLog, changeTypeFilter, entityTypeFilter, searchTitle]);

  useEffect(() => {
    setEntityTypeFilter((prev) => ({
      ...prev,
      options: [
        ...prev.options.filter(
          (option) =>
            option.value !== IChangeLogElementTypeEnum.Ratio && option.value !== IChangeLogElementTypeEnum.Contract,
        ),
        ...(ratiosEnabled ? [{ label: 'Ratio', value: IChangeLogElementTypeEnum.Ratio }] : []),
        ...(contractsEnabled ? [{ label: 'Contract', value: IChangeLogElementTypeEnum.Contract }] : []),
      ],
    }));
  }, [ratiosEnabled, contractsEnabled]);

  const loadingSkeleton = (
    <>
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
      <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" />
    </>
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Change History" showClose size="xl">
      <div className="flex flex-col w-full h-[60vh] overflow-y-scroll hide-scrollbar mt-4">
        <div className="flex gap-2 mb-4">
          <div className="w-[250px] p-1">
            <Input
              state={searchTitle}
              setState={setSearchTitle}
              id="search-title"
              placeholder="Search by title"
              type="search"
            />
          </div>
          <div className="w-[250px] p-1">
            <Select
              state={changeTypeFilter}
              setState={setChangeTypeFilter}
              id="change-type-filter"
              placeholder="Filter by type"
            />
          </div>
          <div className="w-[250px] p-1">
            <Select
              state={entityTypeFilter}
              setState={setEntityTypeFilter}
              id="entity-type-filter"
              placeholder="Filter by entity"
            />
          </div>
        </div>
        <div className="flex flex-col max-h-[95%] overflow-y-scroll hide-scrollbar">
          {loading
            ? loadingSkeleton
            : filteredChangeLog.map((changeLog) => (
                <ChangeLogElement
                  key={changeLog.originalVersionUuid + changeLog.newVersionUuid + changeLog.action}
                  changeLogElement={changeLog}
                  formulaDictionary={formulaDictionary}
                />
              ))}
        </div>
      </div>
    </Modal>
  );
};

export default ChangeLogModal;

import React from 'react';

const EmptyShareLink = (): React.ReactElement => {
  return (
    <svg width="188" height="141" viewBox="0 0 188 141" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="97" cy="62.5" rx="62" ry="62.5" fill="#EAEFE8" />
      <g clipPath="url(#clip0_6093_13585)">
        <rect x="65" y="15" width="101.503" height="96" rx="5.49642" fill="white" />
        <rect x="147.154" y="20.5381" width="13.8462" height="5.53846" rx="0.916069" fill="#BFC9BA" />
        <path d="M74.2148 20.4961L71.4121 23.3171L72.2764 24.1871L75.9434 20.4961H74.2148Z" fill="#7F9376" />
        <path d="M77.6699 22.2295L74 25.9234H75.7285L78.5341 23.0994L77.6699 22.2295Z" fill="#7F9376" />
        <path d="M76.3738 20.9277L72.707 24.6184L73.5713 25.4884L77.2381 21.7977L76.3738 20.9277Z" fill="#7F9376" />
        <g clipPath="url(#clip1_6093_13585)">
          <rect x="71.4121" y="31.0312" width="26.7692" height="5.53846" rx="0.916069" fill="#BFC9BA" />
          <rect x="143.363" y="31.167" width="17.6343" height="5.2674" rx="0.916069" fill="#EAEFE8" />
        </g>
        <rect x="71.5266" y="39.4329" width="89.3562" height="47.1776" rx="3.54977" fill="white" />
        <rect
          x="71.5266"
          y="39.4329"
          width="89.3562"
          height="47.1776"
          rx="3.54977"
          stroke="#DFE5DC"
          strokeWidth="0.229017"
        />
        <path
          d="M147.965 76.8763L157.393 79.6369V81.4576H76.3883V80.2004V47.334L85.2176 52.8024L94.6945 56.5067L104.171 59.3291L112.118 61.9751L121.065 67.286L129.894 70.199L138.724 74.4058L147.965 76.8763Z"
          fill="url(#paint0_linear_6093_13585)"
        />
        <path
          d="M112.43 61.9911L104.166 59.2593L94.6912 56.4534L85.2168 52.7705L76.3896 47.334"
          stroke="#64755C"
          strokeWidth="0.343526"
        />
        <path
          d="M157.393 79.8546L147.997 77.034L138.781 74.4956L129.922 70.2648L121.108 67.3502L112.428 61.9912"
          stroke="#AAB7A4"
          strokeWidth="0.343526"
        />
        <line x1="112.25" y1="61.1543" x2="112.25" y2="63.2155" stroke="#64755C" strokeWidth="0.343526" />
        <rect
          x="-0.114509"
          y="0.114509"
          width="89.3562"
          height="58.1704"
          rx="3.54977"
          transform="matrix(-1 0 0 1 160.769 92.2207)"
          fill="white"
        />
        <rect
          x="-0.114509"
          y="0.114509"
          width="89.3562"
          height="58.1704"
          rx="3.54977"
          transform="matrix(-1 0 0 1 160.769 92.2207)"
          stroke="#DFE5DC"
          strokeWidth="0.229017"
        />
        <path
          d="M84.4712 134.948L74.8438 138.32V140.543H157.564V139.008V98.8623L148.548 105.542L138.87 110.067L129.193 113.514L121.078 116.746L111.941 123.233L102.925 126.791L93.9083 131.93L84.4712 134.948Z"
          fill="url(#paint1_linear_6093_13585)"
        />
        <path
          d="M120.666 116.726L129.127 113.396L138.827 109.977L148.527 105.488L157.564 98.8623"
          stroke="#64755C"
          strokeWidth="0.343526"
        />
        <mask id="path-18-inside-1_6093_13585" fill="white">
          <path d="M91.7754 18.2061H113.99V28.2828H91.7754V18.2061Z" />
        </mask>
        <path
          d="M113.99 28.0538H91.7754V28.5118H113.99V28.0538Z"
          fill="#7F9376"
          mask="url(#path-18-inside-1_6093_13585)"
        />
        <g clipPath="url(#clip2_6093_13585)">
          <path
            d="M104.098 21.3838C103.901 21.3838 103.741 21.544 103.741 21.7416V24.7475C103.741 24.9451 103.901 25.1053 104.098 25.1053H104.241C104.439 25.1053 104.599 24.9451 104.599 24.7475V21.7416C104.599 21.544 104.439 21.3838 104.241 21.3838H104.098Z"
            fill="#7F9376"
          />
          <path
            d="M102.452 22.6004C102.452 22.4028 102.612 22.2426 102.81 22.2426H102.953C103.151 22.2426 103.311 22.4028 103.311 22.6004V24.7475C103.311 24.9451 103.151 25.1053 102.953 25.1053H102.81C102.612 25.1053 102.452 24.9451 102.452 24.7475V22.6004Z"
            fill="#7F9376"
          />
          <path
            d="M101.164 23.4593C101.164 23.2616 101.324 23.1014 101.522 23.1014H101.665C101.863 23.1014 102.023 23.2616 102.023 23.4593V24.7475C102.023 24.9451 101.863 25.1053 101.665 25.1053H101.522C101.324 25.1053 101.164 24.9451 101.164 24.7475V23.4593Z"
            fill="#7F9376"
          />
        </g>
        <mask id="path-21-inside-2_6093_13585" fill="white">
          <path d="M113.99 18.2061H136.205V28.2828H113.99V18.2061Z" />
        </mask>
        <path
          d="M136.205 28.0538H113.99V28.5118H136.205V28.0538Z"
          fill="#E6E6E6"
          mask="url(#path-21-inside-2_6093_13585)"
        />
        <g clipPath="url(#clip3_6093_13585)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.094 22.0278C123.094 21.8301 123.254 21.6699 123.452 21.6699H126.744C126.941 21.6699 127.102 21.8301 127.102 22.0278V24.4611C127.102 24.6587 126.941 24.8189 126.744 24.8189H123.452C123.254 24.8189 123.094 24.6587 123.094 24.4611V22.0278ZM126.815 22.7434C126.815 22.7039 126.783 22.6719 126.744 22.6719H125.312C125.273 22.6719 125.241 22.7039 125.241 22.7434V23.0297C125.241 23.0692 125.273 23.1013 125.312 23.1013H126.744C126.783 23.1013 126.815 23.0692 126.815 23.0297V22.7434ZM126.815 23.4591C126.815 23.4196 126.783 23.3876 126.744 23.3876H125.312C125.273 23.3876 125.241 23.4196 125.241 23.4591V23.7454C125.241 23.7849 125.273 23.817 125.312 23.817H126.744C126.783 23.817 126.815 23.7849 126.815 23.7454V23.4591ZM126.815 24.1748C126.815 24.1353 126.783 24.1032 126.744 24.1032H125.312C125.273 24.1032 125.241 24.1353 125.241 24.1748V24.4611C125.241 24.5006 125.273 24.5326 125.312 24.5326H126.744C126.783 24.5326 126.815 24.5006 126.815 24.4611V24.1748ZM124.883 24.5326C124.922 24.5326 124.955 24.5006 124.955 24.4611V24.1748C124.955 24.1353 124.922 24.1032 124.883 24.1032H123.452C123.412 24.1032 123.38 24.1353 123.38 24.1748V24.4611C123.38 24.5006 123.412 24.5326 123.452 24.5326H124.883ZM123.452 23.817H124.883C124.922 23.817 124.955 23.7849 124.955 23.7454V23.4591C124.955 23.4196 124.922 23.3876 124.883 23.3876H123.452C123.412 23.3876 123.38 23.4196 123.38 23.4591V23.7454C123.38 23.7849 123.412 23.817 123.452 23.817ZM123.452 23.1013H124.883C124.922 23.1013 124.955 23.0692 124.955 23.0297V22.7434C124.955 22.7039 124.922 22.6719 124.883 22.6719H123.452C123.412 22.6719 123.38 22.7039 123.38 22.7434V23.0297C123.38 23.0692 123.412 23.1013 123.452 23.1013Z"
            fill="#7F9376"
          />
        </g>
      </g>
      <rect x="65.5" y="15.5" width="100.503" height="95" rx="4.99642" stroke="#DFE5DC" />
      <rect x="23" y="49" width="29" height="29" rx="14.5" fill="#BFC9BA" />
      <path
        d="M38.3778 60.8149C38.7333 60.9847 39.0664 61.2168 39.3609 61.5112C40.7521 62.9024 40.7521 65.1581 39.3609 66.5493L35.7984 70.1118C34.4071 71.5031 32.1515 71.5031 30.7602 70.1118C29.369 68.7206 29.369 66.4649 30.7602 65.0737L32.1512 63.6828M42.7199 63.1903L44.1109 61.7993C45.5021 60.4081 45.5021 58.1524 44.1109 56.7612C42.7196 55.37 40.464 55.37 39.0727 56.7612L35.5102 60.3237C34.119 61.7149 34.119 63.9706 35.5102 65.3618C35.8047 65.6563 36.1378 65.8884 36.4933 66.0582"
        stroke="white"
        strokeWidth="1.22091"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_6093_13585"
          x1="76.3883"
          y1="42.8607"
          x2="76.3883"
          y2="81.4576"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64755C" stopOpacity="0.22" />
          <stop offset="1" stopColor="#64755C" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6093_13585"
          x1="157.564"
          y1="93.3983"
          x2="157.564"
          y2="140.543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#64755C" stopOpacity="0.22" />
          <stop offset="1" stopColor="#64755C" stopOpacity="0.01" />
        </linearGradient>
        <clipPath id="clip0_6093_13585">
          <rect x="65" y="15" width="101.503" height="96" rx="5.49642" fill="white" />
        </clipPath>
        <clipPath id="clip1_6093_13585">
          <rect width="89.5853" height="11.0349" fill="white" transform="translate(71.4121 28.2832)" />
        </clipPath>
        <clipPath id="clip2_6093_13585">
          <rect width="4.58035" height="4.58035" fill="white" transform="translate(100.592 20.9541)" />
        </clipPath>
        <clipPath id="clip3_6093_13585">
          <rect width="4.58035" height="4.58035" fill="white" transform="translate(122.807 20.9541)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyShareLink;

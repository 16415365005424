import React, { useState } from 'react';
import { IChangeLogElement, IChangeLogElementTypeEnum } from '../entity/types';
import Typography from '~/components/Typography';
import { format } from 'date-fns/format';
import Divider from '~/components/Divider';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import { IFormula } from '~/services/parallel/formulas.types';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import ChangesTable from './ChangesTable';

const ChangeLogElement = ({
  changeLogElement,
  formulaDictionary,
}: {
  changeLogElement: IChangeLogElement;
  formulaDictionary: Record<string, IFormula>;
}): React.ReactElement => {
  const [showChanges, setShowChanges] = useState<boolean>(false);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex gap-2 items-center cursor-pointer" onClick={() => setShowChanges(!showChanges)}>
        <ChevronRightIcon className={`w-4 h-4 ${showChanges && 'rotate-90'}`} />
        <span>
          {changeLogElement.type === IChangeLogElementTypeEnum.ChangeLogGroup ? (
            <Typography weight="bold">{changeLogElement.title} </Typography>
          ) : (
            <>
              <Typography weight="bold">
                {camelCaseToNormal(changeLogElement.action)} {camelCaseToNormal(changeLogElement.type)}:{' '}
              </Typography>
              <Typography>{changeLogElement.title} </Typography>
            </>
          )}
          <Typography color="secondary" className="italic">
            Changed by {changeLogElement.changedBy?.name ?? 'system'} on {format(changeLogElement.planDate, 'Pp')}
          </Typography>
        </span>
      </div>
      {showChanges && (
        <div className="flex flex-col gap-2 pl-6">
          {changeLogElement.changes.length ? (
            <ChangesTable changes={changeLogElement.changes} formulaDictionary={formulaDictionary} />
          ) : null}
          {changeLogElement.subElements.map((subElement: IChangeLogElement) => (
            <ChangeLogElement
              key={subElement.originalVersionUuid + subElement.newVersionUuid + subElement.action}
              changeLogElement={subElement}
              formulaDictionary={formulaDictionary}
            />
          ))}
        </div>
      )}
      <Divider className="mb-4" />
    </div>
  );
};

export default ChangeLogElement;

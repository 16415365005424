import serverRequest from '~/utils/request';
import { IConsolidatedReportCollection } from '~/pages/Dashboard/entity/types';
import { IAPIResponse } from '~/utils/types';
import store from '~/store';
import { ZReportCollection } from '~/pages/Dashboard/entity/schemas';
import combineReportsIntoOne from '~/pages/Dashboard/utils/combineReportsIntoOne';

export const fetchFilteredExpensesReport = async ({
  frequencyFilter,
  tagFilter,
}: {
  frequencyFilter: string;
  tagFilter: string;
}): Promise<IConsolidatedReportCollection> => {
  const {
    organization: { uuid: organizationUuid },
  } = store.getState();
  const scenarioUuid = store.getState().scenario.activeScenarioUuid;
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;

  const expenseFrequencyFilters = [frequencyFilter];
  const expenseTagFilters = [tagFilter];
  const expenseDriverFilters = ['all'];

  const response = (await serverRequest({
    url: `/reports`,
    method: 'GET',
    params: {
      include: ['filteredExpenses'],
      startDate: startDate,
      endDate: endDate,
      scenarioUuid: scenarioUuid ?? undefined,
      compareData: false,
      expenseFrequencyFilters,
      expenseTagFilters,
      expenseDriverFilters,
    },
    headers: { 'Organization-Uuid': organizationUuid },
  })) as IAPIResponse<unknown>;

  const parsedReports = ZReportCollection.parse(response.data.data);
  const consolidatedReports = combineReportsIntoOne({
    reports: [parsedReports],
  });
  return consolidatedReports;
};

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import { RadioInputState } from '~/components/RadioInput/RadioInput.types';
import { useRadioInput } from '~/components/RadioInput';
import { IRatio } from '../entity/types';

const useRatioFormState = (): {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  organizationUuid: string;
  positionNeeded: Types.InputState;
  setPositionNeeded: React.Dispatch<React.SetStateAction<Types.InputState>>;
  amount: Types.InputState;
  setAmount: React.Dispatch<React.SetStateAction<Types.InputState>>;
  drivenByValue: Types.InputState;
  setDrivenByValue: React.Dispatch<React.SetStateAction<Types.InputState>>;
  driverType: RadioInputState;
  setDriverType: React.Dispatch<React.SetStateAction<RadioInputState>>;
  resetFormState: () => void;
  setFormStateFromIRatio: (ratio: IRatio) => Promise<void>;
} => {
  const [errorMessage, setErrorMessage] = useState('');
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [positionNeeded, setPositionNeeded] = useInput({
    validation: /.+/,
    errorMessage: 'This is required',
  });
  const [amount, setAmount] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Amount is required',
  });
  const [drivenByValue, setDrivenByValue] = useInput({
    validation: /.+/,
    errorMessage: 'This is required',
  });
  const [driverType, setDriverType] = useRadioInput({
    options: [
      {
        value: 'modelAttribute',
        label: 'Model Attribute',
      },
      {
        value: 'position',
        label: 'Position',
      },
    ],
    selected: {
      value: 'modelAttribute',
      label: 'Model Attribute',
    },
  });

  const setFormStateFromIRatio = async (ratio: IRatio): Promise<void> => {
    setPositionNeeded({
      ...positionNeeded,
      value: ratio.drivenElement.title,
      valid: positionNeeded.validation.test(ratio.drivenElement.title),
    });
    setAmount({
      ...amount,
      value: ratio.drivingElements[0].value.toString(),
      valid: amount.validation.test(ratio.drivingElements[0].value.toString()),
    });
    setDrivenByValue({
      ...drivenByValue,
      value: ratio.drivingElements[0].title,
      valid: drivenByValue.validation.test(ratio.drivingElements[0].title),
    });
    setDriverType({
      ...driverType,
      selected: {
        value: ratio.drivingElements[0].type === 'position' ? 'position' : 'modelAttribute',
        label: ratio.drivingElements[0].type === 'position' ? 'Position' : 'Model Attribute',
      },
      valid: true,
    });
  };

  const resetFormState = (): void => {
    setPositionNeeded({
      ...positionNeeded,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setAmount({
      ...amount,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setDrivenByValue({
      ...drivenByValue,
      value: '',
      valid: false,
      pristine: true,
      touched: false,
      disabled: false,
    });
    setDriverType({
      ...driverType,
      selected: {
        value: 'modelAttribute',
        label: 'Model Attribute',
      },
    });
  };

  return {
    errorMessage,
    setErrorMessage,
    organizationUuid,
    positionNeeded,
    setPositionNeeded,
    amount,
    setAmount,
    drivenByValue,
    setDrivenByValue,
    driverType,
    setDriverType,
    resetFormState,
    setFormStateFromIRatio,
  };
};

export default useRatioFormState;

import { IExpense } from '../types';
import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { toZonedTime } from 'date-fns-tz';
import { isExpenseAppliedInMonth } from '~/pages/Expenses/utils/isExpenseAppliedInMonth';

export const grayOutByDate = ({
  expenses,
  lockedDate,
  positions,
}: {
  expenses: IExpense[];
  lockedDate: Date;
  positions: IPositionDetails[];
}): IExpense[] => {
  const utcLockedDate = toZonedTime(lockedDate, 'UTC');
  return expenses.map((expense) => {
    return {
      ...expense,
      isGrayedOut: !isExpenseAppliedInMonth({
        expense,
        month: utcLockedDate,
        positions,
      }),
    };
  });
};

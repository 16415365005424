import React, { ReactElement, useContext } from 'react';
import { CellContext } from '@tanstack/react-table';
import CreationInput from './CreationInput';
import CreationDepartment from './CreationDepartment';
import { IInitialValue } from '../Cell/entities/types';
import CreationDate from './CreationDate';
import CancelCreation from './CancelCreation';
import CreationPayRate from './CreationPayRate';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import toast from 'react-hot-toast';
import { fetchSpecificPositions } from '~/pages/Headcount/utils/fetchSpecificPositions';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { IPositionDetails } from '~/pages/Headcount/entity/types';

const Cell = ({
  cellContext,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const {
    inlineCreateTitle,
    setInlineCreateTitle,
    inlineCreateEmployeeName,
    setInlineCreateEmployeeName,
    inlineCreateSelectDepartment,
    setInlineCreateSelectDepartment,
    inlineCreateDate,
    setInlineCreateDate,
    inlineCreatePayRate,
    setInlineCreatePayRate,
    setPositions,
    setRenderedPositions,
    setPositionDataDict,
    setPositionActiveStateDict,
    clearInlineCreateForm,
    fetchedPositions,
  } = useContext(HeadcountContext);

  const handleBlur = async (): Promise<void> => {
    if (
      inlineCreateTitle.valid &&
      inlineCreateDate.valid &&
      inlineCreatePayRate.valid &&
      inlineCreateSelectDepartment.valid
    ) {
      const positionToCreate: {
        title: string;
        departmentUuid: string;
        payRate: {
          value: number;
          effectiveAt: string;
        }[];
        hireDate: string;
        employeeName?: string;
      } = {
        title: inlineCreateTitle.value,
        departmentUuid: inlineCreateSelectDepartment.selected?.value ?? '',
        payRate: [
          {
            value: Number(inlineCreatePayRate.value) * CENTS_PER_DOLLAR,
            effectiveAt: 'onHire',
          },
        ],
        hireDate: inlineCreateDate.value.startDate ?? '',
      };

      if (inlineCreateEmployeeName.value) {
        positionToCreate.employeeName = inlineCreateEmployeeName.value;
      }

      const createPositionResponse = (await request({
        method: 'POST',
        url: `/organizations/${organizationUuid}/positions`,
        body: positionToCreate,
        params: {
          scenarioUuid: activeScenarioUuid ?? undefined,
          numberToCreate: 1,
        },
      })) as IAPIResponse<IPositionDetails[]>;

      if (createPositionResponse.status === 201) {
        toast.success(() => <div data-testid="position-creation-successful">Position created</div>, {
          position: 'top-center',
        });

        const [createdPosition] = await fetchSpecificPositions({
          positionUuids: [createPositionResponse.data.data[0].positionUuid],
          organizationUuid,
          scenarioUuid: activeScenarioUuid ?? undefined,
        });

        fetchedPositions.push(createdPosition);

        setPositions((prev) => {
          const lastOrderedDate = prev.length > 0 ? new Date(prev[prev.length - 1].orderedDate) : new Date();
          const newOrderedDate = new Date(lastOrderedDate);
          newOrderedDate.setDate(lastOrderedDate.getDate() + 1);
          return [...prev, { ...createdPosition, orderedDate: newOrderedDate.toISOString().split('T')[0] }];
        });

        setRenderedPositions((prev) => {
          const lastOrderedDate = prev.length > 0 ? new Date(prev[prev.length - 1].orderedDate) : new Date();
          const newOrderedDate = new Date(lastOrderedDate);
          newOrderedDate.setDate(lastOrderedDate.getDate() + 1);
          return [...prev, { ...createdPosition, orderedDate: newOrderedDate.toISOString().split('T')[0] }];
        });

        setPositionDataDict((prev) => ({
          ...prev,
          [createdPosition.positionUuid]: {
            title: createdPosition.title,
            hireDate: createdPosition.hireDate,
            termDate: createdPosition.termDate,
            fullyBurdenedCost: createdPosition.fullyBurdenedCost,
            currentPayRate: createdPosition.currentPayRate,
            department: createdPosition.currentDepartment,
            employeeName: createdPosition.employeeName ?? '',
            isActive: createdPosition.isActive,
          },
        }));
        setPositionActiveStateDict((prev) => ({
          ...prev,
          [createdPosition.positionUuid]: createdPosition.isActive,
        }));
        clearInlineCreateForm();
      }
    } else {
      setInlineCreateTitle((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
      }));
      setInlineCreateEmployeeName((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
        valid: true,
      }));
      setInlineCreateSelectDepartment((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
      }));
      setInlineCreateDate((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
      }));
      setInlineCreatePayRate((prev) => ({
        ...prev,
        touched: true,
        pristine: false,
      }));
    }
  };

  switch (cellContext.column.id) {
    case 'payRate': {
      return <CreationPayRate cellContext={cellContext} handleBlur={handleBlur} />;
    }
    case 'departmentUuid': {
      return <CreationDepartment handleBlur={handleBlur} />;
    }
    case 'employeeName':
    case 'title': {
      return <CreationInput cellContext={cellContext} handleBlur={handleBlur} />;
    }
    case 'hireDate': {
      return <CreationDate cellContext={cellContext} handleBlur={handleBlur} />;
    }
    case 'options': {
      return <CancelCreation />;
    }
    default: {
      return <div />;
    }
  }
};

export default Cell;

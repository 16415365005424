import React, { useState, useEffect } from 'react';
import Typography from '~/components/Typography';

interface RepeatedFadeTextProps {
  startText: string;
  startTextColor: 'green' | 'blue';
  endText: string;
  endTextColor: 'green' | 'blue';
  fadeIntervalMS?: number;
  msBeforeStart?: number;
}

const RepeatedFadeText: React.FC<RepeatedFadeTextProps> = ({
  startText,
  startTextColor,
  endText,
  endTextColor,
  fadeIntervalMS = 500,
  msBeforeStart = 1000,
}) => {
  const [text, setText] = useState(startText);
  const [isFaded, setIsFaded] = useState(false);
  const [isEndTextVisible, setIsEndTextVisible] = useState(false);

  useEffect(() => {
    // Fade out the startText after the specified delay
    const startFadeTimeout = setTimeout(() => {
      setIsFaded(true);
      setTimeout(() => {
        setText(endText);
        setIsFaded(false);
        setIsEndTextVisible(true);
      }, 900);
    }, msBeforeStart);

    return () => clearTimeout(startFadeTimeout);
  }, [endText, msBeforeStart]);

  useEffect(() => {
    if (isEndTextVisible) {
      const fadeInterval = setInterval(() => {
        setIsFaded((prev) => !prev);
      }, fadeIntervalMS);

      return () => clearInterval(fadeInterval);
    }
    return () => {};
  }, [isEndTextVisible, fadeIntervalMS]);

  return (
    <div>
      <span className={`transition-all duration-[900ms] ease-in-out ${isFaded ? 'opacity-0' : 'opacity-100'}`}>
        <Typography weight="bold" size="2xl" color={isEndTextVisible ? endTextColor : startTextColor}>
          {text}
        </Typography>
      </span>
    </div>
  );
};

export default RepeatedFadeText;

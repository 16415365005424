import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useClickOutside from '~/utils/hooks/useClickOutside';
import ScenarioItemDeleteTooltip from './ScenarioItemDeleteTooltip';

interface ScenarioItemDeleteButtonProps {
  buttonContent: React.ReactNode;
  popoverContent: React.ReactNode;
  panelClassName?: string;
  clickPanelOpen?: boolean;
  tooltip?: {
    content: string;
    anchor?: 'top' | 'bottom';
  };
  onOpenChange?: (isOpen: boolean) => void;
}

const ScenarioItemDeleteButton: React.FC<ScenarioItemDeleteButtonProps> = ({
  buttonContent,
  popoverContent,
  panelClassName,
  clickPanelOpen,
  tooltip,
  onOpenChange,
}) => {
  const [isOpen, setIsOpen] = useState(clickPanelOpen ?? false);
  const buttonRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const handleClick = (): void => {
    setIsOpen(!isOpen);
    onOpenChange?.(!isOpen);
  };

  useEffect(() => {
    setIsOpen(clickPanelOpen ?? false);
  }, [clickPanelOpen]);

  useClickOutside(panelRef, () => {
    setIsOpen(false);
    onOpenChange?.(false);
  });

  useEffect(() => {
    if (isOpen && buttonRef.current && panelRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const panelRect = panelRef.current.getBoundingClientRect();

      const left = buttonRect.left + buttonRect.width / 2 - panelRect.width / 2;
      const top = buttonRect.top - panelRect.height - 10;

      panelRef.current.style.left = `${Math.max(0, left)}px`;
      panelRef.current.style.top = `${Math.max(0, top)}px`;
    }
  }, [isOpen]);

  const buttonElement = (
    <div ref={buttonRef} onClick={handleClick}>
      {buttonContent}
    </div>
  );

  return (
    <>
      {tooltip && !isOpen ? (
        <ScenarioItemDeleteTooltip content={tooltip.content} anchor={tooltip.anchor}>
          {buttonElement}
        </ScenarioItemDeleteTooltip>
      ) : (
        buttonElement
      )}
      {isOpen &&
        createPortal(
          <div ref={panelRef} className={`fixed z-50 ${panelClassName || ''}`}>
            {popoverContent}
          </div>,
          document.body,
        )}
    </>
  );
};

export default ScenarioItemDeleteButton;

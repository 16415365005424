import React from 'react';
import HoverPopover from '../HoverPopover';
import Typography from '../Typography';
import request from '~/utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { update, updateScenarioLoadingState } from '~/store/scenarioSlice';
import { StatusCodes } from 'http-status-codes';
import { closeConversationBox } from '~/store/chatSlice';
import logger from '~/utils/logger';
import { useRevalidator } from 'react-router-dom';

interface ICreateScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const EnterScenarioModeButton = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid, selectedScenarioUuids } = useSelector((state: State) => state.scenario);
  const revalidator = useRevalidator();

  const isDisabled = Boolean(activeScenarioUuid);

  const handleCreateScenario = async (type: 'dynamic' | 'static', title?: string): Promise<void> => {
    if (type === 'dynamic') {
      dispatch(updateScenarioLoadingState('creating'));
    }
    const response = (await request({
      method: 'POST',
      url: `/organizations/${organizationUuid}/scenarios`,
      body: {
        type,
        changeDescription: title,
      },
    })) as ICreateScenarioResponse;

    if (response.status === StatusCodes.CREATED) {
      dispatch(
        update({
          activeScenarioUuid: type === 'dynamic' ? response.data.data.uuid : null,
          activeScenarioData: response.data.data,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          scenarioMode: 'creating',
          scenarioLoadingState: type === 'dynamic' ? 'creating' : 'idle',
        }),
      );
      await revalidator.revalidate();
      dispatch(closeConversationBox());
      dispatch(updateScenarioLoadingState('idle'));
    } else {
      dispatch(updateScenarioLoadingState('idle'));
      logger.error(new Error('Failed to create scenario'));
    }
  };

  return (
    <HoverPopover
      buttonClassName={isDisabled ? 'cursor-default' : ''}
      buttonContent={
        <div
          onClick={() => handleCreateScenario('dynamic')}
          className={`flex border  py-2 px-2.5 rounded-lg items-center justify-center my-auto ${isDisabled ? 'bg-neutral-25 border-neutral-50 stroke-neutral-100 pointer-events-none' : 'hover:bg-blue-15 hover:border-blue-100 border-neutral-50 stroke-neutral-400 hover:stroke-blue-400'}`}
        >
          <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 13H11" strokeLinecap="round" strokeLinejoin="round" />
            <path
              d="M1.99998 11C6.95787 11 10 8 12 6"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeDasharray="3 3"
            />
            <circle cx="14" cy="3" r="1" strokeLinecap="round" strokeLinejoin="round" />
            <circle cx="14" cy="13" r="1" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      }
      panelContent={
        isDisabled ? null : (
          <Typography color="white" size="xs">
            Enter Scenario Mode
          </Typography>
        )
      }
      panelClassName={isDisabled ? '' : `!z-40 bg-black rounded-lg shadow py-2 px-3`}
    />
  );
};

export default EnterScenarioModeButton;

import React from 'react';
import Input from '~/components/Input/InputWrapper';
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';
import Button from '~/components/Button';
import LoadingSpinner from '~/components/Loader';

const ChatBox = ({
  message,
  setMessage,
  submitMessage,
  isLoading,
  isDisabled,
  chatBoxRef,
}: {
  message: Types.InputState;
  setMessage: React.Dispatch<React.SetStateAction<Types.InputState>>;
  submitMessage: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  chatBoxRef?: React.RefObject<HTMLTextAreaElement>;
}): React.ReactNode => {
  return (
    <div className="relative w-full" id="chatbox">
      <Input
        id="message"
        placeholder="Message Parallel"
        type="chat"
        state={message}
        setState={setMessage}
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            submitMessage();
          }
        }}
        chatBoxRef={chatBoxRef}
      />
      <Button
        id="send-chat-button"
        disabled={message.value.trim().length === 0 || isDisabled}
        className="absolute bottom-2 right-2 !rounded-lg !w-fit !p-2"
        onClick={submitMessage}
      >
        {isLoading ? <LoadingSpinner /> : <PaperAirplaneIcon className="w-5 h-auto" />}
      </Button>
    </div>
  );
};

export default ChatBox;

import { format } from 'date-fns';
import React from 'react';
import Typography from '~/components/Typography';

const CreatedAtCell = ({ createdAt }: { createdAt: Date }): React.ReactElement => {
  return (
    <div>
      <Typography size="sm">{format(createdAt, 'MM/dd/yyyy')}</Typography>
    </div>
  );
};

export default CreatedAtCell;

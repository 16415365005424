import React, { ReactElement, useMemo } from 'react';
import Button from '~/components/Button';
import FormulasTable from '~/components/FormulasTable';
import useFormulaContext from '../useFormulaContext';
import GroupRow from './ModelBuilderGroupRow';
import { IColumn } from '~/components/FormulasTable/types';
import Label from './TableColumns/Label';
import ModelBuilderExpression from './TableColumns/ModelBuilderExpression';
import MonthCell from './TableColumns/MonthCell/MonthCell';
import Typography from '~/components/Typography';
import ColumnResize from '~/components/FormulasTable/ColumnResize';
import SearchInput from './SearchInput';
import Footer from './ManageGroupsFooter';

const FinancialModelTable = (): React.ReactElement => {
  const { allFormulasData, filteredFormulasData, toggleGroupCollapse, onDragEnd, selectedMonths } = useFormulaContext();

  const isFullyCollapsed = useMemo(
    () => allFormulasData.filter((group) => group.formulas.length).every((group) => group.isCollapsed),
    [allFormulasData],
  );

  const columns: IColumn[] = useMemo(
    () => [
      {
        key: 'label',
        Header: ({ columnWidth }: { columnWidth: number; columnIndex: number }): React.ReactElement => (
          <div
            className={`flex sticky top-0 left-0 border-b bg-white border-neutral-50 pl-4 pt-2 z-20 border-r`}
            style={{
              width: `${columnWidth}px`,
              minWidth: `${columnWidth}px`,
              boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
            }}
          >
            <div className="flex w-fit gap-2 py-1">
              <SearchInput />
              <Button fill="clear" className="!w-auto !px-2" onClick={() => toggleGroupCollapse(!isFullyCollapsed)}>
                {isFullyCollapsed ? 'Open All' : 'Collapse All'}
              </Button>
            </div>
            <ColumnResize columnIndex={0} />
          </div>
        ),
        Cell: Label,
      },
      {
        key: 'formula',
        Cell: ModelBuilderExpression,
      },
      ...selectedMonths.map((month) => ({
        key: `month-${month}`,
        Header: ({ columnWidth, columnIndex }: { columnWidth: number; columnIndex: number }): ReactElement => {
          const LEADING_COLUMN_COUNT = 2;
          const month = selectedMonths[columnIndex - LEADING_COLUMN_COUNT];
          const showYearDivider = month.includes('Dec');
          return (
            <div
              className={`flex justify-end items-end py-2 bg-white relative border-b border-neutral-50 px-4${showYearDivider ? ' border-r' : ''}`}
              style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
            >
              <Typography size="xs" color="lightGray">
                {month.toUpperCase()}
              </Typography>
            </div>
          );
        },
        Cell: MonthCell,
      })),
    ],
    [selectedMonths, isFullyCollapsed],
  );

  return (
    <div className="relative max-w-full h-[calc(100vh-64px)] overflow-auto">
      <FormulasTable
        columns={columns}
        data={filteredFormulasData}
        groupComponent={GroupRow}
        onDragEnd={onDragEnd}
        footerComponent={Footer}
      />
    </div>
  );
};

export default FinancialModelTable;

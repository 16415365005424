import { IFormattingEnum } from '~/pages/FinancialModelDeprecated/entity/schemas';
import { PERCENT_TO_SNAP_TO } from './percentToSnapTo';

export const getBoundAdjustedValues = ({
  min,
  max,
  draggingValue,
  formatting,
  millisecondsRan,
}: {
  min: number;
  max: number;
  draggingValue: number;
  formatting: IFormattingEnum;
  millisecondsRan: number;
}): { max: number | null; min: number | null } => {
  let CUSHION_TO_ADD = 0.01; // The rate at which the scale of the graph increases
  if (millisecondsRan > 500 && millisecondsRan < 1000) {
    CUSHION_TO_ADD = 0.03;
  } else if (millisecondsRan > 1000 && millisecondsRan < 1500) {
    CUSHION_TO_ADD = 0.05;
  } else if (millisecondsRan > 1500 && millisecondsRan < 2000) {
    CUSHION_TO_ADD = 0.08;
  } else if (millisecondsRan > 2000 && millisecondsRan < 2500) {
    CUSHION_TO_ADD = 0.1;
  } else if (millisecondsRan > 2500) {
    CUSHION_TO_ADD = 0.15;
  }

  const largerValue = Math.max(Math.abs(max), Math.abs(min));
  const adjustmentVAlue = largerValue * CUSHION_TO_ADD;

  if (draggingValue + 1 >= max) {
    return {
      max: adjustmentVAlue > 100 || formatting === IFormattingEnum.Percent ? max + adjustmentVAlue : max + 100,
      min: null,
    };
  } else if (draggingValue - 1 <= min) {
    return {
      min: adjustmentVAlue > 100 || formatting === IFormattingEnum.Percent ? min - adjustmentVAlue : min - 100,
      max: null,
    };
  } else if (draggingValue === 0) {
    const graphRange = max - min;
    const snapToValue = graphRange * PERCENT_TO_SNAP_TO;
    const zeroIsInMax = Math.abs(max) < snapToValue;
    const zeroIsInMin = Math.abs(min) < snapToValue;
    if (Math.abs(max) > Math.abs(min) && zeroIsInMin) {
      return {
        min: adjustmentVAlue > 100 || formatting === IFormattingEnum.Percent ? min - adjustmentVAlue : min - 100,
        max: null,
      };
    } else if (zeroIsInMax) {
      return {
        max: adjustmentVAlue > 100 || formatting === IFormattingEnum.Percent ? max + adjustmentVAlue : max + 100,
        min: null,
      };
    }
  }

  return { max: null, min: null };
};

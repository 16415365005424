import React from 'react';
import { IFormulaSegment, IVariables } from '../entity/types';

const shiftIndexes = ({
  oldFormula,
  oldVariables,
}: {
  oldFormula: IFormulaSegment[];
  oldVariables: IVariables;
}): {
  formulaWithShiftedIndexes: IFormulaSegment[];
  variablesWithShiftedIndexes: IVariables;
} => {
  const formulaWithShiftedIndexes: IFormulaSegment[] = [];
  const variablesWithShiftedIndexes: IVariables = {};

  oldFormula.forEach((segment, index) => {
    const variableIndex = Object.keys(variablesWithShiftedIndexes).length + 1;

    if (segment.type !== 'operator') {
      variablesWithShiftedIndexes[`$${variableIndex}`] = oldVariables[segment.textValue];

      const updatedElement = React.cloneElement(segment.element, {
        formulaIndex: index,
        formulaTextValue: `$${variableIndex}`,
      });

      formulaWithShiftedIndexes.push({
        ...segment,
        element: updatedElement,
        textValue: `$${variableIndex}`,
      });
    } else {
      const updatedElement = React.cloneElement(segment.element, {
        formulaIndex: index,
      });

      formulaWithShiftedIndexes.push({
        ...segment,
        element: updatedElement,
      });
    }
  });

  return { formulaWithShiftedIndexes, variablesWithShiftedIndexes };
};

export default shiftIndexes;

import { SelectMultipleState, SelectMultipleType } from '~/components/SelectMultiple/SelectMultiple.types';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import Typography from '~/components/Typography';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import useSelectMultiple from './useSelectMultiple';
import useInput from '~/components/Input/useInput';
import InputWrapper from '../Input/InputWrapper';

interface Props {
  id: string;
  state: SelectMultipleState;
  setState: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  label?: string;
  className?: string;
  errorMessage?: string;
  placeholder?: string;
  disabled?: boolean;
  checkmark?: boolean;
  direction?: 'below' | 'above';
  required?: boolean;
  listBoxClassName?: string;
  onBlur?: () => void;
  includeSearch?: boolean;
}

const SelectMultiple = ({
  id,
  label,
  state,
  setState,
  className,
  errorMessage = 'Please make a selection',
  placeholder = 'None selected',
  disabled = false,
  checkmark = true,
  direction = 'below',
  required = false,
  listBoxClassName = '',
  onBlur,
  includeSearch = false,
}: Props): React.ReactNode => {
  const listboxRef = useRef<HTMLDivElement>(null);
  const showError = state.touched && !state.pristine && !state.valid;
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useInput({
    validation: /^.*$/,
  });

  let spanClass = 'truncate block truncate text-base';
  if (state.disabled) {
    spanClass += ' text-neutral-75';
  } else if (state.selected && state.selected.length > 0) {
    spanClass += ' text-neutral-900';
  } else {
    spanClass += ' text-neutral-200';
  }

  useEffect(() => {
    if (state.selected?.length === 1 && state.selected[0].value === 'ALL') {
      setAllSelected(true);
      setState((prevState) => ({
        ...prevState,
        options: state.options.map((option) => {
          return {
            ...option,
            selected: option.value === 'ALL',
            disabled: option.value !== 'ALL',
          };
        }),
      }));
    }
  }, [state.selected]);

  const handleChange = ({ selections }: { selections: SelectMultipleType[] }): void => {
    if (selections.some((selection) => selection.value === 'ALL') && !allSelected) {
      const selected = state.options.find((option) => option.value === 'ALL');
      const updatedOptions = state.options.map((option) => {
        return {
          ...option,
          selected: option.value !== 'ALL',
          disabled: option.value !== 'ALL',
        };
      });

      if (selected) {
        selected.disabled = false;
        setState((prevState) => ({
          ...prevState,
          options: updatedOptions,
          valid: true,
          pristine: false,
          touched: true,
          selected: [selected],
        }));
      }
      setAllSelected(true);
    } else if (selections.some((option) => option.value === 'ALL') && allSelected) {
      const options = state.options.map((option) => {
        return {
          ...option,
          disabled: false,
          selected: false,
        };
      });
      setState((prevState) => ({
        ...prevState,
        options,
        selected: [],
      }));
      setAllSelected(false);
    } else {
      const selected = state.options.filter((option) => selections.some(({ value }) => value === option.value));

      setState((prevState) => ({
        ...prevState,
        valid: true,
        pristine: false,
        touched: true,
        selected,
      }));
    }
  };

  const getListboxButtonText = ({
    selected,
    options,
  }: {
    selected?: SelectMultipleType[];
    options: SelectMultipleType[];
  }): string => {
    if (selected?.length === 0 || !selected) {
      return placeholder;
    }
    if (selected.length === options.length - 1) {
      return options.find((option) => option.value === 'ALL')?.label?.toString() ?? '';
    }
    return selected.map((item) => item.label).join(', ');
  };

  const getOptionTextColor = ({
    option,
    selected,
  }: {
    option: SelectMultipleType;
    selected?: SelectMultipleType[];
  }): 'primary' | 'green' | 'disabled' => {
    if (option.disabled) return 'disabled';
    if (selected?.some((selected) => selected.value === option.value)) return 'green';
    return 'primary';
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (listboxRef.current && !listboxRef.current.contains(event.target as Node)) {
        onBlur?.();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onBlur]);

  const filteredOptions = state.options.filter((option) =>
    option.label?.toString().toLowerCase().includes(searchTerm.value.toString().toLowerCase()),
  );

  return (
    <div className={`w-full ${className ?? ''}`} data-testid={id}>
      {label && (
        <div className="flex flex-row mb-1">
          <Typography tag="span" size="xs" className={`${state.disabled ? ' !text-neutral-75' : ''}`}>
            {label}
          </Typography>
          {required && (
            <Typography tag="span" size="2xs" className={`${state.disabled ? ' !text-neutral-75' : ''}`}>
              *
            </Typography>
          )}
        </div>
      )}
      <Listbox
        ref={listboxRef}
        disabled={state.disabled ?? disabled}
        value={state.selected}
        onChange={(selections: SelectMultipleType[]) => handleChange({ selections })}
        multiple
      >
        <div className="relative">
          <ListboxButton
            data-testid={`${id}-button`}
            className={`relative w-full max-w-full truncate cursor-default border border-solid ${
              showError ? 'border-red-300' : 'border-gray-300'
            }
            ${!state.selected?.length ? '!text-neutral-75' : ''}
            rounded shadow-sm bg-white disabled:bg-neutral-25 disabled:text-neutral-75 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-green-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-green-300 sm:text-sm`}
          >
            <span data-testid={`${id}-selection`} className={spanClass}>
              {getListboxButtonText({
                selected: state.selected,
                options: state.options,
              })}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon className="size-5 text-gray-400" aria-hidden="true" />
            </span>
          </ListboxButton>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <ListboxOptions
              className={`${
                direction === 'below' ? 'mt-1' : 'mt-[-286px]'
              } absolute max-h-60 z-20 rounded w-full overflow-auto bg-white text-base shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${listBoxClassName}`}
            >
              {includeSearch && (
                <div className="sticky pt-2 px-2 pb-1 top-0 bg-white z-10">
                  <InputWrapper
                    id={`${id}-search`}
                    type="search"
                    state={searchTerm}
                    setState={setSearchTerm}
                    placeholder="Search..."
                  />
                </div>
              )}
              {filteredOptions.map((option) => {
                const isSelected =
                  state.selected?.some(
                    (selected) => selected.value === option.value || (selected.value === 'ALL' && allSelected),
                  ) ?? false;

                if (option.value === 'divider') {
                  return (
                    <ListboxOption
                      key={option.value}
                      disabled
                      className="relative cursor-default select-none py-2 pl-3 pr-4 text-xs"
                      value={option}
                    >
                      <hr className="border-gray-300" />
                    </ListboxOption>
                  );
                }

                return (
                  <ListboxOption
                    data-testid={`${id}-option-${option.value}`}
                    key={option.value}
                    disabled={option.disabled}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2${
                        checkmark ? ' pl-10' : ' pl-3'
                      } pr-4${active ? ' bg-green-100 text-green-900' : ''}${
                        isSelected ? ' text-green-300' : ' text-gray-900'
                      }`
                    }
                    value={option}
                  >
                    <span className={`block truncate ${option.value ? 'font-medium' : 'font-normal'}`}>
                      <Typography
                        tag="span"
                        size="xs"
                        className={`block truncate${option.value ? ' font-medium' : ' font-normal'}`}
                        color={getOptionTextColor({
                          option,
                          selected: state.selected,
                        })}
                      >
                        {option.node ?? option.label}
                      </Typography>
                    </span>
                    {isSelected && checkmark && (
                      <span
                        className={`absolute inset-y-0 left-0 flex items-center pl-3 ${option.disabled ? 'text-neutral-100' : 'text-green-600'}`}
                      >
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    )}
                  </ListboxOption>
                );
              })}
            </ListboxOptions>
          </Transition>
          {showError && (
            <p className="text-red-500 text-xs italic p-1" data-testid={`${id}-select-error`}>
              {errorMessage}
            </p>
          )}
        </div>
      </Listbox>
    </div>
  );
};

export { useSelectMultiple };
export default SelectMultiple;

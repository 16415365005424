import React, { ReactNode } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import TableCell, { ICellValue, ISelectedCellState } from '~/components/LegacyTable/TableCell';

export interface RowProps {
  id: string;
  values: ICellValue[];
  isSelected?: boolean;
  className?: string;
  tableDataClassName?: string | string[];
  onClick?: () => void;
  columnAlignment?: ('center' | 'left' | 'right' | 'justify' | 'char' | undefined)[];
  expandedContent?: React.ReactNode;
  autoSpacing?: boolean;
  rowColor?: 'white' | 'green' | 'neutral' | 'inactive';
  widths?: number[] | string[];
  editMode?: boolean;
  selectedCell?: ISelectedCellState;
  setSelectedCell?: React.Dispatch<React.SetStateAction<ISelectedCellState | undefined>>;
  edits?: Record<string, { amount: number; departmentUuid: string; effectiveAt: Date }>;
  setEdits?: React.Dispatch<
    React.SetStateAction<Record<string, { amount: number; departmentUuid: string; effectiveAt: Date }>>
  >;
  headerLabels?: string | React.ReactNode[];
}

const TableRow = ({
  id,
  isSelected,
  className,
  tableDataClassName = '',
  onClick,
  values,
  columnAlignment,
  expandedContent,
  autoSpacing = true,
  rowColor = 'white',
  widths,
  editMode,
  selectedCell,
  setSelectedCell,
  edits,
  setEdits,
  headerLabels,
}: RowProps): React.ReactElement => {
  const [expanded, setExpanded] = React.useState(false);
  const rowBackgroundColor = {
    white: 'bg-white',
    green: 'bg-green-15',
    neutral: 'bg-neutral-15',
    inactive: 'bg-neutral-25',
  };

  return (
    <>
      <tr
        data-testid={`table-row-data-${id}`}
        className={`border-t table-row-data ${
          isSelected ? 'bg-green-15' : rowBackgroundColor[rowColor]
        }${onClick ? ' cursor-pointer hover:bg-gray-50' : ''} 
          
        ${className ? ` ${className}` : ''}`}
        onClick={onClick}
      >
        {values.map(
          (
            col: {
              value: string | number | boolean | ReactNode;
              onClickText?: () => void;
              onClickCell?: () => void;
              tdClassName?: string;
              metadata?: {
                departmentUuid?: string;
                effectiveAt?: Date;
                substring?: string | number;
              };
            },
            colIndex: number,
          ) => {
            return (
              <TableCell
                id={`table-cell-${id}-${colIndex}`}
                key={`table-cell-${id}-${colIndex}`}
                editMode={editMode}
                cell={{
                  ...col,
                  metadata: {
                    ...col.metadata,
                    departmentUuid: values[0].metadata?.departmentUuid,
                  },
                }}
                edits={edits}
                setEdits={setEdits}
                totalColumns={values.length}
                cellIndex={colIndex}
                tableDataClassName={tableDataClassName}
                columnAlignment={columnAlignment}
                autoSpacing={autoSpacing}
                width={widths?.[colIndex]}
                selectedCell={selectedCell}
                setSelectedCell={setSelectedCell}
                rowLabel={values[0].value as string}
                headerLabel={headerLabels?.[colIndex] as string}
              >
                {typeof col !== 'object' || React.isValidElement(col) ? col : undefined}
              </TableCell>
            );
          },
        )}
        {expandedContent && (
          <td data-testid={`table-expand-${id}`}>
            {expanded ? (
              <ChevronUpIcon className="size-6 cursor-pointer" onClick={() => setExpanded(!expanded)} />
            ) : (
              <ChevronDownIcon className="size-6 cursor-pointer" onClick={() => setExpanded(!expanded)} />
            )}
          </td>
        )}
      </tr>
      {expandedContent && (
        <tr>
          <td colSpan={values.length}>
            <div className={`overflow-hidden transition-max-height max-h-0 ${expanded && `!max-h-[1000px]`}`}>
              {expandedContent}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TableRow;

import React from 'react';
import Input from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import InputState = Types.InputState;
import { SelectState } from '~/components/Select/Select.types';
import SelectDepartment from '~/components/SelectDepartment';
import DatePicker from '~/components/DatePicker';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';

export enum IEditPositionFormFields {
  EMPLOYEE = 'employee',
  TITLE = 'title',
  DEPARTMENT = 'selectDepartment',
  HIREDATE = 'hireDate',
}

interface Props {
  onClose: () => void;
  employeeName: InputState;
  setEmployeeName: React.Dispatch<React.SetStateAction<InputState>>;
  title: InputState;
  setTitle: React.Dispatch<React.SetStateAction<InputState>>;
  selectDepartment: SelectState;
  setSelectDepartment: React.Dispatch<React.SetStateAction<SelectState>>;
  hireDate: IDatePickerState;
  setHireDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  errorMessage: string;
  createPosition: () => void;
  isSubmitting: boolean;
  fieldsToEdit: IEditPositionFormFields[];
}

const EditPositionForm = ({
  onClose,
  employeeName,
  setEmployeeName,
  title,
  setTitle,
  selectDepartment,
  setSelectDepartment,
  hireDate,
  setHireDate,
  errorMessage,
  createPosition,
  isSubmitting,
  fieldsToEdit,
}: Props): React.ReactNode => {
  return (
    <div className="container px-0">
      <div className="w-full flex flex-col">
        <div className="w-full flex flex-col gap-4 mb-2">
          {fieldsToEdit.includes(IEditPositionFormFields.EMPLOYEE) && (
            <Input
              id="employeeName"
              label="Employee"
              state={employeeName}
              setState={setEmployeeName}
              placeholder="Employee Name"
              optional
            />
          )}
          {fieldsToEdit.includes(IEditPositionFormFields.TITLE) && (
            <Input id="title" label="Title" state={title} setState={setTitle} placeholder="Title" required />
          )}
          {fieldsToEdit.includes(IEditPositionFormFields.DEPARTMENT) && (
            <SelectDepartment
              label="Department"
              departmentOptions={selectDepartment}
              setDepartmentOptions={setSelectDepartment}
              required
            />
          )}
          {fieldsToEdit.includes(IEditPositionFormFields.HIREDATE) && (
            <DatePicker id="hireDate" label="Hire Date" state={hireDate} setState={setHireDate} />
          )}
        </div>
      </div>
      <div className="flex flex-col mt-6 gap-5">
        {errorMessage && <div className="text-red-500 text-sm">{errorMessage}</div>}
        <div className="flex justify-between gap-5">
          <Button className="!w-auto !px-0" id="cancel-create-position" fill="clear" onClick={onClose}>
            Cancel
          </Button>
          <Button id="continue-create-position" onClick={createPosition} className="!w-auto" disabled={isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditPositionForm;

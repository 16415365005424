import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

interface Props {
  id?: string;
  baseColor?: 'white' | 'green';
  className?: string;
  height?: number;
  width?: number | 'full';
}

export const skeletonBaseColorStyles = {
  white: '#FFFFFF',
  green: '#F8F9F6',
};

const SkeletonComponent = ({ id, className, baseColor = 'white', height, width }: Props): React.ReactNode => (
  <SkeletonTheme baseColor={`${skeletonBaseColorStyles[baseColor]}`} highlightColor="#EAEFE8">
    <Skeleton containerTestId={id} className={className ?? ''} height={height} width={width} />
  </SkeletonTheme>
);

export default SkeletonComponent;

import { IAPIResponse } from './types';
import store from '~/store';
import { update } from '~/store/scenarioSlice';

export const handleCreateScenario = ({ response }: { response: IAPIResponse }): void => {
  const dispatch = store.dispatch;
  const scenarioState = store.getState().scenario;
  const organizationUuid = store.getState().organization.uuid;
  const userUuid = store.getState().user.uuid;
  if (scenarioState.activeScenarioUuid) return;
  const scenarioUuidHeader =
    typeof response.headers['scenario-uuid'] === 'string' ? response.headers['scenario-uuid'] : null;
  if (!scenarioUuidHeader) return;
  dispatch(
    update({
      ...scenarioState,
      activeScenarioUuid: scenarioUuidHeader,
      activeScenarioHasChanges: true,
      scenarioLoadingState: 'creating',
      activeScenarioData: {
        type: 'dynamic',
        uuid: scenarioUuidHeader,
        organizationUuid,
        changeDescription: 'Untitled Scenario',
        createdBy: userUuid,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        markedAsStaleAt: null,
      },
    }),
  );
};

import { useState, useEffect, useCallback } from 'react';
import pageData from './pageData';
import logger from '~/utils/logger';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const useRatioData = (): {
  loading: boolean;
  data: unknown | null;
  revalidate: () => void;
} => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unknown | null>(null);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);

  const fetchData = useCallback(async (): Promise<void> => {
    try {
      const result = await pageData();
      setData(result);
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
      throw Error('Failed to fetch page data');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, activeScenarioUuid]);

  return { loading, data, revalidate: fetchData };
};

export default useRatioData;

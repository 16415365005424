import jwtDecode from 'jwt-decode';
import { IAccessToken, ZAccessToken } from '~/services/parallel/accessToken.types';

export const useAccessTokenData = (): IAccessToken | null => {
  const rawAccessToken = localStorage.getItem('accessToken');
  if (!rawAccessToken) return null;

  const decodedToken = jwtDecode(rawAccessToken);
  return ZAccessToken.parse(decodedToken);
};

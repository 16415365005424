import React from 'react';
import HoverPopover from '~/components/HoverPopover';
import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';

const IsolatedPopover = (): React.ReactNode => {
  return (
    <HoverPopover
      buttonContent={<ViewfinderCircleIcon className="size-5" />}
      panelContent={
        <div className="flex flex-col bg-black text-nowrap justify-start">
          <Typography size="xs" weight="bold" color="white">
            Isolated Attribute
          </Typography>
          <Typography size="xs" color="white">
            This attribute is not used to
            <br />
            calculate any other attributes
          </Typography>
        </div>
      }
      panelClassName="!bg-black !shadow !rounded-md !py-2 !px-4"
      anchor="top"
    />
  );
};

export default IsolatedPopover;

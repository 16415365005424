export const mergeScenarioAnimationJson = (): object => {
  const jsonTemplate = {
    nm: 'Merging Scenario 1',
    ddd: 0,
    h: 100.5,
    w: 211.5,
    meta: { g: 'LottieFiles Figma v67' },
    layers: [
      {
        ty: 0,
        nm: 'Logo',
        sr: 1,
        st: 0,
        op: 73,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [42.19, 31.64],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [42.19, 31.64],
                t: 12,
              },
              { s: [56.25, 42.19], t: 72 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 12,
              },
              { s: [100, 100], t: 72 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [52.69, 50.25],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [52.69, 50.25],
                t: 12,
              },
              { s: [107.25, 51.19], t: 72 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 12,
              },
              { s: [0], t: 72 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 12,
              },
              { s: [100], t: 72 },
            ],
          },
        },
        w: 211.5,
        h: 100.5,
        refId: '1',
        ind: 1,
      },
      {
        ty: 0,
        nm: 'Logo',
        sr: 1,
        st: 0,
        op: 73,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [42.19, 31.64],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [42.19, 31.64],
                t: 12,
              },
              { s: [56.25, 42.19], t: 72 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 12,
              },
              { s: [100, 100], t: 72 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [159.56, 50.25],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [159.56, 50.25],
                t: 12,
              },
              { s: [107.25, 51.19], t: 72 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 12,
              },
              { s: [0], t: 72 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 12,
              },
              { s: [100], t: 72 },
            ],
          },
        },
        w: 211.5,
        h: 100.5,
        refId: '2',
        ind: 2,
      },
      {
        ty: 0,
        nm: 'Frame 102014',
        sr: 1,
        st: 0,
        op: 73,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: true,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [1080, 36],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [1080, 36],
                t: 12,
              },
              { s: [1080, 36], t: 72 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 12,
              },
              { s: [100, 100], t: 72 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [1080, 1386],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [1080, 1386],
                t: 12,
              },
              { s: [1080, 1386], t: 72 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 12,
              },
              { s: [0], t: 72 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 12,
              },
              { s: [0], t: 72 },
            ],
          },
        },
        masksProperties: [
          {
            nm: '',
            inv: false,
            mode: 'a',
            x: { a: 0, k: 0 },
            o: { a: 0, k: 100 },
            pt: {
              a: 1,
              k: [
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2160, 0],
                        [2160, 72],
                        [0, 72],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2160, 0],
                        [2160, 72],
                        [0, 72],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 12,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [2160, 0],
                        [2160, 72],
                        [0, 72],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 72,
                },
              ],
            },
          },
        ],
        w: 2160,
        h: 100.5,
        refId: '3',
        ind: 3,
      },
      {
        ty: 4,
        nm: 'page Bg',
        sr: 1,
        st: 0,
        op: 73,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [105.75, 50.25],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [105.75, 50.25],
                t: 12,
              },
              { s: [105.75, 50.25], t: 72 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100, 100],
                t: 12,
              },
              { s: [100, 100], t: 72 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [105.75, 50.25],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [105.75, 50.25],
                t: 12,
              },
              { s: [105.75, 50.25], t: 72 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [0],
                t: 12,
              },
              { s: [0], t: 72 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 0,
              },
              {
                o: { x: 0.84, y: 0.03 },
                i: { x: 0.47, y: 0.97 },
                s: [100],
                t: 12,
              },
              { s: [100], t: 72 },
            ],
          },
        },
        shapes: [
          {
            ty: 'sh',
            bm: 0,
            hd: false,
            nm: '',
            d: 1,
            ks: {
              a: 1,
              k: [
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 12,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 72,
                },
              ],
            },
          },
          {
            ty: 'fl',
            bm: 0,
            hd: false,
            nm: '',
            c: {
              a: 1,
              k: [
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [1, 1, 1],
                  t: 0,
                },
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [1, 1, 1],
                  t: 12,
                },
                { s: [1, 1, 1], t: 72 },
              ],
            },
            r: 1,
            o: {
              a: 1,
              k: [
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [100],
                  t: 0,
                },
                {
                  o: { x: 0.84, y: 0.03 },
                  i: { x: 0.47, y: 0.97 },
                  s: [100],
                  t: 12,
                },
                { s: [100], t: 72 },
              ],
            },
          },
        ],
        ind: 4,
      },
    ],
    v: '5.7.0',
    fr: 60,
    op: 72,
    ip: 0,
    assets: [
      {
        nm: '[Asset] Logo',
        id: '1',
        layers: [
          {
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [100], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.4981, 0.5765, 0.4628],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.4981, 0.5765, 0.4628],
                      t: 12,
                    },
                    { s: [0.4981, 0.5765, 0.4628], t: 72 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 12,
                    },
                    { s: [100], t: 72 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'Logo Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [100], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] Logo',
        id: '2',
        layers: [
          {
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [100], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 12,
                    },
                    { s: [0.353, 0.5177, 0.5883], t: 72 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 12,
                    },
                    { s: [100], t: 72 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'Logo Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [42.19, 31.64],
                    t: 12,
                  },
                  { s: [56.25, 42.19], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [100], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] Frame 102014',
        id: '3',
        layers: [
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [252, 18],
                    t: 0,
                  },
                  { s: [252, 18], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [490.5, 36],
                    t: 0,
                  },
                  { s: [490.5, 36], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.12, 27],
                            [0.91, 27],
                            [7.32, 9.55],
                            [9.5, 9.55],
                            [15.91, 27],
                            [13.69, 27],
                            [8.48, 12.31],
                            [8.34, 12.31],
                            [3.12, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.12, 27],
                            [0.91, 27],
                            [7.32, 9.55],
                            [9.5, 9.55],
                            [15.91, 27],
                            [13.69, 27],
                            [8.48, 12.31],
                            [8.34, 12.31],
                            [3.12, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.94, 20.18],
                            [12.87, 20.18],
                            [12.87, 22.06],
                            [3.94, 22.06],
                            [3.94, 20.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.94, 20.18],
                            [12.87, 20.18],
                            [12.87, 22.06],
                            [3.94, 22.06],
                            [3.94, 20.18],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.05, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.84, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [23.4, 32.18],
                            [20.89, 31.81],
                            [19.14, 30.84],
                            [18.05, 29.56],
                            [19.65, 28.43],
                            [20.34, 29.25],
                            [21.48, 30.06],
                            [23.4, 30.41],
                            [26.01, 29.64],
                            [27.05, 27.24],
                            [27.05, 24.58],
                            [26.87, 24.58],
                            [26.24, 25.47],
                            [25.09, 26.39],
                            [23.06, 26.8],
                            [20.21, 26.05],
                            [18.22, 23.86],
                            [17.5, 20.39],
                            [18.21, 16.88],
                            [20.18, 14.57],
                            [23.09, 13.74],
                            [25.12, 14.16],
                            [26.28, 15.12],
                            [26.91, 15.99],
                            [27.11, 15.99],
                            [27.11, 13.91],
                            [29.06, 13.91],
                            [29.06, 27.38],
                            [28.29, 30.12],
                            [26.24, 31.68],
                            [23.4, 32.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.05, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.84, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [23.4, 32.18],
                            [20.89, 31.81],
                            [19.14, 30.84],
                            [18.05, 29.56],
                            [19.65, 28.43],
                            [20.34, 29.25],
                            [21.48, 30.06],
                            [23.4, 30.41],
                            [26.01, 29.64],
                            [27.05, 27.24],
                            [27.05, 24.58],
                            [26.87, 24.58],
                            [26.24, 25.47],
                            [25.09, 26.39],
                            [23.06, 26.8],
                            [20.21, 26.05],
                            [18.22, 23.86],
                            [17.5, 20.39],
                            [18.21, 16.88],
                            [20.18, 14.57],
                            [23.09, 13.74],
                            [25.12, 14.16],
                            [26.28, 15.12],
                            [26.91, 15.99],
                            [27.11, 15.99],
                            [27.11, 13.91],
                            [29.06, 13.91],
                            [29.06, 27.38],
                            [28.29, 30.12],
                            [26.24, 31.68],
                            [23.4, 32.18],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [23.33, 24.99],
                            [25.37, 24.43],
                            [26.64, 22.84],
                            [27.08, 20.35],
                            [26.65, 17.86],
                            [25.39, 16.16],
                            [23.33, 15.55],
                            [21.2, 16.19],
                            [19.93, 17.93],
                            [19.51, 20.35],
                            [19.94, 22.76],
                            [21.22, 24.4],
                            [23.33, 24.99],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [23.33, 24.99],
                            [25.37, 24.43],
                            [26.64, 22.84],
                            [27.08, 20.35],
                            [26.65, 17.86],
                            [25.39, 16.16],
                            [23.33, 15.55],
                            [21.2, 16.19],
                            [19.93, 17.93],
                            [19.51, 20.35],
                            [19.94, 22.76],
                            [21.22, 24.4],
                            [23.33, 24.99],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.05, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.84, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [37.78, 32.18],
                            [35.28, 31.81],
                            [33.53, 30.84],
                            [32.43, 29.56],
                            [34.03, 28.43],
                            [34.72, 29.25],
                            [35.87, 30.06],
                            [37.78, 30.41],
                            [40.4, 29.64],
                            [41.43, 27.24],
                            [41.43, 24.58],
                            [41.26, 24.58],
                            [40.63, 25.47],
                            [39.47, 26.39],
                            [37.44, 26.8],
                            [34.6, 26.05],
                            [32.61, 23.86],
                            [31.88, 20.39],
                            [32.59, 16.88],
                            [34.56, 14.57],
                            [37.48, 13.74],
                            [39.5, 14.16],
                            [40.66, 15.12],
                            [41.29, 15.99],
                            [41.5, 15.99],
                            [41.5, 13.91],
                            [43.44, 13.91],
                            [43.44, 27.38],
                            [42.67, 30.12],
                            [40.63, 31.68],
                            [37.78, 32.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.05, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.84, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [37.78, 32.18],
                            [35.28, 31.81],
                            [33.53, 30.84],
                            [32.43, 29.56],
                            [34.03, 28.43],
                            [34.72, 29.25],
                            [35.87, 30.06],
                            [37.78, 30.41],
                            [40.4, 29.64],
                            [41.43, 27.24],
                            [41.43, 24.58],
                            [41.26, 24.58],
                            [40.63, 25.47],
                            [39.47, 26.39],
                            [37.44, 26.8],
                            [34.6, 26.05],
                            [32.61, 23.86],
                            [31.88, 20.39],
                            [32.59, 16.88],
                            [34.56, 14.57],
                            [37.48, 13.74],
                            [39.5, 14.16],
                            [40.66, 15.12],
                            [41.29, 15.99],
                            [41.5, 15.99],
                            [41.5, 13.91],
                            [43.44, 13.91],
                            [43.44, 27.38],
                            [42.67, 30.12],
                            [40.63, 31.68],
                            [37.78, 32.18],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [37.71, 24.99],
                            [39.76, 24.43],
                            [41.03, 22.84],
                            [41.46, 20.35],
                            [41.04, 17.86],
                            [39.78, 16.16],
                            [37.71, 15.55],
                            [35.58, 16.19],
                            [34.31, 17.93],
                            [33.9, 20.35],
                            [34.32, 22.76],
                            [35.6, 24.4],
                            [37.71, 24.99],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [37.71, 24.99],
                            [39.76, 24.43],
                            [41.03, 22.84],
                            [41.46, 20.35],
                            [41.04, 17.86],
                            [39.78, 16.16],
                            [37.71, 15.55],
                            [35.58, 16.19],
                            [34.31, 17.93],
                            [33.9, 20.35],
                            [34.32, 22.76],
                            [35.6, 24.4],
                            [37.71, 24.99],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.78, 0],
                            [-0.22, -0.01],
                            [-0.11, -0.01],
                            [0, 0],
                            [0.24, 0.03],
                            [0.27, 0],
                            [0.5, -0.27],
                            [0.29, -0.47],
                            [0, -0.59],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.65],
                            [0.63, -0.4],
                            [0.15, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.07, -0.02],
                            [-0.24, -0.04],
                            [-0.64, 0],
                            [-0.49, 0.26],
                            [-0.28, 0.46],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [46.88, 27],
                            [46.88, 13.91],
                            [48.83, 13.91],
                            [48.83, 15.89],
                            [48.96, 15.89],
                            [50.26, 14.31],
                            [52.37, 13.7],
                            [52.93, 13.71],
                            [53.43, 13.74],
                            [53.43, 15.78],
                            [52.96, 15.71],
                            [52.2, 15.65],
                            [50.5, 16.05],
                            [49.32, 17.14],
                            [48.89, 18.72],
                            [48.89, 27],
                            [46.88, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.78, 0],
                            [-0.22, -0.01],
                            [-0.11, -0.01],
                            [0, 0],
                            [0.24, 0.03],
                            [0.27, 0],
                            [0.5, -0.27],
                            [0.29, -0.47],
                            [0, -0.59],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.65],
                            [0.63, -0.4],
                            [0.15, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.07, -0.02],
                            [-0.24, -0.04],
                            [-0.64, 0],
                            [-0.49, 0.26],
                            [-0.28, 0.46],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [46.88, 27],
                            [46.88, 13.91],
                            [48.83, 13.91],
                            [48.83, 15.89],
                            [48.96, 15.89],
                            [50.26, 14.31],
                            [52.37, 13.7],
                            [52.93, 13.71],
                            [53.43, 13.74],
                            [53.43, 15.78],
                            [52.96, 15.71],
                            [52.2, 15.65],
                            [50.5, 16.05],
                            [49.32, 17.14],
                            [48.89, 18.72],
                            [48.89, 27],
                            [46.88, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.66, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [60.66, 27.27],
                            [57.4, 26.44],
                            [55.29, 24.09],
                            [54.56, 20.56],
                            [55.29, 17.01],
                            [57.36, 14.61],
                            [60.46, 13.74],
                            [62.48, 14.08],
                            [64.29, 15.19],
                            [65.6, 17.2],
                            [66.08, 20.28],
                            [66.08, 21.14],
                            [55.99, 21.14],
                            [55.99, 19.4],
                            [64.04, 19.4],
                            [63.6, 17.42],
                            [62.35, 16.05],
                            [60.46, 15.55],
                            [58.38, 16.14],
                            [57.04, 17.68],
                            [56.57, 19.7],
                            [56.57, 20.86],
                            [57.08, 23.38],
                            [58.52, 24.94],
                            [60.66, 25.47],
                            [62.1, 25.24],
                            [63.21, 24.56],
                            [63.94, 23.42],
                            [65.88, 23.97],
                            [64.85, 25.7],
                            [63.06, 26.86],
                            [60.66, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.66, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [60.66, 27.27],
                            [57.4, 26.44],
                            [55.29, 24.09],
                            [54.56, 20.56],
                            [55.29, 17.01],
                            [57.36, 14.61],
                            [60.46, 13.74],
                            [62.48, 14.08],
                            [64.29, 15.19],
                            [65.6, 17.2],
                            [66.08, 20.28],
                            [66.08, 21.14],
                            [55.99, 21.14],
                            [55.99, 19.4],
                            [64.04, 19.4],
                            [63.6, 17.42],
                            [62.35, 16.05],
                            [60.46, 15.55],
                            [58.38, 16.14],
                            [57.04, 17.68],
                            [56.57, 19.7],
                            [56.57, 20.86],
                            [57.08, 23.38],
                            [58.52, 24.94],
                            [60.66, 25.47],
                            [62.1, 25.24],
                            [63.21, 24.56],
                            [63.94, 23.42],
                            [65.88, 23.97],
                            [64.85, 25.7],
                            [63.06, 26.86],
                            [60.66, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [78.18, 16.84],
                            [76.37, 17.35],
                            [75.87, 16.47],
                            [74.98, 15.76],
                            [73.54, 15.48],
                            [71.54, 16.03],
                            [70.74, 17.42],
                            [71.29, 18.61],
                            [72.99, 19.33],
                            [74.94, 19.81],
                            [77.55, 21.11],
                            [78.41, 23.35],
                            [77.77, 25.36],
                            [75.98, 26.76],
                            [73.3, 27.27],
                            [69.99, 26.4],
                            [68.32, 23.86],
                            [70.23, 23.39],
                            [71.26, 24.97],
                            [73.27, 25.5],
                            [75.5, 24.9],
                            [76.34, 23.45],
                            [75.86, 22.31],
                            [74.39, 21.61],
                            [72.21, 21.1],
                            [69.57, 19.78],
                            [68.73, 17.52],
                            [69.36, 15.56],
                            [71.07, 14.22],
                            [73.54, 13.74],
                            [76.59, 14.59],
                            [78.18, 16.84],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [78.18, 16.84],
                            [76.37, 17.35],
                            [75.87, 16.47],
                            [74.98, 15.76],
                            [73.54, 15.48],
                            [71.54, 16.03],
                            [70.74, 17.42],
                            [71.29, 18.61],
                            [72.99, 19.33],
                            [74.94, 19.81],
                            [77.55, 21.11],
                            [78.41, 23.35],
                            [77.77, 25.36],
                            [75.98, 26.76],
                            [73.3, 27.27],
                            [69.99, 26.4],
                            [68.32, 23.86],
                            [70.23, 23.39],
                            [71.26, 24.97],
                            [73.27, 25.5],
                            [75.5, 24.9],
                            [76.34, 23.45],
                            [75.86, 22.31],
                            [74.39, 21.61],
                            [72.21, 21.1],
                            [69.57, 19.78],
                            [68.73, 17.52],
                            [69.36, 15.56],
                            [71.07, 14.22],
                            [73.54, 13.74],
                            [76.59, 14.59],
                            [78.18, 16.84],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [90.48, 16.84],
                            [88.67, 17.35],
                            [88.17, 16.47],
                            [87.28, 15.76],
                            [85.84, 15.48],
                            [83.84, 16.03],
                            [83.04, 17.42],
                            [83.59, 18.61],
                            [85.29, 19.33],
                            [87.24, 19.81],
                            [89.85, 21.11],
                            [90.71, 23.35],
                            [90.07, 25.36],
                            [88.28, 26.76],
                            [85.6, 27.27],
                            [82.29, 26.4],
                            [80.62, 23.86],
                            [82.53, 23.39],
                            [83.56, 24.97],
                            [85.57, 25.5],
                            [87.8, 24.9],
                            [88.63, 23.45],
                            [88.16, 22.31],
                            [86.69, 21.61],
                            [84.51, 21.1],
                            [81.87, 19.78],
                            [81.03, 17.52],
                            [81.65, 15.56],
                            [83.37, 14.22],
                            [85.84, 13.74],
                            [88.89, 14.59],
                            [90.48, 16.84],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [90.48, 16.84],
                            [88.67, 17.35],
                            [88.17, 16.47],
                            [87.28, 15.76],
                            [85.84, 15.48],
                            [83.84, 16.03],
                            [83.04, 17.42],
                            [83.59, 18.61],
                            [85.29, 19.33],
                            [87.24, 19.81],
                            [89.85, 21.11],
                            [90.71, 23.35],
                            [90.07, 25.36],
                            [88.28, 26.76],
                            [85.6, 27.27],
                            [82.29, 26.4],
                            [80.62, 23.86],
                            [82.53, 23.39],
                            [83.56, 24.97],
                            [85.57, 25.5],
                            [87.8, 24.9],
                            [88.63, 23.45],
                            [88.16, 22.31],
                            [86.69, 21.61],
                            [84.51, 21.1],
                            [81.87, 19.78],
                            [81.03, 17.52],
                            [81.65, 15.56],
                            [83.37, 14.22],
                            [85.84, 13.74],
                            [88.89, 14.59],
                            [90.48, 16.84],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [93.5, 27],
                            [93.5, 13.91],
                            [95.51, 13.91],
                            [95.51, 27],
                            [93.5, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [93.5, 27],
                            [93.5, 13.91],
                            [95.51, 13.91],
                            [95.51, 27],
                            [93.5, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                          ],
                          o: [
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [94.52, 11.73],
                            [93.51, 11.33],
                            [93.09, 10.36],
                            [93.51, 9.4],
                            [94.52, 9],
                            [95.53, 9.4],
                            [95.96, 10.36],
                            [95.53, 11.33],
                            [94.52, 11.73],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                          ],
                          o: [
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [94.52, 11.73],
                            [93.51, 11.33],
                            [93.09, 10.36],
                            [93.51, 9.4],
                            [94.52, 9],
                            [95.53, 9.4],
                            [95.96, 10.36],
                            [95.53, 11.33],
                            [94.52, 11.73],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [109.66, 13.91],
                            [104.82, 27],
                            [102.78, 27],
                            [97.93, 13.91],
                            [100.12, 13.91],
                            [103.73, 24.34],
                            [103.87, 24.34],
                            [107.48, 13.91],
                            [109.66, 13.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [109.66, 13.91],
                            [104.82, 27],
                            [102.78, 27],
                            [97.93, 13.91],
                            [100.12, 13.91],
                            [103.73, 24.34],
                            [103.87, 24.34],
                            [107.48, 13.91],
                            [109.66, 13.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.92, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [117.1, 27.27],
                            [113.83, 26.44],
                            [111.73, 24.09],
                            [110.99, 20.56],
                            [111.73, 17.01],
                            [113.79, 14.61],
                            [116.89, 13.74],
                            [118.91, 14.08],
                            [120.73, 15.19],
                            [122.03, 17.2],
                            [122.52, 20.28],
                            [122.52, 21.14],
                            [112.43, 21.14],
                            [112.43, 19.4],
                            [120.47, 19.4],
                            [120.03, 17.42],
                            [118.78, 16.05],
                            [116.89, 15.55],
                            [114.81, 16.14],
                            [113.47, 17.68],
                            [113.01, 19.7],
                            [113.01, 20.86],
                            [113.52, 23.38],
                            [114.96, 24.94],
                            [117.1, 25.47],
                            [118.53, 25.24],
                            [119.65, 24.56],
                            [120.37, 23.42],
                            [122.31, 23.97],
                            [121.28, 25.7],
                            [119.49, 26.86],
                            [117.1, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.92, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [117.1, 27.27],
                            [113.83, 26.44],
                            [111.73, 24.09],
                            [110.99, 20.56],
                            [111.73, 17.01],
                            [113.79, 14.61],
                            [116.89, 13.74],
                            [118.91, 14.08],
                            [120.73, 15.19],
                            [122.03, 17.2],
                            [122.52, 20.28],
                            [122.52, 21.14],
                            [112.43, 21.14],
                            [112.43, 19.4],
                            [120.47, 19.4],
                            [120.03, 17.42],
                            [118.78, 16.05],
                            [116.89, 15.55],
                            [114.81, 16.14],
                            [113.47, 17.68],
                            [113.01, 19.7],
                            [113.01, 20.86],
                            [113.52, 23.38],
                            [114.96, 24.94],
                            [117.1, 25.47],
                            [118.53, 25.24],
                            [119.65, 24.56],
                            [120.37, 23.42],
                            [122.31, 23.97],
                            [121.28, 25.7],
                            [119.49, 26.86],
                            [117.1, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [132.12, 9.55],
                            [134.64, 9.55],
                            [140.57, 24.03],
                            [140.78, 24.03],
                            [146.71, 9.55],
                            [149.23, 9.55],
                            [149.23, 27],
                            [147.26, 27],
                            [147.26, 13.74],
                            [147.09, 13.74],
                            [141.63, 27],
                            [139.72, 27],
                            [134.27, 13.74],
                            [134.1, 13.74],
                            [134.1, 27],
                            [132.12, 27],
                            [132.12, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [132.12, 9.55],
                            [134.64, 9.55],
                            [140.57, 24.03],
                            [140.78, 24.03],
                            [146.71, 9.55],
                            [149.23, 9.55],
                            [149.23, 27],
                            [147.26, 27],
                            [147.26, 13.74],
                            [147.09, 13.74],
                            [141.63, 27],
                            [139.72, 27],
                            [134.27, 13.74],
                            [134.1, 13.74],
                            [134.1, 27],
                            [132.12, 27],
                            [132.12, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.89, 0.56],
                            [0.5, 1.01],
                            [0, 1.35],
                            [-0.5, 1.02],
                            [-0.89, 0.56],
                            [-1.18, 0],
                            [-0.89, -0.56],
                            [-0.49, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.89, -0.56],
                            [1.18, 0],
                          ],
                          o: [
                            [-1.18, 0],
                            [-0.89, -0.56],
                            [-0.5, -1.01],
                            [0, -1.36],
                            [0.5, -1.02],
                            [0.89, -0.56],
                            [1.18, 0],
                            [0.89, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.49, 1.01],
                            [-0.89, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [158.28, 27.27],
                            [155.17, 26.43],
                            [153.09, 24.07],
                            [152.34, 20.52],
                            [153.09, 16.95],
                            [155.17, 14.58],
                            [158.28, 13.74],
                            [161.38, 14.58],
                            [163.46, 16.95],
                            [164.21, 20.52],
                            [163.46, 24.07],
                            [161.38, 26.43],
                            [158.28, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.89, 0.56],
                            [0.5, 1.01],
                            [0, 1.35],
                            [-0.5, 1.02],
                            [-0.89, 0.56],
                            [-1.18, 0],
                            [-0.89, -0.56],
                            [-0.49, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.89, -0.56],
                            [1.18, 0],
                          ],
                          o: [
                            [-1.18, 0],
                            [-0.89, -0.56],
                            [-0.5, -1.01],
                            [0, -1.36],
                            [0.5, -1.02],
                            [0.89, -0.56],
                            [1.18, 0],
                            [0.89, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.49, 1.01],
                            [-0.89, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [158.28, 27.27],
                            [155.17, 26.43],
                            [153.09, 24.07],
                            [152.34, 20.52],
                            [153.09, 16.95],
                            [155.17, 14.58],
                            [158.28, 13.74],
                            [161.38, 14.58],
                            [163.46, 16.95],
                            [164.21, 20.52],
                            [163.46, 24.07],
                            [161.38, 26.43],
                            [158.28, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.58, 0.46],
                            [-0.28, 0.75],
                            [0, 0.88],
                            [0.28, 0.76],
                            [0.58, 0.47],
                            [0.9, 0],
                            [0.58, -0.47],
                            [0.28, -0.76],
                            [0, -0.87],
                            [-0.28, -0.75],
                            [-0.58, -0.46],
                            [-0.9, 0],
                          ],
                          o: [
                            [0.9, 0],
                            [0.58, -0.46],
                            [0.28, -0.75],
                            [0, -0.87],
                            [-0.28, -0.76],
                            [-0.58, -0.47],
                            [-0.9, 0],
                            [-0.58, 0.47],
                            [-0.28, 0.76],
                            [0, 0.88],
                            [0.28, 0.75],
                            [0.58, 0.46],
                            [0, 0],
                          ],
                          v: [
                            [158.28, 25.47],
                            [160.49, 24.78],
                            [161.78, 22.96],
                            [162.2, 20.52],
                            [161.78, 18.08],
                            [160.49, 16.24],
                            [158.28, 15.55],
                            [156.06, 16.24],
                            [154.77, 18.08],
                            [154.36, 20.52],
                            [154.77, 22.96],
                            [156.06, 24.78],
                            [158.28, 25.47],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.58, 0.46],
                            [-0.28, 0.75],
                            [0, 0.88],
                            [0.28, 0.76],
                            [0.58, 0.47],
                            [0.9, 0],
                            [0.58, -0.47],
                            [0.28, -0.76],
                            [0, -0.87],
                            [-0.28, -0.75],
                            [-0.58, -0.46],
                            [-0.9, 0],
                          ],
                          o: [
                            [0.9, 0],
                            [0.58, -0.46],
                            [0.28, -0.75],
                            [0, -0.87],
                            [-0.28, -0.76],
                            [-0.58, -0.47],
                            [-0.9, 0],
                            [-0.58, 0.47],
                            [-0.28, 0.76],
                            [0, 0.88],
                            [0.28, 0.75],
                            [0.58, 0.46],
                            [0, 0],
                          ],
                          v: [
                            [158.28, 25.47],
                            [160.49, 24.78],
                            [161.78, 22.96],
                            [162.2, 20.52],
                            [161.78, 18.08],
                            [160.49, 16.24],
                            [158.28, 15.55],
                            [156.06, 16.24],
                            [154.77, 18.08],
                            [154.36, 20.52],
                            [154.77, 22.96],
                            [156.06, 24.78],
                            [158.28, 25.47],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.55],
                            [0.47, 1.01],
                            [0, 1.39],
                            [-0.47, 1.01],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.49, -0.28],
                            [-0.26, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.36],
                            [0.51, -0.28],
                            [0.84, 0],
                          ],
                          o: [
                            [-1.09, 0],
                            [-0.84, -0.56],
                            [-0.47, -1.02],
                            [0, -1.37],
                            [0.47, -1.01],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.5, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.36],
                            [-0.5, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [171.98, 27.27],
                            [169.09, 26.45],
                            [167.13, 24.09],
                            [166.43, 20.49],
                            [167.13, 16.91],
                            [169.1, 14.57],
                            [172.02, 13.74],
                            [174.04, 14.16],
                            [175.18, 15.12],
                            [175.8, 15.99],
                            [175.97, 15.99],
                            [175.97, 9.55],
                            [177.98, 9.55],
                            [177.98, 27],
                            [176.04, 27],
                            [176.04, 24.99],
                            [175.8, 24.99],
                            [175.17, 25.89],
                            [174, 26.86],
                            [171.98, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.55],
                            [0.47, 1.01],
                            [0, 1.39],
                            [-0.47, 1.01],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.49, -0.28],
                            [-0.26, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.36],
                            [0.51, -0.28],
                            [0.84, 0],
                          ],
                          o: [
                            [-1.09, 0],
                            [-0.84, -0.56],
                            [-0.47, -1.02],
                            [0, -1.37],
                            [0.47, -1.01],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.5, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.36],
                            [-0.5, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [171.98, 27.27],
                            [169.09, 26.45],
                            [167.13, 24.09],
                            [166.43, 20.49],
                            [167.13, 16.91],
                            [169.1, 14.57],
                            [172.02, 13.74],
                            [174.04, 14.16],
                            [175.18, 15.12],
                            [175.8, 15.99],
                            [175.97, 15.99],
                            [175.97, 9.55],
                            [177.98, 9.55],
                            [177.98, 27],
                            [176.04, 27],
                            [176.04, 24.99],
                            [175.8, 24.99],
                            [175.17, 25.89],
                            [174, 26.86],
                            [171.98, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.42],
                            [-0.29, 0.75],
                            [0, 0.99],
                            [0.28, 0.73],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.44],
                            [0.28, -0.74],
                            [0, -0.92],
                            [-0.28, -0.76],
                            [-0.56, -0.45],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.43],
                            [0.29, -0.76],
                            [0, -0.98],
                            [-0.28, -0.74],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.74],
                            [0, 0.93],
                            [0.29, 0.76],
                            [0.57, 0.44],
                            [0, 0],
                          ],
                          v: [
                            [172.25, 25.47],
                            [174.3, 24.84],
                            [175.57, 23.07],
                            [176, 20.45],
                            [175.58, 17.89],
                            [174.32, 16.17],
                            [172.25, 15.55],
                            [170.12, 16.2],
                            [168.85, 17.97],
                            [168.44, 20.45],
                            [168.86, 22.99],
                            [170.14, 24.8],
                            [172.25, 25.47],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.42],
                            [-0.29, 0.75],
                            [0, 0.99],
                            [0.28, 0.73],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.44],
                            [0.28, -0.74],
                            [0, -0.92],
                            [-0.28, -0.76],
                            [-0.56, -0.45],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.43],
                            [0.29, -0.76],
                            [0, -0.98],
                            [-0.28, -0.74],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.74],
                            [0, 0.93],
                            [0.29, 0.76],
                            [0.57, 0.44],
                            [0, 0],
                          ],
                          v: [
                            [172.25, 25.47],
                            [174.3, 24.84],
                            [175.57, 23.07],
                            [176, 20.45],
                            [175.58, 17.89],
                            [174.32, 16.17],
                            [172.25, 15.55],
                            [170.12, 16.2],
                            [168.85, 17.97],
                            [168.44, 20.45],
                            [168.86, 22.99],
                            [170.14, 24.8],
                            [172.25, 25.47],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.66, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.54, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [187.19, 27.27],
                            [183.93, 26.44],
                            [181.82, 24.09],
                            [181.09, 20.56],
                            [181.82, 17.01],
                            [183.89, 14.61],
                            [186.99, 13.74],
                            [189.01, 14.08],
                            [190.82, 15.19],
                            [192.13, 17.2],
                            [192.61, 20.28],
                            [192.61, 21.14],
                            [182.52, 21.14],
                            [182.52, 19.4],
                            [190.57, 19.4],
                            [190.13, 17.42],
                            [188.88, 16.05],
                            [186.99, 15.55],
                            [184.91, 16.14],
                            [183.57, 17.68],
                            [183.1, 19.7],
                            [183.1, 20.86],
                            [183.61, 23.38],
                            [185.05, 24.94],
                            [187.19, 25.47],
                            [188.63, 25.24],
                            [189.74, 24.56],
                            [190.47, 23.42],
                            [192.41, 23.97],
                            [191.38, 25.7],
                            [189.59, 26.86],
                            [187.19, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.66, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.54, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [187.19, 27.27],
                            [183.93, 26.44],
                            [181.82, 24.09],
                            [181.09, 20.56],
                            [181.82, 17.01],
                            [183.89, 14.61],
                            [186.99, 13.74],
                            [189.01, 14.08],
                            [190.82, 15.19],
                            [192.13, 17.2],
                            [192.61, 20.28],
                            [192.61, 21.14],
                            [182.52, 21.14],
                            [182.52, 19.4],
                            [190.57, 19.4],
                            [190.13, 17.42],
                            [188.88, 16.05],
                            [186.99, 15.55],
                            [184.91, 16.14],
                            [183.57, 17.68],
                            [183.1, 19.7],
                            [183.1, 20.86],
                            [183.61, 23.38],
                            [185.05, 24.94],
                            [187.19, 25.47],
                            [188.63, 25.24],
                            [189.74, 24.56],
                            [190.47, 23.42],
                            [192.41, 23.97],
                            [191.38, 25.7],
                            [189.59, 26.86],
                            [187.19, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [197.45, 9.55],
                            [197.45, 27],
                            [195.43, 27],
                            [195.43, 9.55],
                            [197.45, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [197.45, 9.55],
                            [197.45, 27],
                            [195.43, 27],
                            [195.43, 9.55],
                            [197.45, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [214.9, 18.58],
                            [214.9, 20.45],
                            [207.26, 20.45],
                            [207.26, 18.58],
                            [214.9, 18.58],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [214.9, 18.58],
                            [214.9, 20.45],
                            [207.26, 20.45],
                            [207.26, 18.58],
                            [214.9, 18.58],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [229.82, 23.93],
                            [229.82, 12.68],
                            [231.73, 12.68],
                            [231.73, 23.93],
                            [229.82, 23.93],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [229.82, 23.93],
                            [229.82, 12.68],
                            [231.73, 12.68],
                            [231.73, 23.93],
                            [229.82, 23.93],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [225.15, 19.26],
                            [225.15, 17.35],
                            [236.4, 17.35],
                            [236.4, 19.26],
                            [225.15, 19.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [225.15, 19.26],
                            [225.15, 17.35],
                            [236.4, 17.35],
                            [236.4, 19.26],
                            [225.15, 19.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.44, 0.55],
                            [-0.21, 0.48],
                            [0, 0.53],
                            [0.3, 0.45],
                            [0.51, 0.24],
                            [0.63, 0],
                            [0.5, -0.28],
                            [0.27, -0.49],
                            [0, -0.66],
                            [0, 0],
                            [-0.47, 0.77],
                            [-0.81, 0.43],
                            [-1.02, 0],
                            [-0.79, -0.43],
                            [-0.45, -0.73],
                            [0, -0.9],
                            [0.23, -0.61],
                            [0.57, -0.75],
                            [1, -1.07],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.68, -0.74],
                            [0.44, -0.55],
                            [0.22, -0.49],
                            [0, -0.61],
                            [-0.29, -0.45],
                            [-0.5, -0.24],
                            [-0.67, 0],
                            [-0.49, 0.27],
                            [-0.27, 0.49],
                            [0, 0],
                            [0, -1.02],
                            [0.47, -0.77],
                            [0.82, -0.43],
                            [1.02, 0],
                            [0.79, 0.43],
                            [0.45, 0.73],
                            [0, 0.64],
                            [-0.23, 0.61],
                            [-0.56, 0.74],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [239.43, 27],
                            [239.43, 25.47],
                            [245.2, 19.16],
                            [246.87, 17.23],
                            [247.84, 15.68],
                            [248.16, 14.15],
                            [247.72, 12.55],
                            [246.52, 11.51],
                            [244.82, 11.15],
                            [243.06, 11.57],
                            [241.91, 12.72],
                            [241.51, 14.45],
                            [239.5, 14.45],
                            [240.21, 11.76],
                            [242.14, 9.95],
                            [244.89, 9.31],
                            [247.61, 9.95],
                            [249.47, 11.7],
                            [250.14, 14.15],
                            [249.79, 16.03],
                            [248.6, 18.07],
                            [246.25, 20.8],
                            [242.33, 24.99],
                            [242.33, 25.13],
                            [250.45, 25.13],
                            [250.45, 27],
                            [239.43, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.44, 0.55],
                            [-0.21, 0.48],
                            [0, 0.53],
                            [0.3, 0.45],
                            [0.51, 0.24],
                            [0.63, 0],
                            [0.5, -0.28],
                            [0.27, -0.49],
                            [0, -0.66],
                            [0, 0],
                            [-0.47, 0.77],
                            [-0.81, 0.43],
                            [-1.02, 0],
                            [-0.79, -0.43],
                            [-0.45, -0.73],
                            [0, -0.9],
                            [0.23, -0.61],
                            [0.57, -0.75],
                            [1, -1.07],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.68, -0.74],
                            [0.44, -0.55],
                            [0.22, -0.49],
                            [0, -0.61],
                            [-0.29, -0.45],
                            [-0.5, -0.24],
                            [-0.67, 0],
                            [-0.49, 0.27],
                            [-0.27, 0.49],
                            [0, 0],
                            [0, -1.02],
                            [0.47, -0.77],
                            [0.82, -0.43],
                            [1.02, 0],
                            [0.79, 0.43],
                            [0.45, 0.73],
                            [0, 0.64],
                            [-0.23, 0.61],
                            [-0.56, 0.74],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [239.43, 27],
                            [239.43, 25.47],
                            [245.2, 19.16],
                            [246.87, 17.23],
                            [247.84, 15.68],
                            [248.16, 14.15],
                            [247.72, 12.55],
                            [246.52, 11.51],
                            [244.82, 11.15],
                            [243.06, 11.57],
                            [241.91, 12.72],
                            [241.51, 14.45],
                            [239.5, 14.45],
                            [240.21, 11.76],
                            [242.14, 9.95],
                            [244.89, 9.31],
                            [247.61, 9.95],
                            [249.47, 11.7],
                            [250.14, 14.15],
                            [249.79, 16.03],
                            [248.6, 18.07],
                            [246.25, 20.8],
                            [242.33, 24.99],
                            [242.33, 25.13],
                            [250.45, 25.13],
                            [250.45, 27],
                            [239.43, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [260.54, 27],
                            [260.54, 9.55],
                            [271.08, 9.55],
                            [271.08, 11.42],
                            [262.65, 11.42],
                            [262.65, 17.32],
                            [270.53, 17.32],
                            [270.53, 19.19],
                            [262.65, 19.19],
                            [262.65, 25.13],
                            [271.21, 25.13],
                            [271.21, 27],
                            [260.54, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [260.54, 27],
                            [260.54, 9.55],
                            [271.08, 9.55],
                            [271.08, 11.42],
                            [262.65, 11.42],
                            [262.65, 17.32],
                            [270.53, 17.32],
                            [270.53, 19.19],
                            [262.65, 19.19],
                            [262.65, 25.13],
                            [271.21, 25.13],
                            [271.21, 27],
                            [260.54, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.66, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.67, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [276.38, 19.13],
                            [276.38, 27],
                            [274.37, 27],
                            [274.37, 13.91],
                            [276.32, 13.91],
                            [276.32, 15.95],
                            [276.49, 15.95],
                            [277.88, 14.35],
                            [280.3, 13.74],
                            [282.63, 14.28],
                            [284.18, 15.92],
                            [284.74, 18.68],
                            [284.74, 27],
                            [282.73, 27],
                            [282.73, 18.82],
                            [281.92, 16.41],
                            [279.73, 15.55],
                            [278, 15.96],
                            [276.82, 17.18],
                            [276.38, 19.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.66, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.67, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [276.38, 19.13],
                            [276.38, 27],
                            [274.37, 27],
                            [274.37, 13.91],
                            [276.32, 13.91],
                            [276.32, 15.95],
                            [276.49, 15.95],
                            [277.88, 14.35],
                            [280.3, 13.74],
                            [282.63, 14.28],
                            [284.18, 15.92],
                            [284.74, 18.68],
                            [284.74, 27],
                            [282.73, 27],
                            [282.73, 18.82],
                            [281.92, 16.41],
                            [279.73, 15.55],
                            [278, 15.96],
                            [276.82, 17.18],
                            [276.38, 19.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.04, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.83, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [293.46, 32.18],
                            [290.95, 31.81],
                            [289.2, 30.84],
                            [288.1, 29.56],
                            [289.71, 28.43],
                            [290.4, 29.25],
                            [291.54, 30.06],
                            [293.46, 30.41],
                            [296.07, 29.64],
                            [297.1, 27.24],
                            [297.1, 24.58],
                            [296.93, 24.58],
                            [296.3, 25.47],
                            [295.14, 26.39],
                            [293.12, 26.8],
                            [290.27, 26.05],
                            [288.28, 23.86],
                            [287.56, 20.39],
                            [288.27, 16.88],
                            [290.23, 14.57],
                            [293.15, 13.74],
                            [295.18, 14.16],
                            [296.34, 15.12],
                            [296.97, 15.99],
                            [297.17, 15.99],
                            [297.17, 13.91],
                            [299.12, 13.91],
                            [299.12, 27.38],
                            [298.35, 30.12],
                            [296.3, 31.68],
                            [293.46, 32.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.7, 0.25],
                            [0.47, 0.4],
                            [0.27, 0.45],
                            [0, 0],
                            [-0.28, -0.31],
                            [-0.48, -0.23],
                            [-0.79, 0],
                            [-0.69, 0.51],
                            [0, 1.09],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.35],
                            [0.51, -0.27],
                            [0.85, 0],
                            [0.84, 0.5],
                            [0.49, 0.95],
                            [0, 1.36],
                            [-0.47, 0.99],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.5, -0.28],
                            [-0.27, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.51, -0.7],
                            [0.86, -0.33],
                            [1.04, 0],
                          ],
                          o: [
                            [-0.97, 0],
                            [-0.7, -0.24],
                            [-0.46, -0.4],
                            [0, 0],
                            [0.18, 0.24],
                            [0.28, 0.31],
                            [0.49, 0.23],
                            [1.06, 0],
                            [0.69, -0.51],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.35],
                            [-0.5, 0.27],
                            [-1.06, 0],
                            [-0.83, -0.5],
                            [-0.48, -0.95],
                            [0, -1.34],
                            [0.47, -1],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.51, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 1.13],
                            [-0.51, 0.71],
                            [-0.85, 0.34],
                            [0, 0],
                          ],
                          v: [
                            [293.46, 32.18],
                            [290.95, 31.81],
                            [289.2, 30.84],
                            [288.1, 29.56],
                            [289.71, 28.43],
                            [290.4, 29.25],
                            [291.54, 30.06],
                            [293.46, 30.41],
                            [296.07, 29.64],
                            [297.1, 27.24],
                            [297.1, 24.58],
                            [296.93, 24.58],
                            [296.3, 25.47],
                            [295.14, 26.39],
                            [293.12, 26.8],
                            [290.27, 26.05],
                            [288.28, 23.86],
                            [287.56, 20.39],
                            [288.27, 16.88],
                            [290.23, 14.57],
                            [293.15, 13.74],
                            [295.18, 14.16],
                            [296.34, 15.12],
                            [296.97, 15.99],
                            [297.17, 15.99],
                            [297.17, 13.91],
                            [299.12, 13.91],
                            [299.12, 27.38],
                            [298.35, 30.12],
                            [296.3, 31.68],
                            [293.46, 32.18],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [293.39, 24.99],
                            [295.43, 24.43],
                            [296.7, 22.84],
                            [297.14, 20.35],
                            [296.71, 17.86],
                            [295.45, 16.16],
                            [293.39, 15.55],
                            [291.26, 16.19],
                            [289.99, 17.93],
                            [289.57, 20.35],
                            [290, 22.76],
                            [291.27, 24.4],
                            [293.39, 24.99],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.37],
                            [-0.29, 0.69],
                            [0, 0.97],
                            [0.28, 0.72],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.43],
                            [0.28, -0.73],
                            [0, -0.89],
                            [-0.28, -0.7],
                            [-0.56, -0.4],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.37],
                            [0.29, -0.69],
                            [0, -0.94],
                            [-0.28, -0.72],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.73],
                            [0, 0.91],
                            [0.29, 0.69],
                            [0.57, 0.39],
                            [0, 0],
                          ],
                          v: [
                            [293.39, 24.99],
                            [295.43, 24.43],
                            [296.7, 22.84],
                            [297.14, 20.35],
                            [296.71, 17.86],
                            [295.45, 16.16],
                            [293.39, 15.55],
                            [291.26, 16.19],
                            [289.99, 17.93],
                            [289.57, 20.35],
                            [290, 22.76],
                            [291.27, 24.4],
                            [293.39, 24.99],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [302.56, 27],
                            [302.56, 13.91],
                            [304.57, 13.91],
                            [304.57, 27],
                            [302.56, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [302.56, 27],
                            [302.56, 13.91],
                            [304.57, 13.91],
                            [304.57, 27],
                            [302.56, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                          ],
                          o: [
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [303.58, 11.73],
                            [302.57, 11.33],
                            [302.15, 10.36],
                            [302.57, 9.4],
                            [303.58, 9],
                            [304.58, 9.4],
                            [305.01, 10.36],
                            [304.58, 11.33],
                            [303.58, 11.73],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                          ],
                          o: [
                            [-0.39, 0],
                            [-0.28, -0.27],
                            [0, -0.37],
                            [0.28, -0.27],
                            [0.39, 0],
                            [0.28, 0.27],
                            [0, 0.38],
                            [-0.28, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [303.58, 11.73],
                            [302.57, 11.33],
                            [302.15, 10.36],
                            [302.57, 9.4],
                            [303.58, 9],
                            [304.58, 9.4],
                            [305.01, 10.36],
                            [304.58, 11.33],
                            [303.58, 11.73],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.67, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.66, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [310.02, 19.13],
                            [310.02, 27],
                            [308.01, 27],
                            [308.01, 13.91],
                            [309.96, 13.91],
                            [309.96, 15.95],
                            [310.13, 15.95],
                            [311.52, 14.35],
                            [313.94, 13.74],
                            [316.27, 14.28],
                            [317.82, 15.92],
                            [318.38, 18.68],
                            [318.38, 27],
                            [316.36, 27],
                            [316.36, 18.82],
                            [315.56, 16.41],
                            [313.36, 15.55],
                            [311.64, 15.96],
                            [310.46, 17.18],
                            [310.02, 19.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.67, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.66, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [310.02, 19.13],
                            [310.02, 27],
                            [308.01, 27],
                            [308.01, 13.91],
                            [309.96, 13.91],
                            [309.96, 15.95],
                            [310.13, 15.95],
                            [311.52, 14.35],
                            [313.94, 13.74],
                            [316.27, 14.28],
                            [317.82, 15.92],
                            [318.38, 18.68],
                            [318.38, 27],
                            [316.36, 27],
                            [316.36, 18.82],
                            [315.56, 16.41],
                            [313.36, 15.55],
                            [311.64, 15.96],
                            [310.46, 17.18],
                            [310.02, 19.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.5, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.58, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.5, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.21, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [327.3, 27.27],
                            [324.04, 26.44],
                            [321.93, 24.09],
                            [321.2, 20.56],
                            [321.93, 17.01],
                            [323.99, 14.61],
                            [327.1, 13.74],
                            [329.12, 14.08],
                            [330.93, 15.19],
                            [332.23, 17.2],
                            [332.72, 20.28],
                            [332.72, 21.14],
                            [322.63, 21.14],
                            [322.63, 19.4],
                            [330.68, 19.4],
                            [330.23, 17.42],
                            [328.99, 16.05],
                            [327.1, 15.55],
                            [325.02, 16.14],
                            [323.68, 17.68],
                            [323.21, 19.7],
                            [323.21, 20.86],
                            [323.72, 23.38],
                            [325.16, 24.94],
                            [327.3, 25.47],
                            [328.73, 25.24],
                            [329.85, 24.56],
                            [330.57, 23.42],
                            [332.52, 23.97],
                            [331.48, 25.7],
                            [329.7, 26.86],
                            [327.3, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.5, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.58, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.5, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.66, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.21, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [327.3, 27.27],
                            [324.04, 26.44],
                            [321.93, 24.09],
                            [321.2, 20.56],
                            [321.93, 17.01],
                            [323.99, 14.61],
                            [327.1, 13.74],
                            [329.12, 14.08],
                            [330.93, 15.19],
                            [332.23, 17.2],
                            [332.72, 20.28],
                            [332.72, 21.14],
                            [322.63, 21.14],
                            [322.63, 19.4],
                            [330.68, 19.4],
                            [330.23, 17.42],
                            [328.99, 16.05],
                            [327.1, 15.55],
                            [325.02, 16.14],
                            [323.68, 17.68],
                            [323.21, 19.7],
                            [323.21, 20.86],
                            [323.72, 23.38],
                            [325.16, 24.94],
                            [327.3, 25.47],
                            [328.73, 25.24],
                            [329.85, 24.56],
                            [330.57, 23.42],
                            [332.52, 23.97],
                            [331.48, 25.7],
                            [329.7, 26.86],
                            [327.3, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.67, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [341.03, 27.27],
                            [337.76, 26.44],
                            [335.66, 24.09],
                            [334.93, 20.56],
                            [335.66, 17.01],
                            [337.72, 14.61],
                            [340.82, 13.74],
                            [342.84, 14.08],
                            [344.66, 15.19],
                            [345.96, 17.2],
                            [346.45, 20.28],
                            [346.45, 21.14],
                            [336.36, 21.14],
                            [336.36, 19.4],
                            [344.4, 19.4],
                            [343.96, 17.42],
                            [342.72, 16.05],
                            [340.82, 15.55],
                            [338.74, 16.14],
                            [337.41, 17.68],
                            [336.94, 19.7],
                            [336.94, 20.86],
                            [337.45, 23.38],
                            [338.89, 24.94],
                            [341.03, 25.47],
                            [342.46, 25.24],
                            [343.58, 24.56],
                            [344.3, 23.42],
                            [346.24, 23.97],
                            [345.21, 25.7],
                            [343.42, 26.86],
                            [341.03, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.49, 1.01],
                            [0, 1.34],
                            [-0.49, 1.02],
                            [-0.88, 0.57],
                            [-1.18, 0],
                            [-0.67, -0.23],
                            [-0.55, -0.51],
                            [-0.32, -0.84],
                            [0, -1.22],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, 0.58],
                            [0.54, 0.34],
                            [0.73, 0],
                            [0.59, -0.4],
                            [0.31, -0.63],
                            [0, -0.72],
                            [0, 0],
                            [-0.34, -0.69],
                            [-0.61, -0.36],
                            [-0.81, 0],
                            [-0.43, 0.15],
                            [-0.31, 0.3],
                            [-0.17, 0.45],
                            [0, 0],
                            [0.48, -0.5],
                            [0.71, -0.28],
                            [0.89, 0],
                          ],
                          o: [
                            [-1.26, 0],
                            [-0.91, -0.56],
                            [-0.49, -1.01],
                            [0, -1.34],
                            [0.49, -1.03],
                            [0.89, -0.58],
                            [0.68, 0],
                            [0.67, 0.23],
                            [0.55, 0.51],
                            [0.32, 0.84],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.74],
                            [-0.29, -0.58],
                            [-0.53, -0.34],
                            [-0.8, 0],
                            [-0.58, 0.39],
                            [-0.31, 0.63],
                            [0, 0],
                            [0, 0.99],
                            [0.35, 0.68],
                            [0.61, 0.35],
                            [0.53, 0],
                            [0.43, -0.15],
                            [0.31, -0.31],
                            [0, 0],
                            [-0.2, 0.66],
                            [-0.48, 0.49],
                            [-0.71, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [341.03, 27.27],
                            [337.76, 26.44],
                            [335.66, 24.09],
                            [334.93, 20.56],
                            [335.66, 17.01],
                            [337.72, 14.61],
                            [340.82, 13.74],
                            [342.84, 14.08],
                            [344.66, 15.19],
                            [345.96, 17.2],
                            [346.45, 20.28],
                            [346.45, 21.14],
                            [336.36, 21.14],
                            [336.36, 19.4],
                            [344.4, 19.4],
                            [343.96, 17.42],
                            [342.72, 16.05],
                            [340.82, 15.55],
                            [338.74, 16.14],
                            [337.41, 17.68],
                            [336.94, 19.7],
                            [336.94, 20.86],
                            [337.45, 23.38],
                            [338.89, 24.94],
                            [341.03, 25.47],
                            [342.46, 25.24],
                            [343.58, 24.56],
                            [344.3, 23.42],
                            [346.24, 23.97],
                            [345.21, 25.7],
                            [343.42, 26.86],
                            [341.03, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.78, 0],
                            [-0.22, -0.01],
                            [-0.11, -0.01],
                            [0, 0],
                            [0.24, 0.03],
                            [0.27, 0],
                            [0.5, -0.27],
                            [0.29, -0.47],
                            [0, -0.59],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.65],
                            [0.63, -0.4],
                            [0.15, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.07, -0.02],
                            [-0.24, -0.04],
                            [-0.64, 0],
                            [-0.49, 0.26],
                            [-0.28, 0.46],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [349.27, 27],
                            [349.27, 13.91],
                            [351.21, 13.91],
                            [351.21, 15.89],
                            [351.35, 15.89],
                            [352.64, 14.31],
                            [354.76, 13.7],
                            [355.31, 13.71],
                            [355.81, 13.74],
                            [355.81, 15.78],
                            [355.35, 15.71],
                            [354.59, 15.65],
                            [352.88, 16.05],
                            [351.71, 17.14],
                            [351.28, 18.72],
                            [351.28, 27],
                            [349.27, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.78, 0],
                            [-0.22, -0.01],
                            [-0.11, -0.01],
                            [0, 0],
                            [0.24, 0.03],
                            [0.27, 0],
                            [0.5, -0.27],
                            [0.29, -0.47],
                            [0, -0.59],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.65],
                            [0.63, -0.4],
                            [0.15, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.07, -0.02],
                            [-0.24, -0.04],
                            [-0.64, 0],
                            [-0.49, 0.26],
                            [-0.28, 0.46],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [349.27, 27],
                            [349.27, 13.91],
                            [351.21, 13.91],
                            [351.21, 15.89],
                            [351.35, 15.89],
                            [352.64, 14.31],
                            [354.76, 13.7],
                            [355.31, 13.71],
                            [355.81, 13.74],
                            [355.81, 15.78],
                            [355.35, 15.71],
                            [354.59, 15.65],
                            [352.88, 16.05],
                            [351.71, 17.14],
                            [351.28, 18.72],
                            [351.28, 27],
                            [349.27, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.58, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.42, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.33, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.29, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [367.23, 16.84],
                            [365.42, 17.35],
                            [364.92, 16.47],
                            [364.04, 15.76],
                            [362.6, 15.48],
                            [360.59, 16.03],
                            [359.8, 17.42],
                            [360.35, 18.61],
                            [362.05, 19.33],
                            [363.99, 19.81],
                            [366.61, 21.11],
                            [367.47, 23.35],
                            [366.82, 25.36],
                            [365.03, 26.76],
                            [362.36, 27.27],
                            [359.04, 26.4],
                            [357.38, 23.86],
                            [359.29, 23.39],
                            [360.32, 24.97],
                            [362.32, 25.5],
                            [364.56, 24.9],
                            [365.39, 23.45],
                            [364.91, 22.31],
                            [363.45, 21.61],
                            [361.27, 21.1],
                            [358.62, 19.78],
                            [357.79, 17.52],
                            [358.41, 15.56],
                            [360.12, 14.22],
                            [362.6, 13.74],
                            [365.65, 14.59],
                            [367.23, 16.84],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.58, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.42, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.33, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.29, 0],
                            [0.74, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [367.23, 16.84],
                            [365.42, 17.35],
                            [364.92, 16.47],
                            [364.04, 15.76],
                            [362.6, 15.48],
                            [360.59, 16.03],
                            [359.8, 17.42],
                            [360.35, 18.61],
                            [362.05, 19.33],
                            [363.99, 19.81],
                            [366.61, 21.11],
                            [367.47, 23.35],
                            [366.82, 25.36],
                            [365.03, 26.76],
                            [362.36, 27.27],
                            [359.04, 26.4],
                            [357.38, 23.86],
                            [359.29, 23.39],
                            [360.32, 24.97],
                            [362.32, 25.5],
                            [364.56, 24.9],
                            [365.39, 23.45],
                            [364.91, 22.31],
                            [363.45, 21.61],
                            [361.27, 21.1],
                            [358.62, 19.78],
                            [357.79, 17.52],
                            [358.41, 15.56],
                            [360.12, 14.22],
                            [362.6, 13.74],
                            [365.65, 14.59],
                            [367.23, 16.84],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.68, 0.31],
                            [0.4, 0.6],
                            [0, 0.85],
                            [-0.3, 0.47],
                            [-0.49, 0.27],
                            [-0.6, 0.13],
                            [-0.61, 0.08],
                            [-0.49, 0.05],
                            [-0.23, 0.13],
                            [0, 0.32],
                            [0, 0],
                            [0.46, 0.47],
                            [0.93, 0],
                            [0.55, -0.42],
                            [0.22, -0.48],
                            [0, 0],
                            [-0.57, 0.44],
                            [-0.68, 0.18],
                            [-0.66, 0],
                            [-0.55, -0.1],
                            [-0.51, -0.31],
                            [-0.34, -0.62],
                            [0, -1.04],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.32, -0.32],
                            [0.53, -0.23],
                            [0.76, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.68, -0.32],
                            [-0.4, -0.6],
                            [0, -0.75],
                            [0.3, -0.47],
                            [0.5, -0.27],
                            [0.6, -0.14],
                            [0.8, -0.1],
                            [0.5, -0.06],
                            [0.23, -0.13],
                            [0, 0],
                            [0, -0.84],
                            [-0.45, -0.47],
                            [-0.96, 0],
                            [-0.55, 0.42],
                            [0, 0],
                            [0.34, -0.8],
                            [0.57, -0.45],
                            [0.68, -0.18],
                            [0.42, 0],
                            [0.55, 0.1],
                            [0.52, 0.31],
                            [0.34, 0.62],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.14, 0.28],
                            [-0.32, 0.32],
                            [-0.53, 0.23],
                            [0, 0],
                          ],
                          v: [
                            [380.62, 27.31],
                            [378.36, 26.84],
                            [376.75, 25.47],
                            [376.15, 23.28],
                            [376.6, 21.46],
                            [377.78, 20.35],
                            [379.42, 19.76],
                            [381.23, 19.43],
                            [383.17, 19.2],
                            [384.26, 18.92],
                            [384.61, 18.24],
                            [384.61, 18.17],
                            [383.92, 16.21],
                            [381.85, 15.51],
                            [379.59, 16.14],
                            [378.44, 17.49],
                            [376.53, 16.81],
                            [377.89, 14.95],
                            [379.77, 14.01],
                            [381.78, 13.74],
                            [383.23, 13.89],
                            [384.82, 14.5],
                            [386.11, 15.89],
                            [386.62, 18.38],
                            [386.62, 27],
                            [384.61, 27],
                            [384.61, 25.23],
                            [384.51, 25.23],
                            [383.82, 26.14],
                            [382.55, 26.97],
                            [380.62, 27.31],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.68, 0.31],
                            [0.4, 0.6],
                            [0, 0.85],
                            [-0.3, 0.47],
                            [-0.49, 0.27],
                            [-0.6, 0.13],
                            [-0.61, 0.08],
                            [-0.49, 0.05],
                            [-0.23, 0.13],
                            [0, 0.32],
                            [0, 0],
                            [0.46, 0.47],
                            [0.93, 0],
                            [0.55, -0.42],
                            [0.22, -0.48],
                            [0, 0],
                            [-0.57, 0.44],
                            [-0.68, 0.18],
                            [-0.66, 0],
                            [-0.55, -0.1],
                            [-0.51, -0.31],
                            [-0.34, -0.62],
                            [0, -1.04],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.32, -0.32],
                            [0.53, -0.23],
                            [0.76, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.68, -0.32],
                            [-0.4, -0.6],
                            [0, -0.75],
                            [0.3, -0.47],
                            [0.5, -0.27],
                            [0.6, -0.14],
                            [0.8, -0.1],
                            [0.5, -0.06],
                            [0.23, -0.13],
                            [0, 0],
                            [0, -0.84],
                            [-0.45, -0.47],
                            [-0.96, 0],
                            [-0.55, 0.42],
                            [0, 0],
                            [0.34, -0.8],
                            [0.57, -0.45],
                            [0.68, -0.18],
                            [0.42, 0],
                            [0.55, 0.1],
                            [0.52, 0.31],
                            [0.34, 0.62],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.14, 0.28],
                            [-0.32, 0.32],
                            [-0.53, 0.23],
                            [0, 0],
                          ],
                          v: [
                            [380.62, 27.31],
                            [378.36, 26.84],
                            [376.75, 25.47],
                            [376.15, 23.28],
                            [376.6, 21.46],
                            [377.78, 20.35],
                            [379.42, 19.76],
                            [381.23, 19.43],
                            [383.17, 19.2],
                            [384.26, 18.92],
                            [384.61, 18.24],
                            [384.61, 18.17],
                            [383.92, 16.21],
                            [381.85, 15.51],
                            [379.59, 16.14],
                            [378.44, 17.49],
                            [376.53, 16.81],
                            [377.89, 14.95],
                            [379.77, 14.01],
                            [381.78, 13.74],
                            [383.23, 13.89],
                            [384.82, 14.5],
                            [386.11, 15.89],
                            [386.62, 18.38],
                            [386.62, 27],
                            [384.61, 27],
                            [384.61, 25.23],
                            [384.51, 25.23],
                            [383.82, 26.14],
                            [382.55, 26.97],
                            [380.62, 27.31],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.55, 0.31],
                            [-0.28, 0.49],
                            [0, 0.55],
                            [0, 0],
                            [0.29, -0.09],
                            [0.38, -0.06],
                            [0.35, -0.05],
                            [0.22, -0.03],
                            [0.45, -0.15],
                            [0.28, -0.3],
                            [0, -0.51],
                            [-0.52, -0.36],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.55, -0.31],
                            [0.28, -0.49],
                            [0, 0],
                            [-0.08, 0.1],
                            [-0.28, 0.08],
                            [-0.37, 0.06],
                            [-0.35, 0.04],
                            [-0.52, 0.07],
                            [-0.45, 0.15],
                            [-0.27, 0.3],
                            [0, 0.7],
                            [0.52, 0.35],
                            [0, 0],
                          ],
                          v: [
                            [380.93, 25.5],
                            [382.94, 25.03],
                            [384.18, 23.82],
                            [384.61, 22.26],
                            [384.61, 20.42],
                            [384.05, 20.7],
                            [383.06, 20.91],
                            [381.98, 21.07],
                            [381.13, 21.17],
                            [379.67, 21.5],
                            [378.57, 22.18],
                            [378.17, 23.39],
                            [378.94, 24.97],
                            [380.93, 25.5],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.55, 0.31],
                            [-0.28, 0.49],
                            [0, 0.55],
                            [0, 0],
                            [0.29, -0.09],
                            [0.38, -0.06],
                            [0.35, -0.05],
                            [0.22, -0.03],
                            [0.45, -0.15],
                            [0.28, -0.3],
                            [0, -0.51],
                            [-0.52, -0.36],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.55, -0.31],
                            [0.28, -0.49],
                            [0, 0],
                            [-0.08, 0.1],
                            [-0.28, 0.08],
                            [-0.37, 0.06],
                            [-0.35, 0.04],
                            [-0.52, 0.07],
                            [-0.45, 0.15],
                            [-0.27, 0.3],
                            [0, 0.7],
                            [0.52, 0.35],
                            [0, 0],
                          ],
                          v: [
                            [380.93, 25.5],
                            [382.94, 25.03],
                            [384.18, 23.82],
                            [384.61, 22.26],
                            [384.61, 20.42],
                            [384.05, 20.7],
                            [383.06, 20.91],
                            [381.98, 21.07],
                            [381.13, 21.17],
                            [379.67, 21.5],
                            [378.57, 22.18],
                            [378.17, 23.39],
                            [378.94, 24.97],
                            [380.93, 25.5],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.66, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.66, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [392.06, 19.13],
                            [392.06, 27],
                            [390.05, 27],
                            [390.05, 13.91],
                            [391.99, 13.91],
                            [391.99, 15.95],
                            [392.16, 15.95],
                            [393.56, 14.35],
                            [395.98, 13.74],
                            [398.31, 14.28],
                            [399.86, 15.92],
                            [400.41, 18.68],
                            [400.41, 27],
                            [398.4, 27],
                            [398.4, 18.82],
                            [397.6, 16.41],
                            [395.4, 15.55],
                            [393.68, 15.96],
                            [392.5, 17.18],
                            [392.06, 19.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.62, 0.4],
                            [-0.99, 0],
                            [-0.66, -0.36],
                            [-0.37, -0.73],
                            [0, -1.11],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.53, 0.57],
                            [0.93, 0],
                            [0.51, -0.28],
                            [0.29, -0.53],
                            [0, -0.76],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.31, -0.66],
                            [0.63, -0.41],
                            [0.89, 0],
                            [0.66, 0.36],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -1.03],
                            [-0.53, -0.58],
                            [-0.64, 0],
                            [-0.5, 0.28],
                            [-0.29, 0.53],
                            [0, 0],
                          ],
                          v: [
                            [392.06, 19.13],
                            [392.06, 27],
                            [390.05, 27],
                            [390.05, 13.91],
                            [391.99, 13.91],
                            [391.99, 15.95],
                            [392.16, 15.95],
                            [393.56, 14.35],
                            [395.98, 13.74],
                            [398.31, 14.28],
                            [399.86, 15.92],
                            [400.41, 18.68],
                            [400.41, 27],
                            [398.4, 27],
                            [398.4, 18.82],
                            [397.6, 16.41],
                            [395.4, 15.55],
                            [393.68, 15.96],
                            [392.5, 17.18],
                            [392.06, 19.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.55],
                            [0.47, 1.01],
                            [0, 1.39],
                            [-0.47, 1.01],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.49, -0.28],
                            [-0.26, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.36],
                            [0.51, -0.28],
                            [0.84, 0],
                          ],
                          o: [
                            [-1.09, 0],
                            [-0.83, -0.56],
                            [-0.47, -1.02],
                            [0, -1.37],
                            [0.47, -1.01],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.5, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.36],
                            [-0.51, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [408.79, 27.27],
                            [405.9, 26.45],
                            [403.94, 24.09],
                            [403.24, 20.49],
                            [403.94, 16.91],
                            [405.91, 14.57],
                            [408.83, 13.74],
                            [410.85, 14.16],
                            [411.99, 15.12],
                            [412.61, 15.99],
                            [412.78, 15.99],
                            [412.78, 9.55],
                            [414.79, 9.55],
                            [414.79, 27],
                            [412.85, 27],
                            [412.85, 24.99],
                            [412.61, 24.99],
                            [411.98, 25.89],
                            [410.81, 26.86],
                            [408.79, 27.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.55],
                            [0.47, 1.01],
                            [0, 1.39],
                            [-0.47, 1.01],
                            [-0.84, 0.55],
                            [-1.1, 0],
                            [-0.49, -0.28],
                            [-0.26, -0.36],
                            [-0.15, -0.23],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, -0.36],
                            [0.51, -0.28],
                            [0.84, 0],
                          ],
                          o: [
                            [-1.09, 0],
                            [-0.83, -0.56],
                            [-0.47, -1.02],
                            [0, -1.37],
                            [0.47, -1.01],
                            [0.84, -0.55],
                            [0.85, 0],
                            [0.5, 0.28],
                            [0.27, 0.35],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.24],
                            [-0.27, 0.36],
                            [-0.51, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [408.79, 27.27],
                            [405.9, 26.45],
                            [403.94, 24.09],
                            [403.24, 20.49],
                            [403.94, 16.91],
                            [405.91, 14.57],
                            [408.83, 13.74],
                            [410.85, 14.16],
                            [411.99, 15.12],
                            [412.61, 15.99],
                            [412.78, 15.99],
                            [412.78, 9.55],
                            [414.79, 9.55],
                            [414.79, 27],
                            [412.85, 27],
                            [412.85, 24.99],
                            [412.61, 24.99],
                            [411.98, 25.89],
                            [410.81, 26.86],
                            [408.79, 27.27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.42],
                            [-0.29, 0.75],
                            [0, 0.99],
                            [0.28, 0.73],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.44],
                            [0.28, -0.74],
                            [0, -0.92],
                            [-0.28, -0.76],
                            [-0.56, -0.45],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.43],
                            [0.29, -0.76],
                            [0, -0.98],
                            [-0.28, -0.74],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.74],
                            [0, 0.93],
                            [0.29, 0.76],
                            [0.57, 0.44],
                            [0, 0],
                          ],
                          v: [
                            [409.07, 25.47],
                            [411.11, 24.84],
                            [412.38, 23.07],
                            [412.82, 20.45],
                            [412.39, 17.89],
                            [411.13, 16.17],
                            [409.07, 15.55],
                            [406.94, 16.2],
                            [405.67, 17.97],
                            [405.25, 20.45],
                            [405.67, 22.99],
                            [406.95, 24.8],
                            [409.07, 25.47],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.56, 0.42],
                            [-0.29, 0.75],
                            [0, 0.99],
                            [0.28, 0.73],
                            [0.56, 0.41],
                            [0.82, 0],
                            [0.57, -0.44],
                            [0.28, -0.74],
                            [0, -0.92],
                            [-0.28, -0.76],
                            [-0.56, -0.45],
                            [-0.84, 0],
                          ],
                          o: [
                            [0.81, 0],
                            [0.56, -0.43],
                            [0.29, -0.76],
                            [0, -0.98],
                            [-0.28, -0.74],
                            [-0.56, -0.41],
                            [-0.85, 0],
                            [-0.56, 0.43],
                            [-0.28, 0.74],
                            [0, 0.93],
                            [0.29, 0.76],
                            [0.57, 0.44],
                            [0, 0],
                          ],
                          v: [
                            [409.07, 25.47],
                            [411.11, 24.84],
                            [412.38, 23.07],
                            [412.82, 20.45],
                            [412.39, 17.89],
                            [411.13, 16.17],
                            [409.07, 15.55],
                            [406.94, 16.2],
                            [405.67, 17.97],
                            [405.25, 20.45],
                            [405.67, 22.99],
                            [406.95, 24.8],
                            [409.07, 25.47],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [430.14, 23.93],
                            [430.14, 12.68],
                            [432.05, 12.68],
                            [432.05, 23.93],
                            [430.14, 23.93],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [430.14, 23.93],
                            [430.14, 12.68],
                            [432.05, 12.68],
                            [432.05, 23.93],
                            [430.14, 23.93],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [425.47, 19.26],
                            [425.47, 17.35],
                            [436.72, 17.35],
                            [436.72, 19.26],
                            [425.47, 19.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [425.47, 19.26],
                            [425.47, 17.35],
                            [436.72, 17.35],
                            [436.72, 19.26],
                            [425.47, 19.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.44, 0.55],
                            [-0.21, 0.48],
                            [0, 0.53],
                            [0.3, 0.45],
                            [0.51, 0.24],
                            [0.63, 0],
                            [0.5, -0.28],
                            [0.27, -0.49],
                            [0, -0.66],
                            [0, 0],
                            [-0.47, 0.77],
                            [-0.81, 0.43],
                            [-1.02, 0],
                            [-0.79, -0.43],
                            [-0.45, -0.73],
                            [0, -0.9],
                            [0.23, -0.61],
                            [0.57, -0.75],
                            [1, -1.07],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.68, -0.74],
                            [0.44, -0.55],
                            [0.22, -0.49],
                            [0, -0.61],
                            [-0.29, -0.45],
                            [-0.51, -0.24],
                            [-0.67, 0],
                            [-0.5, 0.27],
                            [-0.27, 0.49],
                            [0, 0],
                            [0, -1.02],
                            [0.47, -0.77],
                            [0.82, -0.43],
                            [1.02, 0],
                            [0.79, 0.43],
                            [0.45, 0.73],
                            [0, 0.64],
                            [-0.23, 0.61],
                            [-0.56, 0.74],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [439.75, 27],
                            [439.75, 25.47],
                            [445.51, 19.16],
                            [447.18, 17.23],
                            [448.16, 15.68],
                            [448.48, 14.15],
                            [448.04, 12.55],
                            [446.84, 11.51],
                            [445.14, 11.15],
                            [443.38, 11.57],
                            [442.23, 12.72],
                            [441.83, 14.45],
                            [439.82, 14.45],
                            [440.53, 11.76],
                            [442.45, 9.95],
                            [445.21, 9.31],
                            [447.93, 9.95],
                            [449.78, 11.7],
                            [450.46, 14.15],
                            [450.11, 16.03],
                            [448.91, 18.07],
                            [446.57, 20.8],
                            [442.65, 24.99],
                            [442.65, 25.13],
                            [450.76, 25.13],
                            [450.76, 27],
                            [439.75, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.44, 0.55],
                            [-0.21, 0.48],
                            [0, 0.53],
                            [0.3, 0.45],
                            [0.51, 0.24],
                            [0.63, 0],
                            [0.5, -0.28],
                            [0.27, -0.49],
                            [0, -0.66],
                            [0, 0],
                            [-0.47, 0.77],
                            [-0.81, 0.43],
                            [-1.02, 0],
                            [-0.79, -0.43],
                            [-0.45, -0.73],
                            [0, -0.9],
                            [0.23, -0.61],
                            [0.57, -0.75],
                            [1, -1.07],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.68, -0.74],
                            [0.44, -0.55],
                            [0.22, -0.49],
                            [0, -0.61],
                            [-0.29, -0.45],
                            [-0.51, -0.24],
                            [-0.67, 0],
                            [-0.5, 0.27],
                            [-0.27, 0.49],
                            [0, 0],
                            [0, -1.02],
                            [0.47, -0.77],
                            [0.82, -0.43],
                            [1.02, 0],
                            [0.79, 0.43],
                            [0.45, 0.73],
                            [0, 0.64],
                            [-0.23, 0.61],
                            [-0.56, 0.74],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [439.75, 27],
                            [439.75, 25.47],
                            [445.51, 19.16],
                            [447.18, 17.23],
                            [448.16, 15.68],
                            [448.48, 14.15],
                            [448.04, 12.55],
                            [446.84, 11.51],
                            [445.14, 11.15],
                            [443.38, 11.57],
                            [442.23, 12.72],
                            [441.83, 14.45],
                            [439.82, 14.45],
                            [440.53, 11.76],
                            [442.45, 9.95],
                            [445.21, 9.31],
                            [447.93, 9.95],
                            [449.78, 11.7],
                            [450.46, 14.15],
                            [450.11, 16.03],
                            [448.91, 18.07],
                            [446.57, 20.8],
                            [442.65, 24.99],
                            [442.65, 25.13],
                            [450.76, 25.13],
                            [450.76, 27],
                            [439.75, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [461.58, 27],
                            [459.36, 27],
                            [465.77, 9.55],
                            [467.95, 9.55],
                            [474.36, 27],
                            [472.14, 27],
                            [466.93, 12.31],
                            [466.79, 12.31],
                            [461.58, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [461.58, 27],
                            [459.36, 27],
                            [465.77, 9.55],
                            [467.95, 9.55],
                            [474.36, 27],
                            [472.14, 27],
                            [466.93, 12.31],
                            [466.79, 12.31],
                            [461.58, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [462.39, 20.18],
                            [471.33, 20.18],
                            [471.33, 22.06],
                            [462.39, 22.06],
                            [462.39, 20.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [462.39, 20.18],
                            [471.33, 20.18],
                            [471.33, 22.06],
                            [462.39, 22.06],
                            [462.39, 20.18],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [476.84, 27],
                            [476.84, 9.55],
                            [487.37, 9.55],
                            [487.37, 11.42],
                            [478.95, 11.42],
                            [478.95, 17.32],
                            [486.83, 17.32],
                            [486.83, 19.19],
                            [478.95, 19.19],
                            [478.95, 25.13],
                            [487.51, 25.13],
                            [487.51, 27],
                            [476.84, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [476.84, 27],
                            [476.84, 9.55],
                            [487.37, 9.55],
                            [487.37, 11.42],
                            [478.95, 11.42],
                            [478.95, 17.32],
                            [486.83, 17.32],
                            [486.83, 19.19],
                            [478.95, 19.19],
                            [478.95, 25.13],
                            [487.51, 25.13],
                            [487.51, 27],
                            [476.84, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.17, -0.53],
                            [0.32, -0.49],
                            [0.45, -0.36],
                            [0, 0],
                            [-0.26, 0.55],
                            [0, 0.63],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0.47],
                            [-0.17, 0.52],
                            [-0.32, 0.49],
                            [0, 0],
                            [0.36, -0.51],
                            [0.27, -0.56],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [492.44, 9.55],
                            [492.44, 11.08],
                            [492.19, 12.57],
                            [491.45, 14.1],
                            [490.3, 15.38],
                            [489.2, 14.49],
                            [490.13, 12.89],
                            [490.53, 11.11],
                            [490.53, 9.55],
                            [492.44, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.17, -0.53],
                            [0.32, -0.49],
                            [0.45, -0.36],
                            [0, 0],
                            [-0.26, 0.55],
                            [0, 0.63],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0.47],
                            [-0.17, 0.52],
                            [-0.32, 0.49],
                            [0, 0],
                            [0.36, -0.51],
                            [0.27, -0.56],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [492.44, 9.55],
                            [492.44, 11.08],
                            [492.19, 12.57],
                            [491.45, 14.1],
                            [490.3, 15.38],
                            [489.2, 14.49],
                            [490.13, 12.89],
                            [490.53, 11.11],
                            [490.53, 9.55],
                            [492.44, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.75, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [502.28, 16.84],
                            [500.47, 17.35],
                            [499.97, 16.47],
                            [499.09, 15.76],
                            [497.64, 15.48],
                            [495.64, 16.03],
                            [494.85, 17.42],
                            [495.39, 18.61],
                            [497.1, 19.33],
                            [499.04, 19.81],
                            [501.66, 21.11],
                            [502.52, 23.35],
                            [501.87, 25.36],
                            [500.08, 26.76],
                            [497.41, 27.27],
                            [494.09, 26.4],
                            [492.43, 23.86],
                            [494.34, 23.39],
                            [495.37, 24.97],
                            [497.37, 25.5],
                            [499.61, 24.9],
                            [500.44, 23.45],
                            [499.96, 22.31],
                            [498.5, 21.61],
                            [496.32, 21.1],
                            [493.67, 19.78],
                            [492.84, 17.52],
                            [493.46, 15.56],
                            [495.17, 14.22],
                            [497.64, 13.74],
                            [500.7, 14.59],
                            [502.28, 16.84],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0.22, 0.28],
                            [0.38, 0.19],
                            [0.59, 0],
                            [0.53, -0.37],
                            [0, -0.56],
                            [-0.36, -0.29],
                            [-0.77, -0.19],
                            [0, 0],
                            [-0.57, -0.59],
                            [0, -0.91],
                            [0.43, -0.59],
                            [0.77, -0.34],
                            [1.02, 0],
                            [0.88, 0.58],
                            [0.23, 1.11],
                            [0, 0],
                            [-0.51, -0.35],
                            [-0.82, 0],
                            [-0.55, 0.4],
                            [0, 0.56],
                            [0.32, 0.31],
                            [0.66, 0.15],
                            [0, 0],
                            [0.56, 0.6],
                            [0, 0.9],
                            [-0.41, 0.57],
                            [-0.72, 0.32],
                            [-0.92, 0],
                            [-0.74, -0.57],
                            [-0.31, -0.93],
                          ],
                          o: [
                            [0, 0],
                            [-0.11, -0.3],
                            [-0.22, -0.29],
                            [-0.37, -0.19],
                            [-0.8, 0],
                            [-0.53, 0.36],
                            [0, 0.5],
                            [0.36, 0.29],
                            [0, 0],
                            [1.17, 0.28],
                            [0.57, 0.58],
                            [0, 0.75],
                            [-0.43, 0.59],
                            [-0.77, 0.34],
                            [-1.34, 0],
                            [-0.87, -0.58],
                            [0, 0],
                            [0.18, 0.7],
                            [0.51, 0.35],
                            [0.94, 0],
                            [0.56, -0.4],
                            [0, -0.45],
                            [-0.32, -0.31],
                            [0, 0],
                            [-1.2, -0.28],
                            [-0.56, -0.6],
                            [0, -0.74],
                            [0.42, -0.57],
                            [0.73, -0.32],
                            [1.3, 0],
                            [0.75, 0.57],
                            [0, 0],
                          ],
                          v: [
                            [502.28, 16.84],
                            [500.47, 17.35],
                            [499.97, 16.47],
                            [499.09, 15.76],
                            [497.64, 15.48],
                            [495.64, 16.03],
                            [494.85, 17.42],
                            [495.39, 18.61],
                            [497.1, 19.33],
                            [499.04, 19.81],
                            [501.66, 21.11],
                            [502.52, 23.35],
                            [501.87, 25.36],
                            [500.08, 26.76],
                            [497.41, 27.27],
                            [494.09, 26.4],
                            [492.43, 23.86],
                            [494.34, 23.39],
                            [495.37, 24.97],
                            [497.37, 25.5],
                            [499.61, 24.9],
                            [500.44, 23.45],
                            [499.96, 22.31],
                            [498.5, 21.61],
                            [496.32, 21.1],
                            [493.67, 19.78],
                            [492.84, 17.52],
                            [493.46, 15.56],
                            [495.17, 14.22],
                            [497.64, 13.74],
                            [500.7, 14.59],
                            [502.28, 16.84],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.6981, 0.7765, 0.8157],
                      t: 0,
                    },
                    { s: [0.6981, 0.7765, 0.8157], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 0,
            nm: 'Frame 1020867',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [268.5, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [268.5, 24],
                    t: 12,
                  },
                  { s: [268.5, 24], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1873.5, 36],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1873.5, 36],
                    t: 12,
                  },
                  { s: [1873.5, 36], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            w: 537,
            h: 100.5,
            refId: '4',
            ind: 2,
          },
          {
            ty: 0,
            nm: 'Frame 102123',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [98.5, 18],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [98.5, 18],
                    t: 12,
                  },
                  { s: [98.5, 18], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [122.5, 36],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [122.5, 36],
                    t: 12,
                  },
                  { s: [122.5, 36], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            w: 211.5,
            h: 100.5,
            refId: '11',
            ind: 3,
          },
          {
            ty: 4,
            nm: 'Frame 102014 Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1080, 36],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1080, 36],
                    t: 12,
                  },
                  { s: [1080, 36], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1080, 36],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [1080, 36],
                    t: 12,
                  },
                  { s: [1080, 36], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2160, 0],
                            [2160, 72],
                            [0, 72],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2160, 0],
                            [2160, 72],
                            [0, 72],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [2160, 0],
                            [2160, 72],
                            [0, 72],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 12,
                    },
                    { s: [0.353, 0.5177, 0.5883], t: 72 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 12,
                    },
                    { s: [100], t: 72 },
                  ],
                },
              },
            ],
            ind: 4,
          },
        ],
      },
      {
        nm: '[Asset] Frame 1020867',
        id: '4',
        layers: [
          {
            ty: 0,
            nm: 'button',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: true,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [97, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [97, 24],
                    t: 12,
                  },
                  { s: [97, 24], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [440, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [440, 24],
                    t: 12,
                  },
                  { s: [440, 24], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            masksProperties: [
              {
                nm: '',
                inv: false,
                mode: 'a',
                x: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
                pt: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [194, 4],
                            [194, 44],
                            [190, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [190, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [194, 4],
                            [194, 44],
                            [190, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [190, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [194, 4],
                            [194, 44],
                            [190, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [190, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
            ],
            w: 211.5,
            h: 100.5,
            refId: '5',
            ind: 1,
          },
          {
            ty: 0,
            nm: 'divider',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 12,
                  },
                  { s: [24, 0], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [325, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [325, 24],
                    t: 12,
                  },
                  { s: [325, 24], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [90],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [90],
                    t: 12,
                  },
                  { s: [90], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            w: 211.5,
            h: 100.5,
            refId: '6',
            ind: 2,
          },
          {
            ty: 0,
            nm: 'Frame 1020883',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 12,
                  },
                  { s: [153.5, 24], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 12,
                  },
                  { s: [153.5, 24], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            w: 307,
            h: 100.5,
            refId: '7',
            ind: 3,
          },
          {
            ty: 4,
            nm: 'Frame 1020867 Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [268.5, 24],
                    t: 0,
                  },
                  { s: [268.5, 24], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [268.5, 24],
                    t: 0,
                  },
                  { s: [268.5, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [537, 0],
                            [537, 48],
                            [0, 48],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [537, 0],
                            [537, 48],
                            [0, 48],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            ind: 4,
          },
        ],
      },
      {
        nm: '[Asset] button',
        id: '5',
        layers: [
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [85, 18],
                    t: 0,
                  },
                  { s: [85, 18], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [97, 24],
                    t: 0,
                  },
                  { s: [97, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.43, 27],
                            [0.64, 27],
                            [6.92, 9.55],
                            [9.96, 9.55],
                            [16.24, 27],
                            [13.45, 27],
                            [8.51, 12.72],
                            [8.38, 12.72],
                            [3.43, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.43, 27],
                            [0.64, 27],
                            [6.92, 9.55],
                            [9.96, 9.55],
                            [16.24, 27],
                            [13.45, 27],
                            [8.51, 12.72],
                            [8.38, 12.72],
                            [3.43, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.9, 20.16],
                            [12.97, 20.16],
                            [12.97, 22.38],
                            [3.9, 22.38],
                            [3.9, 20.16],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.9, 20.16],
                            [12.97, 20.16],
                            [12.97, 22.38],
                            [3.9, 22.38],
                            [3.9, 20.16],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.28, 0.36],
                            [-0.49, 0.27],
                            [-0.81, 0],
                            [-0.83, -0.53],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.47, -1.01],
                            [0.82, -0.55],
                            [1.06, 0],
                            [0.51, 0.27],
                            [0.28, 0.36],
                            [0.15, 0.28],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.15, -0.27],
                            [0.28, -0.36],
                            [0.49, -0.27],
                            [1.06, 0],
                            [0.83, 0.53],
                            [0.48, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.82, 0.54],
                            [-0.8, 0],
                            [-0.5, -0.27],
                            [-0.28, -0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [18.32, 31.91],
                            [18.32, 13.91],
                            [20.81, 13.91],
                            [20.81, 16.03],
                            [21.02, 16.03],
                            [21.66, 15.09],
                            [22.82, 14.15],
                            [24.78, 13.74],
                            [27.61, 14.54],
                            [29.56, 16.85],
                            [30.28, 20.48],
                            [29.57, 24.12],
                            [27.63, 26.45],
                            [24.8, 27.26],
                            [22.85, 26.86],
                            [21.68, 25.92],
                            [21.02, 24.96],
                            [20.87, 24.96],
                            [20.87, 31.91],
                            [18.32, 31.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.28, 0.36],
                            [-0.49, 0.27],
                            [-0.81, 0],
                            [-0.83, -0.53],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.47, -1.01],
                            [0.82, -0.55],
                            [1.06, 0],
                            [0.51, 0.27],
                            [0.28, 0.36],
                            [0.15, 0.28],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.15, -0.27],
                            [0.28, -0.36],
                            [0.49, -0.27],
                            [1.06, 0],
                            [0.83, 0.53],
                            [0.48, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.82, 0.54],
                            [-0.8, 0],
                            [-0.5, -0.27],
                            [-0.28, -0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [18.32, 31.91],
                            [18.32, 13.91],
                            [20.81, 13.91],
                            [20.81, 16.03],
                            [21.02, 16.03],
                            [21.66, 15.09],
                            [22.82, 14.15],
                            [24.78, 13.74],
                            [27.61, 14.54],
                            [29.56, 16.85],
                            [30.28, 20.48],
                            [29.57, 24.12],
                            [27.63, 26.45],
                            [24.8, 27.26],
                            [22.85, 26.86],
                            [21.68, 25.92],
                            [21.02, 24.96],
                            [20.87, 24.96],
                            [20.87, 31.91],
                            [18.32, 31.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.27, -0.69],
                            [-0.51, -0.39],
                            [-0.73, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.7],
                            [0, 0.88],
                            [0.26, 0.69],
                            [0.52, 0.4],
                            [0.77, 0],
                            [0.51, -0.38],
                            [0.26, -0.68],
                            [0, -0.91],
                          ],
                          o: [
                            [0, 0.92],
                            [0.27, 0.69],
                            [0.51, 0.39],
                            [0.76, 0],
                            [0.51, -0.41],
                            [0.27, -0.7],
                            [0, -0.86],
                            [-0.26, -0.69],
                            [-0.51, -0.4],
                            [-0.74, 0],
                            [-0.51, 0.38],
                            [-0.26, 0.68],
                            [0, 0],
                          ],
                          v: [
                            [20.82, 20.45],
                            [21.22, 22.88],
                            [22.38, 24.5],
                            [24.23, 25.08],
                            [26.14, 24.48],
                            [27.3, 22.82],
                            [27.7, 20.45],
                            [27.31, 18.13],
                            [26.15, 16.5],
                            [24.23, 15.9],
                            [22.36, 16.47],
                            [21.21, 18.07],
                            [20.82, 20.45],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.27, -0.69],
                            [-0.51, -0.39],
                            [-0.73, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.7],
                            [0, 0.88],
                            [0.26, 0.69],
                            [0.52, 0.4],
                            [0.77, 0],
                            [0.51, -0.38],
                            [0.26, -0.68],
                            [0, -0.91],
                          ],
                          o: [
                            [0, 0.92],
                            [0.27, 0.69],
                            [0.51, 0.39],
                            [0.76, 0],
                            [0.51, -0.41],
                            [0.27, -0.7],
                            [0, -0.86],
                            [-0.26, -0.69],
                            [-0.51, -0.4],
                            [-0.74, 0],
                            [-0.51, 0.38],
                            [-0.26, 0.68],
                            [0, 0],
                          ],
                          v: [
                            [20.82, 20.45],
                            [21.22, 22.88],
                            [22.38, 24.5],
                            [24.23, 25.08],
                            [26.14, 24.48],
                            [27.3, 22.82],
                            [27.7, 20.45],
                            [27.31, 18.13],
                            [26.15, 16.5],
                            [24.23, 15.9],
                            [22.36, 16.47],
                            [21.21, 18.07],
                            [20.82, 20.45],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.28, 0.36],
                            [-0.49, 0.27],
                            [-0.81, 0],
                            [-0.83, -0.53],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.47, -1.01],
                            [0.82, -0.55],
                            [1.06, 0],
                            [0.51, 0.27],
                            [0.28, 0.36],
                            [0.15, 0.28],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.15, -0.27],
                            [0.28, -0.36],
                            [0.49, -0.27],
                            [1.06, 0],
                            [0.83, 0.53],
                            [0.48, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.82, 0.54],
                            [-0.8, 0],
                            [-0.5, -0.27],
                            [-0.28, -0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [32.89, 31.91],
                            [32.89, 13.91],
                            [35.38, 13.91],
                            [35.38, 16.03],
                            [35.59, 16.03],
                            [36.23, 15.09],
                            [37.39, 14.15],
                            [39.35, 13.74],
                            [42.18, 14.54],
                            [44.13, 16.85],
                            [44.85, 20.48],
                            [44.14, 24.12],
                            [42.2, 26.45],
                            [39.38, 27.26],
                            [37.42, 26.86],
                            [36.25, 25.92],
                            [35.59, 24.96],
                            [35.44, 24.96],
                            [35.44, 31.91],
                            [32.89, 31.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.28, 0.36],
                            [-0.49, 0.27],
                            [-0.81, 0],
                            [-0.83, -0.53],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.47, -1.01],
                            [0.82, -0.55],
                            [1.06, 0],
                            [0.51, 0.27],
                            [0.28, 0.36],
                            [0.15, 0.28],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.15, -0.27],
                            [0.28, -0.36],
                            [0.49, -0.27],
                            [1.06, 0],
                            [0.83, 0.53],
                            [0.48, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.82, 0.54],
                            [-0.8, 0],
                            [-0.5, -0.27],
                            [-0.28, -0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [32.89, 31.91],
                            [32.89, 13.91],
                            [35.38, 13.91],
                            [35.38, 16.03],
                            [35.59, 16.03],
                            [36.23, 15.09],
                            [37.39, 14.15],
                            [39.35, 13.74],
                            [42.18, 14.54],
                            [44.13, 16.85],
                            [44.85, 20.48],
                            [44.14, 24.12],
                            [42.2, 26.45],
                            [39.38, 27.26],
                            [37.42, 26.86],
                            [36.25, 25.92],
                            [35.59, 24.96],
                            [35.44, 24.96],
                            [35.44, 31.91],
                            [32.89, 31.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.27, -0.69],
                            [-0.51, -0.39],
                            [-0.73, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.7],
                            [0, 0.88],
                            [0.26, 0.69],
                            [0.52, 0.4],
                            [0.77, 0],
                            [0.51, -0.38],
                            [0.26, -0.68],
                            [0, -0.91],
                          ],
                          o: [
                            [0, 0.92],
                            [0.27, 0.69],
                            [0.51, 0.39],
                            [0.76, 0],
                            [0.51, -0.41],
                            [0.27, -0.7],
                            [0, -0.86],
                            [-0.26, -0.69],
                            [-0.51, -0.4],
                            [-0.74, 0],
                            [-0.51, 0.38],
                            [-0.26, 0.68],
                            [0, 0],
                          ],
                          v: [
                            [35.39, 20.45],
                            [35.79, 22.88],
                            [36.95, 24.5],
                            [38.81, 25.08],
                            [40.71, 24.48],
                            [41.87, 22.82],
                            [42.27, 20.45],
                            [41.88, 18.13],
                            [40.72, 16.5],
                            [38.81, 15.9],
                            [36.93, 16.47],
                            [35.78, 18.07],
                            [35.39, 20.45],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.27, -0.69],
                            [-0.51, -0.39],
                            [-0.73, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.7],
                            [0, 0.88],
                            [0.26, 0.69],
                            [0.52, 0.4],
                            [0.77, 0],
                            [0.51, -0.38],
                            [0.26, -0.68],
                            [0, -0.91],
                          ],
                          o: [
                            [0, 0.92],
                            [0.27, 0.69],
                            [0.51, 0.39],
                            [0.76, 0],
                            [0.51, -0.41],
                            [0.27, -0.7],
                            [0, -0.86],
                            [-0.26, -0.69],
                            [-0.51, -0.4],
                            [-0.74, 0],
                            [-0.51, 0.38],
                            [-0.26, 0.68],
                            [0, 0],
                          ],
                          v: [
                            [35.39, 20.45],
                            [35.79, 22.88],
                            [36.95, 24.5],
                            [38.81, 25.08],
                            [40.71, 24.48],
                            [41.87, 22.82],
                            [42.27, 20.45],
                            [41.88, 18.13],
                            [40.72, 16.5],
                            [38.81, 15.9],
                            [36.93, 16.47],
                            [35.78, 18.07],
                            [35.39, 20.45],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [50.01, 9.55],
                            [50.01, 27],
                            [47.46, 27],
                            [47.46, 9.55],
                            [50.01, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [50.01, 9.55],
                            [50.01, 27],
                            [47.46, 27],
                            [47.46, 9.55],
                            [50.01, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.31, 0.06],
                            [0.15, 0.07],
                            [0, 0],
                            [-0.36, 0.02],
                            [-0.28, 0.26],
                            [-0.22, 0.58],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.39, -0.47],
                            [0.53, -0.24],
                            [0.7, 0],
                          ],
                          o: [
                            [-0.38, 0],
                            [-0.31, -0.06],
                            [0, 0],
                            [0.47, 0.13],
                            [0.36, -0.02],
                            [0.28, -0.26],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.25, 0.68],
                            [-0.39, 0.48],
                            [-0.53, 0.24],
                            [0, 0],
                          ],
                          v: [
                            [54.81, 31.91],
                            [53.77, 31.82],
                            [53.07, 31.63],
                            [53.69, 29.54],
                            [54.93, 29.7],
                            [55.89, 29.29],
                            [56.64, 28.04],
                            [56.96, 27.17],
                            [52.17, 13.91],
                            [54.9, 13.91],
                            [58.21, 24.07],
                            [58.35, 24.07],
                            [61.66, 13.91],
                            [64.4, 13.91],
                            [59, 28.75],
                            [58.05, 30.48],
                            [56.67, 31.55],
                            [54.81, 31.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.31, 0.06],
                            [0.15, 0.07],
                            [0, 0],
                            [-0.36, 0.02],
                            [-0.28, 0.26],
                            [-0.22, 0.58],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.39, -0.47],
                            [0.53, -0.24],
                            [0.7, 0],
                          ],
                          o: [
                            [-0.38, 0],
                            [-0.31, -0.06],
                            [0, 0],
                            [0.47, 0.13],
                            [0.36, -0.02],
                            [0.28, -0.26],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.25, 0.68],
                            [-0.39, 0.48],
                            [-0.53, 0.24],
                            [0, 0],
                          ],
                          v: [
                            [54.81, 31.91],
                            [53.77, 31.82],
                            [53.07, 31.63],
                            [53.69, 29.54],
                            [54.93, 29.7],
                            [55.89, 29.29],
                            [56.64, 28.04],
                            [56.96, 27.17],
                            [52.17, 13.91],
                            [54.9, 13.91],
                            [58.21, 24.07],
                            [58.35, 24.07],
                            [61.66, 13.91],
                            [64.4, 13.91],
                            [59, 28.75],
                            [58.05, 30.48],
                            [56.67, 31.55],
                            [54.81, 31.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [78.78, 13.91],
                            [78.78, 15.95],
                            [71.63, 15.95],
                            [71.63, 13.91],
                            [78.78, 13.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [78.78, 13.91],
                            [78.78, 15.95],
                            [71.63, 15.95],
                            [71.63, 13.91],
                            [78.78, 13.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, -0.25],
                            [-0.23, -0.09],
                            [-0.28, 0],
                            [-0.15, 0.03],
                            [-0.09, 0.02],
                            [0, 0],
                            [0.27, -0.06],
                            [0.41, -0.01],
                            [0.58, 0.25],
                            [0.36, 0.52],
                            [0, 0.79],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.49],
                            [0.15, 0.24],
                            [0.24, 0.09],
                            [0.2, 0],
                            [0.15, -0.03],
                            [0, 0],
                            [-0.15, 0.06],
                            [-0.27, 0.06],
                            [-0.67, 0.01],
                            [-0.58, -0.25],
                            [-0.36, -0.52],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [73.54, 10.77],
                            [76.09, 10.77],
                            [76.09, 23.16],
                            [76.31, 24.27],
                            [76.88, 24.78],
                            [77.66, 24.9],
                            [78.2, 24.86],
                            [78.55, 24.79],
                            [79.01, 26.9],
                            [78.38, 27.07],
                            [77.36, 27.17],
                            [75.49, 26.81],
                            [74.08, 25.65],
                            [73.54, 23.68],
                            [73.54, 10.77],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, -0.25],
                            [-0.23, -0.09],
                            [-0.28, 0],
                            [-0.15, 0.03],
                            [-0.09, 0.02],
                            [0, 0],
                            [0.27, -0.06],
                            [0.41, -0.01],
                            [0.58, 0.25],
                            [0.36, 0.52],
                            [0, 0.79],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.49],
                            [0.15, 0.24],
                            [0.24, 0.09],
                            [0.2, 0],
                            [0.15, -0.03],
                            [0, 0],
                            [-0.15, 0.06],
                            [-0.27, 0.06],
                            [-0.67, 0.01],
                            [-0.58, -0.25],
                            [-0.36, -0.52],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [73.54, 10.77],
                            [76.09, 10.77],
                            [76.09, 23.16],
                            [76.31, 24.27],
                            [76.88, 24.78],
                            [77.66, 24.9],
                            [78.2, 24.86],
                            [78.55, 24.79],
                            [79.01, 26.9],
                            [78.38, 27.07],
                            [77.36, 27.17],
                            [75.49, 26.81],
                            [74.08, 25.65],
                            [73.54, 23.68],
                            [73.54, 10.77],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.51, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.51, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.91, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [86.73, 27.26],
                            [83.52, 26.42],
                            [81.38, 24.06],
                            [80.63, 20.51],
                            [81.38, 16.95],
                            [83.52, 14.58],
                            [86.73, 13.74],
                            [89.94, 14.58],
                            [92.07, 16.95],
                            [92.83, 20.51],
                            [92.07, 24.06],
                            [89.94, 26.42],
                            [86.73, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.51, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.51, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.91, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [86.73, 27.26],
                            [83.52, 26.42],
                            [81.38, 24.06],
                            [80.63, 20.51],
                            [81.38, 16.95],
                            [83.52, 14.58],
                            [86.73, 13.74],
                            [89.94, 14.58],
                            [92.07, 16.95],
                            [92.83, 20.51],
                            [92.07, 24.06],
                            [89.94, 26.42],
                            [86.73, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [86.74, 25.13],
                            [88.71, 24.49],
                            [89.87, 22.82],
                            [90.26, 20.51],
                            [89.87, 18.2],
                            [88.71, 16.51],
                            [86.74, 15.87],
                            [84.74, 16.51],
                            [83.58, 18.2],
                            [83.2, 20.51],
                            [83.58, 22.82],
                            [84.74, 24.49],
                            [86.74, 25.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [86.74, 25.13],
                            [88.71, 24.49],
                            [89.87, 22.82],
                            [90.26, 20.51],
                            [89.87, 18.2],
                            [88.71, 16.51],
                            [86.74, 15.87],
                            [84.74, 16.51],
                            [83.58, 18.2],
                            [83.2, 20.51],
                            [83.58, 22.82],
                            [84.74, 24.49],
                            [86.74, 25.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [101.75, 9.55],
                            [104.95, 9.55],
                            [110.5, 23.11],
                            [110.71, 23.11],
                            [116.27, 9.55],
                            [119.46, 9.55],
                            [119.46, 27],
                            [116.96, 27],
                            [116.96, 14.37],
                            [116.79, 14.37],
                            [111.65, 26.97],
                            [109.57, 26.97],
                            [104.42, 14.36],
                            [104.26, 14.36],
                            [104.26, 27],
                            [101.75, 27],
                            [101.75, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [101.75, 9.55],
                            [104.95, 9.55],
                            [110.5, 23.11],
                            [110.71, 23.11],
                            [116.27, 9.55],
                            [119.46, 9.55],
                            [119.46, 27],
                            [116.96, 27],
                            [116.96, 14.37],
                            [116.79, 14.37],
                            [111.65, 26.97],
                            [109.57, 26.97],
                            [104.42, 14.36],
                            [104.26, 14.36],
                            [104.26, 27],
                            [101.75, 27],
                            [101.75, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.92, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [128.36, 27.26],
                            [125.15, 26.42],
                            [123.02, 24.06],
                            [122.26, 20.51],
                            [123.02, 16.95],
                            [125.15, 14.58],
                            [128.36, 13.74],
                            [131.57, 14.58],
                            [133.7, 16.95],
                            [134.46, 20.51],
                            [133.7, 24.06],
                            [131.57, 26.42],
                            [128.36, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.92, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [128.36, 27.26],
                            [125.15, 26.42],
                            [123.02, 24.06],
                            [122.26, 20.51],
                            [123.02, 16.95],
                            [125.15, 14.58],
                            [128.36, 13.74],
                            [131.57, 14.58],
                            [133.7, 16.95],
                            [134.46, 20.51],
                            [133.7, 24.06],
                            [131.57, 26.42],
                            [128.36, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [128.37, 25.13],
                            [130.35, 24.49],
                            [131.51, 22.82],
                            [131.89, 20.51],
                            [131.51, 18.2],
                            [130.35, 16.51],
                            [128.37, 15.87],
                            [126.37, 16.51],
                            [125.21, 18.2],
                            [124.83, 20.51],
                            [125.21, 22.82],
                            [126.37, 24.49],
                            [128.37, 25.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [128.37, 25.13],
                            [130.35, 24.49],
                            [131.51, 22.82],
                            [131.89, 20.51],
                            [131.51, 18.2],
                            [130.35, 16.51],
                            [128.37, 15.87],
                            [126.37, 16.51],
                            [125.21, 18.2],
                            [124.83, 20.51],
                            [125.21, 22.82],
                            [126.37, 24.49],
                            [128.37, 25.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.54],
                            [0.47, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.83, 0.53],
                            [-1.05, 0],
                            [-0.5, -0.27],
                            [-0.27, -0.36],
                            [-0.15, -0.27],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, -0.36],
                            [0.5, -0.27],
                            [0.8, 0],
                          ],
                          o: [
                            [-1.06, 0],
                            [-0.82, -0.55],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.48, -1.01],
                            [0.83, -0.53],
                            [0.81, 0],
                            [0.5, 0.27],
                            [0.28, 0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.28],
                            [-0.28, 0.36],
                            [-0.5, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [141.97, 27.26],
                            [139.14, 26.45],
                            [137.2, 24.12],
                            [136.5, 20.48],
                            [137.2, 16.85],
                            [139.16, 14.54],
                            [141.98, 13.74],
                            [143.95, 14.15],
                            [145.1, 15.09],
                            [145.75, 16.03],
                            [145.91, 16.03],
                            [145.91, 9.55],
                            [148.45, 9.55],
                            [148.45, 27],
                            [145.96, 27],
                            [145.96, 24.96],
                            [145.75, 24.96],
                            [145.09, 25.92],
                            [143.91, 26.86],
                            [141.97, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.54],
                            [0.47, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.83, 0.53],
                            [-1.05, 0],
                            [-0.5, -0.27],
                            [-0.27, -0.36],
                            [-0.15, -0.27],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, -0.36],
                            [0.5, -0.27],
                            [0.8, 0],
                          ],
                          o: [
                            [-1.06, 0],
                            [-0.82, -0.55],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.48, -1.01],
                            [0.83, -0.53],
                            [0.81, 0],
                            [0.5, 0.27],
                            [0.28, 0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.28],
                            [-0.28, 0.36],
                            [-0.5, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [141.97, 27.26],
                            [139.14, 26.45],
                            [137.2, 24.12],
                            [136.5, 20.48],
                            [137.2, 16.85],
                            [139.16, 14.54],
                            [141.98, 13.74],
                            [143.95, 14.15],
                            [145.1, 15.09],
                            [145.75, 16.03],
                            [145.91, 16.03],
                            [145.91, 9.55],
                            [148.45, 9.55],
                            [148.45, 27],
                            [145.96, 27],
                            [145.96, 24.96],
                            [145.75, 24.96],
                            [145.09, 25.92],
                            [143.91, 26.86],
                            [141.97, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.51, 0.39],
                            [-0.26, 0.69],
                            [0, 0.92],
                            [0.26, 0.68],
                            [0.5, 0.38],
                            [0.74, 0],
                            [0.51, -0.4],
                            [0.26, -0.69],
                            [0, -0.86],
                            [-0.26, -0.7],
                            [-0.51, -0.41],
                            [-0.76, 0],
                          ],
                          o: [
                            [0.73, 0],
                            [0.51, -0.39],
                            [0.27, -0.69],
                            [0, -0.91],
                            [-0.26, -0.68],
                            [-0.51, -0.38],
                            [-0.77, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.69],
                            [0, 0.88],
                            [0.26, 0.7],
                            [0.52, 0.4],
                            [0, 0],
                          ],
                          v: [
                            [142.53, 25.08],
                            [144.39, 24.5],
                            [145.55, 22.88],
                            [145.95, 20.45],
                            [145.56, 18.07],
                            [144.41, 16.47],
                            [142.53, 15.9],
                            [140.61, 16.5],
                            [139.45, 18.13],
                            [139.07, 20.45],
                            [139.46, 22.82],
                            [140.62, 24.48],
                            [142.53, 25.08],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.51, 0.39],
                            [-0.26, 0.69],
                            [0, 0.92],
                            [0.26, 0.68],
                            [0.5, 0.38],
                            [0.74, 0],
                            [0.51, -0.4],
                            [0.26, -0.69],
                            [0, -0.86],
                            [-0.26, -0.7],
                            [-0.51, -0.41],
                            [-0.76, 0],
                          ],
                          o: [
                            [0.73, 0],
                            [0.51, -0.39],
                            [0.27, -0.69],
                            [0, -0.91],
                            [-0.26, -0.68],
                            [-0.51, -0.38],
                            [-0.77, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.69],
                            [0, 0.88],
                            [0.26, 0.7],
                            [0.52, 0.4],
                            [0, 0],
                          ],
                          v: [
                            [142.53, 25.08],
                            [144.39, 24.5],
                            [145.55, 22.88],
                            [145.95, 20.45],
                            [145.56, 18.07],
                            [144.41, 16.47],
                            [142.53, 15.9],
                            [140.61, 16.5],
                            [139.45, 18.13],
                            [139.07, 20.45],
                            [139.46, 22.82],
                            [140.62, 24.48],
                            [142.53, 25.08],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.75, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.96, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [157.49, 27.26],
                            [154.16, 26.44],
                            [152.01, 24.09],
                            [151.26, 20.54],
                            [152.01, 16.99],
                            [154.13, 14.6],
                            [157.31, 13.74],
                            [159.46, 14.11],
                            [161.33, 15.26],
                            [162.63, 17.29],
                            [163.11, 20.32],
                            [163.11, 21.22],
                            [152.7, 21.22],
                            [152.7, 19.31],
                            [160.61, 19.31],
                            [160.2, 17.53],
                            [159.05, 16.3],
                            [157.33, 15.84],
                            [155.46, 16.37],
                            [154.23, 17.74],
                            [153.8, 19.55],
                            [153.8, 21.04],
                            [154.26, 23.28],
                            [155.57, 24.68],
                            [157.52, 25.16],
                            [158.84, 24.95],
                            [159.87, 24.32],
                            [160.54, 23.29],
                            [162.95, 23.73],
                            [161.91, 25.59],
                            [160.04, 26.83],
                            [157.49, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.75, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.96, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [157.49, 27.26],
                            [154.16, 26.44],
                            [152.01, 24.09],
                            [151.26, 20.54],
                            [152.01, 16.99],
                            [154.13, 14.6],
                            [157.31, 13.74],
                            [159.46, 14.11],
                            [161.33, 15.26],
                            [162.63, 17.29],
                            [163.11, 20.32],
                            [163.11, 21.22],
                            [152.7, 21.22],
                            [152.7, 19.31],
                            [160.61, 19.31],
                            [160.2, 17.53],
                            [159.05, 16.3],
                            [157.33, 15.84],
                            [155.46, 16.37],
                            [154.23, 17.74],
                            [153.8, 19.55],
                            [153.8, 21.04],
                            [154.26, 23.28],
                            [155.57, 24.68],
                            [157.52, 25.16],
                            [158.84, 24.95],
                            [159.87, 24.32],
                            [160.54, 23.29],
                            [162.95, 23.73],
                            [161.91, 25.59],
                            [160.04, 26.83],
                            [157.49, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [168.24, 9.55],
                            [168.24, 27],
                            [165.7, 27],
                            [165.7, 9.55],
                            [168.24, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [168.24, 9.55],
                            [168.24, 27],
                            [165.7, 27],
                            [165.7, 9.55],
                            [168.24, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1, 1, 1],
                      t: 0,
                    },
                    { s: [1, 1, 1], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'button Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [97, 24],
                    t: 0,
                  },
                  { s: [97, 24], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [97, 24],
                    t: 0,
                  },
                  { s: [97, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [194, 4],
                            [194, 44],
                            [190, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [190, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [194, 4],
                            [194, 44],
                            [190, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [190, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.1647, 0.353, 0.4353],
                      t: 0,
                    },
                    { s: [0.1647, 0.353, 0.4353], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] divider',
        id: '6',
        layers: [
          {
            ty: 4,
            nm: 'Border',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 0,
                  },
                  { s: [24, 0], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 0,
                  },
                  { s: [24, 0], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [48, 0],
                            [48, 0],
                            [0, 0],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [48, 0],
                            [48, 0],
                            [0, 0],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                nm: '',
                lc: 1,
                lj: 1,
                ml: 4,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
                w: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1.5],
                      t: 0,
                    },
                    { s: [1.5], t: 12 },
                  ],
                },
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.4628, 0.604, 0.6706],
                      t: 0,
                    },
                    { s: [0.4628, 0.604, 0.6706], t: 12 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'divider Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 0,
                  },
                  { s: [24, 0], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [24, 0],
                    t: 0,
                  },
                  { s: [24, 0], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [48, 0],
                            [48, 0],
                            [0, 0],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [48, 0],
                            [48, 0],
                            [0, 0],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1, 1, 1],
                      t: 0,
                    },
                    { s: [1, 1, 1], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0],
                      t: 0,
                    },
                    { s: [0], t: 12 },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] Frame 1020883',
        id: '7',
        layers: [
          {
            ty: 0,
            nm: 'button',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: true,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [92.5, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [92.5, 24],
                    t: 12,
                  },
                  { s: [92.5, 24], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [214.5, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [214.5, 24],
                    t: 12,
                  },
                  { s: [214.5, 24], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            masksProperties: [
              {
                nm: '',
                inv: false,
                mode: 'a',
                x: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
                pt: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [185, 4],
                            [185, 44],
                            [181, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [181, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [185, 4],
                            [185, 44],
                            [181, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [181, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [185, 4],
                            [185, 44],
                            [181, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [181, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
            ],
            w: 211.5,
            h: 100.5,
            refId: '8',
            ind: 1,
          },
          {
            ty: 0,
            nm: 'button',
            sr: 1,
            st: 0,
            op: 1,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: true,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [55, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [55, 24],
                    t: 12,
                  },
                  { s: [55, 24], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [55, 24],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [55, 24],
                    t: 12,
                  },
                  { s: [55, 24], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            masksProperties: [
              {
                nm: '',
                inv: false,
                mode: 'a',
                x: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
                pt: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [110, 4],
                            [110, 44],
                            [106, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [106, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [110, 4],
                            [110, 44],
                            [106, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [106, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [110, 4],
                            [110, 44],
                            [106, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [106, 0],
                          ],
                        },
                      ],
                      t: 72,
                    },
                  ],
                },
              },
            ],
            w: 211.5,
            h: 100.5,
            refId: '9',
            ind: 2,
          },
          {
            ty: 4,
            nm: 'Frame 1020883 Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 0,
                  },
                  { s: [153.5, 24], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [153.5, 24],
                    t: 0,
                  },
                  { s: [153.5, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [307, 0],
                            [307, 48],
                            [0, 48],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [307, 0],
                            [307, 48],
                            [0, 48],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            ind: 3,
          },
        ],
      },
      {
        nm: '[Asset] button',
        id: '8',
        layers: [
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [80.5, 18],
                    t: 0,
                  },
                  { s: [80.5, 18], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [92.5, 24],
                    t: 0,
                  },
                  { s: [92.5, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.86, 14.13],
                            [10.74, 12.26],
                            [8.25, 11.58],
                            [6.39, 11.92],
                            [5.17, 12.84],
                            [4.75, 14.16],
                            [5.04, 15.24],
                            [5.81, 16],
                            [6.85, 16.5],
                            [7.92, 16.82],
                            [9.62, 17.27],
                            [11.33, 17.82],
                            [12.96, 18.74],
                            [14.17, 20.16],
                            [14.64, 22.18],
                            [13.87, 24.81],
                            [11.67, 26.63],
                            [8.18, 27.29],
                            [4.79, 26.67],
                            [2.55, 24.9],
                            [1.65, 22.18],
                            [4.29, 22.18],
                            [4.91, 23.75],
                            [6.3, 24.68],
                            [8.16, 24.98],
                            [10.15, 24.63],
                            [11.53, 23.64],
                            [12.03, 22.15],
                            [11.59, 20.88],
                            [10.4, 20.06],
                            [8.73, 19.49],
                            [6.66, 18.93],
                            [3.34, 17.25],
                            [2.12, 14.32],
                            [2.94, 11.68],
                            [5.15, 9.94],
                            [8.31, 9.31],
                            [11.44, 9.93],
                            [13.58, 11.64],
                            [14.4, 14.13],
                            [11.86, 14.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.86, 14.13],
                            [10.74, 12.26],
                            [8.25, 11.58],
                            [6.39, 11.92],
                            [5.17, 12.84],
                            [4.75, 14.16],
                            [5.04, 15.24],
                            [5.81, 16],
                            [6.85, 16.5],
                            [7.92, 16.82],
                            [9.62, 17.27],
                            [11.33, 17.82],
                            [12.96, 18.74],
                            [14.17, 20.16],
                            [14.64, 22.18],
                            [13.87, 24.81],
                            [11.67, 26.63],
                            [8.18, 27.29],
                            [4.79, 26.67],
                            [2.55, 24.9],
                            [1.65, 22.18],
                            [4.29, 22.18],
                            [4.91, 23.75],
                            [6.3, 24.68],
                            [8.16, 24.98],
                            [10.15, 24.63],
                            [11.53, 23.64],
                            [12.03, 22.15],
                            [11.59, 20.88],
                            [10.4, 20.06],
                            [8.73, 19.49],
                            [6.66, 18.93],
                            [3.34, 17.25],
                            [2.12, 14.32],
                            [2.94, 11.68],
                            [5.15, 9.94],
                            [8.31, 9.31],
                            [11.44, 9.93],
                            [13.58, 11.64],
                            [14.4, 14.13],
                            [11.86, 14.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.49, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.16, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [21.15, 27.29],
                            [18.9, 26.83],
                            [17.31, 25.47],
                            [16.73, 23.3],
                            [17.16, 21.48],
                            [18.31, 20.34],
                            [19.93, 19.71],
                            [21.75, 19.39],
                            [23.66, 19.17],
                            [24.72, 18.87],
                            [25.06, 18.2],
                            [25.06, 18.14],
                            [24.43, 16.41],
                            [22.57, 15.8],
                            [20.54, 16.37],
                            [19.52, 17.63],
                            [17.13, 17.08],
                            [18.37, 15.15],
                            [20.27, 14.08],
                            [22.53, 13.74],
                            [24.2, 13.93],
                            [25.85, 14.59],
                            [27.12, 15.98],
                            [27.61, 18.31],
                            [27.61, 27],
                            [25.12, 27],
                            [25.12, 25.21],
                            [25.02, 25.21],
                            [24.28, 26.18],
                            [23.01, 26.97],
                            [21.15, 27.29],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.49, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.16, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [21.15, 27.29],
                            [18.9, 26.83],
                            [17.31, 25.47],
                            [16.73, 23.3],
                            [17.16, 21.48],
                            [18.31, 20.34],
                            [19.93, 19.71],
                            [21.75, 19.39],
                            [23.66, 19.17],
                            [24.72, 18.87],
                            [25.06, 18.2],
                            [25.06, 18.14],
                            [24.43, 16.41],
                            [22.57, 15.8],
                            [20.54, 16.37],
                            [19.52, 17.63],
                            [17.13, 17.08],
                            [18.37, 15.15],
                            [20.27, 14.08],
                            [22.53, 13.74],
                            [24.2, 13.93],
                            [25.85, 14.59],
                            [27.12, 15.98],
                            [27.61, 18.31],
                            [27.61, 27],
                            [25.12, 27],
                            [25.12, 25.21],
                            [25.02, 25.21],
                            [24.28, 26.18],
                            [23.01, 26.97],
                            [21.15, 27.29],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.51, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.26, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [21.71, 25.24],
                            [23.51, 24.83],
                            [24.66, 23.74],
                            [25.06, 22.28],
                            [25.06, 20.59],
                            [24.54, 20.85],
                            [23.66, 21.04],
                            [22.7, 21.19],
                            [21.91, 21.29],
                            [20.56, 21.61],
                            [19.58, 22.24],
                            [19.23, 23.34],
                            [19.93, 24.77],
                            [21.71, 25.24],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.51, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.26, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [21.71, 25.24],
                            [23.51, 24.83],
                            [24.66, 23.74],
                            [25.06, 22.28],
                            [25.06, 20.59],
                            [24.54, 20.85],
                            [23.66, 21.04],
                            [22.7, 21.19],
                            [21.91, 21.29],
                            [20.56, 21.61],
                            [19.58, 22.24],
                            [19.23, 23.34],
                            [19.93, 24.77],
                            [21.71, 25.24],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [41.5, 13.91],
                            [36.75, 27],
                            [34.02, 27],
                            [29.27, 13.91],
                            [32, 13.91],
                            [35.32, 23.98],
                            [35.45, 23.98],
                            [38.76, 13.91],
                            [41.5, 13.91],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [41.5, 13.91],
                            [36.75, 27],
                            [34.02, 27],
                            [29.27, 13.91],
                            [32, 13.91],
                            [35.32, 23.98],
                            [35.45, 23.98],
                            [38.76, 13.91],
                            [41.5, 13.91],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [48.85, 27.26],
                            [45.52, 26.44],
                            [43.37, 24.09],
                            [42.62, 20.54],
                            [43.37, 16.99],
                            [45.48, 14.6],
                            [48.67, 13.74],
                            [50.82, 14.11],
                            [52.68, 15.26],
                            [53.99, 17.29],
                            [54.47, 20.32],
                            [54.47, 21.22],
                            [44.06, 21.22],
                            [44.06, 19.31],
                            [51.97, 19.31],
                            [51.56, 17.53],
                            [50.41, 16.3],
                            [48.69, 15.84],
                            [46.81, 16.37],
                            [45.58, 17.74],
                            [45.16, 19.55],
                            [45.16, 21.04],
                            [45.62, 23.28],
                            [46.92, 24.68],
                            [48.87, 25.16],
                            [50.2, 24.95],
                            [51.23, 24.32],
                            [51.89, 23.29],
                            [54.3, 23.73],
                            [53.26, 25.59],
                            [51.4, 26.83],
                            [48.85, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [48.85, 27.26],
                            [45.52, 26.44],
                            [43.37, 24.09],
                            [42.62, 20.54],
                            [43.37, 16.99],
                            [45.48, 14.6],
                            [48.67, 13.74],
                            [50.82, 14.11],
                            [52.68, 15.26],
                            [53.99, 17.29],
                            [54.47, 20.32],
                            [54.47, 21.22],
                            [44.06, 21.22],
                            [44.06, 19.31],
                            [51.97, 19.31],
                            [51.56, 17.53],
                            [50.41, 16.3],
                            [48.69, 15.84],
                            [46.81, 16.37],
                            [45.58, 17.74],
                            [45.16, 19.55],
                            [45.16, 21.04],
                            [45.62, 23.28],
                            [46.92, 24.68],
                            [48.87, 25.16],
                            [50.2, 24.95],
                            [51.23, 24.32],
                            [51.89, 23.29],
                            [54.3, 23.73],
                            [53.26, 25.59],
                            [51.4, 26.83],
                            [48.85, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [72.89, 14.13],
                            [71.76, 12.26],
                            [69.27, 11.58],
                            [67.42, 11.92],
                            [66.2, 12.84],
                            [65.77, 14.16],
                            [66.06, 15.24],
                            [66.84, 16],
                            [67.88, 16.5],
                            [68.94, 16.82],
                            [70.65, 17.27],
                            [72.36, 17.82],
                            [73.99, 18.74],
                            [75.2, 20.16],
                            [75.67, 22.18],
                            [74.9, 24.81],
                            [72.69, 26.63],
                            [69.21, 27.29],
                            [65.81, 26.67],
                            [63.57, 24.9],
                            [62.68, 22.18],
                            [65.32, 22.18],
                            [65.93, 23.75],
                            [67.32, 24.68],
                            [69.19, 24.98],
                            [71.18, 24.63],
                            [72.56, 23.64],
                            [73.06, 22.15],
                            [72.62, 20.88],
                            [71.43, 20.06],
                            [69.75, 19.49],
                            [67.69, 18.93],
                            [64.37, 17.25],
                            [63.15, 14.32],
                            [63.97, 11.68],
                            [66.18, 9.94],
                            [69.33, 9.31],
                            [72.46, 9.93],
                            [74.61, 11.64],
                            [75.43, 14.13],
                            [72.89, 14.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [72.89, 14.13],
                            [71.76, 12.26],
                            [69.27, 11.58],
                            [67.42, 11.92],
                            [66.2, 12.84],
                            [65.77, 14.16],
                            [66.06, 15.24],
                            [66.84, 16],
                            [67.88, 16.5],
                            [68.94, 16.82],
                            [70.65, 17.27],
                            [72.36, 17.82],
                            [73.99, 18.74],
                            [75.2, 20.16],
                            [75.67, 22.18],
                            [74.9, 24.81],
                            [72.69, 26.63],
                            [69.21, 27.29],
                            [65.81, 26.67],
                            [63.57, 24.9],
                            [62.68, 22.18],
                            [65.32, 22.18],
                            [65.93, 23.75],
                            [67.32, 24.68],
                            [69.19, 24.98],
                            [71.18, 24.63],
                            [72.56, 23.64],
                            [73.06, 22.15],
                            [72.62, 20.88],
                            [71.43, 20.06],
                            [69.75, 19.49],
                            [67.69, 18.93],
                            [64.37, 17.25],
                            [63.15, 14.32],
                            [63.97, 11.68],
                            [66.18, 9.94],
                            [69.33, 9.31],
                            [72.46, 9.93],
                            [74.61, 11.64],
                            [75.43, 14.13],
                            [72.89, 14.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.57],
                            [0.49, 1.02],
                            [0, 1.31],
                            [-0.5, 1.02],
                            [-0.91, 0.57],
                            [-1.23, 0],
                            [-0.78, -0.37],
                            [-0.48, -0.66],
                            [-0.09, -0.88],
                            [0, 0],
                            [0.49, 0.44],
                            [0.81, 0],
                            [0.53, -0.37],
                            [0.3, -0.69],
                            [0, -0.93],
                            [-0.29, -0.69],
                            [-0.53, -0.38],
                            [-0.72, 0],
                            [-0.39, 0.18],
                            [-0.27, 0.34],
                            [-0.11, 0.47],
                            [0, 0],
                            [0.46, -0.66],
                            [0.77, -0.38],
                            [1.03, 0],
                          ],
                          o: [
                            [-1.27, 0],
                            [-0.91, -0.58],
                            [-0.49, -1.02],
                            [0, -1.33],
                            [0.5, -1.02],
                            [0.91, -0.57],
                            [0.99, 0],
                            [0.78, 0.36],
                            [0.48, 0.66],
                            [0, 0],
                            [-0.14, -0.61],
                            [-0.48, -0.44],
                            [-0.71, 0],
                            [-0.53, 0.37],
                            [-0.3, 0.68],
                            [0, 0.95],
                            [0.29, 0.69],
                            [0.53, 0.38],
                            [0.48, 0],
                            [0.4, -0.18],
                            [0.27, -0.34],
                            [0, 0],
                            [-0.09, 0.85],
                            [-0.46, 0.66],
                            [-0.77, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [83.88, 27.26],
                            [80.61, 26.4],
                            [78.51, 24.01],
                            [77.78, 20.51],
                            [78.53, 16.99],
                            [80.64, 14.6],
                            [83.86, 13.74],
                            [86.52, 14.29],
                            [88.4, 15.83],
                            [89.26, 18.14],
                            [86.78, 18.14],
                            [85.84, 16.55],
                            [83.9, 15.89],
                            [82.03, 16.45],
                            [80.8, 18.03],
                            [80.36, 20.45],
                            [80.79, 22.93],
                            [82.02, 24.54],
                            [83.9, 25.11],
                            [85.21, 24.84],
                            [86.21, 24.07],
                            [86.78, 22.86],
                            [89.26, 22.86],
                            [88.43, 25.13],
                            [86.59, 26.69],
                            [83.88, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.57],
                            [0.49, 1.02],
                            [0, 1.31],
                            [-0.5, 1.02],
                            [-0.91, 0.57],
                            [-1.23, 0],
                            [-0.78, -0.37],
                            [-0.48, -0.66],
                            [-0.09, -0.88],
                            [0, 0],
                            [0.49, 0.44],
                            [0.81, 0],
                            [0.53, -0.37],
                            [0.3, -0.69],
                            [0, -0.93],
                            [-0.29, -0.69],
                            [-0.53, -0.38],
                            [-0.72, 0],
                            [-0.39, 0.18],
                            [-0.27, 0.34],
                            [-0.11, 0.47],
                            [0, 0],
                            [0.46, -0.66],
                            [0.77, -0.38],
                            [1.03, 0],
                          ],
                          o: [
                            [-1.27, 0],
                            [-0.91, -0.58],
                            [-0.49, -1.02],
                            [0, -1.33],
                            [0.5, -1.02],
                            [0.91, -0.57],
                            [0.99, 0],
                            [0.78, 0.36],
                            [0.48, 0.66],
                            [0, 0],
                            [-0.14, -0.61],
                            [-0.48, -0.44],
                            [-0.71, 0],
                            [-0.53, 0.37],
                            [-0.3, 0.68],
                            [0, 0.95],
                            [0.29, 0.69],
                            [0.53, 0.38],
                            [0.48, 0],
                            [0.4, -0.18],
                            [0.27, -0.34],
                            [0, 0],
                            [-0.09, 0.85],
                            [-0.46, 0.66],
                            [-0.77, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [83.88, 27.26],
                            [80.61, 26.4],
                            [78.51, 24.01],
                            [77.78, 20.51],
                            [78.53, 16.99],
                            [80.64, 14.6],
                            [83.86, 13.74],
                            [86.52, 14.29],
                            [88.4, 15.83],
                            [89.26, 18.14],
                            [86.78, 18.14],
                            [85.84, 16.55],
                            [83.9, 15.89],
                            [82.03, 16.45],
                            [80.8, 18.03],
                            [80.36, 20.45],
                            [80.79, 22.93],
                            [82.02, 24.54],
                            [83.9, 25.11],
                            [85.21, 24.84],
                            [86.21, 24.07],
                            [86.78, 22.86],
                            [89.26, 22.86],
                            [88.43, 25.13],
                            [86.59, 26.69],
                            [83.88, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [97.39, 27.26],
                            [94.06, 26.44],
                            [91.91, 24.09],
                            [91.16, 20.54],
                            [91.91, 16.99],
                            [94.02, 14.6],
                            [97.21, 13.74],
                            [99.36, 14.11],
                            [101.22, 15.26],
                            [102.53, 17.29],
                            [103.01, 20.32],
                            [103.01, 21.22],
                            [92.6, 21.22],
                            [92.6, 19.31],
                            [100.51, 19.31],
                            [100.1, 17.53],
                            [98.95, 16.3],
                            [97.23, 15.84],
                            [95.35, 16.37],
                            [94.12, 17.74],
                            [93.7, 19.55],
                            [93.7, 21.04],
                            [94.16, 23.28],
                            [95.46, 24.68],
                            [97.41, 25.16],
                            [98.74, 24.95],
                            [99.77, 24.32],
                            [100.43, 23.29],
                            [102.84, 23.73],
                            [101.8, 25.59],
                            [99.94, 26.83],
                            [97.39, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [97.39, 27.26],
                            [94.06, 26.44],
                            [91.91, 24.09],
                            [91.16, 20.54],
                            [91.91, 16.99],
                            [94.02, 14.6],
                            [97.21, 13.74],
                            [99.36, 14.11],
                            [101.22, 15.26],
                            [102.53, 17.29],
                            [103.01, 20.32],
                            [103.01, 21.22],
                            [92.6, 21.22],
                            [92.6, 19.31],
                            [100.51, 19.31],
                            [100.1, 17.53],
                            [98.95, 16.3],
                            [97.23, 15.84],
                            [95.35, 16.37],
                            [94.12, 17.74],
                            [93.7, 19.55],
                            [93.7, 21.04],
                            [94.16, 23.28],
                            [95.46, 24.68],
                            [97.41, 25.16],
                            [98.74, 24.95],
                            [99.77, 24.32],
                            [100.43, 23.29],
                            [102.84, 23.73],
                            [101.8, 25.59],
                            [99.94, 26.83],
                            [97.39, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.64, 0.42],
                            [-0.98, 0],
                            [-0.67, -0.37],
                            [-0.37, -0.73],
                            [0, -1.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.49, 0.53],
                            [0.86, 0],
                            [0.46, -0.26],
                            [0.27, -0.49],
                            [0, -0.69],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, -0.69],
                            [0.65, -0.42],
                            [0.89, 0],
                            [0.67, 0.37],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.95],
                            [-0.5, -0.54],
                            [-0.59, 0],
                            [-0.45, 0.26],
                            [-0.26, 0.49],
                            [0, 0],
                          ],
                          v: [
                            [108.14, 19.23],
                            [108.14, 27],
                            [105.59, 27],
                            [105.59, 13.91],
                            [108.04, 13.91],
                            [108.04, 16.04],
                            [108.2, 16.04],
                            [109.62, 14.37],
                            [112.06, 13.74],
                            [114.41, 14.3],
                            [115.97, 15.95],
                            [116.52, 18.67],
                            [116.52, 27],
                            [113.97, 27],
                            [113.97, 18.98],
                            [113.23, 16.76],
                            [111.19, 15.95],
                            [109.62, 16.33],
                            [108.53, 17.45],
                            [108.14, 19.23],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.64, 0.42],
                            [-0.98, 0],
                            [-0.67, -0.37],
                            [-0.37, -0.73],
                            [0, -1.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.49, 0.53],
                            [0.86, 0],
                            [0.46, -0.26],
                            [0.27, -0.49],
                            [0, -0.69],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, -0.69],
                            [0.65, -0.42],
                            [0.89, 0],
                            [0.67, 0.37],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.95],
                            [-0.5, -0.54],
                            [-0.59, 0],
                            [-0.45, 0.26],
                            [-0.26, 0.49],
                            [0, 0],
                          ],
                          v: [
                            [108.14, 19.23],
                            [108.14, 27],
                            [105.59, 27],
                            [105.59, 13.91],
                            [108.04, 13.91],
                            [108.04, 16.04],
                            [108.2, 16.04],
                            [109.62, 14.37],
                            [112.06, 13.74],
                            [114.41, 14.3],
                            [115.97, 15.95],
                            [116.52, 18.67],
                            [116.52, 27],
                            [113.97, 27],
                            [113.97, 18.98],
                            [113.23, 16.76],
                            [111.19, 15.95],
                            [109.62, 16.33],
                            [108.53, 17.45],
                            [108.14, 19.23],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.5, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.17, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [123.51, 27.29],
                            [121.26, 26.83],
                            [119.66, 25.47],
                            [119.08, 23.3],
                            [119.51, 21.48],
                            [120.66, 20.34],
                            [122.28, 19.71],
                            [124.1, 19.39],
                            [126.01, 19.17],
                            [127.08, 18.87],
                            [127.41, 18.2],
                            [127.41, 18.14],
                            [126.78, 16.41],
                            [124.92, 15.8],
                            [122.89, 16.37],
                            [121.88, 17.63],
                            [119.48, 17.08],
                            [120.73, 15.15],
                            [122.63, 14.08],
                            [124.89, 13.74],
                            [126.55, 13.93],
                            [128.2, 14.59],
                            [129.47, 15.98],
                            [129.97, 18.31],
                            [129.97, 27],
                            [127.48, 27],
                            [127.48, 25.21],
                            [127.38, 25.21],
                            [126.63, 26.18],
                            [125.36, 26.97],
                            [123.51, 27.29],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.5, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.17, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [123.51, 27.29],
                            [121.26, 26.83],
                            [119.66, 25.47],
                            [119.08, 23.3],
                            [119.51, 21.48],
                            [120.66, 20.34],
                            [122.28, 19.71],
                            [124.1, 19.39],
                            [126.01, 19.17],
                            [127.08, 18.87],
                            [127.41, 18.2],
                            [127.41, 18.14],
                            [126.78, 16.41],
                            [124.92, 15.8],
                            [122.89, 16.37],
                            [121.88, 17.63],
                            [119.48, 17.08],
                            [120.73, 15.15],
                            [122.63, 14.08],
                            [124.89, 13.74],
                            [126.55, 13.93],
                            [128.2, 14.59],
                            [129.47, 15.98],
                            [129.97, 18.31],
                            [129.97, 27],
                            [127.48, 27],
                            [127.48, 25.21],
                            [127.38, 25.21],
                            [126.63, 26.18],
                            [125.36, 26.97],
                            [123.51, 27.29],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.5, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.25, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [124.06, 25.24],
                            [125.87, 24.83],
                            [127.02, 23.74],
                            [127.42, 22.28],
                            [127.42, 20.59],
                            [126.89, 20.85],
                            [126.01, 21.04],
                            [125.05, 21.19],
                            [124.26, 21.29],
                            [122.91, 21.61],
                            [121.94, 22.24],
                            [121.58, 23.34],
                            [122.28, 24.77],
                            [124.06, 25.24],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.5, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.25, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [124.06, 25.24],
                            [125.87, 24.83],
                            [127.02, 23.74],
                            [127.42, 22.28],
                            [127.42, 20.59],
                            [126.89, 20.85],
                            [126.01, 21.04],
                            [125.05, 21.19],
                            [124.26, 21.29],
                            [122.91, 21.61],
                            [121.94, 22.24],
                            [121.58, 23.34],
                            [122.28, 24.77],
                            [124.06, 25.24],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.6, 0.4],
                            [-0.77, 0],
                            [-0.22, -0.01],
                            [-0.12, -0.02],
                            [0, 0],
                            [0.26, 0.03],
                            [0.26, 0],
                            [0.47, -0.26],
                            [0.27, -0.45],
                            [0, -0.57],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.7],
                            [0.61, -0.41],
                            [0.16, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.1, -0.03],
                            [-0.26, -0.04],
                            [-0.6, 0],
                            [-0.47, 0.25],
                            [-0.27, 0.44],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [133.12, 27],
                            [133.12, 13.91],
                            [135.58, 13.91],
                            [135.58, 15.99],
                            [135.72, 15.99],
                            [136.98, 14.33],
                            [139.04, 13.71],
                            [139.61, 13.73],
                            [140.13, 13.77],
                            [140.13, 16.21],
                            [139.58, 16.12],
                            [138.8, 16.06],
                            [137.19, 16.44],
                            [136.08, 17.49],
                            [135.67, 19.01],
                            [135.67, 27],
                            [133.12, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.6, 0.4],
                            [-0.77, 0],
                            [-0.22, -0.01],
                            [-0.12, -0.02],
                            [0, 0],
                            [0.26, 0.03],
                            [0.26, 0],
                            [0.47, -0.26],
                            [0.27, -0.45],
                            [0, -0.57],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.7],
                            [0.61, -0.41],
                            [0.16, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.1, -0.03],
                            [-0.26, -0.04],
                            [-0.6, 0],
                            [-0.47, 0.25],
                            [-0.27, 0.44],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [133.12, 27],
                            [133.12, 13.91],
                            [135.58, 13.91],
                            [135.58, 15.99],
                            [135.72, 15.99],
                            [136.98, 14.33],
                            [139.04, 13.71],
                            [139.61, 13.73],
                            [140.13, 13.77],
                            [140.13, 16.21],
                            [139.58, 16.12],
                            [138.8, 16.06],
                            [137.19, 16.44],
                            [136.08, 17.49],
                            [135.67, 19.01],
                            [135.67, 27],
                            [133.12, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [142.09, 27],
                            [142.09, 13.91],
                            [144.64, 13.91],
                            [144.64, 27],
                            [142.09, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [142.09, 27],
                            [142.09, 13.91],
                            [144.64, 13.91],
                            [144.64, 27],
                            [142.09, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                          ],
                          o: [
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [0, 0],
                          ],
                          v: [
                            [143.38, 11.89],
                            [142.24, 11.45],
                            [141.77, 10.37],
                            [142.24, 9.3],
                            [143.38, 8.85],
                            [144.51, 9.3],
                            [144.99, 10.37],
                            [144.51, 11.45],
                            [143.38, 11.89],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                          ],
                          o: [
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [0, 0],
                          ],
                          v: [
                            [143.38, 11.89],
                            [142.24, 11.45],
                            [141.77, 10.37],
                            [142.24, 9.3],
                            [143.38, 8.85],
                            [144.51, 9.3],
                            [144.99, 10.37],
                            [144.51, 11.45],
                            [143.38, 11.89],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.92, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.5, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.5, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.92, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [153.34, 27.26],
                            [150.13, 26.42],
                            [148, 24.06],
                            [147.24, 20.51],
                            [148, 16.95],
                            [150.13, 14.58],
                            [153.34, 13.74],
                            [156.56, 14.58],
                            [158.69, 16.95],
                            [159.44, 20.51],
                            [158.69, 24.06],
                            [156.56, 26.42],
                            [153.34, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.92, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.5, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.5, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.92, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [153.34, 27.26],
                            [150.13, 26.42],
                            [148, 24.06],
                            [147.24, 20.51],
                            [148, 16.95],
                            [150.13, 14.58],
                            [153.34, 13.74],
                            [156.56, 14.58],
                            [158.69, 16.95],
                            [159.44, 20.51],
                            [158.69, 24.06],
                            [156.56, 26.42],
                            [153.34, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [153.35, 25.13],
                            [155.33, 24.49],
                            [156.49, 22.82],
                            [156.87, 20.51],
                            [156.49, 18.2],
                            [155.33, 16.51],
                            [153.35, 15.87],
                            [151.36, 16.51],
                            [150.19, 18.2],
                            [149.81, 20.51],
                            [150.19, 22.82],
                            [151.36, 24.49],
                            [153.35, 25.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [153.35, 25.13],
                            [155.33, 24.49],
                            [156.49, 22.82],
                            [156.87, 20.51],
                            [156.49, 18.2],
                            [155.33, 16.51],
                            [153.35, 15.87],
                            [151.36, 16.51],
                            [150.19, 18.2],
                            [149.81, 20.51],
                            [150.19, 22.82],
                            [151.36, 24.49],
                            [153.35, 25.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1, 1, 1],
                      t: 0,
                    },
                    { s: [1, 1, 1], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'button Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [92.5, 24],
                    t: 0,
                  },
                  { s: [92.5, 24], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [92.5, 24],
                    t: 0,
                  },
                  { s: [92.5, 24], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [185, 4],
                            [185, 44],
                            [181, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [181, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [185, 4],
                            [185, 44],
                            [181, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [181, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [0.251, 0.4353, 0.5138],
                      t: 0,
                    },
                    { s: [0.251, 0.4353, 0.5138], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] button',
        id: '9',
        layers: [
          {
            ty: 0,
            nm: 'Frame 101071',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [44, 12], t: 0 },
                  { s: [44, 12], t: 1 },
                ],
              },
              s: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 0 },
                  { s: [100, 100], t: 1 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [44, 20], t: 0 },
                  { s: [44, 20], t: 1 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [0], t: 1 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [100], t: 1 },
                ],
              },
            },
            w: 211.5,
            h: 100.5,
            refId: '10',
            ind: 1,
          },
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [44, 12], t: 0 },
                  { s: [44, 12], t: 1 },
                ],
              },
              s: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 0 },
                  { s: [100, 100], t: 1 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [44, 20], t: 0 },
                  { s: [44, 20], t: 1 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [0], t: 1 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 0 },
                  { s: [0], t: 1 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.54, -0.27],
                            [-0.27, -0.46],
                            [0, -0.58],
                            [0.18, -0.33],
                            [0.3, -0.2],
                            [0.36, -0.09],
                            [0, 0],
                            [-0.37, -0.23],
                            [-0.24, -0.42],
                            [0, -0.6],
                            [0.28, -0.47],
                            [0.58, -0.28],
                            [0.9, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.83, 0],
                            [0.54, 0.27],
                            [0.27, 0.46],
                            [0, 0.48],
                            [-0.18, 0.33],
                            [-0.3, 0.2],
                            [0, 0],
                            [0.39, 0.02],
                            [0.37, 0.23],
                            [0.24, 0.42],
                            [0, 0.59],
                            [-0.28, 0.47],
                            [-0.58, 0.27],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.58, 18],
                            [1.58, 6.36],
                            [5.84, 6.36],
                            [7.89, 6.77],
                            [9.11, 7.87],
                            [9.51, 9.42],
                            [9.25, 10.65],
                            [8.53, 11.44],
                            [7.55, 11.88],
                            [7.55, 11.99],
                            [8.68, 12.36],
                            [9.6, 13.34],
                            [9.97, 14.87],
                            [9.55, 16.47],
                            [8.26, 17.59],
                            [6.04, 18],
                            [1.58, 18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.54, -0.27],
                            [-0.27, -0.46],
                            [0, -0.58],
                            [0.18, -0.33],
                            [0.3, -0.2],
                            [0.36, -0.09],
                            [0, 0],
                            [-0.37, -0.23],
                            [-0.24, -0.42],
                            [0, -0.6],
                            [0.28, -0.47],
                            [0.58, -0.28],
                            [0.9, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.83, 0],
                            [0.54, 0.27],
                            [0.27, 0.46],
                            [0, 0.48],
                            [-0.18, 0.33],
                            [-0.3, 0.2],
                            [0, 0],
                            [0.39, 0.02],
                            [0.37, 0.23],
                            [0.24, 0.42],
                            [0, 0.59],
                            [-0.28, 0.47],
                            [-0.58, 0.27],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.58, 18],
                            [1.58, 6.36],
                            [5.84, 6.36],
                            [7.89, 6.77],
                            [9.11, 7.87],
                            [9.51, 9.42],
                            [9.25, 10.65],
                            [8.53, 11.44],
                            [7.55, 11.88],
                            [7.55, 11.99],
                            [8.68, 12.36],
                            [9.6, 13.34],
                            [9.97, 14.87],
                            [9.55, 16.47],
                            [8.26, 17.59],
                            [6.04, 18],
                            [1.58, 18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.36, 0.33],
                            [0, 0.49],
                            [0.19, 0.31],
                            [0.34, 0.18],
                            [0.47, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.84, 0],
                            [0.36, -0.33],
                            [0, -0.37],
                            [-0.19, -0.31],
                            [-0.34, -0.18],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 16.49],
                            [5.87, 16.49],
                            [7.68, 16.01],
                            [8.22, 14.78],
                            [7.94, 13.77],
                            [7.15, 13.04],
                            [5.93, 12.77],
                            [3.34, 12.77],
                            [3.34, 16.49],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.36, 0.33],
                            [0, 0.49],
                            [0.19, 0.31],
                            [0.34, 0.18],
                            [0.47, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.84, 0],
                            [0.36, -0.33],
                            [0, -0.37],
                            [-0.19, -0.31],
                            [-0.34, -0.18],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 16.49],
                            [5.87, 16.49],
                            [7.68, 16.01],
                            [8.22, 14.78],
                            [7.94, 13.77],
                            [7.15, 13.04],
                            [5.93, 12.77],
                            [3.34, 12.77],
                            [3.34, 16.49],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.31, 0.15],
                            [-0.19, 0.27],
                            [0, 0.38],
                            [0.34, 0.33],
                            [0.7, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.39, 0],
                            [0.32, -0.15],
                            [0.19, -0.28],
                            [0, -0.48],
                            [-0.34, -0.33],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 11.4],
                            [5.69, 11.4],
                            [6.75, 11.17],
                            [7.51, 10.53],
                            [7.79, 9.56],
                            [7.28, 8.35],
                            [5.73, 7.86],
                            [3.34, 7.86],
                            [3.34, 11.4],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.31, 0.15],
                            [-0.19, 0.27],
                            [0, 0.38],
                            [0.34, 0.33],
                            [0.7, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.39, 0],
                            [0.32, -0.15],
                            [0.19, -0.28],
                            [0, -0.48],
                            [-0.34, -0.33],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 11.4],
                            [5.69, 11.4],
                            [6.75, 11.17],
                            [7.51, 10.53],
                            [7.79, 9.56],
                            [7.28, 8.35],
                            [5.73, 7.86],
                            [3.34, 7.86],
                            [3.34, 11.4],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.44, -0.31],
                            [0.66, 0],
                            [0.43, 0.25],
                            [0.25, 0.49],
                            [0, 0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.33, -0.35],
                            [-0.53, 0],
                            [-0.31, 0.16],
                            [-0.21, 0.32],
                            [0, 0.5],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.2, 0.47],
                            [-0.44, 0.31],
                            [-0.56, 0],
                            [-0.43, -0.25],
                            [-0.24, -0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0.59],
                            [0.33, 0.35],
                            [0.32, 0],
                            [0.32, -0.16],
                            [0.21, -0.32],
                            [0, 0],
                          ],
                          v: [
                            [17.28, 14.38],
                            [17.28, 9.27],
                            [18.99, 9.27],
                            [18.99, 18],
                            [17.32, 18],
                            [17.32, 16.49],
                            [17.22, 16.49],
                            [16.26, 17.65],
                            [14.62, 18.11],
                            [13.13, 17.74],
                            [12.12, 16.64],
                            [11.75, 14.82],
                            [11.75, 9.27],
                            [13.45, 9.27],
                            [13.45, 14.62],
                            [13.95, 16.04],
                            [15.23, 16.57],
                            [16.18, 16.33],
                            [16.97, 15.61],
                            [17.28, 14.38],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.44, -0.31],
                            [0.66, 0],
                            [0.43, 0.25],
                            [0.25, 0.49],
                            [0, 0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.33, -0.35],
                            [-0.53, 0],
                            [-0.31, 0.16],
                            [-0.21, 0.32],
                            [0, 0.5],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.2, 0.47],
                            [-0.44, 0.31],
                            [-0.56, 0],
                            [-0.43, -0.25],
                            [-0.24, -0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0.59],
                            [0.33, 0.35],
                            [0.32, 0],
                            [0.32, -0.16],
                            [0.21, -0.32],
                            [0, 0],
                          ],
                          v: [
                            [17.28, 14.38],
                            [17.28, 9.27],
                            [18.99, 9.27],
                            [18.99, 18],
                            [17.32, 18],
                            [17.32, 16.49],
                            [17.22, 16.49],
                            [16.26, 17.65],
                            [14.62, 18.11],
                            [13.13, 17.74],
                            [12.12, 16.64],
                            [11.75, 14.82],
                            [11.75, 9.27],
                            [13.45, 9.27],
                            [13.45, 14.62],
                            [13.95, 16.04],
                            [15.23, 16.57],
                            [16.18, 16.33],
                            [16.97, 15.61],
                            [17.28, 14.38],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [25.18, 9.27],
                            [25.18, 10.64],
                            [20.42, 10.64],
                            [20.42, 9.27],
                            [25.18, 9.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [25.18, 9.27],
                            [25.18, 10.64],
                            [20.42, 10.64],
                            [20.42, 9.27],
                            [25.18, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [21.69, 7.18],
                            [23.39, 7.18],
                            [23.39, 15.44],
                            [23.54, 16.18],
                            [23.92, 16.52],
                            [24.44, 16.6],
                            [24.8, 16.57],
                            [25.03, 16.53],
                            [25.34, 17.93],
                            [24.92, 18.05],
                            [24.24, 18.11],
                            [22.99, 17.88],
                            [22.05, 17.1],
                            [21.69, 15.79],
                            [21.69, 7.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [21.69, 7.18],
                            [23.39, 7.18],
                            [23.39, 15.44],
                            [23.54, 16.18],
                            [23.92, 16.52],
                            [24.44, 16.6],
                            [24.8, 16.57],
                            [25.03, 16.53],
                            [25.34, 17.93],
                            [24.92, 18.05],
                            [24.24, 18.11],
                            [22.99, 17.88],
                            [22.05, 17.1],
                            [21.69, 15.79],
                            [21.69, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [30.98, 9.27],
                            [30.98, 10.64],
                            [26.21, 10.64],
                            [26.21, 9.27],
                            [30.98, 9.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [30.98, 9.27],
                            [30.98, 10.64],
                            [26.21, 10.64],
                            [26.21, 9.27],
                            [30.98, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [27.49, 7.18],
                            [29.19, 7.18],
                            [29.19, 15.44],
                            [29.33, 16.18],
                            [29.71, 16.52],
                            [30.23, 16.6],
                            [30.59, 16.57],
                            [30.83, 16.53],
                            [31.13, 17.93],
                            [30.71, 18.05],
                            [30.03, 18.11],
                            [28.78, 17.88],
                            [27.84, 17.1],
                            [27.49, 15.79],
                            [27.49, 7.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [27.49, 7.18],
                            [29.19, 7.18],
                            [29.19, 15.44],
                            [29.33, 16.18],
                            [29.71, 16.52],
                            [30.23, 16.6],
                            [30.59, 16.57],
                            [30.83, 16.53],
                            [31.13, 17.93],
                            [30.71, 18.05],
                            [30.03, 18.11],
                            [28.78, 17.88],
                            [27.84, 17.1],
                            [27.49, 15.79],
                            [27.49, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.61, 0.38],
                            [0.34, 0.67],
                            [0, 0.9],
                            [-0.34, 0.68],
                            [-0.61, 0.38],
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.68],
                            [0, -0.91],
                            [0.34, -0.67],
                            [0.61, -0.37],
                            [0.82, 0],
                          ],
                          o: [
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.67],
                            [0, -0.91],
                            [0.34, -0.68],
                            [0.61, -0.37],
                            [0.82, 0],
                            [0.61, 0.38],
                            [0.34, 0.68],
                            [0, 0.9],
                            [-0.34, 0.67],
                            [-0.61, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 18.18],
                            [34.13, 17.61],
                            [32.71, 16.04],
                            [32.21, 13.68],
                            [32.71, 11.3],
                            [34.13, 9.72],
                            [36.28, 9.16],
                            [38.42, 9.72],
                            [39.84, 11.3],
                            [40.35, 13.68],
                            [39.84, 16.04],
                            [38.42, 17.61],
                            [36.28, 18.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.61, 0.38],
                            [0.34, 0.67],
                            [0, 0.9],
                            [-0.34, 0.68],
                            [-0.61, 0.38],
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.68],
                            [0, -0.91],
                            [0.34, -0.67],
                            [0.61, -0.37],
                            [0.82, 0],
                          ],
                          o: [
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.67],
                            [0, -0.91],
                            [0.34, -0.68],
                            [0.61, -0.37],
                            [0.82, 0],
                            [0.61, 0.38],
                            [0.34, 0.68],
                            [0, 0.9],
                            [-0.34, 0.67],
                            [-0.61, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 18.18],
                            [34.13, 17.61],
                            [32.71, 16.04],
                            [32.21, 13.68],
                            [32.71, 11.3],
                            [34.13, 9.72],
                            [36.28, 9.16],
                            [38.42, 9.72],
                            [39.84, 11.3],
                            [40.35, 13.68],
                            [39.84, 16.04],
                            [38.42, 17.61],
                            [36.28, 18.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                          ],
                          o: [
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 16.75],
                            [37.6, 16.33],
                            [38.37, 15.21],
                            [38.63, 13.67],
                            [38.37, 12.14],
                            [37.6, 11.01],
                            [36.28, 10.58],
                            [34.95, 11.01],
                            [34.17, 12.14],
                            [33.92, 13.67],
                            [34.17, 15.21],
                            [34.95, 16.33],
                            [36.28, 16.75],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                          ],
                          o: [
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 16.75],
                            [37.6, 16.33],
                            [38.37, 15.21],
                            [38.63, 13.67],
                            [38.37, 12.14],
                            [37.6, 11.01],
                            [36.28, 10.58],
                            [34.95, 11.01],
                            [34.17, 12.14],
                            [33.92, 13.67],
                            [34.17, 15.21],
                            [34.95, 16.33],
                            [36.28, 16.75],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.43, 0.28],
                            [-0.66, 0],
                            [-0.45, -0.25],
                            [-0.25, -0.49],
                            [0, -0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, 0.36],
                            [0.58, 0],
                            [0.31, -0.17],
                            [0.18, -0.33],
                            [0, -0.46],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.2, -0.46],
                            [0.43, -0.28],
                            [0.59, 0],
                            [0.45, 0.25],
                            [0.25, 0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.63],
                            [-0.33, -0.36],
                            [-0.39, 0],
                            [-0.3, 0.17],
                            [-0.17, 0.33],
                            [0, 0],
                          ],
                          v: [
                            [43.78, 12.82],
                            [43.78, 18],
                            [42.08, 18],
                            [42.08, 9.27],
                            [43.71, 9.27],
                            [43.71, 10.69],
                            [43.82, 10.69],
                            [44.76, 9.58],
                            [46.39, 9.16],
                            [47.96, 9.53],
                            [49, 10.64],
                            [49.37, 12.45],
                            [49.37, 18],
                            [47.67, 18],
                            [47.67, 12.65],
                            [47.17, 11.17],
                            [45.81, 10.63],
                            [44.76, 10.89],
                            [44.04, 11.64],
                            [43.78, 12.82],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.43, 0.28],
                            [-0.66, 0],
                            [-0.45, -0.25],
                            [-0.25, -0.49],
                            [0, -0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, 0.36],
                            [0.58, 0],
                            [0.31, -0.17],
                            [0.18, -0.33],
                            [0, -0.46],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.2, -0.46],
                            [0.43, -0.28],
                            [0.59, 0],
                            [0.45, 0.25],
                            [0.25, 0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.63],
                            [-0.33, -0.36],
                            [-0.39, 0],
                            [-0.3, 0.17],
                            [-0.17, 0.33],
                            [0, 0],
                          ],
                          v: [
                            [43.78, 12.82],
                            [43.78, 18],
                            [42.08, 18],
                            [42.08, 9.27],
                            [43.71, 9.27],
                            [43.71, 10.69],
                            [43.82, 10.69],
                            [44.76, 9.58],
                            [46.39, 9.16],
                            [47.96, 9.53],
                            [49, 10.64],
                            [49.37, 12.45],
                            [49.37, 18],
                            [47.67, 18],
                            [47.67, 12.65],
                            [47.17, 11.17],
                            [45.81, 10.63],
                            [44.76, 10.89],
                            [44.04, 11.64],
                            [43.78, 12.82],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [55.12, 7.88],
                            [55.12, 6.36],
                            [64.13, 6.36],
                            [64.13, 7.88],
                            [60.5, 7.88],
                            [60.5, 18],
                            [58.75, 18],
                            [58.75, 7.88],
                            [55.12, 7.88],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [55.12, 7.88],
                            [55.12, 6.36],
                            [64.13, 6.36],
                            [64.13, 7.88],
                            [60.5, 7.88],
                            [60.5, 18],
                            [58.75, 18],
                            [58.75, 7.88],
                            [55.12, 7.88],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [65.82, 18],
                            [65.82, 9.27],
                            [67.52, 9.27],
                            [67.52, 18],
                            [65.82, 18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [65.82, 18],
                            [65.82, 9.27],
                            [67.52, 9.27],
                            [67.52, 18],
                            [65.82, 18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                          ],
                          o: [
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [0, 0],
                          ],
                          v: [
                            [66.68, 7.93],
                            [65.92, 7.63],
                            [65.6, 6.91],
                            [65.92, 6.2],
                            [66.68, 5.9],
                            [67.43, 6.2],
                            [67.75, 6.91],
                            [67.43, 7.63],
                            [66.68, 7.93],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                          ],
                          o: [
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [0, 0],
                          ],
                          v: [
                            [66.68, 7.93],
                            [65.92, 7.63],
                            [65.6, 6.91],
                            [65.92, 6.2],
                            [66.68, 5.9],
                            [67.43, 6.2],
                            [67.75, 6.91],
                            [67.43, 7.63],
                            [66.68, 7.93],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [73.72, 9.27],
                            [73.72, 10.64],
                            [68.95, 10.64],
                            [68.95, 9.27],
                            [73.72, 9.27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [73.72, 9.27],
                            [73.72, 10.64],
                            [68.95, 10.64],
                            [68.95, 9.27],
                            [73.72, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [70.23, 7.18],
                            [71.93, 7.18],
                            [71.93, 15.44],
                            [72.08, 16.18],
                            [72.46, 16.52],
                            [72.97, 16.6],
                            [73.33, 16.57],
                            [73.57, 16.53],
                            [73.88, 17.93],
                            [73.46, 18.05],
                            [72.78, 18.11],
                            [71.53, 17.88],
                            [70.59, 17.1],
                            [70.23, 15.79],
                            [70.23, 7.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [70.23, 7.18],
                            [71.93, 7.18],
                            [71.93, 15.44],
                            [72.08, 16.18],
                            [72.46, 16.52],
                            [72.97, 16.6],
                            [73.33, 16.57],
                            [73.57, 16.53],
                            [73.88, 17.93],
                            [73.46, 18.05],
                            [72.78, 18.11],
                            [71.53, 17.88],
                            [70.59, 17.1],
                            [70.23, 15.79],
                            [70.23, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.32, 6.36],
                            [77.32, 18],
                            [75.63, 18],
                            [75.63, 6.36],
                            [77.32, 6.36],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.32, 6.36],
                            [77.32, 18],
                            [75.63, 18],
                            [75.63, 6.36],
                            [77.32, 6.36],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.62, 0.37],
                            [0.34, 0.67],
                            [0, 0.91],
                            [-0.33, 0.68],
                            [-0.6, 0.38],
                            [-0.81, 0],
                            [-0.46, -0.16],
                            [-0.37, -0.35],
                            [-0.21, -0.56],
                            [0, -0.79],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.18, 0.34],
                            [0.33, 0.2],
                            [0.44, 0],
                            [0.36, -0.23],
                            [0.19, -0.37],
                            [0, -0.44],
                            [0, 0],
                            [-0.2, -0.41],
                            [-0.37, -0.22],
                            [-0.5, 0],
                            [-0.27, 0.09],
                            [-0.19, 0.19],
                            [-0.1, 0.27],
                            [0, 0],
                            [0.33, -0.36],
                            [0.5, -0.2],
                            [0.64, 0],
                          ],
                          o: [
                            [-0.86, 0],
                            [-0.62, -0.37],
                            [-0.33, -0.67],
                            [0, -0.89],
                            [0.34, -0.68],
                            [0.61, -0.38],
                            [0.49, 0],
                            [0.46, 0.16],
                            [0.37, 0.35],
                            [0.21, 0.55],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.45],
                            [-0.18, -0.35],
                            [-0.33, -0.2],
                            [-0.48, 0],
                            [-0.35, 0.23],
                            [-0.19, 0.37],
                            [0, 0],
                            [0, 0.58],
                            [0.21, 0.41],
                            [0.37, 0.21],
                            [0.32, 0],
                            [0.27, -0.09],
                            [0.19, -0.19],
                            [0, 0],
                            [-0.13, 0.47],
                            [-0.33, 0.35],
                            [-0.5, 0.19],
                            [0, 0],
                          ],
                          v: [
                            [83.21, 18.18],
                            [80.99, 17.63],
                            [79.56, 16.06],
                            [79.06, 13.69],
                            [79.56, 11.33],
                            [80.97, 9.73],
                            [83.09, 9.16],
                            [84.52, 9.4],
                            [85.77, 10.17],
                            [86.64, 11.53],
                            [86.96, 13.55],
                            [86.96, 14.15],
                            [80.02, 14.15],
                            [80.02, 12.88],
                            [85.29, 12.88],
                            [85.02, 11.69],
                            [84.25, 10.86],
                            [83.1, 10.56],
                            [81.85, 10.91],
                            [81.03, 11.82],
                            [80.75, 13.03],
                            [80.75, 14.03],
                            [81.06, 15.52],
                            [81.93, 16.45],
                            [83.23, 16.77],
                            [84.11, 16.64],
                            [84.8, 16.22],
                            [85.24, 15.53],
                            [86.85, 15.82],
                            [86.15, 17.06],
                            [84.91, 17.89],
                            [83.21, 18.18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.62, 0.37],
                            [0.34, 0.67],
                            [0, 0.91],
                            [-0.33, 0.68],
                            [-0.6, 0.38],
                            [-0.81, 0],
                            [-0.46, -0.16],
                            [-0.37, -0.35],
                            [-0.21, -0.56],
                            [0, -0.79],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.18, 0.34],
                            [0.33, 0.2],
                            [0.44, 0],
                            [0.36, -0.23],
                            [0.19, -0.37],
                            [0, -0.44],
                            [0, 0],
                            [-0.2, -0.41],
                            [-0.37, -0.22],
                            [-0.5, 0],
                            [-0.27, 0.09],
                            [-0.19, 0.19],
                            [-0.1, 0.27],
                            [0, 0],
                            [0.33, -0.36],
                            [0.5, -0.2],
                            [0.64, 0],
                          ],
                          o: [
                            [-0.86, 0],
                            [-0.62, -0.37],
                            [-0.33, -0.67],
                            [0, -0.89],
                            [0.34, -0.68],
                            [0.61, -0.38],
                            [0.49, 0],
                            [0.46, 0.16],
                            [0.37, 0.35],
                            [0.21, 0.55],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.45],
                            [-0.18, -0.35],
                            [-0.33, -0.2],
                            [-0.48, 0],
                            [-0.35, 0.23],
                            [-0.19, 0.37],
                            [0, 0],
                            [0, 0.58],
                            [0.21, 0.41],
                            [0.37, 0.21],
                            [0.32, 0],
                            [0.27, -0.09],
                            [0.19, -0.19],
                            [0, 0],
                            [-0.13, 0.47],
                            [-0.33, 0.35],
                            [-0.5, 0.19],
                            [0, 0],
                          ],
                          v: [
                            [83.21, 18.18],
                            [80.99, 17.63],
                            [79.56, 16.06],
                            [79.06, 13.69],
                            [79.56, 11.33],
                            [80.97, 9.73],
                            [83.09, 9.16],
                            [84.52, 9.4],
                            [85.77, 10.17],
                            [86.64, 11.53],
                            [86.96, 13.55],
                            [86.96, 14.15],
                            [80.02, 14.15],
                            [80.02, 12.88],
                            [85.29, 12.88],
                            [85.02, 11.69],
                            [84.25, 10.86],
                            [83.1, 10.56],
                            [81.85, 10.91],
                            [81.03, 11.82],
                            [80.75, 13.03],
                            [80.75, 14.03],
                            [81.06, 15.52],
                            [81.93, 16.45],
                            [83.23, 16.77],
                            [84.11, 16.64],
                            [84.8, 16.22],
                            [85.24, 15.53],
                            [86.85, 15.82],
                            [86.15, 17.06],
                            [84.91, 17.89],
                            [83.21, 18.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [0.4981, 0.5765, 0.4628],
                      t: 0,
                    },
                    { s: [0.4981, 0.5765, 0.4628], t: 1 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 0 },
                    { s: [100], t: 1 },
                  ],
                },
              },
            ],
            ind: 2,
          },
          {
            ty: 4,
            nm: 'button Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [55, 24], t: 0 },
                  { s: [55, 24], t: 1 },
                ],
              },
              s: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 0 },
                  { s: [100, 100], t: 1 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [55, 24], t: 0 },
                  { s: [55, 24], t: 1 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [0], t: 1 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 0 },
                  { s: [100], t: 1 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [110, 4],
                            [110, 44],
                            [106, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [106, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 2.21],
                            [0, 0],
                            [-2.21, 0],
                            [0, 0],
                            [0, -2.21],
                            [0, 0],
                            [2.21, 0],
                          ],
                          v: [
                            [110, 4],
                            [110, 44],
                            [106, 48],
                            [4, 48],
                            [0, 44],
                            [0, 4],
                            [4, 0],
                            [106, 0],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
            ],
            ind: 3,
          },
        ],
      },
      {
        nm: '[Asset] Frame 101071',
        id: '10',
        layers: [
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 1,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0, 0],
                    t: 0,
                  },
                  { s: [44, 12], t: 1 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 1 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0, 0],
                    t: 0,
                  },
                  { s: [44, 12], t: 1 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 1 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.167, y: 0.167 },
                    i: { x: 0.833, y: 0.833 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 1 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.54, -0.27],
                            [-0.27, -0.46],
                            [0, -0.58],
                            [0.18, -0.33],
                            [0.3, -0.2],
                            [0.36, -0.09],
                            [0, 0],
                            [-0.37, -0.23],
                            [-0.24, -0.42],
                            [0, -0.6],
                            [0.28, -0.47],
                            [0.58, -0.28],
                            [0.9, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.83, 0],
                            [0.54, 0.27],
                            [0.27, 0.46],
                            [0, 0.48],
                            [-0.18, 0.33],
                            [-0.3, 0.2],
                            [0, 0],
                            [0.39, 0.02],
                            [0.37, 0.23],
                            [0.24, 0.42],
                            [0, 0.59],
                            [-0.28, 0.47],
                            [-0.58, 0.27],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.58, 18],
                            [1.58, 6.36],
                            [5.84, 6.36],
                            [7.89, 6.77],
                            [9.11, 7.87],
                            [9.51, 9.42],
                            [9.25, 10.65],
                            [8.53, 11.44],
                            [7.55, 11.88],
                            [7.55, 11.99],
                            [8.68, 12.36],
                            [9.6, 13.34],
                            [9.97, 14.87],
                            [9.55, 16.47],
                            [8.26, 17.59],
                            [6.04, 18],
                            [1.58, 18],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.54, -0.27],
                            [-0.27, -0.46],
                            [0, -0.58],
                            [0.18, -0.33],
                            [0.3, -0.2],
                            [0.36, -0.09],
                            [0, 0],
                            [-0.37, -0.23],
                            [-0.24, -0.42],
                            [0, -0.6],
                            [0.28, -0.47],
                            [0.58, -0.28],
                            [0.9, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0.83, 0],
                            [0.54, 0.27],
                            [0.27, 0.46],
                            [0, 0.48],
                            [-0.18, 0.33],
                            [-0.3, 0.2],
                            [0, 0],
                            [0.39, 0.02],
                            [0.37, 0.23],
                            [0.24, 0.42],
                            [0, 0.59],
                            [-0.28, 0.47],
                            [-0.58, 0.27],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [1.58, 18],
                            [1.58, 6.36],
                            [5.84, 6.36],
                            [7.89, 6.77],
                            [9.11, 7.87],
                            [9.51, 9.42],
                            [9.25, 10.65],
                            [8.53, 11.44],
                            [7.55, 11.88],
                            [7.55, 11.99],
                            [8.68, 12.36],
                            [9.6, 13.34],
                            [9.97, 14.87],
                            [9.55, 16.47],
                            [8.26, 17.59],
                            [6.04, 18],
                            [1.58, 18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.36, 0.33],
                            [0, 0.49],
                            [0.19, 0.31],
                            [0.34, 0.18],
                            [0.47, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.84, 0],
                            [0.36, -0.33],
                            [0, -0.37],
                            [-0.19, -0.31],
                            [-0.34, -0.18],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 16.49],
                            [5.87, 16.49],
                            [7.68, 16.01],
                            [8.22, 14.78],
                            [7.94, 13.77],
                            [7.15, 13.04],
                            [5.93, 12.77],
                            [3.34, 12.77],
                            [3.34, 16.49],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [-0.31, 0.15],
                            [-0.19, 0.27],
                            [0, 0.38],
                            [0.34, 0.33],
                            [0.7, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0.39, 0],
                            [0.32, -0.15],
                            [0.19, -0.28],
                            [0, -0.48],
                            [-0.34, -0.33],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [3.34, 11.4],
                            [5.69, 11.4],
                            [6.75, 11.17],
                            [7.51, 10.53],
                            [7.79, 9.56],
                            [7.28, 8.35],
                            [5.73, 7.86],
                            [3.34, 7.86],
                            [3.34, 11.4],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.44, -0.31],
                            [0.66, 0],
                            [0.43, 0.25],
                            [0.25, 0.49],
                            [0, 0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.33, -0.35],
                            [-0.53, 0],
                            [-0.31, 0.16],
                            [-0.21, 0.32],
                            [0, 0.5],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.2, 0.47],
                            [-0.44, 0.31],
                            [-0.56, 0],
                            [-0.43, -0.25],
                            [-0.24, -0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0.59],
                            [0.33, 0.35],
                            [0.32, 0],
                            [0.32, -0.16],
                            [0.21, -0.32],
                            [0, 0],
                          ],
                          v: [
                            [17.28, 14.38],
                            [17.28, 9.27],
                            [18.99, 9.27],
                            [18.99, 18],
                            [17.32, 18],
                            [17.32, 16.49],
                            [17.22, 16.49],
                            [16.26, 17.65],
                            [14.62, 18.11],
                            [13.13, 17.74],
                            [12.12, 16.64],
                            [11.75, 14.82],
                            [11.75, 9.27],
                            [13.45, 9.27],
                            [13.45, 14.62],
                            [13.95, 16.04],
                            [15.23, 16.57],
                            [16.18, 16.33],
                            [16.97, 15.61],
                            [17.28, 14.38],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [25.18, 9.27],
                            [25.18, 10.64],
                            [20.42, 10.64],
                            [20.42, 9.27],
                            [25.18, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [21.69, 7.18],
                            [23.39, 7.18],
                            [23.39, 15.44],
                            [23.54, 16.18],
                            [23.92, 16.52],
                            [24.44, 16.6],
                            [24.8, 16.57],
                            [25.03, 16.53],
                            [25.34, 17.93],
                            [24.92, 18.05],
                            [24.24, 18.11],
                            [22.99, 17.88],
                            [22.05, 17.1],
                            [21.69, 15.79],
                            [21.69, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [30.98, 9.27],
                            [30.98, 10.64],
                            [26.21, 10.64],
                            [26.21, 9.27],
                            [30.98, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [27.49, 7.18],
                            [29.19, 7.18],
                            [29.19, 15.44],
                            [29.33, 16.18],
                            [29.71, 16.52],
                            [30.23, 16.6],
                            [30.59, 16.57],
                            [30.83, 16.53],
                            [31.13, 17.93],
                            [30.71, 18.05],
                            [30.03, 18.11],
                            [28.78, 17.88],
                            [27.84, 17.1],
                            [27.49, 15.79],
                            [27.49, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.61, 0.38],
                            [0.34, 0.67],
                            [0, 0.9],
                            [-0.34, 0.68],
                            [-0.61, 0.38],
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.68],
                            [0, -0.91],
                            [0.34, -0.67],
                            [0.61, -0.37],
                            [0.82, 0],
                          ],
                          o: [
                            [-0.82, 0],
                            [-0.61, -0.37],
                            [-0.34, -0.67],
                            [0, -0.91],
                            [0.34, -0.68],
                            [0.61, -0.37],
                            [0.82, 0],
                            [0.61, 0.38],
                            [0.34, 0.68],
                            [0, 0.9],
                            [-0.34, 0.67],
                            [-0.61, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 18.18],
                            [34.13, 17.61],
                            [32.71, 16.04],
                            [32.21, 13.68],
                            [32.71, 11.3],
                            [34.13, 9.72],
                            [36.28, 9.16],
                            [38.42, 9.72],
                            [39.84, 11.3],
                            [40.35, 13.68],
                            [39.84, 16.04],
                            [38.42, 17.61],
                            [36.28, 18.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                          ],
                          o: [
                            [0.53, 0],
                            [0.35, -0.28],
                            [0.17, -0.47],
                            [0, -0.56],
                            [-0.17, -0.47],
                            [-0.35, -0.28],
                            [-0.53, 0],
                            [-0.35, 0.28],
                            [-0.17, 0.47],
                            [0, 0.56],
                            [0.17, 0.47],
                            [0.35, 0.28],
                            [0, 0],
                          ],
                          v: [
                            [36.28, 16.75],
                            [37.6, 16.33],
                            [38.37, 15.21],
                            [38.63, 13.67],
                            [38.37, 12.14],
                            [37.6, 11.01],
                            [36.28, 10.58],
                            [34.95, 11.01],
                            [34.17, 12.14],
                            [33.92, 13.67],
                            [34.17, 15.21],
                            [34.95, 16.33],
                            [36.28, 16.75],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.43, 0.28],
                            [-0.66, 0],
                            [-0.45, -0.25],
                            [-0.25, -0.49],
                            [0, -0.72],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, 0.36],
                            [0.58, 0],
                            [0.31, -0.17],
                            [0.18, -0.33],
                            [0, -0.46],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.2, -0.46],
                            [0.43, -0.28],
                            [0.59, 0],
                            [0.45, 0.25],
                            [0.25, 0.49],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.63],
                            [-0.33, -0.36],
                            [-0.39, 0],
                            [-0.3, 0.17],
                            [-0.17, 0.33],
                            [0, 0],
                          ],
                          v: [
                            [43.78, 12.82],
                            [43.78, 18],
                            [42.08, 18],
                            [42.08, 9.27],
                            [43.71, 9.27],
                            [43.71, 10.69],
                            [43.82, 10.69],
                            [44.76, 9.58],
                            [46.39, 9.16],
                            [47.96, 9.53],
                            [49, 10.64],
                            [49.37, 12.45],
                            [49.37, 18],
                            [47.67, 18],
                            [47.67, 12.65],
                            [47.17, 11.17],
                            [45.81, 10.63],
                            [44.76, 10.89],
                            [44.04, 11.64],
                            [43.78, 12.82],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [55.12, 7.88],
                            [55.12, 6.36],
                            [64.13, 6.36],
                            [64.13, 7.88],
                            [60.5, 7.88],
                            [60.5, 18],
                            [58.75, 18],
                            [58.75, 7.88],
                            [55.12, 7.88],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [65.82, 18],
                            [65.82, 9.27],
                            [67.52, 9.27],
                            [67.52, 18],
                            [65.82, 18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                          ],
                          o: [
                            [-0.3, 0],
                            [-0.21, -0.2],
                            [0, -0.28],
                            [0.21, -0.2],
                            [0.3, 0],
                            [0.21, 0.2],
                            [0, 0.28],
                            [-0.21, 0.2],
                            [0, 0],
                          ],
                          v: [
                            [66.68, 7.93],
                            [65.92, 7.63],
                            [65.6, 6.91],
                            [65.92, 6.2],
                            [66.68, 5.9],
                            [67.43, 6.2],
                            [67.75, 6.91],
                            [67.43, 7.63],
                            [66.68, 7.93],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [73.72, 9.27],
                            [73.72, 10.64],
                            [68.95, 10.64],
                            [68.95, 9.27],
                            [73.72, 9.27],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.1, -0.17],
                            [-0.16, -0.06],
                            [-0.19, 0],
                            [-0.1, 0.02],
                            [-0.06, 0.01],
                            [0, 0],
                            [0.18, -0.04],
                            [0.27, 0],
                            [0.39, 0.17],
                            [0.24, 0.35],
                            [0, 0.53],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0.33],
                            [0.1, 0.16],
                            [0.16, 0.06],
                            [0.14, 0],
                            [0.1, -0.02],
                            [0, 0],
                            [-0.1, 0.04],
                            [-0.18, 0.04],
                            [-0.45, 0.01],
                            [-0.39, -0.17],
                            [-0.24, -0.35],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [70.23, 7.18],
                            [71.93, 7.18],
                            [71.93, 15.44],
                            [72.08, 16.18],
                            [72.46, 16.52],
                            [72.97, 16.6],
                            [73.33, 16.57],
                            [73.57, 16.53],
                            [73.88, 17.93],
                            [73.46, 18.05],
                            [72.78, 18.11],
                            [71.53, 17.88],
                            [70.59, 17.1],
                            [70.23, 15.79],
                            [70.23, 7.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.32, 6.36],
                            [77.32, 18],
                            [75.63, 18],
                            [75.63, 6.36],
                            [77.32, 6.36],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [{ c: true, i: [], o: [], v: [] }],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.62, 0.37],
                            [0.34, 0.67],
                            [0, 0.91],
                            [-0.33, 0.68],
                            [-0.6, 0.38],
                            [-0.81, 0],
                            [-0.46, -0.16],
                            [-0.37, -0.35],
                            [-0.21, -0.56],
                            [0, -0.79],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.18, 0.34],
                            [0.33, 0.2],
                            [0.44, 0],
                            [0.36, -0.23],
                            [0.19, -0.37],
                            [0, -0.44],
                            [0, 0],
                            [-0.2, -0.41],
                            [-0.37, -0.22],
                            [-0.5, 0],
                            [-0.27, 0.09],
                            [-0.19, 0.19],
                            [-0.1, 0.27],
                            [0, 0],
                            [0.33, -0.36],
                            [0.5, -0.2],
                            [0.64, 0],
                          ],
                          o: [
                            [-0.86, 0],
                            [-0.62, -0.37],
                            [-0.33, -0.67],
                            [0, -0.89],
                            [0.34, -0.68],
                            [0.61, -0.38],
                            [0.49, 0],
                            [0.46, 0.16],
                            [0.37, 0.35],
                            [0.21, 0.55],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.45],
                            [-0.18, -0.35],
                            [-0.33, -0.2],
                            [-0.48, 0],
                            [-0.35, 0.23],
                            [-0.19, 0.37],
                            [0, 0],
                            [0, 0.58],
                            [0.21, 0.41],
                            [0.37, 0.21],
                            [0.32, 0],
                            [0.27, -0.09],
                            [0.19, -0.19],
                            [0, 0],
                            [-0.13, 0.47],
                            [-0.33, 0.35],
                            [-0.5, 0.19],
                            [0, 0],
                          ],
                          v: [
                            [83.21, 18.18],
                            [80.99, 17.63],
                            [79.56, 16.06],
                            [79.06, 13.69],
                            [79.56, 11.33],
                            [80.97, 9.73],
                            [83.09, 9.16],
                            [84.52, 9.4],
                            [85.77, 10.17],
                            [86.64, 11.53],
                            [86.96, 13.55],
                            [86.96, 14.15],
                            [80.02, 14.15],
                            [80.02, 12.88],
                            [85.29, 12.88],
                            [85.02, 11.69],
                            [84.25, 10.86],
                            [83.1, 10.56],
                            [81.85, 10.91],
                            [81.03, 11.82],
                            [80.75, 13.03],
                            [80.75, 14.03],
                            [81.06, 15.52],
                            [81.93, 16.45],
                            [83.23, 16.77],
                            [84.11, 16.64],
                            [84.8, 16.22],
                            [85.24, 15.53],
                            [86.85, 15.82],
                            [86.15, 17.06],
                            [84.91, 17.89],
                            [83.21, 18.18],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [0, 0, 0],
                      t: 0,
                    },
                    { s: [0.3922, 0.4589, 0.3608], t: 1 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.167, y: 0.167 },
                      i: { x: 0.833, y: 0.833 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 1 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'Frame 101071 Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [44, 12], t: 0 },
                  { s: [44, 12], t: 1 },
                ],
              },
              s: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100, 100], t: 0 },
                  { s: [100, 100], t: 1 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0, y: 0 },
                    i: { x: 1, y: 1 },
                    s: [44.5, 12.5],
                    t: 0,
                  },
                  { s: [44.5, 12.5], t: 1 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [0], t: 1 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [0], t: 0 },
                  { s: [100], t: 1 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [87, 0],
                            [87, 23],
                            [0, 23],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [87, 0],
                            [87, 23],
                            [0, 23],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 1,
                    },
                  ],
                },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                nm: '',
                lc: 1,
                lj: 1,
                ml: 4,
                o: {
                  a: 1,
                  k: [
                    { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [100], t: 0 },
                    { s: [100], t: 1 },
                  ],
                },
                w: {
                  a: 1,
                  k: [
                    { o: { x: 0, y: 0 }, i: { x: 1, y: 1 }, s: [1], t: 0 },
                    { s: [1], t: 1 },
                  ],
                },
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0, y: 0 },
                      i: { x: 1, y: 1 },
                      s: [0.3922, 0.4589, 0.3608],
                      t: 0,
                    },
                    { s: [0.3922, 0.4589, 0.3608], t: 1 },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] Frame 102123',
        id: '11',
        layers: [
          {
            ty: 0,
            nm: 'toolTipIcon',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 12,
                  },
                  { s: [12, 12], t: 72 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 12,
                  },
                  { s: [100, 100], t: 72 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [185, 18],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [185, 18],
                    t: 12,
                  },
                  { s: [185, 18], t: 72 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            w: 211.5,
            h: 100.5,
            refId: '12',
            ind: 1,
          },
          {
            ty: 4,
            nm: 'text',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [85, 18],
                    t: 0,
                  },
                  { s: [85, 18], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [85, 18],
                    t: 0,
                  },
                  { s: [85, 18], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.74, 14.13],
                            [10.62, 12.26],
                            [8.13, 11.58],
                            [6.27, 11.92],
                            [5.05, 12.84],
                            [4.63, 14.16],
                            [4.92, 15.24],
                            [5.69, 16],
                            [6.73, 16.5],
                            [7.8, 16.82],
                            [9.5, 17.27],
                            [11.22, 17.82],
                            [12.84, 18.74],
                            [14.05, 20.16],
                            [14.52, 22.18],
                            [13.76, 24.81],
                            [11.55, 26.63],
                            [8.06, 27.29],
                            [4.67, 26.67],
                            [2.43, 24.9],
                            [1.53, 22.18],
                            [4.18, 22.18],
                            [4.79, 23.75],
                            [6.18, 24.68],
                            [8.05, 24.98],
                            [10.03, 24.63],
                            [11.41, 23.64],
                            [11.92, 22.15],
                            [11.47, 20.88],
                            [10.29, 20.06],
                            [8.61, 19.49],
                            [6.55, 18.93],
                            [3.22, 17.25],
                            [2, 14.32],
                            [2.82, 11.68],
                            [5.04, 9.94],
                            [8.19, 9.31],
                            [11.32, 9.93],
                            [13.47, 11.64],
                            [14.28, 14.13],
                            [11.74, 14.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.66, 0.44],
                            [1, 0],
                            [0.52, -0.23],
                            [0.29, -0.39],
                            [0, -0.49],
                            [-0.19, -0.3],
                            [-0.32, -0.2],
                            [-0.37, -0.14],
                            [-0.34, -0.09],
                            [0, 0],
                            [-0.59, -0.23],
                            [-0.5, -0.38],
                            [-0.31, -0.56],
                            [0, -0.78],
                            [0.51, -0.77],
                            [0.97, -0.44],
                            [1.36, 0],
                            [0.95, 0.41],
                            [0.54, 0.76],
                            [0.06, 1.05],
                            [0, 0],
                            [-0.36, -0.42],
                            [-0.56, -0.2],
                            [-0.68, 0],
                            [-0.58, 0.23],
                            [-0.34, 0.42],
                            [0, 0.57],
                            [0.3, 0.33],
                            [0.5, 0.22],
                            [0.63, 0.16],
                            [0, 0],
                            [0.82, 0.74],
                            [0, 1.22],
                            [-0.55, 0.75],
                            [-0.93, 0.41],
                            [-1.17, 0],
                            [-0.9, -0.41],
                            [-0.52, -0.73],
                            [-0.02, -0.94],
                            [0, 0],
                          ],
                          o: [
                            [-0.09, -0.81],
                            [-0.66, -0.45],
                            [-0.72, 0],
                            [-0.52, 0.22],
                            [-0.28, 0.39],
                            [0, 0.41],
                            [0.2, 0.3],
                            [0.32, 0.2],
                            [0.37, 0.13],
                            [0, 0],
                            [0.56, 0.14],
                            [0.59, 0.23],
                            [0.5, 0.38],
                            [0.31, 0.56],
                            [0, 0.99],
                            [-0.51, 0.77],
                            [-0.96, 0.44],
                            [-1.31, 0],
                            [-0.95, -0.41],
                            [-0.54, -0.77],
                            [0, 0],
                            [0.05, 0.63],
                            [0.36, 0.41],
                            [0.57, 0.2],
                            [0.74, 0],
                            [0.59, -0.24],
                            [0.34, -0.43],
                            [0, -0.52],
                            [-0.29, -0.33],
                            [-0.49, -0.22],
                            [0, 0],
                            [-1.4, -0.38],
                            [-0.81, -0.74],
                            [0, -1.01],
                            [0.55, -0.75],
                            [0.93, -0.42],
                            [1.18, 0],
                            [0.91, 0.41],
                            [0.52, 0.72],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [11.74, 14.13],
                            [10.62, 12.26],
                            [8.13, 11.58],
                            [6.27, 11.92],
                            [5.05, 12.84],
                            [4.63, 14.16],
                            [4.92, 15.24],
                            [5.69, 16],
                            [6.73, 16.5],
                            [7.8, 16.82],
                            [9.5, 17.27],
                            [11.22, 17.82],
                            [12.84, 18.74],
                            [14.05, 20.16],
                            [14.52, 22.18],
                            [13.76, 24.81],
                            [11.55, 26.63],
                            [8.06, 27.29],
                            [4.67, 26.67],
                            [2.43, 24.9],
                            [1.53, 22.18],
                            [4.18, 22.18],
                            [4.79, 23.75],
                            [6.18, 24.68],
                            [8.05, 24.98],
                            [10.03, 24.63],
                            [11.41, 23.64],
                            [11.92, 22.15],
                            [11.47, 20.88],
                            [10.29, 20.06],
                            [8.61, 19.49],
                            [6.55, 18.93],
                            [3.22, 17.25],
                            [2, 14.32],
                            [2.82, 11.68],
                            [5.04, 9.94],
                            [8.19, 9.31],
                            [11.32, 9.93],
                            [13.47, 11.64],
                            [14.28, 14.13],
                            [11.74, 14.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.57],
                            [0.49, 1.02],
                            [0, 1.31],
                            [-0.5, 1.02],
                            [-0.91, 0.57],
                            [-1.23, 0],
                            [-0.78, -0.37],
                            [-0.48, -0.66],
                            [-0.09, -0.88],
                            [0, 0],
                            [0.49, 0.44],
                            [0.81, 0],
                            [0.53, -0.37],
                            [0.3, -0.69],
                            [0, -0.93],
                            [-0.29, -0.69],
                            [-0.53, -0.38],
                            [-0.72, 0],
                            [-0.39, 0.18],
                            [-0.27, 0.34],
                            [-0.11, 0.47],
                            [0, 0],
                            [0.46, -0.66],
                            [0.77, -0.38],
                            [1.03, 0],
                          ],
                          o: [
                            [-1.27, 0],
                            [-0.91, -0.58],
                            [-0.49, -1.02],
                            [0, -1.33],
                            [0.5, -1.02],
                            [0.91, -0.57],
                            [0.99, 0],
                            [0.78, 0.36],
                            [0.48, 0.66],
                            [0, 0],
                            [-0.14, -0.61],
                            [-0.48, -0.44],
                            [-0.71, 0],
                            [-0.53, 0.37],
                            [-0.3, 0.68],
                            [0, 0.95],
                            [0.29, 0.69],
                            [0.53, 0.38],
                            [0.48, 0],
                            [0.4, -0.18],
                            [0.27, -0.34],
                            [0, 0],
                            [-0.09, 0.85],
                            [-0.46, 0.66],
                            [-0.77, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [22.74, 27.26],
                            [19.47, 26.4],
                            [17.37, 24.01],
                            [16.64, 20.51],
                            [17.39, 16.99],
                            [19.5, 14.6],
                            [22.71, 13.74],
                            [25.37, 14.29],
                            [27.26, 15.83],
                            [28.12, 18.14],
                            [25.64, 18.14],
                            [24.7, 16.55],
                            [22.76, 15.89],
                            [20.89, 16.45],
                            [19.65, 18.03],
                            [19.21, 20.45],
                            [19.65, 22.93],
                            [20.87, 24.54],
                            [22.76, 25.11],
                            [24.07, 24.84],
                            [25.07, 24.07],
                            [25.64, 22.86],
                            [28.12, 22.86],
                            [27.29, 25.13],
                            [25.44, 26.69],
                            [22.74, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.57],
                            [0.49, 1.02],
                            [0, 1.31],
                            [-0.5, 1.02],
                            [-0.91, 0.57],
                            [-1.23, 0],
                            [-0.78, -0.37],
                            [-0.48, -0.66],
                            [-0.09, -0.88],
                            [0, 0],
                            [0.49, 0.44],
                            [0.81, 0],
                            [0.53, -0.37],
                            [0.3, -0.69],
                            [0, -0.93],
                            [-0.29, -0.69],
                            [-0.53, -0.38],
                            [-0.72, 0],
                            [-0.39, 0.18],
                            [-0.27, 0.34],
                            [-0.11, 0.47],
                            [0, 0],
                            [0.46, -0.66],
                            [0.77, -0.38],
                            [1.03, 0],
                          ],
                          o: [
                            [-1.27, 0],
                            [-0.91, -0.58],
                            [-0.49, -1.02],
                            [0, -1.33],
                            [0.5, -1.02],
                            [0.91, -0.57],
                            [0.99, 0],
                            [0.78, 0.36],
                            [0.48, 0.66],
                            [0, 0],
                            [-0.14, -0.61],
                            [-0.48, -0.44],
                            [-0.71, 0],
                            [-0.53, 0.37],
                            [-0.3, 0.68],
                            [0, 0.95],
                            [0.29, 0.69],
                            [0.53, 0.38],
                            [0.48, 0],
                            [0.4, -0.18],
                            [0.27, -0.34],
                            [0, 0],
                            [-0.09, 0.85],
                            [-0.46, 0.66],
                            [-0.77, 0.38],
                            [0, 0],
                          ],
                          v: [
                            [22.74, 27.26],
                            [19.47, 26.4],
                            [17.37, 24.01],
                            [16.64, 20.51],
                            [17.39, 16.99],
                            [19.5, 14.6],
                            [22.71, 13.74],
                            [25.37, 14.29],
                            [27.26, 15.83],
                            [28.12, 18.14],
                            [25.64, 18.14],
                            [24.7, 16.55],
                            [22.76, 15.89],
                            [20.89, 16.45],
                            [19.65, 18.03],
                            [19.21, 20.45],
                            [19.65, 22.93],
                            [20.87, 24.54],
                            [22.76, 25.11],
                            [24.07, 24.84],
                            [25.07, 24.07],
                            [25.64, 22.86],
                            [28.12, 22.86],
                            [27.29, 25.13],
                            [25.44, 26.69],
                            [22.74, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [36.24, 27.26],
                            [32.91, 26.44],
                            [30.76, 24.09],
                            [30.01, 20.54],
                            [30.76, 16.99],
                            [32.88, 14.6],
                            [36.07, 13.74],
                            [38.21, 14.11],
                            [40.08, 15.26],
                            [41.38, 17.29],
                            [41.86, 20.32],
                            [41.86, 21.22],
                            [31.46, 21.22],
                            [31.46, 19.31],
                            [39.36, 19.31],
                            [38.96, 17.53],
                            [37.8, 16.3],
                            [36.08, 15.84],
                            [34.21, 16.37],
                            [32.98, 17.74],
                            [32.55, 19.55],
                            [32.55, 21.04],
                            [33.01, 23.28],
                            [34.32, 24.68],
                            [36.27, 25.16],
                            [37.59, 24.95],
                            [38.62, 24.32],
                            [39.29, 23.29],
                            [41.7, 23.73],
                            [40.66, 25.59],
                            [38.79, 26.83],
                            [36.24, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.74, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.95, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.49, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [36.24, 27.26],
                            [32.91, 26.44],
                            [30.76, 24.09],
                            [30.01, 20.54],
                            [30.76, 16.99],
                            [32.88, 14.6],
                            [36.07, 13.74],
                            [38.21, 14.11],
                            [40.08, 15.26],
                            [41.38, 17.29],
                            [41.86, 20.32],
                            [41.86, 21.22],
                            [31.46, 21.22],
                            [31.46, 19.31],
                            [39.36, 19.31],
                            [38.96, 17.53],
                            [37.8, 16.3],
                            [36.08, 15.84],
                            [34.21, 16.37],
                            [32.98, 17.74],
                            [32.55, 19.55],
                            [32.55, 21.04],
                            [33.01, 23.28],
                            [34.32, 24.68],
                            [36.27, 25.16],
                            [37.59, 24.95],
                            [38.62, 24.32],
                            [39.29, 23.29],
                            [41.7, 23.73],
                            [40.66, 25.59],
                            [38.79, 26.83],
                            [36.24, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.64, 0.42],
                            [-0.98, 0],
                            [-0.67, -0.37],
                            [-0.37, -0.73],
                            [0, -1.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.49, 0.53],
                            [0.86, 0],
                            [0.46, -0.26],
                            [0.27, -0.49],
                            [0, -0.69],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, -0.69],
                            [0.65, -0.42],
                            [0.89, 0],
                            [0.67, 0.37],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.95],
                            [-0.49, -0.54],
                            [-0.59, 0],
                            [-0.45, 0.26],
                            [-0.26, 0.49],
                            [0, 0],
                          ],
                          v: [
                            [47, 19.23],
                            [47, 27],
                            [44.45, 27],
                            [44.45, 13.91],
                            [46.89, 13.91],
                            [46.89, 16.04],
                            [47.06, 16.04],
                            [48.47, 14.37],
                            [50.92, 13.74],
                            [53.26, 14.3],
                            [54.82, 15.95],
                            [55.37, 18.67],
                            [55.37, 27],
                            [52.83, 27],
                            [52.83, 18.98],
                            [52.09, 16.76],
                            [50.05, 15.95],
                            [48.47, 16.33],
                            [47.39, 17.45],
                            [47, 19.23],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.64, 0.42],
                            [-0.98, 0],
                            [-0.67, -0.37],
                            [-0.37, -0.73],
                            [0, -1.08],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.49, 0.53],
                            [0.86, 0],
                            [0.46, -0.26],
                            [0.27, -0.49],
                            [0, -0.69],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.3, -0.69],
                            [0.65, -0.42],
                            [0.89, 0],
                            [0.67, 0.37],
                            [0.37, 0.73],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.95],
                            [-0.49, -0.54],
                            [-0.59, 0],
                            [-0.45, 0.26],
                            [-0.26, 0.49],
                            [0, 0],
                          ],
                          v: [
                            [47, 19.23],
                            [47, 27],
                            [44.45, 27],
                            [44.45, 13.91],
                            [46.89, 13.91],
                            [46.89, 16.04],
                            [47.06, 16.04],
                            [48.47, 14.37],
                            [50.92, 13.74],
                            [53.26, 14.3],
                            [54.82, 15.95],
                            [55.37, 18.67],
                            [55.37, 27],
                            [52.83, 27],
                            [52.83, 18.98],
                            [52.09, 16.76],
                            [50.05, 15.95],
                            [48.47, 16.33],
                            [47.39, 17.45],
                            [47, 19.23],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.49, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.16, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [62.36, 27.29],
                            [60.11, 26.83],
                            [58.52, 25.47],
                            [57.94, 23.3],
                            [58.36, 21.48],
                            [59.52, 20.34],
                            [61.13, 19.71],
                            [62.96, 19.39],
                            [64.87, 19.17],
                            [65.93, 18.87],
                            [66.27, 18.2],
                            [66.27, 18.14],
                            [65.63, 16.41],
                            [63.78, 15.8],
                            [61.75, 16.37],
                            [60.73, 17.63],
                            [58.34, 17.08],
                            [59.58, 15.15],
                            [61.48, 14.08],
                            [63.74, 13.74],
                            [65.4, 13.93],
                            [67.06, 14.59],
                            [68.33, 15.98],
                            [68.82, 18.31],
                            [68.82, 27],
                            [66.33, 27],
                            [66.33, 25.21],
                            [66.23, 25.21],
                            [65.49, 26.18],
                            [64.22, 26.97],
                            [62.36, 27.29],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.67, 0.31],
                            [0.39, 0.59],
                            [0, 0.86],
                            [-0.28, 0.48],
                            [-0.48, 0.28],
                            [-0.6, 0.14],
                            [-0.62, 0.07],
                            [-0.49, 0.06],
                            [-0.22, 0.14],
                            [0, 0.31],
                            [0, 0],
                            [0.42, 0.41],
                            [0.82, 0],
                            [0.49, -0.38],
                            [0.19, -0.46],
                            [0, 0],
                            [-0.55, 0.49],
                            [-0.72, 0.22],
                            [-0.79, 0],
                            [-0.59, -0.12],
                            [-0.51, -0.32],
                            [-0.33, -0.6],
                            [0, -0.95],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.33, -0.32],
                            [0.52, -0.21],
                            [0.72, 0],
                          ],
                          o: [
                            [-0.83, 0],
                            [-0.67, -0.31],
                            [-0.39, -0.59],
                            [0, -0.74],
                            [0.28, -0.48],
                            [0.48, -0.28],
                            [0.6, -0.14],
                            [0.78, -0.09],
                            [0.49, -0.06],
                            [0.22, -0.14],
                            [0, 0],
                            [0, -0.74],
                            [-0.41, -0.41],
                            [-0.86, 0],
                            [-0.49, 0.38],
                            [0, 0],
                            [0.28, -0.8],
                            [0.55, -0.49],
                            [0.72, -0.23],
                            [0.52, 0],
                            [0.59, 0.12],
                            [0.52, 0.32],
                            [0.33, 0.6],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.16, 0.33],
                            [-0.33, 0.32],
                            [-0.52, 0.21],
                            [0, 0],
                          ],
                          v: [
                            [62.36, 27.29],
                            [60.11, 26.83],
                            [58.52, 25.47],
                            [57.94, 23.3],
                            [58.36, 21.48],
                            [59.52, 20.34],
                            [61.13, 19.71],
                            [62.96, 19.39],
                            [64.87, 19.17],
                            [65.93, 18.87],
                            [66.27, 18.2],
                            [66.27, 18.14],
                            [65.63, 16.41],
                            [63.78, 15.8],
                            [61.75, 16.37],
                            [60.73, 17.63],
                            [58.34, 17.08],
                            [59.58, 15.15],
                            [61.48, 14.08],
                            [63.74, 13.74],
                            [65.4, 13.93],
                            [67.06, 14.59],
                            [68.33, 15.98],
                            [68.82, 18.31],
                            [68.82, 27],
                            [66.33, 27],
                            [66.33, 25.21],
                            [66.23, 25.21],
                            [65.49, 26.18],
                            [64.22, 26.97],
                            [62.36, 27.29],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.51, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.26, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [62.92, 25.24],
                            [64.72, 24.83],
                            [65.87, 23.74],
                            [66.27, 22.28],
                            [66.27, 20.59],
                            [65.75, 20.85],
                            [64.87, 21.04],
                            [63.9, 21.19],
                            [63.12, 21.29],
                            [61.77, 21.61],
                            [60.79, 22.24],
                            [60.44, 23.34],
                            [61.13, 24.77],
                            [62.92, 25.24],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.5, 0.28],
                            [-0.26, 0.45],
                            [0, 0.52],
                            [0, 0],
                            [0.26, -0.08],
                            [0.33, -0.06],
                            [0.31, -0.05],
                            [0.21, -0.03],
                            [0.41, -0.15],
                            [0.24, -0.28],
                            [0, -0.45],
                            [-0.47, -0.32],
                            [-0.72, 0],
                          ],
                          o: [
                            [0.7, 0],
                            [0.51, -0.28],
                            [0.27, -0.45],
                            [0, 0],
                            [-0.09, 0.09],
                            [-0.26, 0.07],
                            [-0.33, 0.05],
                            [-0.31, 0.04],
                            [-0.49, 0.06],
                            [-0.4, 0.15],
                            [-0.24, 0.27],
                            [0, 0.63],
                            [0.47, 0.32],
                            [0, 0],
                          ],
                          v: [
                            [62.92, 25.24],
                            [64.72, 24.83],
                            [65.87, 23.74],
                            [66.27, 22.28],
                            [66.27, 20.59],
                            [65.75, 20.85],
                            [64.87, 21.04],
                            [63.9, 21.19],
                            [63.12, 21.29],
                            [61.77, 21.61],
                            [60.79, 22.24],
                            [60.44, 23.34],
                            [61.13, 24.77],
                            [62.92, 25.24],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.6, 0.4],
                            [-0.77, 0],
                            [-0.22, -0.01],
                            [-0.12, -0.02],
                            [0, 0],
                            [0.26, 0.03],
                            [0.26, 0],
                            [0.47, -0.26],
                            [0.27, -0.45],
                            [0, -0.57],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.7],
                            [0.61, -0.41],
                            [0.16, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.1, -0.03],
                            [-0.26, -0.04],
                            [-0.6, 0],
                            [-0.47, 0.25],
                            [-0.27, 0.44],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [71.98, 27],
                            [71.98, 13.91],
                            [74.44, 13.91],
                            [74.44, 15.99],
                            [74.58, 15.99],
                            [75.84, 14.33],
                            [77.9, 13.71],
                            [78.46, 13.73],
                            [78.98, 13.77],
                            [78.98, 16.21],
                            [78.44, 16.12],
                            [77.65, 16.06],
                            [76.04, 16.44],
                            [74.93, 17.49],
                            [74.52, 19.01],
                            [74.52, 27],
                            [71.98, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.6, 0.4],
                            [-0.77, 0],
                            [-0.22, -0.01],
                            [-0.12, -0.02],
                            [0, 0],
                            [0.26, 0.03],
                            [0.26, 0],
                            [0.47, -0.26],
                            [0.27, -0.45],
                            [0, -0.57],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.24, -0.7],
                            [0.61, -0.41],
                            [0.16, 0],
                            [0.22, 0.01],
                            [0, 0],
                            [-0.1, -0.03],
                            [-0.26, -0.04],
                            [-0.6, 0],
                            [-0.47, 0.25],
                            [-0.27, 0.44],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [71.98, 27],
                            [71.98, 13.91],
                            [74.44, 13.91],
                            [74.44, 15.99],
                            [74.58, 15.99],
                            [75.84, 14.33],
                            [77.9, 13.71],
                            [78.46, 13.73],
                            [78.98, 13.77],
                            [78.98, 16.21],
                            [78.44, 16.12],
                            [77.65, 16.06],
                            [76.04, 16.44],
                            [74.93, 17.49],
                            [74.52, 19.01],
                            [74.52, 27],
                            [71.98, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [80.95, 27],
                            [80.95, 13.91],
                            [83.5, 13.91],
                            [83.5, 27],
                            [80.95, 27],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [80.95, 27],
                            [80.95, 13.91],
                            [83.5, 13.91],
                            [83.5, 27],
                            [80.95, 27],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                          ],
                          o: [
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [0, 0],
                          ],
                          v: [
                            [82.23, 11.89],
                            [81.09, 11.45],
                            [80.62, 10.37],
                            [81.09, 9.3],
                            [82.23, 8.85],
                            [83.37, 9.3],
                            [83.85, 10.37],
                            [83.37, 11.45],
                            [82.23, 11.89],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                          ],
                          o: [
                            [-0.44, 0],
                            [-0.31, -0.3],
                            [0, -0.42],
                            [0.32, -0.3],
                            [0.44, 0],
                            [0.32, 0.3],
                            [0, 0.41],
                            [-0.31, 0.3],
                            [0, 0],
                          ],
                          v: [
                            [82.23, 11.89],
                            [81.09, 11.45],
                            [80.62, 10.37],
                            [81.09, 9.3],
                            [82.23, 8.85],
                            [83.37, 9.3],
                            [83.85, 10.37],
                            [83.37, 11.45],
                            [82.23, 11.89],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.51, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.51, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.91, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [92.2, 27.26],
                            [88.99, 26.42],
                            [86.85, 24.06],
                            [86.1, 20.51],
                            [86.85, 16.95],
                            [88.99, 14.58],
                            [92.2, 13.74],
                            [95.41, 14.58],
                            [97.54, 16.95],
                            [98.3, 20.51],
                            [97.54, 24.06],
                            [95.41, 26.42],
                            [92.2, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.51, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.51, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.91, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [92.2, 27.26],
                            [88.99, 26.42],
                            [86.85, 24.06],
                            [86.1, 20.51],
                            [86.85, 16.95],
                            [88.99, 14.58],
                            [92.2, 13.74],
                            [95.41, 14.58],
                            [97.54, 16.95],
                            [98.3, 20.51],
                            [97.54, 24.06],
                            [95.41, 26.42],
                            [92.2, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [92.21, 25.13],
                            [94.18, 24.49],
                            [95.34, 22.82],
                            [95.73, 20.51],
                            [95.34, 18.2],
                            [94.18, 16.51],
                            [92.21, 15.87],
                            [90.21, 16.51],
                            [89.04, 18.2],
                            [88.67, 20.51],
                            [89.04, 22.82],
                            [90.21, 24.49],
                            [92.21, 25.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [92.21, 25.13],
                            [94.18, 24.49],
                            [95.34, 22.82],
                            [95.73, 20.51],
                            [95.34, 18.2],
                            [94.18, 16.51],
                            [92.21, 15.87],
                            [90.21, 16.51],
                            [89.04, 18.2],
                            [88.67, 20.51],
                            [89.04, 22.82],
                            [90.21, 24.49],
                            [92.21, 25.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [107.22, 9.55],
                            [110.42, 9.55],
                            [115.97, 23.11],
                            [116.18, 23.11],
                            [121.74, 9.55],
                            [124.93, 9.55],
                            [124.93, 27],
                            [122.43, 27],
                            [122.43, 14.37],
                            [122.26, 14.37],
                            [117.12, 26.97],
                            [115.04, 26.97],
                            [109.89, 14.36],
                            [109.73, 14.36],
                            [109.73, 27],
                            [107.22, 27],
                            [107.22, 9.55],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [107.22, 9.55],
                            [110.42, 9.55],
                            [115.97, 23.11],
                            [116.18, 23.11],
                            [121.74, 9.55],
                            [124.93, 9.55],
                            [124.93, 27],
                            [122.43, 27],
                            [122.43, 14.37],
                            [122.26, 14.37],
                            [117.12, 26.97],
                            [115.04, 26.97],
                            [109.89, 14.36],
                            [109.73, 14.36],
                            [109.73, 27],
                            [107.22, 27],
                            [107.22, 9.55],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [133.83, 27.26],
                            [130.62, 26.42],
                            [128.49, 24.06],
                            [127.73, 20.51],
                            [128.49, 16.95],
                            [130.62, 14.58],
                            [133.83, 13.74],
                            [137.04, 14.58],
                            [139.17, 16.95],
                            [139.93, 20.51],
                            [139.17, 24.06],
                            [137.04, 26.42],
                            [133.83, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.91, 0.56],
                            [0.51, 1.01],
                            [0, 1.35],
                            [-0.51, 1.02],
                            [-0.91, 0.56],
                            [-1.23, 0],
                            [-0.92, -0.56],
                            [-0.51, -1.02],
                            [0, -1.36],
                            [0.5, -1.01],
                            [0.91, -0.56],
                            [1.23, 0],
                          ],
                          o: [
                            [-1.23, 0],
                            [-0.91, -0.56],
                            [-0.51, -1.01],
                            [0, -1.36],
                            [0.51, -1.02],
                            [0.91, -0.56],
                            [1.23, 0],
                            [0.91, 0.56],
                            [0.5, 1.02],
                            [0, 1.35],
                            [-0.51, 1.01],
                            [-0.92, 0.56],
                            [0, 0],
                          ],
                          v: [
                            [133.83, 27.26],
                            [130.62, 26.42],
                            [128.49, 24.06],
                            [127.73, 20.51],
                            [128.49, 16.95],
                            [130.62, 14.58],
                            [133.83, 13.74],
                            [137.04, 14.58],
                            [139.17, 16.95],
                            [139.93, 20.51],
                            [139.17, 24.06],
                            [137.04, 26.42],
                            [133.83, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [133.84, 25.13],
                            [135.82, 24.49],
                            [136.98, 22.82],
                            [137.36, 20.51],
                            [136.98, 18.2],
                            [135.82, 16.51],
                            [133.84, 15.87],
                            [131.84, 16.51],
                            [130.68, 18.2],
                            [130.3, 20.51],
                            [130.68, 22.82],
                            [131.84, 24.49],
                            [133.84, 25.13],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.52, 0.42],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.25, 0.7],
                            [0.52, 0.43],
                            [0.8, 0],
                            [0.53, -0.43],
                            [0.26, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.42],
                            [-0.8, 0],
                          ],
                          o: [
                            [0.8, 0],
                            [0.52, -0.42],
                            [0.25, -0.7],
                            [0, -0.84],
                            [-0.25, -0.7],
                            [-0.52, -0.43],
                            [-0.8, 0],
                            [-0.52, 0.43],
                            [-0.25, 0.7],
                            [0, 0.84],
                            [0.26, 0.7],
                            [0.53, 0.42],
                            [0, 0],
                          ],
                          v: [
                            [133.84, 25.13],
                            [135.82, 24.49],
                            [136.98, 22.82],
                            [137.36, 20.51],
                            [136.98, 18.2],
                            [135.82, 16.51],
                            [133.84, 15.87],
                            [131.84, 16.51],
                            [130.68, 18.2],
                            [130.3, 20.51],
                            [130.68, 22.82],
                            [131.84, 24.49],
                            [133.84, 25.13],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.54],
                            [0.47, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.83, 0.53],
                            [-1.05, 0],
                            [-0.49, -0.27],
                            [-0.27, -0.36],
                            [-0.15, -0.27],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, -0.36],
                            [0.5, -0.27],
                            [0.8, 0],
                          ],
                          o: [
                            [-1.06, 0],
                            [-0.82, -0.55],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.48, -1.01],
                            [0.83, -0.53],
                            [0.81, 0],
                            [0.5, 0.27],
                            [0.28, 0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.28],
                            [-0.28, 0.36],
                            [-0.5, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [147.44, 27.26],
                            [144.61, 26.45],
                            [142.66, 24.12],
                            [141.97, 20.48],
                            [142.67, 16.85],
                            [144.63, 14.54],
                            [147.45, 13.74],
                            [149.41, 14.15],
                            [150.57, 15.09],
                            [151.22, 16.03],
                            [151.38, 16.03],
                            [151.38, 9.55],
                            [153.92, 9.55],
                            [153.92, 27],
                            [151.43, 27],
                            [151.43, 24.96],
                            [151.22, 24.96],
                            [150.56, 25.92],
                            [149.38, 26.86],
                            [147.44, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.83, 0.54],
                            [0.47, 1.01],
                            [0, 1.41],
                            [-0.47, 1.01],
                            [-0.83, 0.53],
                            [-1.05, 0],
                            [-0.49, -0.27],
                            [-0.27, -0.36],
                            [-0.15, -0.27],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.29, -0.36],
                            [0.5, -0.27],
                            [0.8, 0],
                          ],
                          o: [
                            [-1.06, 0],
                            [-0.82, -0.55],
                            [-0.47, -1.01],
                            [0, -1.41],
                            [0.48, -1.01],
                            [0.83, -0.53],
                            [0.81, 0],
                            [0.5, 0.27],
                            [0.28, 0.36],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [-0.15, 0.28],
                            [-0.28, 0.36],
                            [-0.5, 0.27],
                            [0, 0],
                          ],
                          v: [
                            [147.44, 27.26],
                            [144.61, 26.45],
                            [142.66, 24.12],
                            [141.97, 20.48],
                            [142.67, 16.85],
                            [144.63, 14.54],
                            [147.45, 13.74],
                            [149.41, 14.15],
                            [150.57, 15.09],
                            [151.22, 16.03],
                            [151.38, 16.03],
                            [151.38, 9.55],
                            [153.92, 9.55],
                            [153.92, 27],
                            [151.43, 27],
                            [151.43, 24.96],
                            [151.22, 24.96],
                            [150.56, 25.92],
                            [149.38, 26.86],
                            [147.44, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.51, 0.39],
                            [-0.26, 0.69],
                            [0, 0.92],
                            [0.26, 0.68],
                            [0.5, 0.38],
                            [0.74, 0],
                            [0.51, -0.4],
                            [0.26, -0.69],
                            [0, -0.86],
                            [-0.26, -0.7],
                            [-0.51, -0.41],
                            [-0.76, 0],
                          ],
                          o: [
                            [0.73, 0],
                            [0.51, -0.39],
                            [0.27, -0.69],
                            [0, -0.91],
                            [-0.26, -0.68],
                            [-0.51, -0.38],
                            [-0.77, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.69],
                            [0, 0.88],
                            [0.26, 0.7],
                            [0.52, 0.4],
                            [0, 0],
                          ],
                          v: [
                            [148, 25.08],
                            [149.86, 24.5],
                            [151.02, 22.88],
                            [151.42, 20.45],
                            [151.03, 18.07],
                            [149.88, 16.47],
                            [148, 15.9],
                            [146.08, 16.5],
                            [144.92, 18.13],
                            [144.54, 20.45],
                            [144.93, 22.82],
                            [146.09, 24.48],
                            [148, 25.08],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [-0.51, 0.39],
                            [-0.26, 0.69],
                            [0, 0.92],
                            [0.26, 0.68],
                            [0.5, 0.38],
                            [0.74, 0],
                            [0.51, -0.4],
                            [0.26, -0.69],
                            [0, -0.86],
                            [-0.26, -0.7],
                            [-0.51, -0.41],
                            [-0.76, 0],
                          ],
                          o: [
                            [0.73, 0],
                            [0.51, -0.39],
                            [0.27, -0.69],
                            [0, -0.91],
                            [-0.26, -0.68],
                            [-0.51, -0.38],
                            [-0.77, 0],
                            [-0.51, 0.4],
                            [-0.26, 0.69],
                            [0, 0.88],
                            [0.26, 0.7],
                            [0.52, 0.4],
                            [0, 0],
                          ],
                          v: [
                            [148, 25.08],
                            [149.86, 24.5],
                            [151.02, 22.88],
                            [151.42, 20.45],
                            [151.03, 18.07],
                            [149.88, 16.47],
                            [148, 15.9],
                            [146.08, 16.5],
                            [144.92, 18.13],
                            [144.54, 20.45],
                            [144.93, 22.82],
                            [146.09, 24.48],
                            [148, 25.08],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.75, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.96, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.5, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [162.96, 27.26],
                            [159.63, 26.44],
                            [157.48, 24.09],
                            [156.73, 20.54],
                            [157.48, 16.99],
                            [159.6, 14.6],
                            [162.78, 13.74],
                            [164.93, 14.11],
                            [166.8, 15.26],
                            [168.1, 17.29],
                            [168.58, 20.32],
                            [168.58, 21.22],
                            [158.17, 21.22],
                            [158.17, 19.31],
                            [166.08, 19.31],
                            [165.67, 17.53],
                            [164.52, 16.3],
                            [162.8, 15.84],
                            [160.93, 16.37],
                            [159.7, 17.74],
                            [159.27, 19.55],
                            [159.27, 21.04],
                            [159.73, 23.28],
                            [161.04, 24.68],
                            [162.99, 25.16],
                            [164.31, 24.95],
                            [165.34, 24.32],
                            [166.01, 23.29],
                            [168.42, 23.73],
                            [167.38, 25.59],
                            [165.51, 26.83],
                            [162.96, 27.26],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0.93, 0.55],
                            [0.51, 1.01],
                            [0, 1.36],
                            [-0.5, 1.02],
                            [-0.9, 0.57],
                            [-1.22, 0],
                            [-0.69, -0.24],
                            [-0.55, -0.52],
                            [-0.32, -0.84],
                            [0, -1.19],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0.27, 0.52],
                            [0.49, 0.3],
                            [0.66, 0],
                            [0.53, -0.35],
                            [0.29, -0.56],
                            [0, -0.65],
                            [0, 0],
                            [-0.31, -0.61],
                            [-0.56, -0.32],
                            [-0.75, 0],
                            [-0.4, 0.14],
                            [-0.29, 0.28],
                            [-0.15, 0.41],
                            [0, 0],
                            [0.5, -0.53],
                            [0.75, -0.3],
                            [0.96, 0],
                          ],
                          o: [
                            [-1.29, 0],
                            [-0.93, -0.56],
                            [-0.5, -1.01],
                            [0, -1.34],
                            [0.51, -1.02],
                            [0.91, -0.57],
                            [0.74, 0],
                            [0.69, 0.24],
                            [0.55, 0.52],
                            [0.32, 0.83],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, -0.67],
                            [-0.27, -0.52],
                            [-0.49, -0.3],
                            [-0.72, 0],
                            [-0.53, 0.35],
                            [-0.28, 0.56],
                            [0, 0],
                            [0, 0.88],
                            [0.31, 0.61],
                            [0.56, 0.32],
                            [0.48, 0],
                            [0.4, -0.14],
                            [0.29, -0.28],
                            [0, 0],
                            [-0.19, 0.71],
                            [-0.5, 0.53],
                            [-0.74, 0.29],
                            [0, 0],
                          ],
                          v: [
                            [162.96, 27.26],
                            [159.63, 26.44],
                            [157.48, 24.09],
                            [156.73, 20.54],
                            [157.48, 16.99],
                            [159.6, 14.6],
                            [162.78, 13.74],
                            [164.93, 14.11],
                            [166.8, 15.26],
                            [168.1, 17.29],
                            [168.58, 20.32],
                            [168.58, 21.22],
                            [158.17, 21.22],
                            [158.17, 19.31],
                            [166.08, 19.31],
                            [165.67, 17.53],
                            [164.52, 16.3],
                            [162.8, 15.84],
                            [160.93, 16.37],
                            [159.7, 17.74],
                            [159.27, 19.55],
                            [159.27, 21.04],
                            [159.73, 23.28],
                            [161.04, 24.68],
                            [162.99, 25.16],
                            [164.31, 24.95],
                            [165.34, 24.32],
                            [166.01, 23.29],
                            [168.42, 23.73],
                            [167.38, 25.59],
                            [165.51, 26.83],
                            [162.96, 27.26],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1, 1, 1],
                      t: 0,
                    },
                    { s: [1, 1, 1], t: 12 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
              },
            ],
            ind: 2,
          },
          {
            ty: 4,
            nm: 'Frame 102123 Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [98.5, 18],
                    t: 0,
                  },
                  { s: [98.5, 18], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [98.5, 18],
                    t: 0,
                  },
                  { s: [98.5, 18], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [197, 0],
                            [197, 36],
                            [0, 36],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [197, 0],
                            [197, 36],
                            [0, 36],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            ind: 3,
          },
        ],
      },
      {
        nm: '[Asset] toolTipIcon',
        id: '12',
        layers: [
          {
            ty: 0,
            nm: 'heroicons-outline/information-circle',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: true,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            masksProperties: [
              {
                nm: '',
                inv: false,
                mode: 'a',
                x: { a: 0, k: 0 },
                o: { a: 0, k: 100 },
                pt: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            w: 211.5,
            h: 100.5,
            refId: '13',
            ind: 1,
          },
          {
            ty: 4,
            nm: 'toolTipIcon Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 12,
                  },
                  { s: [0], t: 72 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] heroicons-outline/information-circle',
        id: '13',
        layers: [
          {
            ty: 4,
            nm: 'Vector',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [9, 9],
                    t: 0,
                  },
                  { s: [9, 9], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [4.97, 0],
                            [0, 4.97],
                            [-4.97, 0],
                            [0, -4.97],
                          ],
                          o: [
                            [0, 4.97],
                            [-4.97, 0],
                            [0, -4.97],
                            [4.97, 0],
                            [0, 0],
                          ],
                          v: [
                            [18, 9],
                            [9, 18],
                            [0, 9],
                            [9, 0],
                            [18, 9],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [4.97, 0],
                            [0, 4.97],
                            [-4.97, 0],
                            [0, -4.97],
                          ],
                          o: [
                            [0, 4.97],
                            [-4.97, 0],
                            [0, -4.97],
                            [4.97, 0],
                            [0, 0],
                          ],
                          v: [
                            [18, 9],
                            [9, 18],
                            [0, 9],
                            [9, 0],
                            [18, 9],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9, 5.25],
                            [9.01, 5.25],
                            [9.01, 5.26],
                            [9, 5.26],
                            [9, 5.25],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [9, 5.25],
                            [9.01, 5.25],
                            [9.01, 5.26],
                            [9, 5.26],
                            [9, 5.25],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
              {
                ty: 'st',
                bm: 0,
                hd: false,
                nm: '',
                lc: 2,
                lj: 2,
                ml: 4,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [100],
                      t: 0,
                    },
                    { s: [100], t: 12 },
                  ],
                },
                w: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1.5],
                      t: 0,
                    },
                    { s: [1.5], t: 12 },
                  ],
                },
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [1, 1, 1],
                      t: 0,
                    },
                    { s: [1, 1, 1], t: 12 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'heroicons-outline/information-circle Bg',
            sr: 1,
            st: 0,
            op: 73,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100, 100],
                    t: 0,
                  },
                  { s: [100, 100], t: 12 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [12, 12],
                    t: 0,
                  },
                  { s: [12, 12], t: 12 },
                ],
              },
              r: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [0],
                    t: 0,
                  },
                  { s: [0], t: 12 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 0.84, y: 0.03 },
                    i: { x: 0.47, y: 0.97 },
                    s: [100],
                    t: 0,
                  },
                  { s: [100], t: 12 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 0.84, y: 0.03 },
                      i: { x: 0.47, y: 0.97 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [24, 0],
                            [24, 24],
                            [0, 24],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 12,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
    ],
  };

  return jsonTemplate;
};

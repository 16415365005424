import { ZGraphData } from '~/pages/Dashboard/entity/schemas';
import { IGraphData } from '~/pages/Dashboard/entity/types';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export interface IPageData {
  reportData: IGraphData;
}

export const pageData = async ({ organizationUuid }: { organizationUuid: string }): Promise<IPageData> => {
  const reportsResponsePromise = request({
    url: `/reports/consolidate`,
    method: 'GET',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  }) as Promise<IAPIResponse<IGraphData>>;

  const [reportsResponse] = await Promise.all([reportsResponsePromise]);

  const parsedReportsData = ZGraphData.parse(reportsResponse.data.data);

  return {
    reportData: parsedReportsData,
  };
};

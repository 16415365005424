import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { IScenario } from '../entity/types';
import { ZScenario } from '../entity/schemas';

export const fetchScenarios = async ({ organizationUuid }: { organizationUuid: string }): Promise<IScenario[]> => {
  const response = (await request({
    method: 'GET',
    url: `/organizations/${organizationUuid}/scenarios`,
  })) as IAPIResponse<IScenario[]>;
  return ZScenario.array().parse(response.data.data);
};

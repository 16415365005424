import { createMonthArrayBetweenDates } from '~/utils/dates/createMonthArrayBetweenDates';
import { IFormulaGroupData } from '../ModelBuilderContext';
import { format } from 'date-fns';
import { roundCalculatedValue } from '~/utils/roundCalculatedValue';
import { toZonedTime } from 'date-fns-tz';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';

export const convertModelToCsvData = ({
  modelData,
  startDate,
  endDate,
}: {
  modelData: IFormulaGroupData[];
  startDate: Date;
  endDate: Date;
}): string => {
  const monthsArray = createMonthArrayBetweenDates(toZonedTime(startDate, 'UTC'), toZonedTime(endDate, 'UTC'));
  const headers = ['Attribute Title', ...monthsArray.map((month) => format(month, 'MMM yyyy'))];
  const rows: string[][] = [];

  modelData.forEach((group) => {
    rows.push([group.name, ...Array<string>(monthsArray.length).fill('')]);
    group.formulas.forEach((formula) => {
      const formulaRow = [
        formula.label.name,
        ...Object.values(formula.monthlyValues).map((value) => {
          if (value.actualValue !== null) {
            return roundCalculatedValue({
              value: value.actualValue,
              roundingInstructions: value.roundingInstructions,
            }).toString();
          }
          if (value.overrideValue !== null) {
            return roundCalculatedValue({
              value: value.overrideValue,
              roundingInstructions: value.roundingInstructions,
            }).toString();
          }
          return roundCalculatedValue({
            value: value.calculatedValue / CENTS_PER_DOLLAR,
            roundingInstructions: value.roundingInstructions,
            maxDecimalPlaces: 2,
          }).toString();
        }),
      ];
      rows.push(formulaRow);
    });
  });

  return [headers, ...rows].map((row) => row.join(',')).join('\n');
};

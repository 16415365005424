import { useCallback, useEffect, useState } from 'react';
import pageData from './pageData';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { ZExpensesPageRequest } from './components/Expenses/entity/schemas';
import { IExpense } from './components/Expenses/types';
import { IPositionDetails } from '../Headcount/entity/types';
import { IConsolidatedReportCollection } from '~/pages/Dashboard/entity/types';
import { fetchFilteredExpensesReport } from './fetchFilteredExpensesReport';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IFormula } from '../FinancialModelDeprecated/entity/types';
import serverRequest from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import { store } from '~/store';
import { ZFormula } from '../FinancialModelDeprecated/entity/schemas';
import { z } from 'zod';
import { IIntegration } from '~/services/parallel/integrations.types';

export const useExpensesData = (): {
  loading: boolean;
  initialExpenses: IExpense[];
  initialProposedExpenses: IExpense[];
  initialRecommendedExpenses: IExpense[];
  initialReports: IConsolidatedReportCollection;
  initialPositions: IPositionDetails[];
  revalidate: () => void;
  filteredExpensesReportLoading: boolean;
  revalidateFilteredExpensesReport: () => void;
  revalidateFormulaList: () => void;
  formulaList: IFormula[];
  connectedIntegrations: IIntegration[];
} => {
  const [queryParams] = useQueryParams();
  const frequencyFilter = queryParams.get('frequencyFilter') ?? 'all';
  const tagFilter = queryParams.get('tagFilter') ?? 'all';
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredExpensesReportLoading, setFilteredExpensesReportLoading] = useState<boolean>(true);
  const [initialExpenses, setInitialExpenses] = useState<IExpense[]>([]);
  const [initialReports, setInitialReports] = useState<IConsolidatedReportCollection>({});
  const [initialPositions, setInitialPositions] = useState<IPositionDetails[]>([]);
  const [formulaList, setFormulaList] = useState<IFormula[]>([]);
  const [connectedIntegrations, setConnectedIntegrations] = useState<IIntegration[]>([]);
  const [initialProposedExpenses, setInitialProposedExpenses] = useState<IExpense[]>([]);
  const [initialRecommendedExpenses, setInitialRecommendedExpenses] = useState<IExpense[]>([]);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const {
    organization: { uuid: organizationUuid },
  } = store.getState();

  const fetchData = useCallback(async (): Promise<void> => {
    const response = await pageData({ frequencyFilter, tagFilter });
    const parsedResponse = ZExpensesPageRequest.parse(response);
    setInitialExpenses(parsedResponse.expenses.filter((expense) => expense.creationStatus === 'created'));
    setInitialProposedExpenses(parsedResponse.expenses.filter((expense) => expense.creationStatus === 'proposed'));
    setInitialRecommendedExpenses(
      parsedResponse.expenses.filter((expense) => expense.creationStatus === 'recommended'),
    );
    setInitialReports(parsedResponse.reports);
    setInitialPositions(parsedResponse.positions);
    setFormulaList(parsedResponse.formulaList);
    setLoading(false);
    if (parsedResponse.connectedIntegrations && parsedResponse.connectedIntegrations.length > 0) {
      setConnectedIntegrations(parsedResponse.connectedIntegrations);
    }
    setFilteredExpensesReportLoading(false);
  }, [frequencyFilter, tagFilter]);

  useEffect(() => {
    fetchData();
  }, [fetchData, activeScenarioUuid]);

  const revalidateFilteredExpensesReport = async (): Promise<void> => {
    setFilteredExpensesReportLoading(true);
    const response = await fetchFilteredExpensesReport({
      frequencyFilter,
      tagFilter,
    });
    setInitialReports(response);
    setFilteredExpensesReportLoading(false);
  };

  const revalidateFormulaList = async (): Promise<void> => {
    const response = (await serverRequest({
      url: '/formulas',
      method: 'GET',
      params: {
        startDate,
        endDate,
        includes: ['calculations'],
        scenarioUuid: activeScenarioUuid ?? undefined,
        types: [IFormulaTypeEnum.ModelBuilder, IFormulaTypeEnum.Expense],
        includeProposed: true,
      },
      headers: { 'Organization-Uuid': organizationUuid },
    })) as unknown as IAPIResponse;

    const parsedFormulas = z.array(ZFormula).parse(response.data.data);

    setFormulaList(parsedFormulas);
  };

  return {
    loading,
    initialExpenses,
    initialReports,
    initialPositions,
    formulaList,
    revalidate: fetchData,
    filteredExpensesReportLoading,
    revalidateFilteredExpensesReport,
    revalidateFormulaList,
    connectedIntegrations,
    initialProposedExpenses,
    initialRecommendedExpenses,
  };
};

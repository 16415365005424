import React from 'react';
import { DndContext, PointerSensor, useSensor, useSensors, DragOverEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from '~/components/Button';
import DraggableItem from '~/components/FormulasTable/DraggableItem';
import { useState, useRef, useEffect } from 'react';
import Typography from '~/components/Typography';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import HoverPopover from '~/components/HoverPopover';
import { TrashIcon } from '@heroicons/react/24/outline';
import ContentEditableInput from '~/components/Input/ContentEditable';
import { useInput } from '~/components/Input';
import { isEqual } from 'lodash';
import useTableContext from '../useFormulaContext';
import { v4 as uuid } from 'uuid';
import { IFormulaGroupData } from '../ModelBuilderContext';
import { IRecordTypeEnum } from '~/components/FormulasTable/TableBody';
import DragHandle from './DragHandle';

interface IProps {
  onClose: () => void;
}

const EditableGroupName = ({
  groupName,
  onUpdate,
  isLatest,
}: {
  groupName: string;
  onUpdate: (name: string) => void;
  isLatest: boolean;
}): React.ReactNode => {
  const [state, setState] = useInput({ value: groupName });
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isLatest && inputRef.current) {
      inputRef.current.focus();
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(inputRef.current);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }, [isLatest]);

  const handleBlur = (): void => {
    onUpdate(state.value);
  };

  return (
    <ContentEditableInput
      ref={inputRef}
      id={groupName}
      state={state}
      setState={setState}
      onBlur={handleBlur}
      className="py-[5px]"
    />
  );
};

const ManageGroups = ({ onClose }: IProps): React.ReactNode => {
  // Could go right into ModelBuilderContext but this is easier for now as it is the pattern
  const { allFormulasData, updateSortOrder } = useTableContext();
  const [pendingGroups, setPendingGroups] = useState(allFormulasData);
  const latestGroupRef = useRef<string | null>(null);
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 0,
        tolerance: 5,
      },
    }),
  );

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      setPendingGroups((items) => {
        const oldIndex = items.findIndex((item) => item.uuid === active.id);
        const newIndex = items.findIndex((item) => item.uuid === over.id);
        const newItems = [...items];
        newItems.splice(newIndex, 0, newItems.splice(oldIndex, 1)[0]);
        return newItems;
      });
    }
  };

  const handleDeleteGroup = (id: string): void => {
    setPendingGroups((items) => items.filter((item) => item.uuid !== id));
  };

  const getUniqueGroupName = (groups: IFormulaGroupData[]): string => {
    let newName = 'New Group';
    let counter = 0;
    const groupNames = groups.map((group) => group.name);

    while (groupNames.includes(newName)) {
      counter += 1;
      newName = `New Group (${counter})`;
    }

    return newName;
  };

  const handleUpdateGroupName = (id: string, name: string): void => {
    setPendingGroups((prevState) => {
      const index = prevState.findIndex((item) => item.uuid === id);
      const newState = [...prevState];
      newState[index].name = name;
      return newState;
    });
  };

  return (
    <div className="flex flex-col gap-2 w-full mt-3">
      <div className="flex flex-col gap-2">
        <DndContext sensors={sensors} onDragOver={handleDragOver} modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={pendingGroups.map((group) => group.uuid)} strategy={verticalListSortingStrategy}>
            {pendingGroups.map((group) => {
              const hasAttributes = group.formulas.length > 0;
              const DeleteButtonContent = ({ disabled }: { disabled?: boolean }): React.ReactNode => {
                return (
                  <Button
                    className="!w-auto !px-1"
                    fill="clear"
                    onClick={() => handleDeleteGroup(group.uuid)}
                    disabled={disabled}
                  >
                    <TrashIcon
                      className={`size-[18px] ${
                        hasAttributes ? 'text-neutral-50' : 'text-neutral-200 hover/sortableitem:text-red-500'
                      }`}
                    />
                  </Button>
                );
              };
              return (
                <DraggableItem
                  key={group.uuid}
                  id={group.uuid}
                  iconAlwaysVisible
                  className="bg-white border border-neutral-50 rounded-md"
                  liveUpdate
                >
                  <DragHandle />
                  <div className="flex justify-between items-center w-full py-[3px]">
                    <div className="flex">
                      <EditableGroupName
                        groupName={group.name}
                        onUpdate={(value) => handleUpdateGroupName(group.uuid, value)}
                        isLatest={group.uuid === latestGroupRef.current}
                      />
                    </div>
                    <div className="pr-2">
                      {!hasAttributes ? (
                        <DeleteButtonContent />
                      ) : (
                        <HoverPopover
                          buttonContent={<DeleteButtonContent disabled />}
                          panelContent={
                            <div className="p-3 bg-white w-[300px] flex flex-col justify-start items-start gap-4">
                              <Typography size="xs">Unable to delete a group unless it has no attributes</Typography>
                            </div>
                          }
                          panelClassName="shadow-lg rounded-xl"
                          hoverDelay={500}
                        />
                      )}
                    </div>
                  </div>
                </DraggableItem>
              );
            })}
          </SortableContext>
        </DndContext>
        <Button
          className="w-full !px-3 text-left !justify-start !bg-green-15 hover:!bg-green-25 text-green border-none"
          onClick={() => {
            const newUuid = uuid();
            latestGroupRef.current = newUuid;
            setPendingGroups((prevState) => [
              ...prevState,
              {
                uuid: newUuid,
                type: IRecordTypeEnum.Group,
                name: getUniqueGroupName(prevState),
                isCollapsed: false,
                formulas: [],
              },
            ]);
          }}
        >
          Add Group
        </Button>
      </div>
      <div className="w-full flex justify-between gap-2">
        <Button className="!w-auto !px-0" fill="clear" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="!w-auto"
          onClick={() => {
            // Only trigger an update if the groups have changed
            if (!isEqual(allFormulasData, pendingGroups)) {
              updateSortOrder(pendingGroups);
            }
            onClose();
          }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default ManageGroups;

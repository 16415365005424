const ONE_HUNDRED_THOUSAND_WITH_CENTS = 100000000;
const TEN_THOUSAND_WITH_CENTS = 1000000;
const NEAREST_FIVE_HUNDRED = 50000;
const NEAREST_FIVE_THOUSAND = 500000;
const NEAREST_FIVE_HUNDRED_THOUSAND = 50000000;

const roundMiddleValues = ({
  intervalValue,
  tickInterval,
}: {
  intervalValue: number;
  tickInterval: number;
}): number => {
  return tickInterval / ONE_HUNDRED_THOUSAND_WITH_CENTS > 1
    ? Math.round(intervalValue / NEAREST_FIVE_HUNDRED_THOUSAND) * NEAREST_FIVE_HUNDRED_THOUSAND
    : tickInterval / TEN_THOUSAND_WITH_CENTS > 1
      ? Math.round(intervalValue / NEAREST_FIVE_THOUSAND) * NEAREST_FIVE_THOUSAND
      : Math.round(intervalValue / NEAREST_FIVE_HUNDRED) * NEAREST_FIVE_HUNDRED;
};

const standardTickValues = ({
  yMin,
  yMax,
  tickCount,
  isDashboardGraph,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
  isDashboardGraph?: boolean;
}): number[] => {
  if (yMax < 10) {
    tickCount = 2;
  }
  const adjustedTickCount = tickCount - 1;
  const tickValues = new Array<number>(tickCount).fill(0);
  tickValues[0] = yMin;
  if (isDashboardGraph) {
    tickValues[tickValues.length - 1] = yMax * 1.2;
  } else {
    tickValues[tickValues.length - 1] = yMax;
  }
  const tickInterval = (yMax - yMin) / adjustedTickCount;
  for (let i = 1; i <= adjustedTickCount - 1; i++) {
    tickValues[i] = parseFloat((yMin + tickInterval * i).toFixed(0));
  }
  return Array.from(new Set(tickValues)).sort((a, b) => a - b);
};

const generateMoneyTicks = ({
  yMin,
  yMax,
  tickCount,
  isDashboardGraph,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
  isDashboardGraph?: boolean;
}): number[] => {
  const adjustedTickCount = tickCount - 1;

  const roundedMax = Math.ceil((yMax * 1.1) / TEN_THOUSAND_WITH_CENTS) * TEN_THOUSAND_WITH_CENTS;

  const roundedMin = yMin < 0 ? Math.floor(yMin / TEN_THOUSAND_WITH_CENTS) * TEN_THOUSAND_WITH_CENTS : 0;

  const tickValues: number[] = new Array<number>(tickCount).fill(0);
  tickValues[0] = roundedMin;
  tickValues[tickValues.length - 1] = roundedMax;
  const tickInterval = (roundedMax - roundedMin) / adjustedTickCount;

  for (let i = 1; i <= adjustedTickCount - 1; i++) {
    const intervalValue = roundedMin + tickInterval * i;
    tickValues[i] = roundMiddleValues({ intervalValue, tickInterval });
  }

  const middleIndex = Math.floor(tickValues.length / 2);

  if (isDashboardGraph && tickValues[0] < 0 && tickValues[tickValues.length - 1] > 0) {
    tickValues[middleIndex] = 0;
  }

  // Give extra cushion if needed to try to make three ticks always render
  if (tickValues[middleIndex] === 0 && isDashboardGraph) {
    const maxAbsValue = Math.max(Math.abs(tickValues[0]), tickValues[tickValues.length - 1]);
    const twentyFivePercentOfMax = maxAbsValue * 0.25;

    if (Math.abs(tickValues[0]) < twentyFivePercentOfMax) {
      tickValues[0] = tickValues[0] - twentyFivePercentOfMax;
    }

    if (Math.abs(tickValues[tickValues.length - 1]) < twentyFivePercentOfMax) {
      tickValues[tickValues.length - 1] = tickValues[tickValues.length - 1] + twentyFivePercentOfMax;
    }
  }

  /**
   * Recharts depends on the tick value as a key at some point
   * so duplicate values must be removed to avoid duplicate key
   * errors which cause the ticks to overlap on the graph when
   * the ticks are updated
   */
  return Array.from(new Set(tickValues)).sort((a, b) => a - b);
};

const generateStandardTicks = ({
  yMin,
  yMax,
  tickCount,
  roundToMoney,
  isDashboardGraph,
}: {
  yMin: number;
  yMax: number;
  tickCount: number;
  roundToMoney: boolean;
  isDashboardGraph?: boolean;
}): number[] => {
  if (roundToMoney) {
    return generateMoneyTicks({
      yMin,
      yMax,
      tickCount,
      isDashboardGraph,
    });
  }
  return standardTickValues({
    yMin,
    yMax,
    tickCount,
    isDashboardGraph,
  });
};

export default generateStandardTicks;

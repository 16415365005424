import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import utc from 'dayjs/plugin/utc';
import { DashboardPageContext, DashboardPageContextProvider } from './context/DashboardContext';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import request from '~/utils/request';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { update, updateScenarioLoadingState } from '~/store/scenarioSlice';
import { closeConversationBox } from '~/store/chatSlice';
import { StatusCodes } from 'http-status-codes';
import Header from '~/components/Header';
import ScenarioMenuDropdown from './components/ScenariosContainer/ScenarioMenuDropdown/ScenarioMenuDropdown';
import UserDateRange from '~/components/UserDateRange';
import Typography from '~/components/Typography';
import SelectedScenarioContainer from './components/ScenariosContainer/SelectedScenarioContainer';
import DashboardGraphs from './components/DashboardGraphs/DashboardGraphs';
import DashboardMetrics from './components/DashboardMetrics/DashboardMetrics';
import HeadcountProvider, { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import * as HeadcountTimeline from '~/pages/Headcount/components/HeadcountTimeline';
import CreatePosition from '../Headcount/components/CreatePosition';
import { AdjustmentsVerticalIcon, ChevronRightIcon, UserPlusIcon } from '@heroicons/react/24/outline';
import SegmentedControlUnderlined from '~/components/SegmentedControlUnderlined';
import DashboardLeverGraphs from './components/DashboardLeverGraphs/DashboardLeverGraphs';
import Button from '~/components/Button';
import CustomizeDashboardModal from './components/CustomizeDashboard/CustomizeDashboardModal';
import logger from '~/utils/logger';
import { settingsSlice } from '~/store/settingsSlice';
import EnterScenarioModeButton from '~/components/EnterScenarioModeButton';
import { ShareButton } from './components/ShareButton';

dayjs.extend(quarterOfYear);
dayjs.extend(utc);

interface ICreateScenarioResponse {
  status: number;
  data: {
    data: IScenario;
  };
}

const getScenarioContainerClass = (screenWidth: number, sideMenuExpanded: boolean): string => {
  screenWidth = sideMenuExpanded ? screenWidth - 260 : screenWidth;
  if (screenWidth < 1350 && screenWidth >= 1275) {
    return 'max-w-[40vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 1275 && screenWidth >= 1190) {
    return 'max-w-[35vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 1190 && screenWidth >= 1090) {
    return 'max-w-[30vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 1090 && screenWidth >= 1020) {
    return 'max-w-[25vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 1020 && screenWidth >= 915) {
    return 'max-w-[20vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 915 && screenWidth >= 870) {
    return 'max-w-[15vw] overflow-x-auto no-scrollbar';
  } else if (screenWidth < 870) {
    return 'hidden';
  }
  return '';
};

const DashboardContainer = (): React.ReactNode => {
  const { reports, scenarios, reload, pageLoading } = useContext(DashboardPageContext);
  const {
    reload: reloadHeadcount,
    positionFormState,
    createPositionModalIsOpen,
    setCreatePositionModalIsOpen,
    hasReloaded,
    setHasReloaded,
    pageLoading: headcountPageLoading,
  } = useContext(HeadcountContext);
  const dispatch = useDispatch();
  const { activeScenarioUuid, activeScenarioData, selectedScenarioUuids } = useSelector(
    (state: State) => state.scenario,
  );
  const { collapseInteractiveSection, isInteractiveSectionCollapsed } = useSelector((state: State) => state.settings);
  const { isOpen } = useSelector((state: State) => state.chat);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const sideMenuExpanded = useSelector((state: State) => state.user.preferences.sideMenuExpanded);
  const userUuid = useSelector((state: State) => state.user.uuid);
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth < 2600);
  const [hideInteractiveSection, setHideInteractiveSection] = useState<boolean>(false);
  const [selectedInteractiveSection, setSelectedInteractiveSection] = useState<string>('levers');
  const [showCustomizeDashboardModal, setShowCustomizeDashboardModal] = useState<boolean>(false);
  const [rightPosition, setRightPosition] = useState<string>('-right-10');
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const checkScrollbar = (): void => {
      const childContainer = document.getElementById('child-container');
      if (childContainer) {
        const hasScrollbar = childContainer.scrollHeight > childContainer.clientHeight;
        if (hasScrollbar) {
          setRightPosition('-right-10'); // Adjust this value as needed
        } else {
          setRightPosition('-right-[52px]'); // Original value
        }
      }
    };

    checkScrollbar();
    window.addEventListener('resize', checkScrollbar);

    return () => {
      window.removeEventListener('resize', checkScrollbar);
    };
  }, [pageLoading, headcountPageLoading]);

  useEffect(() => {
    const handleResize = (): void => {
      setIsSmallScreen(window.innerWidth < 2600);
      if (window.innerWidth <= 1000) {
        setHideInteractiveSection(true);
      } else if (sideMenuExpanded && window.innerWidth <= 1080) {
        setHideInteractiveSection(true);
      } else if (!sideMenuExpanded && window.innerWidth > 1000) {
        setHideInteractiveSection(false);
      } else {
        setHideInteractiveSection(false);
      }
      setScreenWidth(window.innerWidth);
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sideMenuExpanded]);

  useEffect(() => {
    if (!pageLoading && !headcountPageLoading) {
      reload();
    }
  }, [hasReloaded]);

  const handleCreateScenario = async (type: 'dynamic' | 'static', title?: string): Promise<void> => {
    if (type === 'dynamic') {
      dispatch(updateScenarioLoadingState('creating'));
    }
    const response = (await request({
      method: 'POST',
      url: `/organizations/${organizationUuid}/scenarios`,
      body: {
        type,
        changeDescription: title,
      },
    })) as ICreateScenarioResponse;

    if (response.status === StatusCodes.CREATED) {
      dispatch(
        update({
          activeScenarioUuid: type === 'dynamic' ? response.data.data.uuid : null,
          activeScenarioData: response.data.data,
          activeScenarioHasChanges: false,
          leverChanges: [],
          cashBalanceLockedIndexes: [],
          cashBalanceSelectedPoint: null,
          selectedScenarioUuids,
          scenarioMode: 'creating',
          scenarioLoadingState: 'idle',
        }),
      );
      await reload();
      dispatch(closeConversationBox());
      dispatch(updateScenarioLoadingState('idle'));
    } else {
      dispatch(updateScenarioLoadingState('idle'));
      logger.error(new Error('Failed to create scenario'));
    }
  };

  const selectedScenarioDisplay = (
    <div className={`flex flex-row gap-2 ${getScenarioContainerClass(screenWidth, sideMenuExpanded)}`}>
      {selectedScenarioUuids.map((scenario, index) => {
        const scenarioForUuid = scenarios.find((s) => s.uuid === scenario);
        if (!scenarioForUuid) {
          return null;
        }

        return <SelectedScenarioContainer scenario={scenarioForUuid} key={scenario} index={index} />;
      })}
      {activeScenarioUuid && activeScenarioData && <SelectedScenarioContainer scenario={activeScenarioData} isActive />}
    </div>
  );

  const dashboardHeader = (
    <Header
      title="Dashboard"
      startChildren={
        <div className="flex flex-row gap-2">
          <EnterScenarioModeButton />
          <ScenarioMenuDropdown scenarios={scenarios} createScenario={handleCreateScenario} />
          {selectedScenarioDisplay}
        </div>
      }
      endChildren={
        <div className="w-full flex flex-row justify-between items-center max-sm:hidden gap-2">
          {!hideInteractiveSection && <ShareButton disabled={!!activeScenarioUuid} />}
          {userUuid && <UserDateRange pickerAlignment="right" />}
        </div>
      }
    />
  );

  const hiresLabel = (
    <div className="flex gap-2 justify-center items-center">
      <UserPlusIcon className="size-5" />
      <span>Hires</span>
    </div>
  );
  const leversLabel = (
    <div className="flex gap-2 justify-center items-center">
      <AdjustmentsVerticalIcon className="size-5" />
      <span>Levers</span>
    </div>
  );

  return (
    <div
      className={`transition-all duration-500 ${
        isOpen && isSmallScreen ? 'pr-[25%]' : ''
      } w-full flex flex-col max-sm:items-center max-sm:pb-16 overflow-x-clip`}
    >
      {dashboardHeader}
      <div className="flex flex-row w-full justify-center px-10 py-6 gap-3 z-10">
        <div className="w-full flex flex-col gap-3 max-w-[1000px]">
          <DashboardMetrics staticMetrics={reports.static} consolidatedReports={reports.consolidated} />
          <DashboardGraphs consolidatedReports={reports.consolidated} />
        </div>
        {!hideInteractiveSection && !isInteractiveSectionCollapsed && (
          <div
            className={`sticky top-[88px] flex flex-col gap-2 max-h-[calc(95dvh-106px)] h-fit w-[426px] max-w-[426px] min-w-[426px] transform transition-transform duration-500 ${
              collapseInteractiveSection && ' translate-x-[calc(50vw)]'
            }`}
          >
            <div className="border border border-green-50 rounded-xl px-4 pb-4 flex flex-col flex-grow w-full h-full overflow-y-auto !shadow-[0_2px_9px_0_rgba(0,_0,_0,_0.04)]">
              <div className="mb-3 pt-4 sticky top-0 bg-white z-10">
                <SegmentedControlUnderlined
                  name="selectInteractiveSection"
                  segments={[
                    { value: 'levers', label: leversLabel },
                    { value: 'hires', label: hiresLabel },
                  ]}
                  value={selectedInteractiveSection}
                  setValue={setSelectedInteractiveSection}
                />
              </div>
              {selectedInteractiveSection === 'levers' ? (
                <DashboardLeverGraphs consolidatedReports={reports.consolidated} />
              ) : (
                <HeadcountTimeline.component
                  onlyNewHires
                  addNewPosition={() => setCreatePositionModalIsOpen(true)}
                  createScenario
                  reloadDashboard={reload}
                />
              )}
            </div>
            <button
              className="absolute -right-8 top-[calc(50%-28px)] w-8 h-12 bg-white flex justify-center items-center rounded-r-full border-r border-t border-b border-green-50"
              onClick={() => {
                dispatch(settingsSlice.actions.update({ collapseInteractiveSection: true }));
                setTimeout(() => {
                  dispatch(settingsSlice.actions.update({ isInteractiveSectionCollapsed: true }));
                }, 300);
              }}
            >
              <ChevronRightIcon className="size-5 text-green-400" />
            </button>
            {!pageLoading && (
              <div className="flex h-fit w-full justify-end items-center gap-2">
                <Button
                  fill="clear"
                  className="!w-fit !p-0"
                  id="customize-dashboard-button"
                  onClick={() => setShowCustomizeDashboardModal(true)}
                >
                  Customize Dashboard
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {!hideInteractiveSection && (
        <div
          className={`absolute top-[calc(50%-64px)] ${rightPosition} -rotate-90 transform transition-opacity duration-500 ${
            collapseInteractiveSection ? 'opacity-100 z-10' : 'opacity-0 z-0'
          }`}
        >
          <button
            onClick={() => {
              dispatch(settingsSlice.actions.update({ isInteractiveSectionCollapsed: false }));
              setTimeout(() => {
                dispatch(settingsSlice.actions.update({ collapseInteractiveSection: false }));
              }, 150);
            }}
            className="border-l border-t border-r border-green-50 rounded-tl-lg rounded-tr-lg py-1 px-3 bg-white"
          >
            <Typography size="xs" color="green" className="!font-thin tracking-widest ">
              SHOW LEVERS
            </Typography>
          </button>
        </div>
      )}
      <CustomizeDashboardModal
        isOpen={showCustomizeDashboardModal}
        close={() => setShowCustomizeDashboardModal(false)}
      />
      <CreatePosition
        isOpen={createPositionModalIsOpen}
        setModal={setCreatePositionModalIsOpen}
        positionFormState={positionFormState}
        reload={() => {
          reload();
          reloadHeadcount();
        }}
        setHasReloaded={setHasReloaded}
        createScenario
      />
    </div>
  );
};

const Dashboard = (): React.ReactNode => {
  return (
    <DashboardPageContextProvider>
      <HeadcountProvider>
        <DashboardContainer />
      </HeadcountProvider>
    </DashboardPageContextProvider>
  );
};

export default Dashboard;

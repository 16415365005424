import React from 'react';
import Typography from '~/components/Typography';

export const FinancialModelLegend = (): React.ReactElement => {
  return (
    <div className="flex items-center gap-3 pr-4">
      <div className="flex items-center gap-1">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4.46826" cy="4.93237" r="4" transform="rotate(45 4.46826 4.93237)" fill="#DC960A" />
        </svg>
        <Typography size="sm" color="secondary">
          Actuals
        </Typography>
      </div>
      <div className="flex items-center gap-1">
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="4.46826" cy="4.93237" r="4" transform="rotate(45 4.46826 4.93237)" fill="#5A8496" />
        </svg>
        <Typography size="sm" color="secondary">
          Overrides
        </Typography>
      </div>
    </div>
  );
};

export default FinancialModelLegend;

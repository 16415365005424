import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Typography from '../Typography';
import scenarioModeInfo from '~/assets/scenarioModeInfo.svg';
import HoverPopover from '../HoverPopover';

const ScenarioInfoPopover = (): React.ReactElement => {
  return (
    <HoverPopover
      buttonContent={<InformationCircleIcon className="size-5 text-white pointer-events-auto" />}
      panelContent={
        <div className="bg-white w-[469px] flex flex-col gap-2 px-6 py-6 rounded-lg justify-center items-center text-center">
          <img src={scenarioModeInfo} alt="Scenario Mode Info" />
          <Typography weight="bold">Scenario Mode</Typography>
          <Typography color="secondary">
            {`Here you'll be able to adjust company levers to model the impact
        on your KPIs, empowering you to make informed decisions and
        meaningful bets.`}
          </Typography>
          <Typography color="secondary">
            {`Any changes made while in this mode are temporary and will not affect your base model.`}
          </Typography>
        </div>
      }
      anchor="top end"
      panelClassName="z-[100] shadow rounded-lg"
    />
  );
};

export default ScenarioInfoPopover;

import { IFormula } from '~/pages/FinancialModelDeprecated/entity/types';
import { IFormulaTypeEnum } from '~/services/parallel/formulas.types';

export const filterFormulaList = ({
  value,
  formulaList,
  inputAttributeTitle,
}: {
  value: string;
  formulaList: IFormula[];
  inputAttributeTitle: string;
}): IFormula[] => {
  if (!value.length) return [];

  const prefilteredList = [...formulaList];
  // Allow Formulas to self reference immediately
  if (inputAttributeTitle) {
    const exists = prefilteredList.some((formula) => formula.recipe.name === inputAttributeTitle);
    if (!exists) {
      // We are mocking the formula object here so it can be selected
      prefilteredList.push({
        uuid: 'self',
        formulaUuid: 'self',
        dataSourceUuids: [],
        organizationUuid: '',
        scenarioUuid: null,
        createdBy: null,
        deletedBy: null,
        createdAt: '',
        deletedAt: null,
        isProtected: false,
        type: IFormulaTypeEnum.ModelBuilder,
        recipe: {
          name: inputAttributeTitle,
          expression: '',
          variables: {},
        },
      });
    }
  }

  const filteredList = prefilteredList.filter((formula) =>
    formula.recipe.name.toLowerCase().includes(value.trim().toLowerCase()),
  );

  const filteredListWithoutExpense = filteredList.filter((formula) => formula.type !== IFormulaTypeEnum.Expense);
  return filteredListWithoutExpense;
};

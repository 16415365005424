import React from 'react';
import CompanyGoals from './CompanyGoals';
import Typography from '~/components/Typography';
import Card from '~/components/Card';

const CompanyGoalsContainer = (): React.ReactNode => {
  return (
    <Card>
      <div className="flex flex-col w-full">
        <Typography size="md" weight="bold">
          Company Goals
        </Typography>
        <Typography size="sm" color="secondary" className="mb-4">
          Goals are connected to specific attributes of your model and will be displayed on the dashboard
        </Typography>
        <CompanyGoals />
      </div>
    </Card>
  );
};

export default CompanyGoalsContainer;

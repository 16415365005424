import store from '~/store';

export const getDepartmentsSelected = async ({
  departmentUuids,
}: {
  departmentUuids: string[];
}): Promise<{ label: string; value: string }[]> => {
  const departments = store.getState().organization.departments;

  let selected: { label: string; value: string }[] = [];
  selected =
    departmentUuids.length === 1 && departmentUuids[0] === 'ALL'
      ? [{ label: 'All Departments', value: 'ALL' }]
      : departments.reduce(
          (acc, department) => {
            if (departmentUuids.includes(department.departmentUuid)) {
              acc.push({
                label: department.name,
                value: department.departmentUuid,
              });
            }
            return acc;
          },
          [] as { label: string; value: string }[],
        );

  return selected;
};

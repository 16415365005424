import zod from 'zod';

export default zod
  .union([zod.string(), zod.date()])
  .refine(
    (value) => {
      if (value instanceof Date) return true;

      const timeStamp = Date.parse(value);
      return !Number.isNaN(timeStamp);
    },
    {
      message: 'Must be a valid ISO8601 date string',
    },
  )
  .transform((value) => new Date(value));

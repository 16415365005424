import React from 'react';
import { DraggableContext, useDraggableProvider } from './contexts/DraggableContext';

const DraggableItem = ({
  id,
  children,
  className,
  liveUpdate = false,
  iconAlwaysVisible = false,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
  liveUpdate?: boolean;
  iconAlwaysVisible?: boolean;
}): React.ReactNode => {
  const { style, attributes, listeners, isDragging, isOver, setNodeRef } = useDraggableProvider(id, liveUpdate);

  return (
    <DraggableContext.Provider value={{ attributes, listeners, style, isDragging, isOver, iconAlwaysVisible }}>
      <div ref={setNodeRef} className={`flex group ${className}`} style={style}>
        {children}
      </div>
    </DraggableContext.Provider>
  );
};

export default DraggableItem;

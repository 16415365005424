import React from 'react';
import Typography from '~/components/Typography';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDispatch } from 'react-redux';
import { removeScenarioUuid } from '~/store/scenarioSlice';

const SelectedScenarioContainer = ({
  scenario,
  index,
  isActive,
}: {
  scenario: IScenario;
  index?: number;
  isActive?: boolean;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const handleClick = (): void => {
    dispatch(removeScenarioUuid(scenario.uuid));
  };

  const backgroundColor = ((): string => {
    if (isActive) {
      return 'bg-blue-400';
    } else if (index === 0) {
      return 'bg-orange';
    } else if (index === 1) {
      return 'bg-purple';
    } else {
      return 'bg-[#45A59F]';
    }
  })();

  return (
    <div
      className={`${backgroundColor} max-h-[28px] min-h-[28px] my-auto flex items-center justify-center gap-1 pr-2.5 pl-3.5 rounded-l-full rounded-r-full`}
    >
      <Typography color="white" weight="light" className="truncate max-w-[150px]">
        {scenario.changeDescription}
      </Typography>
      {isActive ? (
        <Typography weight="light" color="white" className="italic opacity-50 mr-1">
          Active
        </Typography>
      ) : (
        <XMarkIcon
          className="text-white opacity-50 cursor-pointer size-5"
          onClick={handleClick}
          data-testid={`remove-scenario-${scenario.uuid}`}
        />
      )}
    </div>
  );
};

export default SelectedScenarioContainer;

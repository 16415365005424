import { CellContext } from '@tanstack/react-table';
import React, { useContext } from 'react';
import { ICellData, TableRow } from '../../entity/types';
import Typography from '~/components/Typography';
import { RatiosContext } from '../../context/RatiosContext';

const TitleCell = ({ info }: { info: CellContext<TableRow, ICellData> }): React.ReactNode => {
  const { positionTitle, ratioUuid } = info.getValue();
  const { setEditRatioUuid, setIsCreateRatioModalOpen } = useContext(RatiosContext);
  return (
    <div
      data-testid={`${positionTitle}-${ratioUuid}`}
      id={ratioUuid}
      className="flex !h-full px-4 items-center flex-row gap-2 text-nowrap relative"
      onClick={() => {
        setIsCreateRatioModalOpen(true);
        setEditRatioUuid(ratioUuid);
      }}
    >
      <Typography weight="medium">{positionTitle}</Typography>
      <Typography color="empty" className="italic">
        Planned/Required
      </Typography>
      <div className="absolute h-full top-0 right-[-1px] w-px bg-gray-200" />
    </div>
  );
};

export default TitleCell;

import React, { useContext, useEffect, useState } from 'react';
import { IInitialValue } from '../entities/types';
import { CellContext } from '@tanstack/react-table';
import Typography from '~/components/Typography';
import formatCurrency from '~/utils/formatCurrency';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import { useSelector } from 'react-redux';
import { State } from '~/store';

const CellTotalCost = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): React.ReactNode => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const hideSensitiveData = useSelector((state: State) => state.settings.hideSensitiveData);
  const { positionActiveStateDict, positionDataDict } = useContext(HeadcountContext);
  const [isActive, setIsActive] = useState(positionActiveStateDict[initialValue.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[initialValue.positionUuid]);
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  if (hideSensitiveData) {
    return (
      <div className="text-right" data-testid={`${cellContext.column.id}-${cellContext.row.index}`}>
        <Typography>{'*********'}</Typography>
      </div>
    );
  }

  return (
    <div className="text-right" data-testid={`${cellContext.column.id}-${cellContext.row.index}`}>
      <Typography color={!isActive && Boolean(activeScenarioUuid) ? 'disabled' : undefined}>
        {typeof positionDataDict[initialValue.positionUuid].fullyBurdenedCost === 'number' && isActive
          ? // @ts-expect-error - typescript is dumb and doesn't recognize that I check the type
            formatCurrency(positionDataDict[initialValue.positionUuid].fullyBurdenedCost, false)
          : '-'}
      </Typography>
    </div>
  );
};

export default CellTotalCost;

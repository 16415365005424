import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Modal from '~/components/Modal';
import type { State } from '~/store';
import request from '~/utils/request';
import { ZBusinessContext } from './entity/schemas';
import Input, { useInput } from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import toast from 'react-hot-toast';

interface IAPIResponse {
  data: {
    data: unknown;
  };
  status: number;
}

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSave: (summary: string) => void;
}

const UpdateBusinessContextModal = ({ isOpen, onClose, onSave }: Props): React.ReactNode => {
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);

  const [savingUpdate, setSavingUpdate] = useState<boolean>(false);

  const [companyUrl, setCompanyUrl] = useInput({});
  const [companyDescription, setCompanyDescription] = useInput({});
  const [keyGoals, setKeyGoals] = useInput({});
  const [offeringDetails, setOfferingDetails] = useInput({});
  const [fundingHistory, setFundingHistory] = useInput({});

  useEffect(() => {
    const getBusinessContext = async (): Promise<void> => {
      const response = (await request({
        url: `/business-contexts`,
        method: 'GET',
        headers: { 'Organization-Uuid': organizationUuid },
      })) as IAPIResponse;

      if (response.status >= 400) return;
      const {
        context: { companyUrl, companyDescription, keyGoals, offeringDetails, fundingHistory },
      } = ZBusinessContext.parse(response.data.data);

      setCompanyUrl((prevState) => ({
        ...prevState,
        value: companyUrl,
        valid: true,
        pristine: true,
        touched: false,
      }));
      setCompanyDescription((prevState) => ({
        ...prevState,
        value: companyDescription,
        valid: true,
        pristine: true,
        touched: false,
      }));
      setKeyGoals((prevState) => ({
        ...prevState,
        value: keyGoals,
        valid: true,
        pristine: true,
        touched: false,
      }));
      setOfferingDetails((prevState) => ({
        ...prevState,
        value: offeringDetails,
        valid: true,
        pristine: true,
        touched: false,
      }));
      setFundingHistory((prevState) => ({
        ...prevState,
        value: fundingHistory,
        valid: true,
        pristine: true,
        touched: false,
      }));
    };

    isOpen && getBusinessContext();
  }, [isOpen]);

  const updateBusinessContext = async (): Promise<void> => {
    setSavingUpdate(true);
    const updateResponse = (await request({
      url: `/business-contexts`,
      method: 'PATCH',
      headers: { 'Organization-Uuid': organizationUuid },
      body: {
        companyUrl: companyUrl.value,
        companyDescription: companyDescription.value,
        keyGoals: keyGoals.value,
        offeringDetails: offeringDetails.value,
        fundingHistory: fundingHistory.value,
      },
    })) as IAPIResponse;

    const {
      context: { summary },
    } = ZBusinessContext.parse(updateResponse.data.data);

    if (updateResponse.status >= 400) {
      toast.error('Unable to update your business context. Try again later.');
    } else {
      onSave(summary);
      setSavingUpdate(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} showClose title="Business Context" size="lg">
      <div className="flex flex-col gap-3 w-full mt-1">
        <Input
          className="!w-auto text-neutral-800"
          id="companyUrl"
          label="Company URL"
          type="text"
          state={companyUrl}
          setState={setCompanyUrl}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="companyDescription"
          label="Company Description (industry, market sector, size, business model, differentiators, etc.)"
          type="textarea"
          state={companyDescription}
          setState={setCompanyDescription}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="keyGoals"
          label="Key Company Goals and Milestones"
          type="textarea"
          state={keyGoals}
          setState={setKeyGoals}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="offeringDetails"
          label="Offering details (key products or offerings, revenue streams, target customers, etc.)"
          type="textarea"
          state={offeringDetails}
          setState={setOfferingDetails}
        />
        <Input
          className="min-h-32 text-neutral-800 max-h-[50vh]"
          id="fundingHistory"
          label="Funding History and Needs (previous funding rounds, next round requirements, etc.)"
          type="textarea"
          state={fundingHistory}
          setState={setFundingHistory}
        />
        <div className="flex flex-row justify-between mt-3">
          <Button onClick={onClose} id="cancel-navigate-away-button" fill="clear" className="!w-fit !px-0">
            Cancel
          </Button>
          <Button
            id="continue-to-next-page-button"
            className="!w-auto"
            onClick={updateBusinessContext}
            loading={savingUpdate}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateBusinessContextModal;

import {
  ArrowLeftEndOnRectangleIcon,
  CogIcon,
  NewspaperIcon,
  UserGroupIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ArrowPathIcon,
  CurrencyDollarIcon,
  Bars3Icon,
  XMarkIcon,
  TableCellsIcon,
  ChartPieIcon,
  UserCircleIcon,
  ChartBarIcon,
  IdentificationIcon,
  ListBulletIcon,
  BanknotesIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { State } from '~/store';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import logo from '~/assets/parallelLogoIcon.svg';
import request from '~/utils/request';
import Typography from '../Typography';
import useHover from '~/utils/hooks/useHover';
import parallelLogo from '~/assets/parallelWordmarkLogo.svg';
import { userSlice, IUserPreferences } from '~/store/userSlice';
import Button from '../Button';
import SwitchOrgs from './SwitchOrgs';
import useIsMobile from '~/utils/hooks/useIsMobile';
import { IAPIResponse } from '~/utils/types';
import { IUserNotification } from '~/pages/Ratios/entity/types';
import { Channel } from 'pusher-js';
import useQueryParams from '~/utils/hooks/useQueryParams';

interface Props {
  children: React.ReactNode;
  showRefresh: boolean;
  setShowChangeLogModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IUserPreferencesResponse {
  data: {
    data: IUserPreferences;
  };
  status: number;
}

const SideMenu = ({ children, showRefresh, setShowChangeLogModal }: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { uuid: userUuid, preferences } = useSelector((state: State) => state.user);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { sideMenuExpanded } = preferences;
  const [ref, hovering, setHovering] = useHover();
  const isMobile = useIsMobile();
  const pusher = useSelector((state: State) => state._sockets.pusher);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [showRatioNotification, setShowRatioNotification] = useState<boolean>(false);
  const [queryParams] = useQueryParams();
  const { contractsEnabled, ratiosEnabled } = useSelector((state: State) => state.organization.configuration);

  useEffect(() => {
    let userNotificationChannel: Channel | null = null;
    if (organizationUuid) {
      if (pusher) {
        userNotificationChannel = pusher.subscribe(organizationUuid);

        userNotificationChannel.bind('notification-created', () => {
          if (location.pathname !== '/ratios') {
            setShowRatioNotification(true);
          }
        });
      }
    }

    const fetchUserNotifications = async (): Promise<void> => {
      const userNotificationResponse = (await request({
        url: `/users/${userUuid}/notifications`,
        method: 'GET',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      })) as IAPIResponse<IUserNotification[]>;

      if (userNotificationResponse.status === 200) {
        const hasUnreadNotifications = userNotificationResponse.data.data.some((notification) => !notification.isRead);

        setShowRatioNotification(hasUnreadNotifications);
      }
    };

    if (!showRatioNotification && location.pathname !== '/ratios' && organizationUuid) {
      fetchUserNotifications();
    } else if (location.pathname === '/ratios') {
      setShowRatioNotification(false);
    }

    return () => {
      if (userNotificationChannel) {
        userNotificationChannel.unbind('notification-created');
      }
    };
  }, [userUuid, pusher, organizationUuid, location.pathname]);

  const handleClickLink = (to?: string): void => {
    if (isMobile) {
      updateSideMenuPreference(false);
    }

    if (showRefresh && !activeScenarioUuid && to) {
      window.location.href = to;
    }
  };

  const updateSideMenuPreference = async (updatedValue: boolean): Promise<void> => {
    dispatch(
      userSlice.actions.setPreferences({
        ...preferences,
        sideMenuExpanded: updatedValue,
      }),
    );

    const userPreferenceResponse = (await request({
      url: `/users/${userUuid}/preferences`,
      method: 'PATCH',
      body: {
        sideMenuExpanded: updatedValue,
      },
    })) as IUserPreferencesResponse;

    if (userPreferenceResponse.status >= 400) {
      throw Error('Failed to update user preferences');
    }
  };

  const menuWidth = sideMenuExpanded
    ? 'min-w-[260px] max-sm:w-screen max-sm:fixed max-sm:top-0 max-sm:h-dvh max-sm:flex-col max-sm:z-50 max-sm:transition-all max-sm:duration-150 max-sm:overflow-y-scroll max-sm:overflow-x-hidden max-sm:shadow-xl max-sm:border-r max-sm:border-green-25'
    : 'w-[72px] max-sm:hidden';
  const menuHeight = activeScenarioUuid ? 'max-h-[calc(100vh-56px)] min-h-[calc(100vh-56px)]' : 'max-h-dvh min-h-dvh';
  const navItemStyle = 'flex items-center justify-start p-2 py-2 space-x-2 rounded mb-2 ml-2 mr-2';
  const iconStyle = 'size-6';
  const childClasses = ` ${
    sideMenuExpanded
      ? `sm:max-w-[calc(100%-260px)] sm:min-w-[calc(100%-260px)] ${activeScenarioUuid ? 'max-h-[calc(100vh-56px)]' : 'max-h-screen'} overflow-y-auto max-sm:max-w-screen max-sm:min-w-screen`
      : `sm:max-w-[calc(100%-72px)] sm:min-w-[calc(100%-72px)] ${activeScenarioUuid ? 'max-h-[calc(100vh-56px)]' : 'max-h-screen'} overflow-y-auto max-sm:max-w-screen max-sm:min-w-screen max-sm:left-0`
  }`;

  const isActive = (path: string): string =>
    location.pathname === path ? 'bg-white text-green-500' : 'text-green-500 hover:bg-white';

  const isActiveHeadcount = (view: string): string => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (location.pathname.includes('headcount')) {
      if (view === 'timeline' && currentParams.view === 'timeline') {
        return 'bg-white text-green-500';
      }
      if (view === 'table' && (currentParams.view === 'table' || !currentParams.view)) {
        return 'bg-white text-green-500';
      }
    }
    return 'text-green-500 hover:bg-white';
  };

  const isActiveContracts = (view: string): string => {
    const currentParams = Object.fromEntries(queryParams.entries());
    if (location.pathname.includes('contracts')) {
      if (view === 'details' && currentParams.view === 'details') {
        return 'bg-white text-green-500';
      }
      if (view === 'collections' && currentParams.view === 'collections') {
        return 'bg-white text-green-500';
      }
      if (view === 'revenue' && currentParams.view === 'revenue') {
        return 'bg-white text-green-500';
      }
    }
    return 'text-green-500 hover:bg-white';
  };

  const isMac = navigator.userAgent.includes('Macintosh');

  return (
    <div className={`flex max-h-screen max-sm:fixed max-sm:top-0 max-sm:w-screen max-sm:flex-col`}>
      {/* Mobile header to access sidebar */}
      <div className="sm:hidden max-sm:w-screen max-sm:h-[100px] max-sm:flex max-sm:items-center max-sm:justify-between max-sm:px-3">
        <Link to="/dashboard">
          <img src={logo} alt="alt-logo" className="w-[27px] ml-2" />
        </Link>
        <Bars3Icon className="w-8 h-8 text-green-400" onClick={() => updateSideMenuPreference(true)} />
      </div>

      <div className={`z-40 transition-all duration-[150ms] ${menuWidth} ${menuHeight} sticky top-0`}>
        <div
          ref={ref}
          className={`flex flex-col h-full sidebar-bg border-r border-green-25 transition-all ${
            hovering ? `${sideMenuExpanded ? 'w-full' : 'w-[260px] shadow-xl'}` : 'w-full'
          }`}
        >
          <div className="w-full">
            <div className="relative flex flex-row w-full start-end justify-end text-neutral-800 font-semibold text-xl px-[22px] py-4 mt-1 overflow-hidden">
              <div className="absolute top-6 left-[22px]">
                <img
                  src={logo}
                  alt="alt-logo"
                  className={`min-w-[27px] ${sideMenuExpanded || hovering ? 'hidden' : ''}`}
                />
                <img
                  src={parallelLogo}
                  alt="Parallel logo"
                  className={`min-w-[107px] ${sideMenuExpanded || hovering ? '' : 'hidden'}`}
                />
              </div>
              <div className="flex items-center min-h-[34.875px]">
                <div
                  className={`w-fit h-fit p-[2px] ${hovering && 'bg-white max-sm:bg-transparent'} 
                  text-green-500 rounded-full cursor-pointer transition-opacity duration-300 ${
                    sideMenuExpanded || hovering ? 'opacity-100' : 'opacity-0'
                  }`}
                  onClick={() => {
                    updateSideMenuPreference(!sideMenuExpanded);
                    setHovering(false);
                  }}
                >
                  {sideMenuExpanded ? (
                    <>
                      <ChevronLeftIcon
                        className={`size-5 transition-opacity duration-300 max-sm:hidden ${
                          hovering ? 'opacity-100' : 'opacity-0'
                        }`}
                      />
                      <XMarkIcon className="size-6 sm:hidden text-green-400" />
                    </>
                  ) : hovering ? (
                    <ChevronRightIcon className="size-5 max-sm:hidden" />
                  ) : null}
                </div>
              </div>
            </div>
            <SwitchOrgs sideMenuExpanded={Boolean(sideMenuExpanded || hovering)} />
          </div>
          <nav
            className="px-2 py-4 mt-3 text-green-500 flex flex-col flex-grow justify-between"
            data-testid="sidemenu-container"
          >
            <ul>
              <Link to="/dashboard" data-testid="sidemenu-dashboard" onClick={() => handleClickLink('/dashboard')}>
                <li className={`${navItemStyle} ${isActive('/dashboard')}`}>
                  <NewspaperIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <Typography
                    color="green"
                    className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                  >
                    Dashboard
                  </Typography>
                </li>
              </Link>
              <div>
                <Link
                  to="/headcount?view=table"
                  data-testid="sidemenu-headcount"
                  onClick={() => handleClickLink('/headcount')}
                  className="hidden md:block"
                >
                  <li className={`${navItemStyle} ${isActive('/headcount')} ${isActive('/ratios')}`}>
                    <UserGroupIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                    <Typography
                      color="green"
                      className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                    >
                      Headcount
                    </Typography>
                  </li>
                </Link>
                <div
                  className={`flex flex-col -mt-2 ${(sideMenuExpanded || hovering) && ['/headcount', '/ratios'].includes(location.pathname) ? '' : 'hidden'}`}
                >
                  <Link
                    to="/headcount?view=table"
                    data-testid="sidemenu-positions"
                    onClick={() => handleClickLink('/headcount')}
                    className="hidden md:block"
                  >
                    <li
                      className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveHeadcount('table')}`}
                    >
                      <UserCircleIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                      <Typography
                        color="green"
                        className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                      >
                        Positions
                      </Typography>
                    </li>
                  </Link>
                  <Link
                    to="/headcount?view=timeline"
                    data-testid="sidemenu-timeline"
                    onClick={() => handleClickLink('/headcount')}
                    className="hidden md:block"
                  >
                    <li
                      className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveHeadcount('timeline')}`}
                    >
                      <ChartBarIcon className={`flex-shrink-0 -rotate-90  size-5`} aria-hidden="true" />
                      <Typography
                        color="green"
                        className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                      >
                        Timeline
                      </Typography>
                    </li>
                  </Link>
                  {ratiosEnabled && (
                    <Link
                      to="/ratios"
                      data-testid="sidemenu-ratios"
                      onClick={() => handleClickLink('/ratios')}
                      className="hidden md:block"
                    >
                      <li
                        className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActive('/ratios')}`}
                      >
                        <ChartPieIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                        >
                          Ratios
                        </Typography>
                      </li>
                    </Link>
                  )}
                </div>
              </div>
              <Link
                to="/expenses"
                data-testid="sidemenu-expenses"
                onClick={() => handleClickLink('/expenses')}
                className="hidden md:block"
              >
                <li className={`${navItemStyle} ${isActive('/expenses')}`}>
                  <CurrencyDollarIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <Typography
                    color="green"
                    className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                  >
                    Expenses
                  </Typography>
                </li>
              </Link>
              {contractsEnabled && (
                <div>
                  <Link
                    to="/contracts?view=details"
                    data-testid="sidemenu-contracts"
                    onClick={() => handleClickLink('/contracts')}
                    className="hidden md:block"
                  >
                    <li className={`${navItemStyle} ${isActive('/contracts')}`}>
                      <IdentificationIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                      <Typography
                        color="green"
                        className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                      >
                        Contracts
                      </Typography>
                    </li>
                  </Link>
                  <div
                    className={`flex flex-col -mt-2 ${(sideMenuExpanded || hovering) && ['/contracts'].includes(location.pathname) ? '' : 'hidden'}`}
                  >
                    <Link
                      to="/contracts?view=details"
                      data-testid="sidemenu-contracts"
                      onClick={() => handleClickLink('/contracts')}
                      className="hidden md:block"
                    >
                      <li
                        className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('details')}`}
                      >
                        <ListBulletIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                        >
                          Details
                        </Typography>
                      </li>
                    </Link>
                    <Link
                      to="/contracts?view=collections"
                      data-testid="sidemenu-collections"
                      onClick={() => handleClickLink('/contracts')}
                      className="hidden md:block"
                    >
                      <li
                        className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('collections')}`}
                      >
                        <BanknotesIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                        >
                          Collections
                        </Typography>
                      </li>
                    </Link>
                    <Link
                      to="/contracts?view=revenue"
                      data-testid="sidemenu-revenue"
                      onClick={() => handleClickLink('/contracts')}
                      className="hidden md:block"
                    >
                      <li
                        className={`flex items-center justify-start pl-8 py-2 mx-2 space-x-2 rounded ${isActiveContracts('revenue')}`}
                      >
                        <ChartBarIcon className={`flex-shrink-0 size-5`} aria-hidden="true" />
                        <Typography
                          color="green"
                          className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                        >
                          Revenue
                        </Typography>
                      </li>
                    </Link>
                  </div>
                </div>
              )}
              <Link
                to="/financial-model"
                data-testid="sidemenu-financial-model"
                onClick={() => handleClickLink('/financial-model')}
                className="hidden md:block"
              >
                <li className={`${navItemStyle} ${isActive('/financial-model')}`}>
                  <TableCellsIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <Typography
                    color="green"
                    className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                  >
                    Financial Model
                  </Typography>
                </li>
              </Link>
            </ul>
            <ul>
              <Link to="/settings" data-testid="sidemenu-settings" onClick={() => handleClickLink('/settings')}>
                <li className={`${navItemStyle} ${isActive('/settings')}`}>
                  <CogIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <Typography
                    color="green"
                    className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                  >
                    Settings
                  </Typography>
                </li>
              </Link>
              <div
                data-testid="sidemenu-change-log"
                className="cursor-pointer hover:bg-white"
                onClick={() => setShowChangeLogModal((prev) => !prev)}
              >
                <li className={`${navItemStyle}`}>
                  <DocumentTextIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <div className="flex items-center justify-between w-full">
                    <Typography
                      color="green"
                      className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                    >
                      Change History{' '}
                    </Typography>
                    <Typography
                      size="xs"
                      color="primaryGreen"
                      className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                    >
                      {isMac ? '⌘' : 'Ctrl+'}O
                    </Typography>
                  </div>
                </li>
              </div>
              <Link to="/auth/logout" data-testid="sidemenu-logout" onClick={() => handleClickLink()}>
                <li className={`${navItemStyle} ${isActive('/auth/logout')}`}>
                  <ArrowLeftEndOnRectangleIcon className={`flex-shrink-0 ${iconStyle}`} aria-hidden="true" />
                  <Typography
                    color="green"
                    className={` ${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden `}
                  >
                    Log Out
                  </Typography>
                </li>
              </Link>
              {showRefresh && (
                <li className={`${navItemStyle}`}>
                  <ArrowPathIcon
                    className={`flex-shrink-0 ${iconStyle} ${sideMenuExpanded || hovering ? 'hidden' : ''}`}
                    aria-hidden="true"
                  />
                  <Button
                    fill="outline"
                    className={`${sideMenuExpanded || hovering ? '' : 'hidden'} whitespace-nowrap overflow-hidden`}
                    onClick={() => window.location.reload()}
                  >
                    Update Version
                  </Button>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
      <div className={childClasses} id="child-container">
        {children}
      </div>
    </div>
  );
};

export default SideMenu;

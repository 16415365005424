import React from 'react';

type Size = '2xs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
interface Props {
  id?: string;
  className?: string;
  children: string | React.ReactNode;
  tag?: 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  size?: Size;
  weight?: 'thin' | 'extralight' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  color?:
    | 'invert'
    | 'primary'
    | 'secondary'
    | 'disabled'
    | 'empty'
    | 'warning'
    | 'special'
    | 'lightGray'
    | 'primaryGreen'
    | 'green'
    | 'subHead'
    | 'lightBlue'
    | 'blue'
    | 'orange'
    | 'purple'
    | 'white'
    | 'turquoise'
    | 'actualBlue'
    | 'lightGreen';
}

const Typography = React.forwardRef(
  (
    { id = '', className = '', children, tag = 'span', size = 'sm', weight = 'normal', color = 'primary' }: Props,
    forwardedRef: React.Ref<HTMLElement | undefined>,
  ) => {
    const lineHeight: { [K in Size]: number } = {
      '2xs': 4,
      xs: 5,
      sm: 6,
      md: 7,
      lg: 7,
      xl: 8,
      '2xl': 9,
    };

    const fontColor: { [K in typeof color]: string } = {
      invert: 'text-neutral-0',
      primary: 'text-neutral-800',
      secondary: 'text-neutral-400',
      disabled: 'text-neutral-100',
      empty: 'text-neutral-200',
      warning: 'text-red-500',
      special: 'text-orange',
      lightGray: 'text-neutral-300',
      primaryGreen: 'text-green-400',
      green: 'text-green-500',
      subHead: 'text-neutral-700',
      lightBlue: 'text-blue-300',
      blue: 'text-blue-500',
      orange: 'text-orange',
      purple: 'text-purple',
      white: 'text-white',
      turquoise: 'text-[#45A59F]',
      actualBlue: 'text-[#3C98C0]',
      lightGreen: 'text-green-200',
    };

    const Tag = tag;

    return (
      <Tag
        ref={forwardedRef}
        title={className.includes('truncate') && typeof children === 'string' ? children : undefined}
        className={`tracking-normal ${fontColor[color]} text-${size} font-${weight} leading-${lineHeight[size]} ${className}`}
        data-testid={id}
      >
        {children}
      </Tag>
    );
  },
);

Typography.displayName = 'Typography';

export default Typography;

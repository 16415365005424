import React from 'react';
import Typography from '~/components/Typography';

const TitleCell = ({ title, model }: { title: string; model: string }): React.ReactElement => {
  return (
    <div className="flex flex-col">
      <Typography size="sm" className="max-w-[300px] truncate">
        {title}
      </Typography>
      <Typography size="xs" color="empty" className="max-w-[300px] truncate">
        {model}
      </Typography>
    </div>
  );
};

export default TitleCell;

import {
  IFormula,
  IFormulaSegment,
  IUpdateCalculationModifier,
  IUpdateTimeModifier,
  IValueToAdd,
  IVariables,
} from '~/pages/FinancialModelDeprecated/entity/types';
import shiftFormulaIndexes from '~/pages/FinancialModelDeprecated/utils/shiftFormulaIndexes';
import updateFormulaAndVariables from '~/pages/FinancialModelDeprecated/utils/updateFormulaAndVariables';

export const handleSelectAttribute = ({
  selectedFormula,
  formulaTitle,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  editingFormulaUuid,
  handleUpdateCalculationModifier,
  handleUpdateTimeModifier,
  inputPosition,
  setInputPosition,
  setValue,
  inputRef,
}: {
  selectedFormula: IFormula;
  formulaTitle?: string;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  editingFormulaUuid?: string;
  handleUpdateCalculationModifier?: ({
    calculationModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateCalculationModifier) => void;
  handleUpdateTimeModifier: ({
    timeModifier,
    formulaForUpdate,
    formulaTextValue,
    refToUpdate,
  }: IUpdateTimeModifier) => void;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const valuesToAdd: IValueToAdd[] = [];

  const isCalculated = Boolean(
    (formulaTitle &&
      [
        'Headcount',
        'New Hires',
        'Software Expenses',
        'Salary and Wages',
        'Other Expenses',
        'People & Facilities Expenses',
        'Cost of Goods Sold Expenses',
        'Marketing Expenses',
      ].includes(formulaTitle)) ||
      ['Headcount', 'New Hires'].includes(selectedFormula.recipe.name),
  );

  valuesToAdd.push({
    type: isCalculated ? 'calculated' : 'formula',
    value: selectedFormula.recipe.name,
    newIndex: inputPosition,
    associatedFormula: selectedFormula,
  });

  const { newFormula, newVariables } = updateFormulaAndVariables({
    valuesToAdd,
    formulaCopy: [...updatedFormula],
    variablesCopy: { ...variables },
    formulaUuid: editingFormulaUuid,
    handleUpdateCalculationModifier,
    handleUpdateTimeModifier,
  });

  const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } = shiftFormulaIndexes({
    oldFormula: newFormula,
    oldVariables: newVariables,
  });

  setFormula(formulaWithShiftedIndexes);
  setVariables(variablesWithShiftedIndexes);

  setUpdatedFormula(formulaWithShiftedIndexes);

  setVariables(variablesWithShiftedIndexes);
  setInputPosition(inputPosition + 1);

  setValue('');
  inputRef.current?.focus();
};

import React from 'react';
import { ModelBuilderProvider } from '~/components/FormulasTable';
import FinancialModelTable from './Components/FinancialModelTable';
import FinancialModelHeader from './Components/FinancialModelHeader';

const FinancialModelPage = (): React.ReactElement => {
  return (
    <ModelBuilderProvider>
      <div className="w-full bg-neutral-15 h-screen">
        <FinancialModelHeader />
        <FinancialModelTable />
      </div>
    </ModelBuilderProvider>
  );
};

export default FinancialModelPage;

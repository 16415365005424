import { endOfMonth, getMonth, getQuarter, getYear } from 'date-fns';

export const MONTH_SHORT_NAMES: Record<string, number> = {
  Jan: 0,
  Feb: 1,
  Mar: 2,
  Apr: 3,
  May: 4,
  Jun: 5,
  Jul: 6,
  Aug: 7,
  Sep: 8,
  Oct: 9,
  Nov: 10,
  Dec: 11,
};

export const QUARTER_SHORT_NAMES = ['Q1', 'Q2', 'Q3', 'Q4'];

export const getStartAndEndDatesFromSelection = (
  mode: string,
  selection: string | number,
  pendingYearDate: number,
): {
  startDate: Date;
  endDate: Date;
} => {
  const QUARTER_MODIFIER = 3;

  let startDate;
  let endDate;

  switch (mode) {
    case 'month': {
      const monthIndex = MONTH_SHORT_NAMES[selection];
      startDate = new Date(pendingYearDate, monthIndex, 1);
      endDate = endOfMonth(new Date(pendingYearDate, monthIndex));
      break;
    }
    case 'quarter': {
      if (typeof selection === 'number') {
        throw Error('Quarter selection must be a string');
      }
      const selectedQuarter = Number(selection.slice(-1)) - 1;
      startDate = new Date(pendingYearDate, selectedQuarter * QUARTER_MODIFIER, 1);
      endDate = endOfMonth(new Date(pendingYearDate, selectedQuarter * QUARTER_MODIFIER + 2));
      break;
    }
    case 'year':
      startDate = new Date(Number(selection), 0, 1);
      endDate = endOfMonth(new Date(Number(selection), 11));
      break;
    default:
      throw Error('Invalid mode');
  }

  return { startDate, endDate };
};

const gridDisplaysForYear = (middleYear: number, minYear: number, maxYear: number): number[] => {
  const displayYears = [
    middleYear - 1 >= minYear ? middleYear - 1 : null,
    middleYear,
    middleYear + 1 <= maxYear ? middleYear + 1 : null,
  ].filter((year) => year !== null);

  return displayYears as number[];
};

export const getGridDisplayValues = (
  mode: string,
  pendingYearDate: number,
  minYear: number,
  maxYear: number,
): (string | number)[] => {
  switch (mode) {
    case 'month':
      return Object.keys(MONTH_SHORT_NAMES);
    case 'quarter':
      return QUARTER_SHORT_NAMES;
    case 'year':
      return gridDisplaysForYear(pendingYearDate, minYear, maxYear);
    default:
      return [];
  }
};

export const getInputDisplayValue = (mode: string, startDate: Date | null, defaultInputDisplay: string): string => {
  if (!startDate) {
    return defaultInputDisplay;
  }
  switch (mode) {
    case 'month':
      return `${Object.keys(MONTH_SHORT_NAMES)[getMonth(startDate)]} ${getYear(startDate)}`;
    case 'quarter':
      return `Q${getQuarter(startDate)} ${getYear(startDate)}`;
    case 'year':
      return `${getYear(startDate)}`;
    default:
      return 'No';
  }
};

import React from 'react';
import Modal from '~/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import DatePicker, { useDatePicker } from '~/components/DatePicker';
import Button from '~/components/Button';
import { toZonedTime } from 'date-fns-tz';
import { IAPIResponse } from '~/utils/types';
import request from '~/utils/request';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';

interface Props {
  id?: string;
  isOpen: boolean;
  onClose: () => void;
  positionUuid: string;
  handleOptimisticUpdate: () => Promise<void>;
  currentTerminationDate: string | null;
  minTerminationDate: string;
  organizationUuid: string;
  createScenario?: boolean;
}

const updateTerminationDate = async ({
  termDate,
  setTermDate,
  positionUuid,
  scenarioUuid,
  organizationUuid,
  successCallback,
  createScenario,
}: {
  termDate: IDatePickerState;
  setTermDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  positionUuid: string;
  scenarioUuid?: string;
  organizationUuid: string;
  successCallback: () => void;
  createScenario?: boolean;
}): Promise<void> => {
  if (termDate.value.startDate && termDate.valid) {
    const editPositionResponse = (await request({
      method: 'PATCH',
      url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
      body: { termDate: termDate.value.startDate },
      params: {
        scenarioUuid,
        awaitCalculations: true,
        createScenario: scenarioUuid ? false : createScenario,
      },
    })) as IAPIResponse;

    if (editPositionResponse.status === 201) {
      successCallback();
      handleCreateScenario({
        response: editPositionResponse,
      });
    }
  } else {
    setTermDate((prev) => ({
      ...prev,
      touched: true,
      pristine: false,
      error: 'Please select a date',
    }));
  }
};

const deleteTerminationDate = async ({
  positionUuid,
  scenarioUuid,
  organizationUuid,
  successCallback,
  createScenario,
}: {
  positionUuid: string;
  scenarioUuid?: string;
  organizationUuid: string;
  successCallback: () => void;
  createScenario?: boolean;
}): Promise<void> => {
  const editPositionResponse = (await request({
    method: 'PATCH',
    url: `/organizations/${organizationUuid}/positions/${positionUuid}`,
    body: { termDate: null },
    params: {
      scenarioUuid,
      awaitCalculations: true,
      createScenario: scenarioUuid ? false : createScenario,
    },
  })) as IAPIResponse;

  if (editPositionResponse.status === 201) {
    successCallback();
    handleCreateScenario({
      response: editPositionResponse,
    });
  }
};

const EditTermDate = ({
  id,
  isOpen,
  onClose,
  positionUuid,
  currentTerminationDate,
  minTerminationDate,
  handleOptimisticUpdate,
  organizationUuid,
  createScenario,
}: Props): React.ReactNode => {
  const dispatch = useDispatch();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [termDate, setTermDate] = useDatePicker({
    value: {
      startDate: currentTerminationDate ? toZonedTime(currentTerminationDate, 'UTC').toString() : null,
      endDate: currentTerminationDate ? toZonedTime(currentTerminationDate, 'UTC').toString() : null,
    },
    minDate: minTerminationDate,
  });

  return (
    <Modal id={id} isOpen={isOpen} title="Term Position" size="sm">
      <div data-testid="term-position-modal" className="mt-4 w-full">
        <DatePicker id={`${id}-date-picker`} label="Termination Date" state={termDate} setState={setTermDate} />
        <div className="flex flex-row justify-between mt-6">
          <Button fill="clear" id="cancel-edit-term-date" onClick={onClose} className="!w-fit !px-0">
            Cancel
          </Button>
          <div className="flex flex-row gap-8">
            {currentTerminationDate && (
              <Button
                id="delete-term-date"
                fill="destructiveClear"
                onClick={() => {
                  if (activeScenarioUuid) {
                    dispatch(updateScenarioLoadingState('updating'));
                  } else if (createScenario) {
                    dispatch(updateScenarioLoadingState('creating'));
                    dispatch(updateScenarioMode('creating'));
                  }
                  deleteTerminationDate({
                    positionUuid,
                    scenarioUuid: activeScenarioUuid ?? undefined,
                    organizationUuid,
                    successCallback: () => {
                      handleOptimisticUpdate();
                      onClose();
                    },
                    createScenario,
                  });
                }}
                className="!w-fit !px-0"
              >
                Delete Term
              </Button>
            )}
            <Button
              id="save-term-date"
              fill="solid"
              onClick={() => {
                if (activeScenarioUuid) {
                  dispatch(updateScenarioLoadingState('updating'));
                } else if (createScenario) {
                  dispatch(updateScenarioLoadingState('creating'));
                  dispatch(updateScenarioMode('creating'));
                }
                updateTerminationDate({
                  termDate,
                  setTermDate,
                  positionUuid,
                  scenarioUuid: activeScenarioUuid ?? undefined,
                  organizationUuid,
                  successCallback: () => {
                    handleOptimisticUpdate();
                    onClose();
                  },
                  createScenario,
                });
                if (activeScenarioUuid) {
                  dispatch(updateScenarioLoadingState('idle'));
                }
              }}
              className="!w-fit !px-5"
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditTermDate;

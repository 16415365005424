import React, { ReactElement, useEffect } from 'react';
import { useInput } from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import { TrashIcon } from '@heroicons/react/24/outline';
import { format, toZonedTime } from 'date-fns-tz';
import Typography from '~/components/Typography';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { isSameDay } from 'date-fns';

const PayRate = ({
  hireDate,
  effectiveAt,
  currentValue,
  updatedPayRates,
  setUpdatedPayRates,
  setPayRatesRequireUpdate,
}: {
  hireDate: string;
  effectiveAt: 'onHire' | string;
  currentValue: number;
  updatedPayRates: {
    effectiveAt: 'onHire' | string;
    value: number;
  }[];
  setUpdatedPayRates: React.Dispatch<
    React.SetStateAction<
      {
        effectiveAt: 'onHire' | string;
        value: number;
      }[]
    >
  >;
  payRatesRequireUpdate: boolean;
  setPayRatesRequireUpdate: React.Dispatch<React.SetStateAction<boolean>>;
}): ReactElement => {
  const [payRate, setPayRate] = useInput({
    // eslint-disable-next-line security/detect-unsafe-regex
    validation: /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/,
    errorMessage: 'Annual Pay is required',
    value: (currentValue / CENTS_PER_DOLLAR).toString(),
  });

  const formattedEffectiveAt = format(
    toZonedTime(effectiveAt === 'onHire' ? hireDate : effectiveAt, 'UTC'),
    'yyyy-MM-dd',
  );

  useEffect(() => {
    if (Number(payRate.value) * CENTS_PER_DOLLAR !== currentValue && payRate.valid) {
      const newPayRates = updatedPayRates.map((existingPayRate) => {
        if (existingPayRate.effectiveAt === effectiveAt) {
          return {
            ...existingPayRate,
            value: Number(payRate.value) * CENTS_PER_DOLLAR,
          };
        }
        return existingPayRate;
      });
      setUpdatedPayRates(newPayRates);
      setPayRatesRequireUpdate(true);
    }
  }, [payRate, setUpdatedPayRates]);

  const handleDeletePayChange = async (): Promise<void> => {
    setUpdatedPayRates((prev) =>
      prev.filter(
        (existingPayRate) =>
          existingPayRate.effectiveAt === 'onHire' || !isSameDay(existingPayRate.effectiveAt, effectiveAt),
      ),
    );
    setPayRatesRequireUpdate(true);
  };

  return (
    <div className={`flex flex-row gap-2 items-start my-1 transition-all`}>
      <div className="w-[46%]">
        <CurrencyInput id={`payRate-${formattedEffectiveAt}`} state={payRate} setState={setPayRate} />
      </div>
      <div className="w-[46%] py-2 flex justify-center items-center border border-neutral-75 rounded bg-neutral-25">
        <Typography id={`pay-change-date-${formattedEffectiveAt}`} color="disabled">
          {effectiveAt === 'onHire' ? 'Hire Date' : format(toZonedTime(effectiveAt, 'UTC'), 'MM/dd/yyyy')}
        </Typography>
      </div>
      {effectiveAt !== 'onHire' && (
        <TrashIcon
          onClick={handleDeletePayChange}
          className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
          data-testid={`delete-pay-change-${formattedEffectiveAt}`}
        />
      )}
    </div>
  );
};

export default PayRate;

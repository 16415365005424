export default (val: string | number, includeDecimal = true, currency = 'USD'): string => {
  let cents: number;
  if (typeof val === 'string') {
    cents = parseInt(val, 10);
  } else {
    cents = val;
  }

  const fractionDigits = includeDecimal ? 2 : 0;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });
  let formattedValue = formatter.format(Math.abs(cents) / 100);

  if (cents < 0) {
    formattedValue = `(${formattedValue})`;
  }

  return formattedValue;
};

import { useCallback, useEffect, useState } from 'react';
import pageData from './pageData';
import logger from '~/utils/logger';
import { State } from '~/store';
import { useSelector } from 'react-redux';

export const useContractsData = (): {
  loading: boolean;
  data: unknown | null;
  revalidate: () => Promise<void>;
} => {
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<unknown | null>(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await pageData();
      setData(result);
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData, activeScenarioUuid]);

  return { loading, data, revalidate: fetchData };
};

import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';

export const getMinMaxDate = ({
  consolidatedGraphData,
}: {
  consolidatedGraphData: IConsolidatedGraphBody;
}): { minDate: number; maxDate: number } => {
  return consolidatedGraphData.data.reduce(
    (acc: { minDate: number; maxDate: number }, item: Record<string, number>) => {
      const date = item.date;
      if (date < acc.minDate) {
        acc.minDate = date;
      }
      if (date > acc.maxDate) {
        acc.maxDate = date;
      }
      return acc;
    },
    { minDate: Number.MAX_SAFE_INTEGER, maxDate: Number.MIN_SAFE_INTEGER },
  );
};

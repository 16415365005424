import React, { Fragment, useEffect, useState } from 'react';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import Typography from '~/components/Typography';
import { useShareLinkData } from './useShareLinkData';
import ShareLinkDashboard from './ShareLinkDashboard';
import { IGraphData } from '~/pages/Dashboard/entity/types';
import ShareLinkFormulasContainer from './ShareLinkFormulasContainer';
import { toZonedTime } from 'date-fns-tz';
import { ModelBuilderProvider } from '~/pages/FinancialModel/ModelBuilderContext';
import { IOrganizationState } from '~/store/organizationSlice';
import { UserState } from '~/store/userSlice';
import { IUserRole } from '~/utils/waitForStoreRehydration';
import { ScenarioState } from '~/store/scenarioSlice';
import ShareLinkHeader from './ShareLinkHeader';
import { Transition } from '@headlessui/react';

const Authenticated = ({
  preview,
  previewStartDate,
  previewEndDate,
  previewReportData,
  previewScenarioUuid,
  previewOrganizationName,
}: {
  preview?: boolean;
  previewStartDate?: Date;
  previewEndDate?: Date;
  previewReportData?: IGraphData;
  previewScenarioUuid?: string;
  previewOrganizationName?: string;
}): React.ReactNode => {
  const {
    data,
    dashboardConfiguration,
    startDate,
    endDate,
    pageLoading,
    organizationUuid,
    permissionedFormulaUuids,
    organizationName,
  } = useShareLinkData();

  const [selectedView, setSelectedView] = useState<string>('dashboard');
  const [reportData, setReportData] = useState<IGraphData>({ consolidated: {}, static: {} });
  const [startDateState, setStartDateState] = usePeriodPicker({
    startDate: toZonedTime(previewStartDate ?? startDate, 'UTC'),
    endDate: new Date(),
    mode: 'month',
  });
  const [endDateState, setEndDateState] = usePeriodPicker({
    startDate: toZonedTime(previewEndDate ?? endDate, 'UTC'),
    endDate: new Date(),
    mode: 'month',
  });
  const [userActiveIndex, setUserActiveIndex] = useState<number>(-1);

  useEffect(() => {
    if (data && !preview) {
      setReportData(data.reportData);
    }
  }, [data, preview]);

  const scenario: ScenarioState = {
    activeScenarioUuid: null,
    activeScenarioHasChanges: false,
    scenarioMode: 'creating',
    activeScenarioData: null,
    leverChanges: [],
    cashBalanceLockedIndexes: [],
    cashBalanceSelectedPoint: null,
    selectedScenarioUuids: [],
  };

  const user: UserState = {
    preferences: {
      defaultGraphStartDate: startDate.toISOString(),
      defaultGraphEndDate: endDate.toISOString(),
      primaryOrganizationUuid: organizationUuid,
      sideMenuExpanded: true,
    },
    uuid: 'user-uuid',
    name: 'User',
    email: 'user@example.com',
    role: IUserRole.LINK,
    permissions: {
      role: IUserRole.LINK,
      departmentAccessList: [],
    },
  };

  const organization: IOrganizationState = {
    uuid: organizationUuid,
    name: 'Organization',
    configuration: {
      companyStartDate: startDate.toISOString(),
      isModelBuilderVisibleToUser: true,
      dashboardConfiguration: {
        metrics: [],
        graphs: [],
        levers: [],
      },
      contractsEnabled: false,
      ratiosEnabled: false,
    },
    departments: [],
  };

  return (
    <div className="w-screen flex flex-col items-center gap-20 z-50 fixed top-0 left-0 bg-white h-screen overflow-y-scroll pb-4">
      <ModelBuilderProvider
        defaultData={!preview ? { scenario, user, organization } : undefined}
        viewOnly
        startDate={previewStartDate}
        endDate={previewEndDate}
        scenarioUuid={previewScenarioUuid}
      >
        <ShareLinkHeader
          selectedView={selectedView}
          setSelectedView={setSelectedView}
          permissionedFormulaUuids={!preview ? permissionedFormulaUuids : ['*']}
          startDate={startDate}
          endDate={endDate}
          organizationName={previewOrganizationName ?? organizationName}
        />
        <div className="w-full px-4 flex flex-col items-center">
          <Transition
            as="div"
            show={selectedView === 'dashboard'}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className={`flex justify-between w-full max-w-[1016px] items-center ${selectedView === 'spreadsheet' && 'hidden'}`}
          >
            <div className="flex gap-4 items-center">
              <Typography weight="bold" size="lg">
                {previewOrganizationName ?? organizationName}
              </Typography>
            </div>
            <div className="flex gap-2 items-center">
              <PeriodPicker
                id="share-link-date-range-start-date"
                state={startDateState}
                setState={setStartDateState}
                disabled
              />
              <Typography size="xl" color="empty" className="pb-[1px]">
                -
              </Typography>
              <PeriodPicker
                id="share-link-date-range-end-date"
                state={endDateState}
                setState={setEndDateState}
                disabled
              />
            </div>
          </Transition>
          <Transition
            as="div"
            show={selectedView === 'spreadsheet'}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className={`flex justify-between w-full px-12 items-center ${selectedView === 'dashboard' && 'hidden'}`}
          >
            <div className="flex gap-4 items-center">
              <Typography weight="bold" size="lg">
                {previewOrganizationName ?? organizationName}
              </Typography>
              <>
                <div className="flex gap-1 items-center">
                  <div className="size-2 bg-neutral-200 rounded-full" />
                  <Typography size="sm" color="empty">
                    Actuals
                  </Typography>
                </div>
                <div className="flex gap-1 items-center">
                  <div className="size-2 bg-neutral-800 rounded-full" />
                  <Typography size="sm" color="empty">
                    Forecast
                  </Typography>
                </div>
              </>
            </div>
            <div className="flex gap-2 items-center">
              <PeriodPicker
                id="share-link-date-range-start-date"
                state={startDateState}
                setState={setStartDateState}
                disabled
              />
              <Typography size="xl" color="empty" className="pb-[1px]">
                -
              </Typography>
              <PeriodPicker
                id="share-link-date-range-end-date"
                state={endDateState}
                setState={setEndDateState}
                disabled
              />
            </div>
          </Transition>
          <Transition
            as="div"
            show={selectedView === 'dashboard'}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className={`flex w-full justify-center ${selectedView === 'spreadsheet' && 'hidden'}`}
          >
            <ShareLinkDashboard
              reportData={!preview ? reportData : previewReportData}
              pageLoading={!preview ? pageLoading : false}
              dashboardConfiguration={!preview ? dashboardConfiguration : undefined}
              overrideUserActiveIndex={userActiveIndex}
              setOverrideUserActiveIndex={setUserActiveIndex}
            />
          </Transition>
          <Transition
            as="div"
            show={selectedView === 'spreadsheet'}
            enter="transition-opacity duration-200"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            className={`flex w-full ${selectedView === 'dashboard' && 'hidden'}`}
          >
            <ShareLinkFormulasContainer
              permissionedFormulaUuids={!preview ? permissionedFormulaUuids : ['*']}
              startDate={previewStartDate ?? startDate}
              endDate={previewEndDate ?? endDate}
            />
          </Transition>
        </div>
      </ModelBuilderProvider>
    </div>
  );
};

export default Authenticated;

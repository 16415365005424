import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { SelectType } from '~/components/Select/Select.types';
import leven from 'leven';

const Autocomplete = ({
  searchValue,
  position,
  selectOptions,
  onSelect,
  onFocus,
  onBlur,
}: {
  searchValue: string;
  position: { top: number; left: number };
  selectOptions: SelectType[];
  onSelect: (option: SelectType) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}): React.ReactElement | null => {
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(-1);

  const filteredOptions = selectOptions
    .map((option) => {
      const searchValueLower = searchValue.toLowerCase();
      const label = option.label?.toLowerCase() || '';

      // Check for substring match first
      if (label.includes(searchValueLower)) {
        return {
          ...option,
          distance: 1.5,
        };
      }

      // Fall back to levenshtein distance
      return {
        ...option,
        distance: leven(searchValueLower, label),
      };
    })
    .sort((a, b) => a.distance - b.distance)
    .filter((option) => option.distance <= 2)
    .slice(0, 5);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent): void => {
      if (filteredOptions.length === 0) return;
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          if (prevIndex >= filteredOptions.length - 1) return 0;
          return prevIndex + 1;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setHighlightedIndex((prevIndex) => {
          if (prevIndex <= 0) return filteredOptions.length - 1;
          return prevIndex - 1;
        });
      } else if (e.key === 'Enter' && highlightedIndex >= 0) {
        e.preventDefault();
        onSelect(filteredOptions[highlightedIndex]);
        setHighlightedIndex(-1);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [filteredOptions, highlightedIndex, onSelect]);

  useEffect(() => {
    if (highlightedIndex >= 0) {
      if (onFocus) {
        onFocus();
      } else if (onBlur) {
        onBlur();
      }
    }
  }, [highlightedIndex]);

  return createPortal(
    <div className="bg-white rounded z-50 shadow" style={{ position: 'fixed', top: position.top, left: position.left }}>
      <div className="flex flex-col">
        {filteredOptions.map((option, index) => (
          <div
            role="button"
            key={option.value}
            className={`border-gray-50 cursor-pointer px-5 py-2 ${highlightedIndex === index ? 'bg-gray-100' : ''}`}
            onMouseDown={(e) => {
              e.stopPropagation();
              onSelect(option);
              setHighlightedIndex(-1);
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>,
    document.body,
  );
};

export default Autocomplete;

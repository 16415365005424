import React, { useContext } from 'react';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import SelectDepartment from '~/components/SelectDepartment';

const CreationDepartment = ({ handleBlur }: { handleBlur: () => void }): React.ReactNode => {
  const { inlineCreate, inlineCreateSelectDepartment, setInlineCreateSelectDepartment } = useContext(HeadcountContext);

  return inlineCreate ? (
    <SelectDepartment
      departmentOptions={inlineCreateSelectDepartment}
      setDepartmentOptions={setInlineCreateSelectDepartment}
      id={`create-department`}
      className="mt-0.5"
      onBlur={handleBlur}
      errorMessage=""
    />
  ) : (
    <div />
  );
};

export default CreationDepartment;

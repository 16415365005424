import React, { useState } from 'react';
import { IFormulaSegment, IVariables } from '../../entity/types';
import { useSelect } from '~/components/Select';
import { SelectState } from '~/components/Select/Select.types';
import { useInput } from '~/components/Input';
import { IFormattingEnum } from '../../entity/schemas';
import type { SelectMultipleState } from '~/components/SelectMultiple/SelectMultiple.types';
import { useSelectMultiple } from '~/components/SelectMultiple';

interface IFormulaBuilderContext {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  displayFormulaError: {
    isDisplayed: boolean;
    message: string;
  };
  setDisplayFormulaError: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  formula: IFormulaSegment[];
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  showRounding: boolean;
  setShowRounding: React.Dispatch<React.SetStateAction<boolean>>;
  roundingDirection: string;
  setRoundingDirection: React.Dispatch<React.SetStateAction<string>>;
  roundingPrecisionState: SelectState;
  setRoundingPrecisionState: React.Dispatch<React.SetStateAction<SelectState>>;
  attributeTitle: Types.InputState;
  setAttributeTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  resetAttributeTitle: () => void;
  resetForm: () => void;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dataSourceState: SelectMultipleState;
  setDataSourceState: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  formatting: IFormattingEnum;
  setFormatting: React.Dispatch<React.SetStateAction<IFormattingEnum>>;
}

export const FormulaBuilderContext = React.createContext({} as IFormulaBuilderContext);

export const FormulaBuilderProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [variables, setVariables] = useState<IVariables>({});
  const [displayFormulaError, setDisplayFormulaError] = useState<{
    isDisplayed: boolean;
    message: string;
  }>({
    isDisplayed: false,
    message: '',
  });
  const [attributeTitle, setAttributeTitle, resetAttributeTitle] = useInput({
    validation: /.+/,
    errorMessage: 'Attribute title is required',
  });
  const [dataSourceState, setDataSourceState] = useSelectMultiple({
    options: [],
    isNullable: true,
    isLoading: false,
  });
  const [formula, setFormula] = useState<IFormulaSegment[]>([]);
  const [updatedFormula, setUpdatedFormula] = useState<IFormulaSegment[]>([]);
  const [showRounding, setShowRounding] = useState<boolean>(false);
  const [roundingDirection, setRoundingDirection] = useState<string>('nearest');
  const [roundingPrecisionState, setRoundingPrecisionState] = useSelect({
    options: [
      {
        label: 'Whole Number',
        value: '1',
      },
      {
        label: 'Nearest 10',
        value: '10',
      },
      {
        label: 'Nearest 100',
        value: '100',
      },
      {
        label: 'Nearest 1000',
        value: '1000',
      },
      {
        label: 'Nearest 10000',
        value: '10000',
      },
      {
        label: 'Nearest 100000',
        value: '100000',
      },
      {
        label: '1 Decimal Place',
        value: '0.1',
      },
      {
        label: '2 Decimal Places',
        value: '0.01',
      },
    ],
    selected: { label: 'Whole Number', value: '1' },
  });
  const [formatting, setFormatting] = useState<IFormattingEnum>(IFormattingEnum.Number);

  const resetForm = (): void => {
    resetAttributeTitle();
    setUpdatedFormula([]);
    setFormula([]);
    setDisplayFormulaError({
      isDisplayed: false,
      message: '',
    });
    setValue('');
    setVariables({});
    setShowRounding(false);
    setRoundingDirection('nearest');
    setRoundingPrecisionState((prev) => ({
      ...prev,
      selected: { label: 'Whole Number', value: '1' },
    }));
    setDataSourceState((prev) => ({
      ...prev,
      selected: undefined,
    }));
  };

  return (
    <FormulaBuilderContext.Provider
      value={{
        value,
        setValue,
        variables,
        setVariables,
        displayFormulaError,
        setDisplayFormulaError,
        formula,
        setFormula,
        updatedFormula,
        setUpdatedFormula,
        showRounding,
        setShowRounding,
        roundingDirection,
        setRoundingDirection,
        roundingPrecisionState,
        setRoundingPrecisionState,
        attributeTitle,
        setAttributeTitle,
        resetAttributeTitle,
        resetForm,
        isLoading,
        setIsLoading,
        dataSourceState,
        setDataSourceState,
        formatting,
        setFormatting,
      }}
    >
      {children}
    </FormulaBuilderContext.Provider>
  );
};

export default FormulaBuilderProvider;

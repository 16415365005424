import React, { LegacyRef, ReactElement, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IInitialValue } from '../entities/types';
import { CellContext } from '@tanstack/react-table';
import useClickOutside from '~/utils/hooks/useClickOutside';
import useHover from '~/utils/hooks/useHover';
import DatePicker, { useDatePicker } from '~/components/DatePicker';
import { addDays, subDays } from 'date-fns';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { z } from 'zod';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { HeadcountContext, IPositionDataDict } from '~/pages/Headcount/context/HeadcountContext';
import debounce from 'lodash/debounce';
import toast from 'react-hot-toast';
import { StatusCodes } from 'http-status-codes';
import { fetchSpecificPositions } from '~/pages/Headcount/utils/fetchSpecificPositions';
import { ZPositionDetails } from '~/pages/Headcount/entity/schemas';
import './styles.css';
import logger from '~/utils/logger';

const ZUpdatePositionResponse = z.object({
  data: z.object({
    data: ZPositionDetails,
  }),
  status: z.number(),
});

const CellDateInput = ({
  initialValue,
  cellContext,
}: {
  initialValue: IInitialValue;
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
}): ReactElement => {
  const parsedInitialValue = z
    .object({
      value: z.string().nullable(),
      positionUuid: z.string(),
      minDate: z.string().optional(),
      maxDate: z.string().optional(),
    })
    .parse(initialValue);

  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const { openTermDateRowIndex, positionActiveStateDict, positionDataDict, setPositionDataDict, setRenderedPositions } =
    useContext(HeadcountContext);

  const [isActive, setIsActive] = useState(positionActiveStateDict[initialValue.positionUuid]);

  useEffect(() => {
    setIsActive(positionActiveStateDict[initialValue.positionUuid]);
  }, [positionActiveStateDict[initialValue.positionUuid]]);

  const [date, setDate] = useDatePicker({
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    value: positionDataDict[parsedInitialValue.positionUuid]
      ? {
          startDate:
            cellContext.column.id === 'hireDate'
              ? positionDataDict[parsedInitialValue.positionUuid].hireDate
              : positionDataDict[parsedInitialValue.positionUuid].termDate,
          endDate:
            cellContext.column.id === 'hireDate'
              ? positionDataDict[parsedInitialValue.positionUuid].hireDate
              : positionDataDict[parsedInitialValue.positionUuid].termDate,
        }
      : {
          startDate: null,
          endDate: null,
        },
    minDate: parsedInitialValue.minDate
      ? formatInTimeZone(addDays(parsedInitialValue.minDate, 1), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : undefined,
    maxDate: parsedInitialValue.maxDate
      ? formatInTimeZone(subDays(parsedInitialValue.maxDate, 1), 'UTC', "yyyy-MM-dd'T'HH:mm:ss'Z'")
      : undefined,
  });

  const wrapperRef = useRef<HTMLElement>(null);
  const [ref, hovering] = useHover();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [inEditMode, setInEditMode] = useState(false);

  const updatePositionValue = debounce(async () => {
    const isValidUpdateToHireDate = Boolean(
      cellContext.column.id === 'hireDate' &&
        date.value.startDate &&
        formatInTimeZone(date.value.startDate, 'UTC', 'yyyy-MM-dd') !==
          (positionDataDict[parsedInitialValue.positionUuid][cellContext.column.id]
            ? formatInTimeZone(
                positionDataDict[parsedInitialValue.positionUuid][cellContext.column.id],
                'UTC',
                'yyyy-MM-dd',
              )
            : null),
    );
    const isValidUpdateToTermDate = Boolean(
      cellContext.column.id === 'termDate' &&
        date.value.startDate !==
          (positionDataDict[parsedInitialValue.positionUuid].termDate
            ? formatInTimeZone(positionDataDict[parsedInitialValue.positionUuid].termDate ?? '', 'UTC', 'yyyy-MM-dd')
            : null),
    );

    if (isValidUpdateToHireDate || isValidUpdateToTermDate) {
      try {
        const updatedValueResponse = await request({
          method: 'PATCH',
          url: `/organizations/${organizationUuid}/positions/${parsedInitialValue.positionUuid}`,
          params: {
            scenarioUuid: activeScenarioUuid ?? undefined,
          },
          body: {
            hireDate: cellContext.column.id === 'hireDate' ? date.value.startDate : undefined,
            termDate: cellContext.column.id === 'termDate' ? date.value.startDate : undefined,
          },
        });

        const parsedResponse = ZUpdatePositionResponse.parse(updatedValueResponse);

        if (parsedResponse.status === StatusCodes.CREATED) {
          const [updatedPosition] = await fetchSpecificPositions({
            organizationUuid,
            positionUuids: [parsedInitialValue.positionUuid],
            scenarioUuid: activeScenarioUuid ?? undefined,
          });

          setRenderedPositions((prev) =>
            prev.map((position) => {
              if (position.positionUuid === updatedPosition.positionUuid) {
                return {
                  ...position,
                  hireDate: updatedPosition.hireDate,
                  termDate: updatedPosition.termDate,
                };
              }
              return position;
            }),
          );
          setPositionDataDict((prev) => ({
            ...prev,
            [parsedInitialValue.positionUuid]: {
              employeeName: updatedPosition.employeeName ?? '',
              title: updatedPosition.title,
              department: updatedPosition.currentDepartment,
              hireDate: updatedPosition.hireDate,
              termDate: updatedPosition.termDate,
              fullyBurdenedCost: updatedPosition.fullyBurdenedCost,
            },
          }));
        } else {
          toast.error('Failed to update date');
          resetDate();
        }

        setInEditMode(false);
        setShowDatePicker(false);
      } catch (error) {
        if (error instanceof Error) {
          logger.error(error);
        }
        toast.error('Failed to update date');
        resetDate();
      }
    } else {
      resetDate();
    }
  }, 300);

  const resetDate = (): void => {
    setDate((prev) => ({
      ...prev,
      value: {
        startDate: parsedInitialValue.value ? parsedInitialValue.value : null,
        endDate: parsedInitialValue.value ? parsedInitialValue.value : null,
      },
      minDate: parsedInitialValue.minDate
        ? addDays(toZonedTime(parsedInitialValue.minDate, 'UTC'), 1).toString()
        : undefined,
      maxDate: parsedInitialValue.maxDate
        ? addDays(toZonedTime(parsedInitialValue.maxDate, 'UTC'), -1).toString()
        : undefined,
      valid: true,
      pristine: true,
      touched: false,
      disabled: false,
    }));
    setInEditMode(false);
    setShowDatePicker(false);
  };

  const onBlur = async (): Promise<void> => {
    await updatePositionValue();
  };

  useEffect(() => {
    if (hovering && isActive) {
      if (!showDatePicker) {
        setShowDatePicker(true);
      }
    } else if (!hovering && isActive && !inEditMode) {
      setShowDatePicker(false);
    }
  }, [hovering, isActive]);

  useEffect(() => {
    if (
      openTermDateRowIndex !== null &&
      openTermDateRowIndex === cellContext.row.index &&
      cellContext.column.id === 'termDate'
    ) {
      if (inEditMode) {
        const datePickerElement: HTMLElement | null = document.querySelector(
          `[data-testid="termDate-${cellContext.row.index}-date-picker"]`,
        );

        if (datePickerElement) {
          datePickerElement.click();
        }
      }
    } else if (cellContext.column.id === 'termDate' && inEditMode && !showDatePicker) {
      setInEditMode(false);
    }
  }, [openTermDateRowIndex, inEditMode, cellContext.column.id]);

  useEffect(() => {
    if (
      date.value.startDate !==
      positionDataDict[parsedInitialValue.positionUuid][cellContext.column.id as keyof IPositionDataDict]
    ) {
      onBlur();
    }
  }, [date.value.startDate]);

  useClickOutside(wrapperRef, resetDate);

  const dateToRender = useMemo(() => {
    if (date.value.startDate) {
      return formatInTimeZone(date.value.startDate, 'UTC', 'MM/dd/yyyy');
    } else if (cellContext.column.id === 'termDate' && parsedInitialValue.value && !date.value.startDate) {
      return '-';
    } else if (parsedInitialValue.value) {
      return formatInTimeZone(parsedInitialValue.value, 'UTC', 'MM/dd/yyyy');
    } else {
      return '-';
    }
  }, [date.value.startDate, parsedInitialValue.value]);

  const datePicker = useMemo(() => {
    return (
      <DatePicker
        id={`${cellContext.column.id}-${cellContext.row.index}-date-picker`}
        className={cellContext.column.id === 'hireDate' ? 'clearDateHidden' : ''}
        state={date}
        setState={setDate}
        required={cellContext.column.id === 'hireDate'}
      />
    );
  }, [showDatePicker, date]);

  return (
    <div
      className={cellContext.column.id === 'hireDate' ? 'w-[115px]' : 'w-[150px]'}
      ref={ref as LegacyRef<HTMLDivElement>}
    >
      {inEditMode || showDatePicker ? (
        <div
          ref={wrapperRef as LegacyRef<HTMLDivElement>}
          data-testid={`${cellContext.column.id}-${cellContext.row.index}-container`}
          onClick={(e) => {
            e.stopPropagation();
            isActive && setInEditMode(true);
          }}
        >
          {datePicker}
        </div>
      ) : (
        <div
          className={`flex text-nowrap overflow-hidden${
            isActive || !activeScenarioUuid ? '' : ' text-neutral-100 cursor-default'
          }`}
          data-testid={`${cellContext.column.id}-${cellContext.row.index}-container`}
          onClick={(e) => {
            e.stopPropagation();
            isActive && setInEditMode(true);
          }}
        >
          {dateToRender}
        </div>
      )}
    </div>
  );
};

export default CellDateInput;

import React from 'react';
import useRadioInput from '~/components/RadioInput/useRadioInput';
import { RadioInputState } from '~/components/RadioInput/RadioInput.types';
import RadioInput from './RadioInput';

interface Props {
  id: string;
  state: RadioInputState;
  setState: React.Dispatch<React.SetStateAction<RadioInputState>>;
  className?: string;
  disabled?: boolean;
  flexDirection?: 'flex-col' | 'flex-row';
}

const RadioInputWrapper = ({
  id,
  state,
  setState,
  className = '',
  disabled = false,
  flexDirection = 'flex-col',
}: Props): React.ReactNode => {
  const showError = state.touched && !state.pristine && !state.valid;

  return (
    <div className={`w-full flex ${flexDirection} gap-2 ${className}`} data-testid={id}>
      {state.options.map((option) => (
        <RadioInput
          key={option.value}
          id={`${id}-${option.value}`}
          state={state}
          setState={setState}
          label={option.label}
          description={option.description}
          onChange={() => setState({ ...state, selected: option })}
          disabled={disabled}
          option={option}
        />
      ))}

      {showError && <p className="text-red-500 text-sm mt-1">{state.errorMessage}</p>}
    </div>
  );
};

export { useRadioInput };
export default RadioInputWrapper;

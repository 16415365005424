import { useState, useEffect } from 'react';

const useIsMobile = (): boolean => {
  const MOBILE_SCREEN_MAX = 640;

  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= MOBILE_SCREEN_MAX);

  useEffect(() => {
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= MOBILE_SCREEN_MAX);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};

export default useIsMobile;

import React, { CSSProperties, ReactElement } from 'react';
import { Column, flexRender, Table, Header } from '@tanstack/react-table';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

interface IProps {
  table: Table<Record<string, unknown>>;
  emptyState: string | ReactElement;
  styles?: {
    table?: string;
    tHead?: string;
    tBody?: string;
    tHeadTr?: string;
    tRow?: string;
    th?: string;
    td?: string;
  };
  id?: string;
  showHeaderTitle?: boolean;
}

const getCommonPinningStyles = (column: Column<Record<string, unknown>, unknown>): CSSProperties => {
  const canPin = column.getCanPin();

  return {
    left: canPin ? `${column.getStart()}px` : undefined,
    position: canPin ? 'sticky' : 'relative',
    zIndex: canPin ? 1 : undefined,
    opacity: canPin ? 1 : undefined,
  };
};

const disableTextSelection = (): void => {
  document.body.style.userSelect = 'none';
};

const enableTextSelection = (): void => {
  document.body.style.userSelect = '';
};

const BaseTable = ({ table, emptyState = 'No Data', styles, id, showHeaderTitle = true }: IProps): ReactElement => {
  const tableRows = table.getRowModel().rows;
  const columnCount = table.getHeaderGroups().reduce((acc, headerGroup) => headerGroup.headers.length, 0);

  return (
    <table className={`table-auto border-collapse${styles?.table ? ` ${styles.table}` : ''}`} data-testid={id ?? ''}>
      <thead className={`${styles?.tHead ? ` ${styles.tHead}` : ''}`}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id} className={`${styles?.tHeadTr ? ` ${styles.tHeadTr}` : ''}`}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className={`${styles?.th ? ` ${styles.th}` : ''} relative`}
                style={
                  header.column.getCanResize()
                    ? {
                        ...getCommonPinningStyles(header.column),
                        width: header.getSize(),
                        maxWidth: header.getSize(),
                        minWidth: header.getSize(),
                      }
                    : header.column.getCanPin()
                      ? {
                          ...getCommonPinningStyles(header.column),
                          width: header.getSize(),
                          maxWidth: header.getSize(),
                          minWidth: header.getSize(),
                          zIndex: 2, // Needs to be higher than pinned td beneath it
                        }
                      : undefined
                }
              >
                {header.isPlaceholder ? null : (
                  <div
                    className={`${header.column.getCanSort() ? 'cursor-pointer select-none' : ''} flex flex-row gap-4`}
                    onClick={header.column.getToggleSortingHandler()}
                    title={
                      header.column.getCanSort() && showHeaderTitle
                        ? header.column.getNextSortingOrder() === 'asc'
                          ? 'Sort ascending'
                          : header.column.getNextSortingOrder() === 'desc'
                            ? 'Sort descending'
                            : 'Clear sort'
                        : undefined
                    }
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                    {header.column.getCanSort() && (
                      <div className="flex flex-col">
                        <ChevronUpIcon
                          className={`h-3 w-3 ${header.column.getIsSorted() === 'asc' ? 'text-black' : ''}`}
                        />
                        <ChevronDownIcon
                          className={`h-3 w-3 -mt-1 ${header.column.getIsSorted() === 'desc' ? 'text-black' : ''}`}
                        />
                      </div>
                    )}
                    {header.column.getCanResize() && (
                      <div
                        onMouseDown={(e) => {
                          header.getResizeHandler()(e);
                          disableTextSelection();
                          document.addEventListener('mouseup', enableTextSelection, { once: true });
                        }}
                        onTouchStart={(e) => {
                          header.getResizeHandler()(e);
                          disableTextSelection();
                          document.addEventListener('touchend', enableTextSelection, { once: true });
                        }}
                        className="absolute pl-1 h-full w-[5px] right-0 top-0 cursor-col-resize"
                      >
                        <div className="bg-gray-200 h-full w-[1px]" />
                      </div>
                    )}
                  </div>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={`${styles?.tBody ? ` ${styles.tBody}` : ''}`}>
        {tableRows.length ? (
          tableRows.map((row) => (
            <tr key={row.id} className={`${styles?.tRow ? ` ${styles.tRow}` : ''}`}>
              {row.getVisibleCells().map((cell) => {
                let associatedHeader: Header<Record<string, unknown>, unknown> | undefined;
                if (cell.column.getCanResize()) {
                  const headerGroups = table.getHeaderGroups();
                  associatedHeader = headerGroups[0].headers.find((header) => header.column.id === cell.column.id);
                }
                return (
                  <td
                    key={cell.id}
                    className={`${styles?.td ? ` ${styles.td}` : ''} relative`}
                    style={
                      cell.column.getCanResize()
                        ? {
                            ...getCommonPinningStyles(cell.column),
                            width: cell.column.getSize(),
                            maxWidth: cell.column.getSize(),
                            minWidth: cell.column.getSize(),
                          }
                        : cell.column.getCanPin()
                          ? {
                              ...getCommonPinningStyles(cell.column),
                              width: cell.column.getSize(),
                              maxWidth: cell.column.getSize(),
                              minWidth: cell.column.getSize(),
                            }
                          : undefined
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    {cell.column.getCanResize() && (
                      <div
                        onMouseDown={(e) => {
                          associatedHeader?.getResizeHandler()(e);
                          disableTextSelection();
                          document.addEventListener('mouseup', enableTextSelection, { once: true });
                        }}
                        onTouchStart={(e) => {
                          associatedHeader?.getResizeHandler()(e);
                          disableTextSelection();
                          document.addEventListener('touchend', enableTextSelection, { once: true });
                        }}
                        className="absolute flex pl-1 w-[5px] h-full right-0 top-0 cursor-col-resize"
                      >
                        <div className="bg-gray-200 h-full w-[1px] cursor-col-resize" />
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columnCount} className="text-center">
              {emptyState}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default BaseTable;

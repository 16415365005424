import React, { ReactElement, useContext } from 'react';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import quickbooksLogo from '../../../Settings/Integrations/logos/quickbooks.svg';
import xeroLogo from '../../../Settings/Integrations/logos/xero.svg';
import HoverPopover from '~/components/HoverPopover';
import { ViewfinderCircleIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';

const FormulaCell = ({
  onClick,
  valueToDisplay,
  integration,
  isContractFormula,
  isIsolated = false,
}: {
  onClick: () => void;
  valueToDisplay: ReactElement[] | string;
  integration?: 'quickbooks-online' | 'xero';
  isContractFormula?: string;
  isIsolated?: boolean;
}): React.ReactNode => {
  const { overridesHaveChanges, actualsHaveChanges } = useContext(FinancialModelContext);
  const isTitle = typeof valueToDisplay === 'string';

  const accountingLogos: Record<string, ReactElement> = {
    'quickbooks-online': <img src={quickbooksLogo} alt="Quickbooks logo" className="size-5" />,
    xero: <img src={xeroLogo} alt="Xero logo" className="size-[22px]" />,
  };

  return (
    <div
      className={`w-full h-full pl-10 pr-4 flex items-center text-left text-nowrap ${(overridesHaveChanges || actualsHaveChanges) && '!bg-neutral-15 text-neutral-100'}${isTitle && ' justify-between'}`}
      onClick={() => {
        if (!overridesHaveChanges && !actualsHaveChanges) {
          onClick();
        }
      }}
      data-testid={typeof valueToDisplay === 'string' && `formula-cell-title-${valueToDisplay}`}
    >
      {isContractFormula ? (
        <div className="flex flex-row text-nowrap border border-neutral-100 focus:border-green-400 rounded-full bg-neutral-15 px-3 ml-2 py-0.5">
          {isContractFormula}
        </div>
      ) : (
        valueToDisplay
      )}
      <div className="flex gap-2">
        {isTitle && isIsolated && (
          <HoverPopover
            buttonContent={<ViewfinderCircleIcon className="size-5" />}
            panelContent={
              <div className="flex flex-col text-nowrap justify-start">
                <Typography size="xs" weight="bold">
                  Isolated Attribute
                </Typography>
                <Typography size="xs">
                  This attribute is not used to
                  <br />
                  calculate any other attributes
                </Typography>
              </div>
            }
            panelClassName="!bg-white !shadow !rounded-md !py-2 !px-4"
            anchor="top"
          />
        )}
        {isTitle && integration && accountingLogos[integration]}
      </div>
    </div>
  );
};

export default FormulaCell;

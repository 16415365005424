import { restoreYValue } from './invertYValue';
import { PERCENT_TO_SNAP_TO } from './percentToSnapTo';

export const convertGraphValueToRawOverrideValue = ({
  graphValue,
  yMultiplier,
  maxY,
  minY,
  activeScenarioValue,
  workingModelValue,
}: {
  graphValue: number;
  yMultiplier: number;
  maxY: number;
  minY: number;
  activeScenarioValue: number;
  workingModelValue: number;
}): number => {
  const originalValue = restoreYValue({
    value: graphValue,
    maxY,
    yMultiplier,
  });
  const graphRange = maxY - minY;
  const snapToValue = graphRange * PERCENT_TO_SNAP_TO;
  const activeScenarioDifference = Math.abs(activeScenarioValue - originalValue);
  const workingModelDifference = Math.abs(workingModelValue - originalValue);
  const zeroDifference = Math.abs(0 - originalValue);
  if (activeScenarioDifference < snapToValue) {
    return activeScenarioValue;
  }
  if (workingModelDifference < snapToValue) {
    return workingModelValue;
  }
  if (zeroDifference < snapToValue) {
    return 0;
  }

  return originalValue;
};

export const findScaleMultiplier = ({
  numOfIndexes,
  minY,
  maxY,
  graphWidth,
  graphHeight,
}: {
  numOfIndexes: number;
  minY: number;
  maxY: number;
  graphWidth: number;
  graphHeight: number;
}): { xMultiplier: number; yMultiplier: number } => {
  const xMultiplier = (graphWidth / (numOfIndexes - 1)) * 0.95;
  const yMultiplier = graphHeight / (maxY - minY);
  return { xMultiplier, yMultiplier };
};

import React, { useEffect, useRef } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Input, { useInput } from '~/components/Input/InputWrapper';
import { IFormattingEnum } from '~/services/parallel/formulas.types';
import { State } from '~/store';
import logger from '~/utils/logger';
import { generateOverridesAndActuals } from '../utils/generateOverridesAndActuals';
import { updateScenario } from '../utils/updateScenario';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import { updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';
import { createPortal } from 'react-dom';

const DraggableGraphInput = ({
  formatting,
  consolidatedGraphData,
  changeDate,
  formulaUuid,
  reload,
  x,
  y,
  setInputData,
}: {
  formatting: IFormattingEnum;
  consolidatedGraphData: IConsolidatedGraphBody;
  changeDate: Date;
  formulaUuid: string;
  reload: () => Promise<void>;
  x: number;
  y: number;
  setInputData: (data: { x: number | null; y: number | null; index: number | null }) => void;
}): React.ReactElement => {
  const dispatch = useDispatch();
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [value, setValue] = useInput({
    validation: /.*/,
  });
  const inputRef = useRef<HTMLInputElement>(null);
  const valueRef = useRef<string>(value.value);

  useEffect(() => {
    valueRef.current = value.value;
  }, [value.value]);

  const handleBlur = async ({ formatting }: { formatting: IFormattingEnum }): Promise<void> => {
    try {
      setInputData({ x: null, y: null, index: null });
      if (!valueRef.current) return;
      if (!activeScenarioUuid) {
        dispatch(updateScenarioLoadingState('creating'));
        dispatch(updateScenarioMode('creating'));
      } else {
        dispatch(updateScenarioLoadingState('updating'));
      }
      const formattedValue =
        formatting === IFormattingEnum.Percent
          ? parseFloat(valueRef.current) / CENTS_PER_DOLLAR
          : parseFloat(valueRef.current);
      const { overrides, actuals } = generateOverridesAndActuals({
        changeDate,
        value: formattedValue,
        consolidatedGraphData,
        activeScenarioUuid,
      });
      await updateScenario({
        scenarioUuid: activeScenarioUuid,
        organizationUuid,
        formulaUuid,
        overrides,
        actuals,
      });
      await reload();
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to update value');
    } finally {
      dispatch(updateScenarioLoadingState('idle'));
    }
  };

  useEffect(() => {
    const handlePressEscapeAndEnter = async (e: KeyboardEvent): Promise<void> => {
      if (e.key === 'Escape') {
        setInputData({ x: null, y: null, index: null });
      }
      if (e.key === 'Enter') {
        await handleBlur({ formatting });
      }
    };
    window.addEventListener('keydown', handlePressEscapeAndEnter);
    return () => {
      window.removeEventListener('keydown', handlePressEscapeAndEnter);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return createPortal(
    <div className="fixed z-50" style={{ left: x, top: y }}>
      <div className="flex items-center gap-1">
        <div className="w-[100px]">
          <Input
            id="draggable-graph-input"
            state={value}
            setState={setValue}
            type={formatting === IFormattingEnum.Percent ? 'percentage' : 'currency'}
            className="focus-visible:!border-blue-400 focus-visible:!ring-offset-blue-100 focus-visible:!ring-blue-100"
            onBlur={() => handleBlur({ formatting })}
            ref={inputRef}
          />
        </div>
      </div>
    </div>,
    document.body,
    'draggable-graph-input',
  );
};

export default DraggableGraphInput;

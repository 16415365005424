import React from 'react';
import TableHeaders from './TableHeaders';
import TableBody from './TableBody';
import { TableProvider } from './contexts/TableContext';
import { IColumn } from './types';
import { DragEndEvent } from '@dnd-kit/core';
import { IFormulaGroupData } from '../../pages/FinancialModel/ModelBuilderContext';

interface IProps {
  columns: IColumn[];
  data: IFormulaGroupData[];
  groupComponent: React.ComponentType<{
    uuid: string;
    name: string;
    isCollapsed: boolean;
    count: number;
    index: number;
  }>;
  onDragEnd: (result: DragEndEvent) => void;
  startDate?: Date;
  endDate?: Date;
  footerComponent?: React.ComponentType;
}

const FormulasTable = ({
  columns,
  data,
  groupComponent,
  onDragEnd,
  startDate,
  endDate,
  footerComponent: FooterComponent,
}: IProps): React.ReactElement => {
  return (
    <div className="w-full pb-5">
      <TableProvider value={{ onDragEnd, startDate, endDate }}>
        <TableHeaders columns={columns} />
        <TableBody columns={columns} tableData={data} groupComponent={groupComponent} />
        {FooterComponent && <FooterComponent />}
      </TableProvider>
    </div>
  );
};

export default FormulasTable;

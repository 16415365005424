import useRadioInput from '~/components/RadioInput/useRadioInput';
import { useInput } from '~/components/Input/InputWrapper';
import { useSelectMultiple } from '~/components/SelectMultiple';
import { SelectMultipleState } from '~/components/SelectMultiple/SelectMultiple.types';
import { RadioInputState } from '~/components/RadioInput/RadioInput.types';

const useUserFormState = (): {
  userName: Types.InputState;
  setUserName: React.Dispatch<React.SetStateAction<Types.InputState>>;
  userEmail: Types.InputState;
  setUserEmail: React.Dispatch<React.SetStateAction<Types.InputState>>;
  role: RadioInputState;
  setRole: React.Dispatch<React.SetStateAction<RadioInputState>>;
  departmentAccessList: SelectMultipleState;
  setDepartmentAccessList: React.Dispatch<React.SetStateAction<SelectMultipleState>>;
  resetFormState: () => void;
} => {
  const [userName, setUserName] = useInput({
    validation: /.+/,
    errorMessage: 'Name is required',
  });
  const [userEmail, setUserEmail] = useInput({
    validation: /.+/,
    errorMessage: 'Email is required',
  });
  const [role, setRole] = useRadioInput({
    options: [
      {
        value: 'admin',
        label: 'Admin',
        description:
          'Able to manage users access and has full access to all personnel data, including individual salaries and department budgets.',
      },
      {
        value: 'user',
        label: 'User',
        description: 'Has access to specific departments and all individuals who are associated to those departments.',
      },
    ],
    selected: undefined,
  });
  const [departmentAccessList, setDepartmentAccessList] = useSelectMultiple({
    options: [],
  });

  const resetFormState = (): void => {
    setUserName({
      ...userName,
      value: '',
      touched: false,
      pristine: true,
      valid: false,
    });
    setUserEmail({
      ...userEmail,
      value: '',
      touched: false,
      pristine: true,
      valid: false,
    });
    setRole({ ...role, selected: undefined });
    setDepartmentAccessList({
      ...departmentAccessList,
      selected: undefined,
    });
  };

  return {
    userName,
    setUserName,
    userEmail,
    setUserEmail,
    role,
    setRole,
    departmentAccessList,
    setDepartmentAccessList,
    resetFormState,
  };
};

export default useUserFormState;

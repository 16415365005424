import React, { useEffect, useRef, useState } from 'react';
import {
  endOfMonth,
  endOfQuarter,
  endOfYear,
  getYear,
  isAfter,
  isBefore,
  isEqual,
  startOfMonth,
  startOfQuarter,
  startOfYear,
} from 'date-fns';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import { CalendarIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { IPeriodPickerState } from './usePeriodPicker';
import { getGridDisplayValues, getInputDisplayValue, getStartAndEndDatesFromSelection } from './pickerModeHelpers';
import date from '~/utils/dates/date';
import { XMarkIcon } from '@heroicons/react/24/solid';
import HoverPopover from '../HoverPopover';

const PeriodPicker = ({
  label,
  id,
  minYear = 2015,
  maxYear = 2030,
  clearable,
  optional,
  state,
  setState,
  pickerAlignment = 'left',
  beBefore,
  beAfter,
  border,
  defaultInputDisplay = '-',
  infoIcon,
  disabled,
}: {
  label?: string;
  id: string;
  minYear?: number;
  maxYear?: number;
  clearable?: boolean;
  optional?: boolean;
  state: IPeriodPickerState;
  setState: React.Dispatch<React.SetStateAction<IPeriodPickerState>>;
  pickerAlignment?: 'left' | 'right';
  beBefore?: Date | null;
  beAfter?: Date | null;
  border?: 'solid' | 'hover';
  defaultInputDisplay?: string;
  infoIcon?: string;
  disabled?: boolean;
}): React.ReactNode => {
  const pickerRef = useRef(null);
  const [showPicker, setShowPicker] = useState(false);
  const [pendingYearDate, setPendingYearDate] = useState<number>(
    state.startDate ? getYear(state.startDate) : getYear(new Date()),
  );
  const [displayState, setDisplayState] = useState<{
    gridDisplayValues: (string | number)[];
    gridDisplay: string;
  }>({
    gridDisplayValues: [],
    gridDisplay: '',
  });

  const displayPickerReset = clearable && state.startDate && state.endDate;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setShowPicker(false);
      }
    };

    if (showPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showPicker]);

  useEffect(() => {
    setDisplayState({
      gridDisplayValues: getGridDisplayValues(state.mode, pendingYearDate, minYear, maxYear),
      gridDisplay: getInputDisplayValue(state.mode, state.startDate, defaultInputDisplay),
    });
  }, [state.startDate, state.endDate, pendingYearDate]);

  useEffect(() => {
    if (!state.startDate) return;
    let { startDate, endDate } = state;
    switch (state.mode) {
      case 'month': {
        startDate = startOfMonth(startDate);
        endDate = endOfMonth(startDate);
        break;
      }
      case 'quarter': {
        startDate = startOfQuarter(startDate);
        endDate = endOfQuarter(startDate);
        break;
      }
      case 'year': {
        startDate = startOfYear(startDate);
        endDate = endOfYear(startDate);
        break;
      }
      default:
        throw Error('Invalid mode');
    }
    if (Boolean(beBefore && isAfter(startDate, beBefore)) || (beAfter && isBefore(endDate, beAfter))) {
      setShowPicker(true); // Open the picker if outside bounds
    } else if (!isEqual(startDate, state.startDate) || (state.endDate && !isEqual(endDate, state.endDate))) {
      setState({ ...state, startDate, endDate, valid: true });
    }
  }, [state.mode, beBefore, beAfter]);

  const togglePicker = (): void => {
    if (disabled) return;
    setShowPicker(!showPicker);
    setPendingYearDate(getYear(state.startDate ?? date()));
  };

  const handleGridSelection = (selection: string | number): void => {
    setShowPicker(false);
    const { startDate, endDate } = getStartAndEndDatesFromSelection(state.mode, selection, pendingYearDate);
    setState({
      ...state,
      startDate,
      endDate,
      valid: true,
    });
  };

  const pickerPositionLocation = pickerAlignment === 'left' ? 'left-0' : 'right-0';

  const getDisplayGrid = (): React.ReactNode => {
    const gridColumns = ((): number => {
      switch (state.mode) {
        case 'quarter':
          return 1;
        case 'month':
        case 'year':
        default:
          return 3;
      }
    })();

    const isDateDisabled = (gridValue: string | number): boolean => {
      let dateToCheck: Date;
      switch (state.mode) {
        case 'month':
          dateToCheck = new Date(pendingYearDate, new Date(`${gridValue} + " 1, ${pendingYearDate}"`).getMonth(), 1);
          break;
        case 'quarter':
          dateToCheck = new Date(pendingYearDate, (Number(gridValue) - 1) * 3, 1);
          break;
        case 'year':
          dateToCheck = new Date(Number(gridValue), 0, 1);
          break;
        default:
          return false;
      }

      return Boolean(
        (beBefore && isAfter(dateToCheck, endOfMonth(beBefore))) ||
          (beAfter && isBefore(dateToCheck, startOfMonth(beAfter))),
      );
    };

    return (
      <div className={`grid grid-cols-${gridColumns} gap-2`}>
        {displayState.gridDisplayValues.map((gridValue) => {
          const disabled = isDateDisabled(gridValue);
          return (
            <Button
              key={`${state.mode}-key-${gridValue}`}
              fill="outline"
              className={`!w-full text-center p-2 border rounded cursor-pointer hover:bg-gray-200 !text-black !border-gray-300 !hover:text-black ${
                disabled ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              id={`${gridValue}`}
              onClick={() => !disabled && handleGridSelection(gridValue)}
              disabled={disabled}
            >
              {gridValue}
            </Button>
          );
        })}
      </div>
    );
  };

  return (
    <div className="relative">
      {label && (
        <div className="flex flex-row">
          <label htmlFor="period-picker-button" className="inline-flex">
            <Typography tag="span" size="xs" className={`${label && ' mb-1'}`}>
              {label}
            </Typography>
            {infoIcon && (
              <HoverPopover
                buttonContent={<InformationCircleIcon className="stroke-neutral-400 ml-1 mt-0.5 size-4" />}
                panelContent={
                  <div className="px-3 py-2 bg-black max-w-[250px]">
                    <Typography tag="span" size="xs" color="white">
                      {infoIcon}
                    </Typography>
                  </div>
                }
                panelClassName="shadow-md rounded-lg"
                anchor="top"
              />
            )}
            {optional && (
              <Typography className="ml-1" tag="span" size="xs" color="empty">
                {'(optional)'}
              </Typography>
            )}
          </label>
        </div>
      )}
      <button
        id="period-picker-button"
        data-testid={`period-picker-button-${id}`}
        type="button"
        className={`w-full text-left rounded-lg p-2 cursor-pointer flex items-center border ${
          border === 'hover'
            ? 'border-transparent hover:border-neutral-50'
            : 'border-neutral-50 hover:border-neutral-100'
        } ${disabled && 'border-none !cursor-default'} ${disabled && 'text-neutral-400'}`}
        onClick={togglePicker}
      >
        <CalendarIcon className="size-4 inline-block mr-2" />
        <span className="min-w-fit whitespace-nowrap" data-testid={`period-picker-display-${id}`}>
          {displayState.gridDisplay}
        </span>
        {displayPickerReset && (
          <XMarkIcon
            type="button"
            className="flex-shrink-0 size-5 text-gray-500 hover:text-gray-700"
            onClick={(e) => {
              e.stopPropagation();
              setState({ ...state, startDate: null, endDate: null, valid: false });
            }}
          />
        )}
      </button>

      {showPicker && (
        <div
          ref={pickerRef}
          className={`min-w-[280px] absolute z-30 top-full mt-2 ${pickerPositionLocation} bg-white border border-gray-300 rounded p-4 shadow-lg max-sm:min-w-[230px]`}
          id="PeriodPickerSelector"
        >
          <div className={`grid grid-cols-3 justify-around items-center ${state.mode === 'year' ? '' : 'mb-4'}`}>
            {/* 
              Year mode will display 3 years at a time. 
              The magic numbers provide the correct increments for this mode until we make them customizable.
             */}
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate - (state.mode === 'year' ? 2 : 1) < minYear ? 'hidden' : ''
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate - 1;
                  if (newYear < minYear) return minYear;
                  return newYear;
                })
              }
            >
              -
            </Button>
            <span className="text-center col-start-2" data-testid={`period-picker-pending-year-${id}`}>
              {pendingYearDate}
            </span>
            <Button
              fill="clear"
              className={`p-1 text-lg !text-black ${
                pendingYearDate + (state.mode === 'year' ? 2 : 1) > maxYear ? 'hidden' : ''
              }`}
              onClick={() =>
                setPendingYearDate((prevDate) => {
                  const newYear = prevDate + 1;
                  if (newYear > maxYear) return maxYear;
                  return newYear;
                })
              }
            >
              +
            </Button>
          </div>
          {getDisplayGrid()}
        </div>
      )}
      {state.errorMessage && !state.valid ? (
        <Typography tag="span" size="xs" color="warning" className="italic p-1">
          {state.errorMessage}
        </Typography>
      ) : null}
    </div>
  );
};

export default PeriodPicker;

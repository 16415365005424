import { Node, mergeAttributes } from '@tiptap/core';
import FormulaNodeView from './FormulaNodeView';
import { ReactNodeViewRenderer } from '@tiptap/react';

const FormulaNode = Node.create({
  name: 'formulaNode',
  group: 'inline',
  inline: true,
  selectable: true,

  addAttributes() {
    return {
      label: { default: null },
      formulaUuid: { default: null },
      timeModifier: { default: null },
      calculationModifier: { default: null },
      type: { default: null },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'formula-node',
        getAttrs: (element): Record<string, unknown> => {
          if (typeof element === 'string') return {};
          const label = element.getAttribute('data-label');
          const formulaUuid = element.getAttribute('data-formula-uuid');
          const timeModifier = element.getAttribute('data-time-modifier');
          const calculationModifier = element.getAttribute('data-calculation-modifier');
          const type = element.getAttribute('data-type');
          return { label, formulaUuid, timeModifier, calculationModifier, type };
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ['formula-node', mergeAttributes({ ...HTMLAttributes, class: 'px-8' })];
  },

  addNodeView() {
    return ReactNodeViewRenderer(FormulaNodeView);
  },
});

export default FormulaNode;

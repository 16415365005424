import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ExpenseFrequencyEnum } from '~/pages/Expenses/components/Expenses/types';

export const formatEndDateForTable = ({
  endDate,
  frequency,
}: {
  endDate: string | null;
  frequency: ExpenseFrequencyEnum;
}): string => {
  if (endDate) {
    return format(toZonedTime(endDate, 'UTC'), 'MMMM yyyy');
  }

  return frequency === ExpenseFrequencyEnum.Monthly ||
    frequency === ExpenseFrequencyEnum.Annually ||
    frequency === ExpenseFrequencyEnum.Quarterly
    ? '∞'
    : '';
};

import { z } from 'zod';
import { IFormulaSegment, IValueToAdd, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import shiftFormulaIndexes from '~/pages/FinancialModelDeprecated/utils/shiftFormulaIndexes';
import updateFormulaAndVariables from '~/pages/FinancialModelDeprecated/utils/updateFormulaAndVariables';

const handlePercentage = (value: string): string => {
  if (value.includes('%')) {
    return (parseFloat(value.replace('%', '')) / 100).toString();
  }
  return value;
};

export const handleAddOperator = ({
  value,
  setValue,
  operator,
  desiredInputIndex,
  splitValueAtIndex,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  inputRef,
}: {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  operator: string | null;
  desiredInputIndex?: number;
  splitValueAtIndex?: number;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const valuesToAdd: IValueToAdd[] = [];
  let amountToAdd = 0;
  if (value.length && operator && splitValueAtIndex !== undefined) {
    const firstValue = handlePercentage(value.substring(0, splitValueAtIndex).trim());
    const replacedFirstValue = firstValue.replace(/[$,]/g, '').replace(/[^0-9.]/g, '');
    const subsequentValue = handlePercentage(value.substring(splitValueAtIndex).trim());
    const replacedSubsequentValue = subsequentValue.replace(/[$,]/g, '').replace(/[^0-9.]/g, '');

    if (replacedFirstValue) {
      const isValidNumber = z.number().safeParse(Number(replacedFirstValue));
      valuesToAdd.push({
        type: isValidNumber.success ? 'constant' : 'invalid',
        value: replacedFirstValue,
        newIndex: inputPosition,
      });
      amountToAdd++;
    }

    valuesToAdd.push({
      type: 'operator',
      value: operator,
      newIndex: firstValue ? inputPosition + 1 : inputPosition,
    });
    amountToAdd++;

    if (replacedSubsequentValue) {
      const isValidNumber = z.number().safeParse(Number(replacedSubsequentValue));
      valuesToAdd.push({
        type: isValidNumber.success ? 'constant' : 'invalid',
        value: replacedSubsequentValue,
        newIndex: firstValue ? inputPosition + 2 : inputPosition + 1,
      });
    }
  } else if (value.length) {
    const replaced = handlePercentage(value.replace(/[$,]/g, '').replace(/[^0-9.]/g, ''));
    const isValidNumber = z.number().safeParse(Number(replaced.trim()));
    valuesToAdd.push({
      type: isValidNumber.success ? 'constant' : 'invalid',
      value: replaced.trim(),
      newIndex: inputPosition,
    });
    amountToAdd++;
  } else if (operator) {
    const indexForOperator = value.length ? inputPosition + 1 : inputPosition;
    valuesToAdd.push({
      type: 'operator',
      value: operator,
      newIndex: indexForOperator,
    });
    amountToAdd++;
  }

  const { newFormula, newVariables } = updateFormulaAndVariables({
    valuesToAdd,
    formulaCopy: [...updatedFormula],
    variablesCopy: { ...variables },
  });

  const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } = shiftFormulaIndexes({
    oldFormula: newFormula,
    oldVariables: newVariables,
  });

  setFormula(formulaWithShiftedIndexes);

  setVariables(variablesWithShiftedIndexes);
  setUpdatedFormula(formulaWithShiftedIndexes);
  const newInputIndex = desiredInputIndex ?? inputPosition + amountToAdd;
  setInputPosition(newInputIndex);
  setValue('');
  inputRef.current?.focus();
};

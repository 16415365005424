import React from 'react';

interface Props {
  orientation?: 'vertical' | 'horizontal';
  className?: string;
}
const Divider = ({ orientation = 'horizontal', className = '' }: Props): React.ReactElement => (
  <div className={className}>
    {orientation === 'horizontal' && <hr />}
    {orientation === 'vertical' && '|'}
  </div>
);

export default Divider;

import React, { ReactElement } from 'react';
import { IMessageRoleEnum, type IMessage } from '~/pages/Dashboard/entity/types';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import WhiteLogo from '~/assets/parallelLogoSVGWhite.svg';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import 'katex/dist/katex.min.css';
import rehypeKatex from 'rehype-katex';

const generateMessageList = ({ messages, id }: { messages: IMessage[]; id: string }): ReactElement[] => {
  return messages.map(({ role, content }, index) => {
    id = `${id}-${role}-${index}`;
    return (
      <div key={id} className="flex flex-row gap-2">
        <div className="flex flex-col gap-1">
          {role === IMessageRoleEnum.User ? (
            <UserCircleIcon className="w-8 h-8 fill-neutral-100 self-start" />
          ) : (
            <div className="m-[3px] w-[26px] h-[26px] bg-green-400 rounded-full flex items-center justify-center self-start">
              <img src={WhiteLogo} alt="logo" className="w-4 h-auto" />
            </div>
          )}
        </div>
        <div
          className={`mt-1 px-4 rounded-lg ${role === IMessageRoleEnum.User ? 'bg-neutral-50' : 'bg-green-25'} flex flex-col flex-grow`}
        >
          <ReactMarkdown className="markdown max-w-[400px]" remarkPlugins={[remarkMath]} rehypePlugins={[rehypeKatex]}>
            {content.replace(/\\\[|\\\]/g, '$')}
          </ReactMarkdown>
        </div>
      </div>
    );
  });
};
export default generateMessageList;

import { isSameMonth } from 'date-fns';
import { IConsolidatedGraphBody } from '~/pages/Dashboard/entity/types';
import { createMonthArrayBetweenDates } from '~/utils/dates/createMonthArrayBetweenDates';

export const getMonthHasScenarioOverrideOrActual = ({
  xMin,
  xMax,
  consolidatedGraphData,
}: {
  xMin: number;
  xMax: number;
  consolidatedGraphData: IConsolidatedGraphBody;
}): boolean[] => {
  const startMonth = new Date(xMin);
  const endMonth = new Date(xMax);
  const monthArray = createMonthArrayBetweenDates(startMonth, endMonth);
  let actualsArrActiveScenario: { date: Date; value: number }[] = [];
  let overridesArrActiveScenario: { date: Date; value: number }[] = [];
  let actualsArrWorkingModel: { date: Date; value: number }[] = [];
  let overridesArrWorkingModel: { date: Date; value: number }[] = [];

  if (consolidatedGraphData.dataActuals && 'activeScenario' in consolidatedGraphData.dataActuals) {
    actualsArrActiveScenario = consolidatedGraphData.dataActuals['activeScenario'];
  }
  if (consolidatedGraphData.dataOverrides && 'activeScenario' in consolidatedGraphData.dataOverrides) {
    overridesArrActiveScenario = consolidatedGraphData.dataOverrides['activeScenario'];
  }
  if (consolidatedGraphData.dataActuals && 'workingModel' in consolidatedGraphData.dataActuals) {
    actualsArrWorkingModel = consolidatedGraphData.dataActuals['workingModel'];
  }
  if (consolidatedGraphData.dataOverrides && 'workingModel' in consolidatedGraphData.dataOverrides) {
    overridesArrWorkingModel = consolidatedGraphData.dataOverrides['workingModel'];
  }
  return monthArray.map((month) => {
    const foundActualActiveScenario = actualsArrActiveScenario.find((item) => isSameMonth(new Date(item.date), month));
    const foundOverrideActiveScenario = overridesArrActiveScenario.find((item) =>
      isSameMonth(new Date(item.date), month),
    );
    const foundActualWorkingModel = actualsArrWorkingModel.find((item) => isSameMonth(new Date(item.date), month));
    const foundOverrideWorkingModel = overridesArrWorkingModel.find((item) => isSameMonth(new Date(item.date), month));
    const scenarioActualDiffs = Boolean(
      foundActualActiveScenario && foundActualActiveScenario.value !== foundActualWorkingModel?.value,
    );
    const scenarioOverrideDiffs = Boolean(
      foundOverrideActiveScenario && foundOverrideActiveScenario.value !== foundOverrideWorkingModel?.value,
    );
    return scenarioActualDiffs || scenarioOverrideDiffs;
  });
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILockedIndex } from '~/components/LineGraph/entity/types';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { differenceInCalendarMonths } from 'date-fns';
export interface ScenarioState {
  activeScenarioUuid: string | null;
  activeScenarioHasChanges: boolean;
  scenarioLoadingState?: 'idle' | 'creating' | 'entering' | 'merging' | 'updating' | 'viewing' | 'exiting';
  scenarioMode: 'creating' | 'editing';
  activeScenarioData: IScenario | null;
  leverChanges: {
    index: number;
    value: number;
    date: number;
  }[];
  cashBalanceLockedIndexes: ILockedIndex[];
  cashBalanceSelectedPoint: {
    index: number;
    value: number;
    date: number;
  } | null;
  selectedScenarioUuids: string[];
}

const initialState: ScenarioState = {
  activeScenarioUuid: null,
  activeScenarioHasChanges: false,
  activeScenarioData: null,
  scenarioLoadingState: 'idle',
  scenarioMode: 'creating',
  leverChanges: [],
  cashBalanceLockedIndexes: [],
  cashBalanceSelectedPoint: null,
  selectedScenarioUuids: [],
};

export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<ScenarioState>) => {
      return action.payload;
    },
    reset: () => initialState,
    updateSelectedPoint: (state, action: PayloadAction<ScenarioState['cashBalanceSelectedPoint']>) => {
      state.cashBalanceSelectedPoint = action.payload;
    },
    updateCashBalanceLockedIndexes: (state, action: PayloadAction<ScenarioState['cashBalanceLockedIndexes']>) => {
      state.cashBalanceLockedIndexes = action.payload;
    },
    addScenarioUuid: (state, action) => {
      if (!state.selectedScenarioUuids.includes(action.payload)) {
        state.selectedScenarioUuids.push(action.payload);
      } else {
        // eslint-disable-next-line no-console
        console.warn('Scenario UUID already exists in selectedScenarioUuids');
      }
    },
    removeScenarioUuid: (state, action) => {
      state.selectedScenarioUuids = state.selectedScenarioUuids.filter((uuid) => uuid !== action.payload);
    },
    updateActiveScenarioHasChanges: (state, action: PayloadAction<boolean>) => {
      state.activeScenarioHasChanges = action.payload;
    },
    updateActiveScenarioData: (state, action: PayloadAction<ScenarioState['activeScenarioData']>) => {
      state.activeScenarioData = action.payload;
    },
    updateScenarioLoadingState: (state, action: PayloadAction<ScenarioState['scenarioLoadingState']>) => {
      state.scenarioLoadingState = action.payload;
    },
    updateScenarioMode: (state, action: PayloadAction<ScenarioState['scenarioMode']>) => {
      state.scenarioMode = action.payload;
    },
    reassignSelectedIndexesForNewDate: (state, action: PayloadAction<Date>) => {
      const newDate = action.payload.getTime();

      state.leverChanges = state.leverChanges.map((item) => {
        if (state.cashBalanceLockedIndexes.some((locked) => locked.index === item.index)) {
          state.cashBalanceLockedIndexes = state.cashBalanceLockedIndexes.map((locked) => {
            if (locked.index === item.index) {
              return {
                ...locked,
                index: differenceInCalendarMonths(item.date, newDate),
              };
            }
            return locked;
          });
        }
        return {
          ...item,
          index: differenceInCalendarMonths(item.date, newDate),
        };
      });
      if (state.cashBalanceSelectedPoint) {
        const newSelectedPointIndex = differenceInCalendarMonths(state.cashBalanceSelectedPoint.date, newDate);

        state.cashBalanceLockedIndexes = state.cashBalanceLockedIndexes.map((locked) => {
          if (locked.index === state.cashBalanceSelectedPoint!.index) {
            return {
              ...locked,
              index: newSelectedPointIndex,
            };
          }
          return locked;
        });

        state.cashBalanceSelectedPoint = {
          ...state.cashBalanceSelectedPoint,
          index: newSelectedPointIndex,
        };
      }
    },
  },
});

const {
  update,
  reset,
  updateSelectedPoint,
  updateCashBalanceLockedIndexes,
  addScenarioUuid,
  removeScenarioUuid,
  updateActiveScenarioHasChanges,
  updateActiveScenarioData,
  updateScenarioLoadingState,
  updateScenarioMode,
  reassignSelectedIndexesForNewDate,
} = scenarioSlice.actions;
export {
  update,
  reset,
  updateSelectedPoint,
  updateCashBalanceLockedIndexes,
  addScenarioUuid,
  removeScenarioUuid,
  updateActiveScenarioHasChanges,
  updateActiveScenarioData,
  updateScenarioLoadingState,
  reassignSelectedIndexesForNewDate,
  updateScenarioMode,
};
export default scenarioSlice.reducer;

import { store } from '~/store';
import request from '~/utils/request';
import waitForStoreRehydration from '~/utils/waitForStoreRehydration';

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

const combineFormulasToFetch = ({
  metrics,
  graphs,
  levers,
}: {
  metrics: string[];
  graphs: string[];
  levers: string[];
}): string[] => {
  const combinedSet = new Set([...metrics, ...graphs, ...levers]);
  combinedSet.delete('Runway');
  return Array.from(combinedSet);
};

export default async (): Promise<unknown> => {
  await waitForStoreRehydration(store);
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const { activeScenarioUuid, selectedScenarioUuids } = store.getState().scenario;
  const {
    uuid: organizationUuid,
    configuration: { dashboardConfiguration },
  } = store.getState().organization;

  const formulasToFetch = combineFormulasToFetch(dashboardConfiguration);
  const additionalReports = dashboardConfiguration.metrics.includes('Runway') ? ['Runway'] : [];

  const promises = [
    await request({
      url: `/integrations/sources`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Organization-Uuid': organizationUuid,
      },
    }),
    await request({
      url: `/integrations`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Organization-Uuid': organizationUuid,
      },
    }),
    await request({
      url: `/reports/consolidate`,
      method: 'GET',
      params: {
        scenarioUuid: activeScenarioUuid,
        formulaUuids: formulasToFetch,
        scenariosToInclude: selectedScenarioUuids,
        startDate,
        endDate,
        additionalReports,
      },
      headers: { 'Organization-Uuid': organizationUuid },
    }),
  ];

  const [integrationSources, connectedIntegrations, reports]: IAPIResponse[] = await Promise.all(promises);

  return {
    integrations: {
      sources: integrationSources.data.data,
      connected: connectedIntegrations.data.data,
    },
    reports: reports.data.data,
  };
};

import React from 'react';
import { CellContext } from '@tanstack/react-table';
import { IContractTablularTableRow } from '../entity/types';
import formatCurrency from '~/utils/formatCurrency';
import Typography from '~/components/Typography';
import { isAfter, isBefore, isSameMonth } from 'date-fns';

const ContractTabularTableMonthCell = ({
  info,
}: {
  info: CellContext<
    IContractTablularTableRow,
    {
      value: number | null;
      contractStartDate: Date;
      contractEndDate: Date | null;
      contractInvoiceDate: Date;
      mode: 'collections' | 'revenue';
    }
  >;
}): React.ReactElement => {
  const { value, contractStartDate, contractEndDate, contractInvoiceDate, mode } = info.getValue();
  const startDateToUse = mode === 'collections' ? contractInvoiceDate : contractStartDate;
  const date = new Date(info.column.id);
  const isBeforeContractStartDate = isBefore(date, startDateToUse) && !isSameMonth(date, startDateToUse);
  const isAfterContractEndDate =
    contractEndDate && isAfter(date, contractEndDate) && !isSameMonth(date, contractEndDate);

  return (
    <div className="text-right w-[159px] px-4">
      <Typography color={isBeforeContractStartDate || isAfterContractEndDate ? 'disabled' : undefined}>
        {typeof value === 'number' && !isBeforeContractStartDate && !isAfterContractEndDate
          ? formatCurrency(value)
          : '-'}
      </Typography>
    </div>
  );
};

export default ContractTabularTableMonthCell;

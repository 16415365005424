import React, { useMemo } from 'react';
import { ISelectionMode, useTimelineContext } from './TimelineContext';
import preciseDifferenceInMonths from './utils/preciseDifferenceInMonths';
import { SPACE_PER_MONTH } from './utils/constants';
import { addDays, format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import Typography from '../Typography';

interface IProps {
  id: string;
  startDate: Date;
  endDate?: Date;
  children: React.ReactNode | React.ReactNode[];
}

const TimelineNode = ({ id, startDate, endDate, children }: IProps): React.ReactElement => {
  const context = useTimelineContext();

  const leftOffset = useMemo(
    (): number =>
      preciseDifferenceInMonths({
        startDate: context.containerStartDate,
        endDate: startDate,
      }),
    [context, startDate],
  );

  const lengthOfNode = useMemo(
    (): number =>
      preciseDifferenceInMonths({
        // overriding the start date so that the node can be dragged and be updated on drag complete
        startDate,
        // Add a day to the end date so that it can look right by butting up against the correct date
        endDate: endDate ? addDays(endDate, 1) : addDays(context.containerEndDate, 1),
      }),
    [context.containerEndDate, startDate, endDate],
  );

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.stopPropagation();
    e.preventDefault();
    if (e.metaKey || e.ctrlKey) {
      context.toggleSelectedNode({ id, mode: ISelectionMode.NONCONTIGUOUS });
    } else if (e.shiftKey) {
      context.toggleSelectedNode({ id, mode: ISelectionMode.CONTIGUOUS });
    } else {
      context.toggleSelectedNode({ id, mode: ISelectionMode.SINGLE });
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLButtonElement>): void => {
    // User should not be able to initiate drag from inside the toggle
    const toggleElement = document.querySelector('.position-toggle');
    if (toggleElement) {
      const toggleRect = toggleElement.getBoundingClientRect();
      const mouseX = event.clientX;
      const mouseY = event.clientY;
      const isMouseInToggle =
        mouseX >= toggleRect.left &&
        mouseX <= toggleRect.right &&
        mouseY >= toggleRect.top &&
        mouseY <= toggleRect.bottom;

      if (isMouseInToggle) {
        event.stopPropagation();
        event.preventDefault();
        return;
      }
    }

    if (!context.selection.selected.includes(id)) {
      context.toggleSelectedNode({ id, mode: ISelectionMode.SINGLE });
    }
    context.handleDragStart({ event });
  };

  const isSelected = context.selection.selected.includes(id);

  let draggingStartDate = startDate;
  let draggingEndDate = endDate;

  if (context.draggingDaysToAdd > 0 && isSelected) {
    draggingStartDate = addDays(startDate, context.draggingDaysToAdd);
    draggingEndDate = endDate ? addDays(endDate, context.draggingDaysToAdd) : undefined;
  }
  return (
    <div
      className="timeline-node relative ml-3 z-10 flex group items-center"
      style={{
        marginLeft: `${leftOffset * SPACE_PER_MONTH}rem`,
        width: `${lengthOfNode * SPACE_PER_MONTH}rem`,
      }}
    >
      <div className="absolute -left-14">
        <Typography size="xs" color="disabled">
          {format(toZonedTime(draggingStartDate, 'UTC'), 'MMM d')}
        </Typography>
      </div>
      <button
        data-testid="timeline-node"
        className={`focus:outline-none bg-white rounded-lg border px-1 py-2 w-full overflow-clip cursor-pointer items-center${!endDate ? ' rounded-r-none border-r-0' : ''}${isSelected ? ' !border-green-200 !bg-green-15' : ''}`}
        draggable
        onClick={handleClick}
        onDragStart={handleDragStart} // Updated to use the new handleDragStart
        onDrag={(event) => context.handleDrag({ event, startDate })}
        onDragEnd={context.handleDragEnd}
      >
        {children}
      </button>
      {draggingEndDate && (
        <div className="absolute -right-14">
          <Typography size="xs" color="disabled">
            {format(toZonedTime(draggingEndDate, 'UTC'), 'MMM d')}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TimelineNode;

import React, { useState, useContext, useEffect, useMemo } from 'react';
import LineGraph from '~/components/LineGraph';
import { IDataArrayDictionary, ILineProps, ILockedIndex } from '~/components/LineGraph/entity/types';
import Segment from '~/components/SegmentedControl';
import Typography from '~/components/Typography';
import LockedIcon from './graphIcons/LockedIcon';
import RemoveLockIcon from './graphIcons/RemoveLockIcon';
import UnlockedIcon from './graphIcons/UnlockedIcon';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import useQueryParams from '~/utils/hooks/useQueryParams';
import ExpensesGraphTooltip from './ExpensesGraphTooltip';
import { graphDataByType } from './graphDataByType';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import date from '~/utils/dates/date';
import { camelCaseToNormal } from '~/utils/text/camelCaseToNormal';
import HoverPopover from '~/components/HoverPopover';
import TogglesAreDeactivated from './TogglesAreDeactivated';
import Skeleton from 'react-loading-skeleton';

const ExpensesGraph = (): React.ReactElement | null => {
  const {
    reports,
    expenses,
    positions,
    setLockedDate,
    setSearchByName,
    filteredExpensesReportLoading,
    categories,
    types,
    searchState,
  } = useContext(ExpensesPageContext);
  const { defaultGraphStartDate, defaultGraphEndDate } = useSelector((state: State) => state.user.preferences);
  const startDate = useMemo(() => {
    return date(defaultGraphStartDate);
  }, [defaultGraphStartDate]);
  const endDate = useMemo(() => {
    return date(defaultGraphEndDate);
  }, [defaultGraphEndDate]);
  const [lockedIndexes, setLockedIndexes] = useState<ILockedIndex[]>([]);
  const [queryParams, setQueryParams] = useQueryParams();
  const baseLines = [
    {
      dataKey: 'report0',
      stroke: '#64755C',
      isDashed: false,
    },
  ];
  const typeLines = [
    { dataKey: 'oneTime', stroke: '#7F9376' },
    { dataKey: 'monthly', stroke: '#8A6190' },
    { dataKey: 'quarterly', stroke: '#EBA61E' },
    { dataKey: 'annual', stroke: '#5A8496' },
    { dataKey: 'onHire', stroke: '#B34255' },
  ];
  const categoryLines = [
    { dataKey: 'software', stroke: '#8A6190' },
    { dataKey: 'other', stroke: '#EBA61E' },
    { dataKey: 'peopleAndFacilities', stroke: '#5A8496' },
    { dataKey: 'cogs', stroke: '#B34255' },
    { dataKey: 'marketing', stroke: '#64755C' },
  ];
  const dataKeys = ['date', 'report0'];
  const [segmentValue, setSegmentValue] = useState<string>('total');
  const [typesGraphData, setTypesGraphData] = useState<IDataArrayDictionary[]>([]);
  const [categoryGraphData, setCategoryGraphData] = useState<IDataArrayDictionary[]>([]);

  useEffect(() => {
    setSearchByName(searchState.value);
  }, [searchState.value]);

  useEffect(() => {
    const currentParams = Object.fromEntries(queryParams);
    if (categories.selected?.value) {
      currentParams.tagFilter = categories.selected.value;
      setSegmentValue('total');
    }

    if (types.selected?.value) {
      currentParams.frequencyFilter = types.selected.value;
      setSegmentValue('total');
    }

    setQueryParams(currentParams);
  }, [categories.selected, types.selected]);

  useEffect(() => {
    const graphData = graphDataByType({
      expenses,
      startDate,
      endDate,
      positions,
    });
    setTypesGraphData(graphData.typeData);
    setCategoryGraphData(graphData.categoryData);
  }, [expenses, startDate, endDate, positions]);

  const handleClick = (index: number): void => {
    if (reports.filteredExpenses?.data[index].date && index !== lockedIndexes[0]?.index) {
      setLockedDate(new Date(reports.filteredExpenses.data[index].date));
      setLockedIndexes([{ index, icon: <LockedIcon /> }]);
    } else {
      setLockedDate(undefined);
      setLockedIndexes([]);
    }
  };

  const getGraphData = (): IDataArrayDictionary[] => {
    if (reports.filteredExpenses) {
      if (segmentValue === 'categories') {
        return categoryGraphData;
      }
      if (segmentValue === 'types') {
        return typesGraphData;
      }

      return reports.filteredExpenses.data;
    }

    return [];
  };

  const getGraphLines = (): ILineProps[] => {
    if (segmentValue === 'categories') {
      return categoryLines;
    }
    if (segmentValue === 'types') {
      return typeLines;
    }
    return baseLines;
  };

  const getGraphHeader = (): React.ReactElement | null => {
    if (segmentValue === 'categories') {
      return (
        <React.Fragment>
          {categoryLines
            .filter((line) => line.dataKey !== 'date')
            .map((line) => {
              return (
                <div className="flex items-center gap-2" key={line.dataKey}>
                  <div className="size-2 rounded-full" style={{ backgroundColor: line.stroke }} />
                  <Typography>{camelCaseToNormal(line.dataKey)}</Typography>
                </div>
              );
            })}
        </React.Fragment>
      );
    }
    if (segmentValue === 'types') {
      return (
        <React.Fragment>
          {typeLines
            .filter((line) => line.dataKey !== 'date')
            .map((line) => {
              return (
                <div className="flex items-center gap-2" key={line.dataKey}>
                  <div className="size-2 rounded-full" style={{ backgroundColor: line.stroke }} />
                  <Typography>{camelCaseToNormal(line.dataKey)}</Typography>
                </div>
              );
            })}
        </React.Fragment>
      );
    }
    return null;
  };

  const graphTitle = (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center justify-start gap-3">
        <Typography size="sm">💰 Expenses</Typography>
        {segmentValue === 'total' ? null : getGraphHeader()}
      </div>
      <div>
        <HoverPopover
          buttonContent={
            <Segment
              name="expenseBreakdown"
              segments={[
                { label: 'Total', value: 'total' },
                { label: 'Categories', value: 'categories' },
                { label: 'Types', value: 'types' },
              ]}
              value={segmentValue}
              setValue={setSegmentValue}
              disabled={
                !!(types.selected?.value && types.selected.value !== 'all') ||
                !!(categories.selected?.value && categories.selected.value !== 'all')
              }
            />
          }
          panelContent={
            !!(types.selected?.value && types.selected.value !== 'all') ||
            !!(categories.selected?.value && categories.selected.value !== 'all') ? (
              <TogglesAreDeactivated />
            ) : null
          }
          panelClassName="shadow-md"
        />
      </div>
    </div>
  );

  if (filteredExpensesReportLoading) {
    return (
      <div className="px-10 mt-10 mb-4">
        <Skeleton className="h-[250px] w-full" baseColor="#F8F9F6" />
      </div>
    );
  }

  if (expenses.length === 0) {
    return null;
  }

  if (reports.filteredExpenses) {
    return (
      <div className="px-10 mb-4">
        <div className="h-[250px]">
          <LineGraph
            data={getGraphData()}
            card={{
              title: graphTitle,
            }}
            lines={getGraphLines()}
            dataKeys={dataKeys}
            hoverCursorIcon={<UnlockedIcon />}
            hoverLockedIndexIcon={<RemoveLockIcon />}
            lockedIndexes={lockedIndexes}
            onClick={handleClick}
            id="expenses-page"
            customTooltip={segmentValue === 'total' ? undefined : <ExpensesGraphTooltip lines={getGraphLines()} />}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default ExpensesGraph;

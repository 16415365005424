import React, { ReactElement } from 'react';
import Button from '~/components/Button';
import { IDatePickerState } from '~/components/DatePicker/useDatePicker';
import DatePicker from '~/components/DatePicker';
import { TrashIcon } from '@heroicons/react/24/outline';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';

interface Props {
  addNewPayRate: boolean;
  setAddNewPayRate: React.Dispatch<React.SetStateAction<boolean>>;
  effectiveDate: IDatePickerState;
  setEffectiveDate: React.Dispatch<React.SetStateAction<IDatePickerState>>;
  payRate: Types.InputState;
  setPayRate: React.Dispatch<React.SetStateAction<Types.InputState>>;
  onCancel: () => void;
}

const NewPayForm = ({
  addNewPayRate,
  setAddNewPayRate,
  effectiveDate,
  setEffectiveDate,
  payRate,
  setPayRate,
  onCancel,
}: Props): ReactElement => {
  return addNewPayRate ? (
    <div className="flex flex-row gap-2 items-start my-1">
      <div className="w-[46%]">
        <CurrencyInput id="payRate" state={payRate} setState={setPayRate} required />
      </div>
      <div className="w-[46%]">
        <DatePicker id="effectiveDate" state={effectiveDate} setState={setEffectiveDate} required />
      </div>
      <TrashIcon
        onClick={onCancel}
        className="fixed right-6 mt-2.5 size-5 text-neutral-100 cursor-pointer hover:text-neutral-400"
      />
    </div>
  ) : (
    <Button
      id="add-pay-change"
      onClick={() => {
        setAddNewPayRate(true);
      }}
      fill="clear"
      className="!w-fit !px-0 my-1"
    >
      + Add Change
    </Button>
  );
};

export default NewPayForm;

import React from 'react';
import { createPortal } from 'react-dom';

const ErrorPopover = ({
  visible,
  formulaName,
  right,
  top,
}: {
  visible: boolean;
  formulaName: string;
  right: number;
  top: number;
}): React.ReactElement => {
  return createPortal(
    <div
      style={{
        position: 'absolute',
        left: right + 8,
        top: top + 4,
      }}
      className={`bg-red text-white text-sm rounded whitespace-nowrap shadow-md px-3 py-2 flex items-center z-30 ${
        visible ? 'visible' : 'invisible'
      }`}
    >
      {`"${formulaName}" already exists`}
    </div>,
    document.body,
  );
};

export default ErrorPopover;

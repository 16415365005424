import { IAPIResponse } from '~/utils/types';
import { IPositionDetails } from '../entity/types';
import request from '~/utils/request';
import { ZPositionDetails } from '../entity/schemas';

export const fetchSpecificPositions = async ({
  organizationUuid,
  positionUuids,
  scenarioUuid,
}: {
  organizationUuid: string;
  positionUuids: string[];
  scenarioUuid?: string;
}): Promise<IPositionDetails[]> => {
  const response = (await request({
    url: `/organizations/${organizationUuid}/positions`,
    method: 'GET',
    params: {
      scenarioUuid,
      positionUuids,
      includeFullyBurdened: true,
    },
  })) as IAPIResponse<IPositionDetails>;

  const parsedData = ZPositionDetails.array().parse(response.data.data);

  return parsedData;
};

import { IRoundingInstructions } from '~/pages/FinancialModel/ModelBuilderContext';
import { IRoundDirectionEnum } from '~/services/parallel/formulas.types';

export const roundCalculatedValue = ({
  value,
  roundingInstructions,
  maxDecimalPlaces,
}: {
  value: number;
  roundingInstructions?: IRoundingInstructions | null;
  maxDecimalPlaces?: number;
}): number => {
  if (!roundingInstructions || roundingInstructions.precision === 0) {
    if (typeof maxDecimalPlaces === 'number') {
      return parseFloat(value.toFixed(maxDecimalPlaces));
    }
    return value;
  }

  const factor = 1 / Math.abs(roundingInstructions.precision);
  let result;

  if (roundingInstructions.direction === IRoundDirectionEnum.Up) {
    result = Math.ceil(value * factor) / factor;
  } else if (roundingInstructions.direction === IRoundDirectionEnum.Down) {
    result = Math.floor(value * factor) / factor;
  } else {
    result = Math.round(value * factor) / factor;
  }

  return result;
};

import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';

export const deleteGoal = async ({
  organizationUuid,
  goalUuid,
}: {
  organizationUuid: string;
  goalUuid: string;
}): Promise<IAPIResponse> => {
  const response = await request({
    url: `/goals/${goalUuid}`,
    method: 'DELETE',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });
  return response as IAPIResponse;
};

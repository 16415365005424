import Lottie from 'lottie-react';
import React from 'react';
import { generateLogoAnimationJson } from './generateLogoAnimationJson';
import { hexToRgb } from '~/utils/hexToRgb';

const LoadingSpinner = ({
  size = 'size-[24px]',
  color = '#5C5C5C',
  isStatic = false,
}: {
  size?: string;
  color?: string;
  isStatic?: boolean;
}): React.ReactElement => {
  const rgb = hexToRgb(color);
  return <Lottie animationData={generateLogoAnimationJson(rgb)} className={`${size} m-auto`} autoplay={!isStatic} />;
};

export default LoadingSpinner;

import React, { useContext, useState } from 'react';
import { IExpense } from '../types';
import Typography from '~/components/Typography';
import formatPercent from '~/utils/formatPercent';
import formatCurrency from '~/utils/formatCurrency';
import Button from '~/components/Button';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import { convertDepartmentUuidsToDisplayName } from '~/pages/Expenses/utils/convertDepartmentUuidsToDisplayName';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Divider from '~/components/Divider';
import createExpense from '../createExpense/createExpense';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { parseISO } from 'date-fns';
import request from '~/utils/request';
import { StatusCodes } from 'http-status-codes';

const TYPE_DISPLAY_MAP = {
  setCost: 'Set Cost',
  headcountFixed: 'Amount per Employee',
  headcountPercentCompensation: 'Percent of Salary',
  custom: 'Custom',
};

const FREQUENCY_DISPLAY_MAP = {
  monthly: 'Monthly',
  oneTime: 'One Time',
  onHire: 'On Hire',
  quarterly: 'Quarterly',
  annually: 'Annually',
};

const ProposedExpenseCard = ({ expense }: { expense: IExpense }): React.ReactNode => {
  const {
    departmentDict,
    setExpenseUuid,
    setExpenseModal,
    setDesiredCreationStatus,
    expenseIntegrationMappings,
    proposedExpenses,
    setProposedExpenses,
  } = useContext(ExpensesPageContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [displayDataSources, setDisplayDataSources] = useState<boolean>(false);
  const getAcronym = (name: string): string => {
    const words = name.split(' ');
    if (words.length > 1) {
      return `${words[0][0]}${words[words.length - 1][0]}`.toUpperCase();
    }
    return name[0].toUpperCase();
  };

  const handleDeleteExpense = async (): Promise<void> => {
    const response = await request({
      method: 'DELETE',
      url: `/expenses/${expense.expenseUuid}`,
      headers: { 'Organization-Uuid': organizationUuid },
    });

    if (response.status === StatusCodes.NO_CONTENT) {
      setProposedExpenses(proposedExpenses.filter((proposedExpense) => proposedExpense.uuid !== expense.uuid));
    }
  };

  return (
    <div className="flex flex-col border border-neutral-50 rounded-lg">
      <div className="flex flex-row w-full px-6 py-4">
        <div className="max-h-[46px] max-w-[46px] min-h-[46px] min-w-[46px] text-[16.5px] text-neutral-200 bg-neutral-15 border border-neutral-50 rounded-full flex items-center justify-center mr-4">
          {getAcronym(expense.name)}
        </div>
        <div className="flex flex-row justify-between w-full">
          <div className="flex flex-col">
            <Typography weight="semibold">{expense.name}</Typography>
            <Typography color="secondary">{TYPE_DISPLAY_MAP[expense.context.driver]}</Typography>
          </div>
          <div className="flex flex-col">
            <Typography color="empty">Amount</Typography>
            {expense.context.amount ? (
              <Typography>
                {expense.context.driver === 'headcountPercentCompensation'
                  ? formatPercent({ value: expense.context.amount })
                  : formatCurrency(expense.context.amount)}
              </Typography>
            ) : (
              <Typography>-</Typography>
            )}
          </div>
          <div className="flex flex-col">
            <Typography color="empty">Frequency</Typography>
            <Typography>{FREQUENCY_DISPLAY_MAP[expense.context.frequency]}</Typography>
          </div>
          <div className="flex flex-col">
            <Typography color="empty">Departments</Typography>
            <Typography>
              {expense.context.departments[0] === 'ALL'
                ? 'All'
                : convertDepartmentUuidsToDisplayName({
                    departmentUuids: expense.context.departments,
                    departmentDict,
                  })}
            </Typography>
          </div>
          <div className="flex flex-row gap-4">
            {!!expense.dataSourceUuids?.length && (
              <div className="flex justify-center items-center">
                <div
                  onClick={() => setDisplayDataSources(!displayDataSources)}
                  className="flex flex-row cursor-pointer hover:bg-neutral-15 hover:border-neutral-75 items-center border border-neutral-50 justify-center rounded-lg p-2 gap-1"
                >
                  <img src={quickbooksIcon} className="size-5" />
                  <Typography color="lightGray" size="xs">
                    {expense.dataSourceUuids.length}
                  </Typography>
                  {displayDataSources ? (
                    <ChevronUpIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  ) : (
                    <ChevronDownIcon className="size-4 stroke-neutral-400 stroke-[1.5]" />
                  )}
                </div>
              </div>
            )}
            <EllipsisDropdown
              className="flex items-center justify-center"
              options={[
                {
                  label: 'Edit',
                  onClick: (): void => {
                    setExpenseUuid({
                      uuid: expense.uuid,
                      expenseUuid: expense.expenseUuid,
                      type: 'edit',
                    });
                    setDesiredCreationStatus('proposed');
                    setExpenseModal(true);
                  },
                  id: `edit-proposed-expense-${expense.name}`,
                },
                {
                  label: 'Delete',
                  className: 'text-red-400',
                  onClick: handleDeleteExpense,
                },
              ]}
            />
          </div>
        </div>
      </div>
      {displayDataSources && !!expense.dataSourceUuids?.length && (
        <div className="flex flex-col bg-neutral-15">
          <Divider />
          <div className="flex flex-col px-6 py-3">
            {expense.dataSourceUuids.map((dataSourceUuid, index) => {
              const dataSource = expenseIntegrationMappings.find((mapping) => mapping.uuid === dataSourceUuid);
              const isLastItem = index === (expense.dataSourceUuids?.length ?? 0) - 1;
              return (
                <div className="flex flex-col" key={dataSourceUuid}>
                  <div className="flex flex-row w-full justify-between">
                    <Typography weight="light" color="empty" className="italic">
                      {dataSource?.name}
                    </Typography>
                    <Button
                      onClick={() =>
                        createExpense({
                          organizationUuid,
                          scenarioUuid: null,
                          type: expense.context.driver,
                          name: expense.name,
                          category: expense.context.tag,
                          frequency: expense.context.frequency,
                          amount: ((expense.context.amount ?? 0) / 100).toString(),
                          departments: expense.context.departments,
                          startDate: parseISO(expense.context.startDate),
                          endDate: expense.context.endDate ? parseISO(expense.context.endDate) : null,
                          updatedFormula: [],
                          variables: {},
                          dataSourceUuids: [...(expense.dataSourceUuids ?? [])].filter(
                            (uuid) => uuid !== dataSourceUuid,
                          ),
                          successCallback: ({ createdExpense }: { createdExpense: IExpense }) => {
                            const updatedProposedExpenses = [...proposedExpenses];
                            const proposedExpenseIndex = updatedProposedExpenses.findIndex(
                              (proposedExpense) => proposedExpense.uuid === expense.uuid,
                            );
                            updatedProposedExpenses[proposedExpenseIndex] = createdExpense;
                            setProposedExpenses(updatedProposedExpenses);
                          },
                          failureCallback: () => {},
                          validateFormState: () => true,
                          formulaList: [],
                          expenseUuid: {
                            uuid: expense.uuid,
                            expenseUuid: expense.expenseUuid,
                            type: 'edit',
                          },
                          desiredCreationStatus: 'proposed',
                        })
                      }
                      fill="disabledToDestructiveClear"
                      className="!w-fit !px-0 !py-0"
                    >
                      Remove
                    </Button>
                  </div>
                  {!isLastItem && <Divider className="my-2" />}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProposedExpenseCard;

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSSProperties, ReactElement } from 'react';
import { CSS } from '@dnd-kit/utilities';

export const SortableItem = ({
  id,
  children,
  className,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
}): ReactElement => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id.toString() });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 0, // Set a higher z-index when dragging
  };
  return (
    <tr ref={setNodeRef} style={style} {...attributes} {...listeners} className={className}>
      {children}
    </tr>
  );
};

import React from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';

const TotalCostColumnHeader = (): React.ReactNode => {
  const buttonContent = (
    <div className="flex items-center justify-start gap-2">
      <div>TOTAL COST</div>
      <InformationCircleIcon className="size-4" />
    </div>
  );

  const panelContent = (
    <div className="px-5 pt-2.5 pb-3 bg-black w-[308px] flex flex-col justify-start items-start">
      <div>
        <Typography color="white" weight="bold" size="xs">
          Total Cost / Fully Burdened Cost
        </Typography>
      </div>
      <div>
        <Typography color="white" size="xs">
          The total cost of an employee includes their pay rate, benefits, and any other costs associated with their
          employment
        </Typography>
      </div>
    </div>
  );

  return (
    <HoverPopover buttonContent={buttonContent} panelContent={panelContent} panelClassName="shadow-lg rounded-xl" />
  );
};

export default TotalCostColumnHeader;

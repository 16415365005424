import React, { useEffect, useMemo, useState } from 'react';
import { IFormula, IFormulaActual, IFormulaOverride } from '../entity/types';
import isEqual from 'lodash.isequal';
import { useFinancialModelData } from '../useFinancialModelData';
import type { IIntegrationMapping } from '~/utils/schemas/integrations';
import { IPrebuiltComponentType } from '../components/PrebuiltComponents/PrebuiltOptions';
import { useInput } from '~/components/Input';

interface IFinancialModelContext {
  dragMode: {
    isDragging: boolean;
    isGroup: boolean;
  };
  setDragMode: React.Dispatch<
    React.SetStateAction<{
      isDragging: boolean;
      isGroup: boolean;
    }>
  >;
  dataSources: IIntegrationMapping[];
  overridesList: Record<string, IFormulaOverride[]>;
  setOverridesList: React.Dispatch<React.SetStateAction<Record<string, IFormulaOverride[]>>>;
  parsedFormulas: {
    list: IFormula[];
    sorting: { name: string; sortOrder: string[] }[];
    monthsBetweenDates: Date[];
  };
  overridesHaveChanges: boolean;
  actualsList: Record<string, IFormulaActual[]>;
  setActualsList: React.Dispatch<React.SetStateAction<Record<string, IFormulaActual[]>>>;
  actualsHaveChanges: boolean;
  selectedMonthCell: {
    colIndex?: number;
    rowIndex?: number;
    maxCol: number;
    minCol: number;
    maxRow: number;
    minRow: number;
  };
  setSelectedMonthCell: React.Dispatch<
    React.SetStateAction<{
      colIndex?: number;
      rowIndex?: number;
      maxCol: number;
      minCol: number;
      maxRow: number;
      minRow: number;
    }>
  >;
  setParsedFormulas: React.Dispatch<
    React.SetStateAction<{
      list: IFormula[];
      sorting: { name: string; sortOrder: string[] }[];
      monthsBetweenDates: Date[];
    }>
  >;
  downloadableModel: string | null;
  setDownloadableModel: React.Dispatch<React.SetStateAction<string | null>>;
  monthsBetweenDates: Date[];
  loading: boolean;
  revalidate: () => void;
  revalidateLoading: boolean;
  prebuiltComponentModalState: {
    isOpen: boolean;
    display: IPrebuiltComponentType;
  };
  setPrebuiltComponentModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      display: IPrebuiltComponentType;
    }>
  >;
  search: Types.InputState;
  setSearch: React.Dispatch<React.SetStateAction<Types.InputState>>;
}

export const FinancialModelContext = React.createContext({} as IFinancialModelContext);

export const FinancialModelProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const { data, loading, revalidate, revalidateLoading } = useFinancialModelData();
  const [parsedFormulas, setParsedFormulas] = useState({
    monthsBetweenDates: data.monthsBetweenDates,
    list: data.list,
    sorting: data.sorting,
  });

  useEffect(() => {
    setParsedFormulas({
      monthsBetweenDates: data.monthsBetweenDates,
      list: data.list,
      sorting: data.sorting,
    });
  }, [data]);

  const [search, setSearch] = useInput({
    value: '',
    validation: /.*/,
  });

  const [overridesList, setOverridesList] = useState<Record<string, IFormulaOverride[]>>({});
  const [actualsList, setActualsList] = useState<Record<string, IFormulaActual[]>>({});
  const [prebuiltComponentModalState, setPrebuiltComponentModalState] = useState<{
    isOpen: boolean;
    display: IPrebuiltComponentType;
  }>({
    isOpen: false,
    display: IPrebuiltComponentType.Options,
  });
  const [selectedMonthCell, setSelectedMonthCell] = useState<{
    colIndex?: number;
    rowIndex?: number;
    maxCol: number;
    minCol: number;
    maxRow: number;
    minRow: number;
  }>({
    colIndex: undefined,
    rowIndex: undefined,
    maxCol: 0,
    minCol: 0,
    maxRow: 0,
    minRow: 0,
  });
  const [downloadableModel, setDownloadableModel] = useState<string | null>(null);

  const [dragMode, setDragMode] = useState({
    isDragging: false,
    isGroup: false,
  });

  useEffect(() => {
    const initialOverridesList = parsedFormulas.list.reduce(
      (acc, formula) => {
        acc[formula.formulaUuid] = formula.overrides ?? [];
        return acc;
      },
      {} as Record<string, IFormulaOverride[]>,
    );
    const initialActualsList = parsedFormulas.list.reduce(
      (acc, formula) => {
        acc[formula.formulaUuid] = formula.actuals ?? [];
        return acc;
      },
      {} as Record<string, IFormulaActual[]>,
    );
    setOverridesList(initialOverridesList);
    setActualsList(initialActualsList);
  }, [parsedFormulas]);

  const flattenedOverridesList = useMemo(
    () =>
      Object.values(overridesList).reduce(
        (acc, overrides) => [...acc, ...overrides],
        [] as { date: string; value: number }[],
      ),
    [overridesList],
  );

  const flattenedParsedOverridesList = useMemo(
    () =>
      parsedFormulas.list.reduce(
        (acc, formula) => [
          ...acc,
          ...(formula.overrides ?? []).map((override) => ({
            date: override.date,
            value: override.value,
          })),
        ],
        [] as { date: string; value: number }[],
      ),
    [parsedFormulas],
  );

  const flattenedActualsList = useMemo(
    () =>
      Object.values(actualsList).reduce(
        (acc, actuals) => [...acc, ...actuals],
        [] as { date: string; value: number }[],
      ),
    [actualsList],
  );

  const flattenedParsedActualsList = useMemo(
    () =>
      parsedFormulas.list.reduce(
        (acc, formula) => [
          ...acc,
          ...(formula.actuals ?? []).map((actual) => ({
            date: actual.date,
            value: actual.value,
          })),
        ],
        [] as { date: string; value: number }[],
      ),
    [parsedFormulas],
  );

  const actualsHaveChanges = useMemo(() => {
    if (flattenedActualsList.length || flattenedParsedActualsList.length) {
      return !isEqual(flattenedActualsList, flattenedParsedActualsList);
    } else {
      return false;
    }
  }, [flattenedActualsList, flattenedParsedActualsList, parsedFormulas]);

  const overridesHaveChanges = useMemo(() => {
    if (flattenedOverridesList.length || flattenedParsedOverridesList.length) {
      return !isEqual(flattenedOverridesList, flattenedParsedOverridesList);
    } else {
      return false;
    }
  }, [flattenedOverridesList, flattenedParsedOverridesList, parsedFormulas]);

  return (
    <FinancialModelContext.Provider
      value={{
        dataSources: data.dataSources,
        overridesList,
        setOverridesList,
        parsedFormulas,
        overridesHaveChanges,
        actualsList,
        setActualsList,
        actualsHaveChanges,
        selectedMonthCell,
        setSelectedMonthCell,
        setParsedFormulas,
        downloadableModel,
        setDownloadableModel,
        monthsBetweenDates: parsedFormulas.monthsBetweenDates,
        loading,
        revalidate,
        revalidateLoading,
        dragMode,
        setDragMode,
        prebuiltComponentModalState,
        setPrebuiltComponentModalState,
        search,
        setSearch,
      }}
    >
      {children}
    </FinancialModelContext.Provider>
  );
};

export default FinancialModelProvider;

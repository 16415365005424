import React, { useRef, useState, useEffect, useMemo } from 'react';
import { IFormulaItemData } from '../../../ModelBuilderContext';
import IsolatedPopover from './IsolatedPopover';
import quickbooksIcon from '~/assets/logos/quickbooks.svg';
import xeroIcon from '~/assets/logos/xero.svg';
import ColumnResize from '~/components/FormulasTable/ColumnResize';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { useDraggableContext } from '~/components/FormulasTable/contexts/DraggableContext';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import useFormulaContext from '~/pages/FinancialModel/useFormulaContext';
import ErrorPopover from './ErrorPopover';
import ConfigurationPopover from './ConfigurationPopover';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import HoverPopover from '~/components/HoverPopover';
import useTableContext from '~/components/FormulasTable/hooks/useTableContext';
import { IFormula } from '~/services/parallel/formulas.types';

interface IProps {
  rowIndex: number;
  columnWidth: number;
  data: IFormulaItemData;
  columnIndex: number;
}

const integrationIconMapping = {
  'quickbooks-online': quickbooksIcon,
  xero: xeroIcon,
};

const Label = ({ columnWidth, data, columnIndex }: IProps): React.ReactElement => {
  const { attributes, listeners, iconAlwaysVisible } = useDraggableContext();
  const { updateFormulaName, formulaDictionary, viewOnly } = useFormulaContext();
  const { columnWidths } = useTableContext();

  const isValid = useRef(true);
  const cursorPositionRef = useRef<{ from: number; to: number } | null>(null);
  const [isConfigurationPopoverOpen, setIsConfigurationPopoverOpen] = useState<boolean>(false);
  const [isRenamingLabel, setIsRenamingLabel] = useState<boolean>(false);
  const labelRef = useRef<HTMLDivElement>(null);
  const [labelIsTruncated, setLabelIsTruncated] = useState<boolean>(false);
  const labelWasLastClickedRef = useRef<boolean>(false);

  /**
   * Sets the popover up to be able to close by click on the label,
   * but then reopenable by click on the label if you're a psychopath
   */
  useEffect(() => {
    const handleClick = (event: MouseEvent): void => {
      const clickedOnLabel = labelRef.current?.contains(event.target as Node) ?? false;

      if (clickedOnLabel && !isConfigurationPopoverOpen && labelWasLastClickedRef.current) {
        labelWasLastClickedRef.current = false;
      } else {
        labelWasLastClickedRef.current = clickedOnLabel;
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  useEffect(() => {
    const checkTruncation = (): void => {
      if (labelRef.current) {
        const element = labelRef.current;
        const labelTextElement = element.querySelector('.label-text');
        if (labelTextElement) {
          const isolationIconSpace = data.label.isIsolated ? 36 : 0;
          const integrationIconSpace = data.label.integration ? 36 : 0;
          const availableWidth = element.clientWidth - isolationIconSpace - integrationIconSpace;
          const contentWidth = labelTextElement.scrollWidth;
          setLabelIsTruncated(contentWidth > availableWidth);
        }
      }
    };

    checkTruncation();

    const resizeObserver = new ResizeObserver(checkTruncation);
    if (labelRef.current) {
      resizeObserver.observe(labelRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [columnWidth, data.label.name, columnWidths]);

  const isNameUnique = (name: string): boolean => {
    if (data.label.name === name) return true;
    const formulaWithSameName = Object.values(formulaDictionary).find(
      (formula) => formula.recipe.name === name && formula.formulaUuid !== data.formulaUuid,
    );
    return !formulaWithSameName;
  };

  const editor = useEditor({
    extensions: [StarterKit],
    content: data.label.name,
    editorProps: {
      attributes: {
        class: `rounded w-full !whitespace-nowrap p-2 focus:outline-none focus:border border text-neutral-500 ${
          isValid.current ? 'border-transparent focus:border-green' : 'focus:border-red-500 border-red-500'
        } h-[48px] flex items-center gap-1 overflow-x-auto no-scrollbar`,
      },
      handleClick: (view) => {
        const { from, to } = view.state.selection;
        cursorPositionRef.current = { from, to };
        return false;
      },
      handleKeyDown: (view, event) => {
        const { from, to } = view.state.selection;
        cursorPositionRef.current = { from, to };

        if (event.key === 'Enter') {
          event.preventDefault();
          view.dom.blur();
          return true;
        } else if (event.key === 'Escape') {
          event.preventDefault();
          editor?.commands.setContent(data.label.name);
          view.dom.blur();
          return true;
        }
        return false;
      },
    },
    onUpdate: ({ editor }) => {
      isValid.current = isNameUnique(editor.getText());
    },
    onBlur: ({ editor }) => {
      if (data.label.name !== editor.getText()) {
        if (isValid.current) {
          updateFormulaName({ formulaUuid: data.formulaUuid, name: editor.getText() });
        }
      }
      setIsRenamingLabel(false);
    },
  });

  const formula: IFormula | null = useMemo(
    () => (data.formulaUuid in formulaDictionary ? formulaDictionary[data.formulaUuid] : null),
    [formulaDictionary, data.formulaUuid],
  );

  return (
    <div
      className="bg-white sticky z-10 top-0 left-0 flex pr-2 border-r border-neutral-50 group"
      style={{ width: `${columnWidth}px`, minWidth: `${columnWidth}px` }}
    >
      <div
        className={`bg-white flex items-center cursor-grab ${viewOnly && 'hidden'}`}
        {...attributes}
        {...listeners}
        tabIndex={-1}
      >
        <div className={`flex items-center ${iconAlwaysVisible ? '' : 'group-hover:opacity-100 opacity-0'}`}>
          <EllipsisVerticalIcon className="size-5 text-neutral-200" />
          <EllipsisVerticalIcon className="size-5 -ml-[15px] text-neutral-200" />
        </div>
      </div>
      {!isValid.current && editor && (
        <ErrorPopover
          visible={true}
          formulaName={editor.getText()}
          right={labelRef.current?.getBoundingClientRect().right ?? 0}
          top={labelRef.current?.getBoundingClientRect().top ?? 0}
        />
      )}
      <div className="flex-1 overflow-hidden" ref={labelRef}>
        {!isRenamingLabel && !labelIsTruncated && (
          <Button
            fill="clear"
            className={`flex items-start !justify-start !p-2 h-[48px] border border-transparent !text-neutral-500 text-nowrap ${
              !viewOnly ? 'hover:underline' : 'ml-4'
            }`}
            onClick={() => {
              if (!labelWasLastClickedRef.current) {
                setIsConfigurationPopoverOpen(true);
              }
            }}
            disabled={viewOnly}
          >
            <span className="whitespace-nowrap label-text">{editor?.getText()}</span>
          </Button>
        )}
        {!isRenamingLabel && labelIsTruncated && (
          <HoverPopover
            buttonContent={
              <Button
                fill="clear"
                className={`flex text-start !justify-start !p-2 h-[48px] border border-transparent !text-neutral-500 text-nowrap ${
                  !viewOnly ? 'hover:underline' : 'ml-4 cursor-default'
                }`}
                onClick={() => {
                  setIsConfigurationPopoverOpen(true);
                }}
              >
                <span className="whitespace-nowrap label-text">{editor?.getText()}</span>
              </Button>
            }
            panelContent={
              <div className="pt-1.5 pb-2 px-3 bg-black">
                <Typography size="xs" color="white">
                  {editor?.getText()}
                </Typography>
              </div>
            }
            panelClassName="rounded-lg shadow-md"
            hoverDelay={750}
            anchor="top"
          />
        )}
        {isRenamingLabel && <EditorContent editor={editor} className="overflow-x-auto no-scrollbar" />}
      </div>
      {formula && (
        <ConfigurationPopover
          id={`configuration-popover-${data.formulaUuid}`}
          formulaUuid={data.formulaUuid}
          formula={formula}
          position={{
            left: labelRef.current?.getBoundingClientRect().left ?? 0,
            top: labelRef.current?.getBoundingClientRect().top ?? 0,
            bottom: labelRef.current?.getBoundingClientRect().bottom ?? 0,
          }}
          isOpen={isConfigurationPopoverOpen}
          onClose={() => setIsConfigurationPopoverOpen(false)}
          editLabel={() => {
            setIsConfigurationPopoverOpen(false);
            setIsRenamingLabel(true);
            editor?.commands.focus();
          }}
        />
      )}
      <div className="absolute right-0 top-[14px] flex items-center gap-1 bg-white px-2">
        {data.label.isIsolated && !viewOnly && <IsolatedPopover />}
        {data.label.integration && !viewOnly && (
          <div key={`${data.formulaUuid}-${data.label.integration}`}>
            <img
              className="size-5"
              src={integrationIconMapping[data.label.integration as keyof typeof integrationIconMapping]}
              alt={`Integration icon for ${data.label.integration}`}
            />
          </div>
        )}
      </div>
      <ColumnResize columnIndex={columnIndex} />
    </div>
  );
};

export default Label;

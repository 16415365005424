import { IFormulaGroupData } from '~/pages/FinancialModel/ModelBuilderContext';

export const getFilteredFormulaGroups = ({
  formulaGroups,
  permissionedFormulaUuids,
}: {
  formulaGroups: IFormulaGroupData[];
  permissionedFormulaUuids: string[];
}): IFormulaGroupData[] => {
  if (permissionedFormulaUuids.includes('*')) return formulaGroups;
  return formulaGroups
    .filter((group) => {
      return group.formulas.some((formula) =>
        permissionedFormulaUuids.find((pFormulaUuid) => pFormulaUuid === formula.formulaUuid),
      );
    })
    .map((group) => ({
      ...group,
      formulas: group.formulas.filter((formula) =>
        permissionedFormulaUuids.find((pFormulaUuid) => pFormulaUuid === formula.formulaUuid),
      ),
    }));
};

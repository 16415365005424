import React, { useContext, useEffect, useMemo, useState } from 'react';
import PercentagePerMonthInput from './PercentagePerMonthInput';
import Button from '~/components/Button';
import Divider from '~/components/Divider';
import useInput from '~/components/Input/useInput';
import Input from '~/components/Input/InputWrapper';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import Typography from '~/components/Typography';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { IPrebuiltComponentType } from './PrebuiltOptions';
import { useFormulaInput } from '~/components/FormulaListInput/useFormulaListInput';
import FormulaListInput from '~/components/FormulaListInput';

const MonthlyPercentageAggregatorForm = ({
  type,
  handleClose,
}: {
  type: IPrebuiltComponentType.CashCollections | IPrebuiltComponentType.RevenueRecognition;
  handleClose: () => void;
}): React.ReactNode => {
  const { parsedFormulas, revalidate } = useContext(FinancialModelContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [percentageError, setPercentageError] = useState<string>('');
  const [percentagePerMonth, setPercentagePerMonth] = useState(['', '', '']);
  const [titlePrefix, setTitlePrefix] = useInput({
    value: '',
    valid: false,
    touched: false,
    pristine: true,
    errorMessage: 'Please enter a valid title',
  });
  const [attributeTitle, setAttributeTitle] = useInput({});

  const { filteredFormulaList, highlightedFormula, setHighlightedFormula, handleKeyDown, onSelectAttribute } =
    useFormulaInput({
      formulaList: parsedFormulas.list,
      inputValue: attributeTitle.value,
      onSelectAttribute: (attribute) => {
        setAttributeTitle((prev) => ({
          ...prev,
          value: attribute.recipe.name,
          valid: true,
          touched: true,
          pristine: false,
          errorMessage: '',
        }));
      },
    });

  const needTitlePrefix = useMemo(() => {
    return parsedFormulas.list.some((formula) => {
      if (type === IPrebuiltComponentType.CashCollections) {
        return formula.recipe.name.includes('Cash Collections');
      }
      return formula.recipe.name.includes('Revenue Recognition');
    });
  }, []);

  const handleInputChange = ({ index, value }: { index: number; value: string }): void => {
    const newPercentages = [...percentagePerMonth];
    newPercentages[index] = value;
    setPercentagePerMonth(newPercentages);
  };

  const addMonth = (): void => {
    const newPercentages = [...percentagePerMonth];
    newPercentages.push('');

    setPercentagePerMonth(newPercentages);
  };

  const removeMonth = (): void => {
    if (percentagePerMonth.length > 2) {
      const newPercentages = [...percentagePerMonth];
      newPercentages.pop();

      setPercentagePerMonth(newPercentages);
    }
  };

  const getSideLabel = (index: number): string => {
    if (index === 0) {
      return '(Signed)';
    }
    return '';
  };

  const validateForm = (): boolean => {
    let isValid = true;
    if (needTitlePrefix && !titlePrefix.value.trim()) {
      setTitlePrefix((prev) => ({ ...prev, valid: false, touched: true }));
      isValid = false;
    }
    if (!attributeTitle.value.trim()) {
      setAttributeTitle((prev) => ({ ...prev, valid: false, touched: true }));
      isValid = false;
    }

    const isPercentagesValid = percentagePerMonth.every(
      (p) => p !== '' && !isNaN(Number(p)) && Number(p) >= 0 && Number(p) <= 100,
    );

    if (!isPercentagesValid) {
      setPercentageError('All percentages must be valid numbers between 0 and 100.');
      isValid = false;
    } else {
      const total = percentagePerMonth.reduce((sum, p) => sum + Number(p), 0);
      if (total !== 100) {
        setPercentageError(`Percentages must add to 100%. Current: ${total}%`);
        isValid = false;
      } else {
        setPercentageError('');
      }
    }
    return isValid;
  };

  const handleSave = async ({
    titlePrefix,
    attributeTitle,
    percentagePerMonth,
  }: {
    titlePrefix: string;
    attributeTitle: string;
    percentagePerMonth: string[];
  }): Promise<void> => {
    if (validateForm()) {
      const monthlyPercentageAggregator: {
        titlePrefix?: string;
        attributeUuid: string;
        percentages: number[];
      } = {
        attributeUuid:
          parsedFormulas.list.find((formula) => {
            return formula.recipe.name === attributeTitle;
          })?.formulaUuid ?? '',
        percentages: percentagePerMonth.map((value) => Number(value) / 100),
      };

      if (needTitlePrefix) {
        monthlyPercentageAggregator.titlePrefix = titlePrefix;
      }

      const response = await request({
        method: 'POST',
        url: `formulas/template/${
          type === IPrebuiltComponentType.CashCollections ? 'cash-collections' : 'revenue-recognition'
        }`,
        body: monthlyPercentageAggregator,
        params: {
          scenarioUuid: activeScenarioUuid,
        },
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      });

      if (response.status >= 400) {
        toast.error('Failed to create rep ramping');
        logger.error(new Error(response.data));
      } else {
        handleClose();
        revalidate();
      }
    } else {
      if (needTitlePrefix) {
        setTitlePrefix((prev) => ({ ...prev, pristine: false, touched: true }));
      }
      setAttributeTitle((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
    }
  };

  useEffect(() => {
    if (percentageError) {
      setPercentageError('');
    }
  }, [percentagePerMonth]);

  const description = useMemo(() => {
    if (type === IPrebuiltComponentType.CashCollections) {
      return "Estimate the timing and amount of revenue that will be received as cash, helping project the company's cash flow";
    }
    return 'Forecasting when new revenue will start being recognized';
  }, [type]);

  return (
    <div className="w-full flex flex-col gap-2">
      <Typography color="secondary" className="mb-2" size="xs">
        {description}
      </Typography>
      {needTitlePrefix && (
        <Input
          label="Title Prefix"
          state={titlePrefix}
          setState={setTitlePrefix}
          id="title-prefix-input"
          placeholder=""
        />
      )}
      <FormulaListInput
        attributeTitle={attributeTitle}
        setAttributeTitle={setAttributeTitle}
        handleKeyDown={handleKeyDown}
        filteredFormulaList={filteredFormulaList}
        onSelectAttribute={onSelectAttribute}
        highlightedFormula={highlightedFormula}
        setHighlightedFormula={setHighlightedFormula}
        inputLabel={type === IPrebuiltComponentType.CashCollections ? 'Revenue Attribute' : 'New Bookings Attribute'}
        inputPlaceholder={`Indicate which Attribute indicates ${
          type === IPrebuiltComponentType.CashCollections ? 'Revenue' : 'New Bookings'
        }`}
        inputId={type}
      />
      <Divider className="my-1" />
      <div className="flex flex-col w-full gap-2 items-center">
        {percentagePerMonth.map((value, index) => (
          <PercentagePerMonthInput
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            label={`Month ${index}`}
            sideLabel={getSideLabel(index)}
            value={value}
            onChange={(val) => handleInputChange({ index, value: val })}
          />
        ))}
        {percentageError && (
          <Typography className="w-full italic" color="warning" size="xs">
            {percentageError}
          </Typography>
        )}
        <div className="flex flex-row gap-2 items-center w-full justify-start">
          <Button onClick={addMonth} fill="clear" className="!w-fit !px-0">
            Add
          </Button>
          <Divider orientation="vertical" className="text-neutral-50" />
          <Button onClick={removeMonth} fill="clear" className="!w-fit !px-0">
            Remove
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between w-full mt-1">
        <div className="w-fit">
          <Button fill="clear" className="!w-fit !px-0" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className="w-fit">
          <Button
            onClick={() =>
              handleSave({
                titlePrefix: titlePrefix.value,
                attributeTitle: attributeTitle.value,
                percentagePerMonth,
              })
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MonthlyPercentageAggregatorForm;

import React, { useState, useRef, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Transition } from '@headlessui/react';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import SegmentedControl from '~/components/SegmentedControl';
import Select, { useSelect } from '~/components/Select';
import useFormulaContext from '~/pages/FinancialModel/useFormulaContext';
import { IFormattingEnum, IFormula, IRoundDirectionEnum } from '~/services/parallel/formulas.types';
import SelectMultiple from '~/components/SelectMultiple';
import { useSelectMultiple } from '~/components/SelectMultiple';
import { isEqual } from 'lodash';
import { IIntegrationSources } from '~/utils/schemas/integrations';

interface Props {
  isOpen: boolean;
  position: {
    left: number;
    top: number;
    bottom: number;
  };
  id: string;
  onClose: () => void;
  editLabel: () => void;
  formulaUuid: string;
  formula: IFormula;
}

const ConfigurationPopover = ({
  isOpen,
  id,
  formula,
  formulaUuid,
  onClose,
  editLabel,
  position,
}: Props): React.ReactPortal => {
  const {
    formulaDictionary,
    updateFormulaFormatting,
    updateFormulaRounding,
    dataSources,
    updateFormulaDataSource,
    deleteFormula,
    availableIntegration,
  } = useFormulaContext();

  const [formatting, setFormatting] = useState<IFormattingEnum>(formula.formatting ?? IFormattingEnum.Number);

  const [roundResults, setRoundResults] = useState<boolean>(formula.recipe.roundingInstructions !== null);
  const prevRoundResults = useRef<boolean>(roundResults);
  const [roundingDirection, setRoundingDirection] = useState<IRoundDirectionEnum>(
    formula.recipe.roundingInstructions?.direction ?? IRoundDirectionEnum.Nearest,
  );
  const [roundingPrecision, setRoundingPrecision] = useSelect({
    options: [
      {
        label: 'Whole Number',
        value: '1',
      },
      {
        label: 'Nearest 10',
        value: '10',
      },
      {
        label: 'Nearest 100',
        value: '100',
      },
      {
        label: 'Nearest 1000',
        value: '1000',
      },
      {
        label: 'Nearest 10000',
        value: '10000',
      },
      {
        label: 'Nearest 100000',
        value: '100000',
      },
      {
        label: '1 Decimal Place',
        value: '0.1',
      },
      {
        label: '2 Decimal Places',
        value: '0.01',
      },
    ],
    selected: { label: 'Whole Number', value: '1' },
  });

  const [integrationMappingEnabled, setIntegrationMappingEnabled] = useState<boolean>(
    formula.dataSourceUuids.length > 0,
  );
  const [dataSourceState, setDataSourceState] = useSelectMultiple({
    options: [],
  });

  const popoverRef = useRef<HTMLDivElement>(null);

  // Submit request to update formatting when changed
  useEffect(() => {
    if (formatting !== formula.formatting) {
      updateFormulaFormatting({ formulaUuid, formatting });
    }
  }, [formatting]);

  // Set the options for datasource select component
  useEffect(() => {
    setDataSourceState((prevState) => {
      const matchingDataSources = dataSources.filter((ds) => formula.dataSourceUuids.includes(ds.uuid));
      return {
        ...prevState,
        options: [
          ...dataSources.map((ds) => ({
            label: ds.name,
            value: ds.uuid,
            disabled: ds.currentlyInUse && !formula.dataSourceUuids.includes(ds.uuid),
          })),
        ],
        selected: matchingDataSources.length
          ? matchingDataSources.map((ds) => ({
              label: ds.name,
              value: ds.uuid,
            }))
          : [],
      };
    });
  }, [dataSources]);

  // Submit request to update rounding when changed
  useEffect(() => {
    if (roundResults) {
      if (
        roundingPrecision.selected?.value !== formula.recipe.roundingInstructions?.precision.toString() ||
        roundingDirection !== formula.recipe.roundingInstructions?.direction
      ) {
        updateFormulaRounding({
          formulaUuid,
          direction: roundingDirection,
          roundingPrecision: Number(roundingPrecision.selected?.value),
        });
      }
    } else if (prevRoundResults.current) {
      updateFormulaRounding({
        formulaUuid,
      });
    }
    prevRoundResults.current = roundResults;
  }, [roundResults, roundingPrecision, roundingDirection]);

  // Close the popover when clicking outside or pressing escape
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleEscapeKey = (event: KeyboardEvent): void => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isOpen, onClose]);

  const updateDataSource = (): void => {
    const newDataSources: string[] =
      dataSourceState.selected?.map((ds) => ds.value).filter((value): value is string => value !== null) ?? [];
    if (!isEqual(newDataSources, formula.dataSourceUuids)) {
      updateFormulaDataSource({
        formulaUuid,
        dataSourceUuids: newDataSources,
      });
    }
  };

  const isNotReferencedInOtherFormulas = useMemo(() => {
    return !Object.values(formulaDictionary).some((f) => {
      return Object.values(f.recipe.variables).some((variable) => {
        return variable.formulaUuid === formulaUuid;
      });
    });
  }, [formulaDictionary, formulaUuid]);

  let integrationName;
  if (availableIntegration !== null) {
    integrationName = availableIntegration === IIntegrationSources.Quickbooks ? 'Quickbooks' : 'Xero';
  }

  return createPortal(
    <Transition
      show={isOpen}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <div
        ref={popoverRef}
        id={id}
        className="absolute flex-col w-[245px] bg-white z-30 rounded-lg shadow-md flex"
        style={{
          left: position.left,
          top: position.bottom + 4,
        }}
      >
        <div className="border-b p-2">
          <SegmentedControl
            name="format-control"
            value={formatting}
            setValue={(val) => {
              setFormatting(val as IFormattingEnum);
            }}
            segments={[
              { value: IFormattingEnum.Number, label: '#' },
              { value: IFormattingEnum.Currency, label: '$' },
              { value: IFormattingEnum.Percent, label: '%' },
            ]}
          />
        </div>
        <div className="border-b">
          <div className="flex flex-col gap-2 p-2">
            <Checkbox
              id={`roundResults-${id}`}
              label="Round results"
              checked={roundResults}
              toggleValue={() => setRoundResults(!roundResults)}
            />
            {roundResults && (
              <SegmentedControl
                name="round-direction"
                value={roundingDirection}
                setValue={(val) => {
                  setRoundingDirection(val as IRoundDirectionEnum);
                }}
                segments={[
                  { value: IRoundDirectionEnum.Nearest, label: 'Nearest' },
                  { value: IRoundDirectionEnum.Down, label: 'Down' },
                  { value: IRoundDirectionEnum.Up, label: 'Up' },
                ]}
              />
            )}
            {roundResults && (
              <Select
                id={`roundingPrecision-${id}`}
                placeholder="Please select"
                state={roundingPrecision}
                setState={setRoundingPrecision}
              />
            )}
          </div>
        </div>
        {availableIntegration && (
          <div className="border-b">
            <div className="p-2 flex flex-col gap-2">
              <Checkbox
                id={`integrationMapping-${id}`}
                label={`Connect to ${integrationName}`}
                checked={integrationMappingEnabled}
                toggleValue={() => {
                  setIntegrationMappingEnabled(!integrationMappingEnabled);
                  updateFormulaDataSource({
                    formulaUuid,
                    dataSourceUuids: [],
                  });
                }}
              />
              {integrationMappingEnabled && (
                <SelectMultiple
                  id="connected-data-source"
                  state={dataSourceState}
                  setState={setDataSourceState}
                  label="Connected Data Source"
                  className="!w-auto"
                  onBlur={updateDataSource}
                />
              )}
            </div>
          </div>
        )}
        <div className="flex-col py-1">
          <Button fill="clear" className="!justify-start !px-4" onClick={editLabel}>
            Rename
          </Button>
          <Button
            disabled={!isNotReferencedInOtherFormulas}
            fill="destructiveClear"
            className="!justify-start !px-4"
            onClick={() => {
              onClose();
              deleteFormula(formulaUuid);
            }}
          >
            Delete
          </Button>
        </div>
      </div>
    </Transition>,
    document.body,
  );
};

export default ConfigurationPopover;

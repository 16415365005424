import { datadogRum } from '@datadog/browser-rum';

const logger = {
  info: (...data: unknown[]): void => {
    // eslint-disable-next-line no-console
    console.info(...data);
  },
  error: (error: Error, context?: object): void => {
    if (import.meta.env.PROD) {
      datadogRum.addError(error, context);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  },
};

export default logger;

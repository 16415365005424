import React from 'react';
import Typography from '~/components/Typography';
import { ITimeModifierFunctionEnum } from '~/services/parallel/formulas.types';

const TimeModifier = ({
  timeModifier,
}: {
  timeModifier?: { period?: number; function?: ITimeModifierFunctionEnum };
}): React.ReactElement | null => {
  if (!timeModifier?.period) return null;

  return (
    <Typography size="xs" color="empty" className="italic">
      &nbsp;
      {timeModifier.period === 1 ? 'Last Month' : `${timeModifier.period} Months Ago`}
    </Typography>
  );
};

export default TimeModifier;

import { IBillingScheduleEnum } from '../entity/types';

export const getBillingScheduleDisplay = (billingSchedule: IBillingScheduleEnum): string => {
  switch (billingSchedule) {
    case IBillingScheduleEnum.Monthly:
      return 'Monthly';
    case IBillingScheduleEnum.Quarterly:
      return 'Quarterly';
    case IBillingScheduleEnum.Annual:
      return 'Annually';
    default:
      return 'Unknown';
  }
};

import { format } from 'date-fns';
import React from 'react';
import Typography from '~/components/Typography';

const GraphFooter = ({ xMin, xMax }: { xMin: number; xMax: number }): React.ReactElement => {
  return (
    <div className="w-full flex items-center justify-between pl-[15%]">
      <Typography size="xs" color="empty">
        {format(new Date(xMin), "MMM ''yy").toUpperCase()}
      </Typography>
      <Typography size="xs" color="empty">
        {format(new Date(xMax), "MMM ''yy").toUpperCase()}
      </Typography>
    </div>
  );
};

export default GraphFooter;

import React, { useMemo } from 'react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import { useDroppable } from '@dnd-kit/core';
import useFormulaContext from '../useFormulaContext';
import useTableContext from '../../../components/FormulasTable/hooks/useTableContext';
import PendingAttributeInput from './PendingAttributeInput';

const GroupRow = ({
  uuid,
  name,
  isCollapsed,
  count,
  index,
}: {
  uuid: string;
  name: string;
  isCollapsed: boolean;
  count: number;
  index: number;
}): React.ReactNode => {
  const { setNodeRef } = useDroppable({
    id: uuid,
  });
  const { toggleGroupCollapse, createNewAttribute, pendingAttributeData, viewOnly } = useFormulaContext();
  const { columnWidths, draggingOverId } = useTableContext();

  const isDraggingOverThisGroup = draggingOverId === uuid;

  let backgroundColor = 'bg-white';
  if (isDraggingOverThisGroup) {
    backgroundColor = !isCollapsed ? 'bg-green-50' : 'bg-green-50';
  }

  const fullWidth = useMemo(() => columnWidths.reduce((acc, curr) => acc + curr, 0), [columnWidths]);

  const pendingAttributeActive = useMemo(() => pendingAttributeData?.groupUuid === uuid, [pendingAttributeData, uuid]);

  return (
    <React.Fragment>
      <div
        className={`relative mt-1.5 border-t border-neutral-50 border-b py-[9px] group/formula-group ${backgroundColor}`}
        style={{ width: `${fullWidth}px`, minWidth: `${fullWidth}px` }}
      >
        <div ref={setNodeRef} className={`sticky top-0 left-0 inline-flex gap-5 items-center pl-3`}>
          <button
            className="flex items-center bg-neutral-25 rounded-full py-[5px] px-3 hover:bg-green-25 hover:text-green-500 gap-1 focus:outline-none"
            onClick={() => toggleGroupCollapse(uuid)}
          >
            <ChevronUpIcon
              className={`text-neutral-300 stroke-2 size-4 ${!isCollapsed ? 'rotate-180' : 'rotate-90'}`}
            />
            <Typography size="xs" weight="medium" color="secondary">
              {name}
            </Typography>
            <div className="flex items-center justify-center">
              <Typography size="xs" color="lightGray" className="!no-underline">
                {count}
              </Typography>
            </div>
          </button>
          {!pendingAttributeData && !viewOnly && (
            <Button
              fill="clear"
              className="!w-auto invisible group-hover/formula-group:visible !p-0"
              onClick={() => createNewAttribute({ uuid })}
            >
              New Attribute
            </Button>
          )}
        </div>
      </div>
      <PendingAttributeInput groupIndex={index} active={pendingAttributeActive} />
    </React.Fragment>
  );
};

export default GroupRow;

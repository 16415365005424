import { z } from 'zod';
import { ZShareLink } from '~/pages/ShareLink/models/schema';
import { IUserRole } from '~/utils/waitForStoreRehydration';

export const ZAccessToken = z.object({
  userUuid: z.string(),
  role: z.nativeEnum(IUserRole),
  link: ZShareLink.optional(),
});

export type IAccessToken = z.infer<typeof ZAccessToken>;

export const createOrEditScenarioAnimationJson = (): object => {
  const jsonTemplate = {
    nm: 'Generating Scenario',
    ddd: 0,
    h: 100.5,
    w: 211.5,
    meta: { g: 'LottieFiles Figma v67' },
    layers: [
      {
        ty: 0,
        nm: 'Logo',
        sr: 1,
        st: 0,
        op: 64,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [56.25, 42.19],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [56.25, 42.19],
                t: 3,
              },
              { s: [42.19, 31.64], t: 63 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 3,
              },
              { s: [100, 100], t: 63 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 51.19],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 51.19],
                t: 3,
              },
              { s: [52.69, 50.25], t: 63 },
            ],
          },
          r: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
              { s: [0], t: 63 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 3 },
              { s: [100], t: 63 },
            ],
          },
        },
        w: 211.5,
        h: 100.5,
        refId: '1',
        ind: 1,
      },
      {
        ty: 0,
        nm: 'Logo',
        sr: 1,
        st: 0,
        op: 64,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [56.25, 42.19],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [56.25, 42.19],
                t: 3,
              },
              { s: [42.19, 31.64], t: 63 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 3,
              },
              { s: [100, 100], t: 63 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 51.19],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 51.19],
                t: 3,
              },
              { s: [159.56, 50.25], t: 63 },
            ],
          },
          r: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
              { s: [0], t: 63 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 3 },
              { s: [100], t: 63 },
            ],
          },
        },
        w: 211.5,
        h: 100.5,
        refId: '2',
        ind: 2,
      },
      {
        ty: 4,
        nm: 'page-1 Bg',
        sr: 1,
        st: 0,
        op: 64,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 50.25],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 50.25],
                t: 3,
              },
              { s: [105.75, 50.25], t: 63 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [100, 100],
                t: 3,
              },
              { s: [100, 100], t: 63 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 50.25],
                t: 0,
              },
              {
                o: { x: 1, y: -0.05 },
                i: { x: 0.35, y: 1 },
                s: [105.75, 50.25],
                t: 3,
              },
              { s: [105.75, 50.25], t: 63 },
            ],
          },
          r: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
              { s: [0], t: 63 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 0 },
              { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 3 },
              { s: [100], t: 63 },
            ],
          },
        },
        shapes: [
          {
            ty: 'sh',
            bm: 0,
            hd: false,
            nm: '',
            d: 1,
            ks: {
              a: 1,
              k: [
                {
                  o: { x: 1, y: -0.05 },
                  i: { x: 0.35, y: 1 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  o: { x: 1, y: -0.05 },
                  i: { x: 0.35, y: 1 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 3,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [211.5, 0],
                        [211.5, 100.5],
                        [0, 100.5],
                        [0, 0],
                      ],
                    },
                  ],
                  t: 63,
                },
              ],
            },
          },
          {
            ty: 'fl',
            bm: 0,
            hd: false,
            nm: '',
            c: {
              a: 1,
              k: [
                {
                  o: { x: 1, y: -0.05 },
                  i: { x: 0.35, y: 1 },
                  s: [1, 1, 1],
                  t: 0,
                },
                {
                  o: { x: 1, y: -0.05 },
                  i: { x: 0.35, y: 1 },
                  s: [1, 1, 1],
                  t: 3,
                },
                { s: [1, 1, 1], t: 63 },
              ],
            },
            r: 1,
            o: {
              a: 1,
              k: [
                { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 0 },
                { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [100], t: 3 },
                { s: [100], t: 63 },
              ],
            },
          },
        ],
        ind: 3,
      },
    ],
    v: '5.7.0',
    fr: 60,
    op: 63,
    ip: 0,
    assets: [
      {
        nm: '[Asset] Logo',
        id: '1',
        layers: [
          {
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            st: 0,
            op: 64,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 3,
                  },
                  { s: [100, 100], t: 63 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
                  { s: [0], t: 63 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 3,
                  },
                  { s: [100], t: 63 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [0.4981, 0.5765, 0.4628],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [0.4981, 0.5765, 0.4628],
                      t: 3,
                    },
                    { s: [0.4981, 0.5765, 0.4628], t: 63 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [100],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [100],
                      t: 3,
                    },
                    { s: [100], t: 63 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'Logo Bg',
            sr: 1,
            st: 0,
            op: 64,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 3,
                  },
                  { s: [100, 100], t: 63 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
                  { s: [0], t: 63 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 3,
                  },
                  { s: [100], t: 63 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
      {
        nm: '[Asset] Logo',
        id: '2',
        layers: [
          {
            ty: 4,
            nm: 'Subtract',
            sr: 1,
            st: 0,
            op: 64,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 3,
                  },
                  { s: [100, 100], t: 63 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
                  { s: [0], t: 63 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 3,
                  },
                  { s: [100], t: 63 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 0],
                            [70.31, 0],
                            [14.06, 56.25],
                            [0, 42.19],
                            [42.19, 0],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 0],
                            [52.73, 0],
                            [10.55, 42.19],
                            [0, 31.64],
                            [31.64, 0],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [42.19, 84.38],
                            [98.44, 28.13],
                            [112.5, 42.19],
                            [70.31, 84.38],
                            [42.19, 84.38],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [31.64, 63.28],
                            [73.83, 21.09],
                            [84.38, 31.64],
                            [52.73, 63.28],
                            [31.64, 63.28],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [77.35, 7.03],
                            [21.1, 63.28],
                            [35.16, 77.34],
                            [91.41, 21.09],
                            [77.35, 7.03],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [58.01, 5.27],
                            [15.82, 47.46],
                            [26.37, 58.01],
                            [68.56, 15.82],
                            [58.01, 5.27],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
              {
                ty: 'fl',
                bm: 0,
                hd: false,
                nm: '',
                c: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [0.353, 0.5177, 0.5883],
                      t: 3,
                    },
                    { s: [0.353, 0.5177, 0.5883], t: 63 },
                  ],
                },
                r: 1,
                o: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [100],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [100],
                      t: 3,
                    },
                    { s: [100], t: 63 },
                  ],
                },
              },
            ],
            ind: 1,
          },
          {
            ty: 4,
            nm: 'Logo Bg',
            sr: 1,
            st: 0,
            op: 64,
            ip: 0,
            hd: false,
            ddd: 0,
            bm: 0,
            hasMask: false,
            ao: 0,
            ks: {
              a: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              s: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100, 100],
                    t: 3,
                  },
                  { s: [100, 100], t: 63 },
                ],
              },
              sk: { a: 0, k: 0 },
              p: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [56.25, 42.19],
                    t: 3,
                  },
                  { s: [42.19, 31.64], t: 63 },
                ],
              },
              r: {
                a: 1,
                k: [
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 0 },
                  { o: { x: 1, y: -0.05 }, i: { x: 0.35, y: 1 }, s: [0], t: 3 },
                  { s: [0], t: 63 },
                ],
              },
              sa: { a: 0, k: 0 },
              o: {
                a: 1,
                k: [
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 0,
                  },
                  {
                    o: { x: 1, y: -0.05 },
                    i: { x: 0.35, y: 1 },
                    s: [100],
                    t: 3,
                  },
                  { s: [100], t: 63 },
                ],
              },
            },
            shapes: [
              {
                ty: 'sh',
                bm: 0,
                hd: false,
                nm: '',
                d: 1,
                ks: {
                  a: 1,
                  k: [
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 0,
                    },
                    {
                      o: { x: 1, y: -0.05 },
                      i: { x: 0.35, y: 1 },
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [112.5, 0],
                            [112.5, 84.38],
                            [0, 84.38],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 3,
                    },
                    {
                      s: [
                        {
                          c: true,
                          i: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          o: [
                            [0, 0],
                            [0, 0],
                            [0, 0],
                            [0, 0],
                          ],
                          v: [
                            [84.38, 0],
                            [84.38, 63.28],
                            [0, 63.28],
                            [0, 0],
                          ],
                        },
                      ],
                      t: 63,
                    },
                  ],
                },
              },
            ],
            ind: 2,
          },
        ],
      },
    ],
  };

  return jsonTemplate;
};

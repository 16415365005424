import React from 'react';
import HoverPopover from '~/components/HoverPopover';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';

const ErrorPopover = ({ errorMessage }: { errorMessage: string }): React.ReactElement => {
  return (
    <div className="absolute top-3 right-2">
      <HoverPopover
        buttonContent={<ExclamationCircleIcon className="size-6 text-red-500 bg-white rounded-full" />}
        panelContent={
          <div
            className={`bg-red text-white text-sm rounded whitespace-nowrap shadow-md px-3 py-2 flex items-center z-30`}
          >
            {errorMessage}
          </div>
        }
      />
    </div>
  );
};

export default ErrorPopover;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import { IOrganizationState } from '~/store/organizationSlice';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import PopoverComponent from '~/components/Popover';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import Modal from '~/components/Modal';
import InputWrapper, { useInput } from '~/components/Input/InputWrapper';
import Button from '~/components/Button';
import { setPermissions } from '~/store/userSlice';
import { StatusCodes } from 'http-status-codes';
import { isBefore } from 'date-fns';
import { scenarioSlice } from '~/store/scenarioSlice';
interface IOrganizationResponse {
  data: {
    data?: IOrganizationState[];
  };
  status: number;
}

interface IUserPreferencesResponse {
  status: number;
}

interface IHandleSelectOrgParams {
  orgUuid: string;
  orgName: string;
  orgStartDate: string;
  userUuid: string;
}

interface IOption {
  onClick: () => void;
  id: string;
  label: string;
  disabled?: boolean;
  className?: string;
}

const SwitchOrgs = ({ sideMenuExpanded }: { sideMenuExpanded: boolean }): React.ReactElement | null => {
  const dispatch = useDispatch();
  const selectedOrg = useSelector((state: State) => state.organization);
  const { uuid, preferences, permissions, role } = useSelector((state: State) => state.user);
  const [userOrgs, setUserOrgs] = useState<IOption[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [createNewOrgOpen, setCreateNewOrgOpen] = useState<boolean>(false);
  const [orgName, setOrgName] = useInput({});

  const getUserOrgs = async (): Promise<void> => {
    const response = (await request({
      url: '/organizations',
      method: 'GET',
    })) as IOrganizationResponse;
    if (response.data.data) {
      const formattedOrgs = response.data.data
        .map((org) => {
          return {
            onClick: () =>
              handleSelectOrg({
                orgName: org.name,
                orgUuid: org.uuid,
                orgStartDate: org.configuration.companyStartDate,
                userUuid: uuid,
              }),
            label: org.name,
            disabled: org.uuid === selectedOrg.uuid,
            className: 'w-[250px] text-left',
            id: org.uuid,
          };
        })
        .sort((a, b) => a.label.localeCompare(b.label));
      setUserOrgs((prev) => [...prev, ...formattedOrgs.filter((org) => !prev.some((o) => o.id === org.id))]);
    }
  };

  useEffect(() => {
    if (uuid) {
      getUserOrgs();
    }
  }, [uuid]);

  useEffect(() => {
    if (role !== 'user') {
      setUserOrgs((prev) => [
        {
          id: '1',
          label: 'Create New Org',
          onClick: () => setCreateNewOrgOpen(true),
          className:
            'text-green-500 hover:bg-transparent hover:text-green-600 border-b-2 border-green-75 w-full flex justify-center items-center w-[250px]',
        },
        ...prev.filter((org) => org.id !== '1'),
      ]);
    }
  }, [role]);

  const handleSelectOrg = async (selectParams: IHandleSelectOrgParams): Promise<void> => {
    let defaultGraphStartDate = selectParams.orgStartDate;
    if (preferences.defaultGraphStartDate) {
      const needToChangeDefaultDate = isBefore(preferences.defaultGraphStartDate, selectParams.orgStartDate);
      defaultGraphStartDate = needToChangeDefaultDate ? selectParams.orgStartDate : preferences.defaultGraphStartDate;
    }

    dispatch(scenarioSlice.actions.reset());

    const response = (await request({
      url: `/users/${selectParams.userUuid}/preferences`,
      method: 'PATCH',
      body: {
        sideMenuExpanded: preferences.sideMenuExpanded,
        primaryOrganizationUuid: selectParams.orgUuid,
        defaultGraphStartDate,
      },
    })) as IUserPreferencesResponse;

    if (response.status === StatusCodes.OK) {
      window.location.href = window.location.pathname;
    } else {
      toast.error('Failed to switch organization');
    }
  };

  const handleCreateOrg = async (): Promise<void> => {
    if (orgName.validation.test(orgName.value)) {
      const response = (await request({
        url: '/organizations',
        method: 'POST',
        body: {
          name: orgName.value,
        },
      })) as IOrganizationResponse;

      if (response.status === StatusCodes.CREATED) {
        await getUserOrgs();
        dispatch(
          setPermissions({
            ...permissions,
          }),
        );
        setCreateNewOrgOpen(false);
        toast.success('Organization created successfully');
      } else {
        toast.error('Failed to create organization');
      }
    }
  };

  if (userOrgs.length <= 1 || !sideMenuExpanded) return null;

  return (
    <>
      <div className="w-full flex justify-center px-2">
        <PopoverComponent
          fill="clear"
          options={userOrgs}
          className="truncate w-[250px] outline-none border-green-75 border-2 hover:bg-white hover:border-white"
          direction="below"
          maxPanelHeight="max-h-[600px] max-sm:max-h-[400px]"
          onOpenChange={(isOpen) => setOpen(isOpen)}
        >
          <div className="flex w-full justify-between items-center" data-testid="org-selector">
            <span className="truncate truncate-ellipsis max-w-[80%]">{selectedOrg.name}</span>
            {open ? <ChevronUpIcon className="size-5" /> : <ChevronDownIcon className="size-5" />}
          </div>
        </PopoverComponent>
      </div>
      <Modal
        isOpen={createNewOrgOpen}
        onClose={() => setCreateNewOrgOpen(false)}
        title="Create Organization"
        size="xs"
        id="create-org-modal"
      >
        <div className="flex flex-col gap-4 w-full">
          <InputWrapper
            label="Organization Name"
            type="text"
            state={orgName}
            setState={setOrgName}
            id="create-org-name"
          />
          <div className="flex w-full justify-between items-center">
            <Button fill="destructiveClear" className="!w-fit" onClick={() => setCreateNewOrgOpen(false)}>
              Cancel
            </Button>
            <Button fill="solid" className="!w-fit" onClick={handleCreateOrg} id="create-org-button">
              Create Org
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SwitchOrgs;

import { IPositionDetails } from '~/pages/Headcount/entity/types';
import { ExpenseFrequencyEnum, IExpense } from '../components/Expenses/types';
import { addMonths, getMonth, getYear, isSameMonth, isSameYear } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const isExpenseAppliedInMonth = ({
  expense,
  month,
  positions,
}: {
  expense: IExpense;
  month: Date;
  positions: IPositionDetails[];
}): boolean => {
  const expenseStartDate = toZonedTime(new Date(expense.context.startDate), 'UTC');
  const expenseEndDate = expense.context.endDate ? toZonedTime(new Date(expense.context.endDate), 'UTC') : null;

  switch (expense.context.frequency) {
    case ExpenseFrequencyEnum.OneTime:
      return isSameMonth(expenseStartDate, month);
    case ExpenseFrequencyEnum.Monthly:
      return expenseStartDate <= month && (!expenseEndDate || expenseEndDate >= month);
    case ExpenseFrequencyEnum.Quarterly: {
      const validMonths = [
        expenseStartDate,
        addMonths(expenseStartDate, 3),
        addMonths(expenseStartDate, 6),
        addMonths(expenseStartDate, 9),
      ];
      return (
        expenseStartDate <= month &&
        validMonths.some((validMonth) => getMonth(validMonth) === getMonth(month)) &&
        (!expenseEndDate || expenseEndDate >= month)
      );
    }
    case ExpenseFrequencyEnum.Annually: {
      const startYear = getYear(expenseStartDate);
      return (
        getMonth(expenseStartDate) === getMonth(month) &&
        startYear <= getYear(month) &&
        (!expenseEndDate || expenseEndDate >= month)
      );
    }
    case ExpenseFrequencyEnum.OnHire: {
      return positions.some((position) => {
        const hireDateStringWithoutTimezone = position.hireDate.split('T')[0];
        const dateOnlyString = hireDateStringWithoutTimezone.split('T')[0];
        const hireDateWithoutTimezone = new Date(`${dateOnlyString}T00:00:00`);
        return (
          isSameMonth(new Date(hireDateWithoutTimezone), month) && isSameYear(new Date(hireDateWithoutTimezone), month)
        );
      });
    }
    default:
      return false;
  }
};

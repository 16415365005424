import { CSSProperties } from 'react';
import { Column } from '@tanstack/react-table';

export const getCommonPinningStyles = ({
  column,
  type,
}: {
  column: Column<Record<string, unknown>, unknown>;
  type?: 'header' | 'cell';
}): CSSProperties => {
  const canPin = column.getCanPin();

  const zIndex = ((): number | undefined => {
    if (canPin && type === 'header') {
      return 20;
    } else if (canPin && type === 'cell') {
      return 10;
    } else {
      return undefined;
    }
  })();

  return {
    left: canPin ? `${column.getStart()}px` : undefined,
    position: canPin ? 'sticky' : 'relative',
    zIndex,
    opacity: canPin ? 1 : undefined,
  };
};

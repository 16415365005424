import React, { ReactElement } from 'react';
import { IColumn } from './types';
import useTableContext from './hooks/useTableContext';

interface IProps {
  columns: IColumn[];
}

const TableHeaders = ({ columns }: IProps): ReactElement => {
  const { columnWidths } = useTableContext();

  const headerWidths = columns.reduce<number[]>((output, column, index) => {
    if (column.Header) {
      output[output.length] = columnWidths[index];
    } else {
      output[output.length - 1] += columnWidths[index];
    }
    if (index === columns.length - 1) {
      output[index] = columnWidths[index];
    }
    return output;
  }, []);

  const totalWidth = columnWidths.reduce((sum, width) => sum + width, 0);

  return (
    <div
      className="sticky top-0 z-20 flex bg-white border-b border-neutral-50 min-w-full"
      style={{ width: `${totalWidth}px` }}
    >
      {columns.map(({ Header, key }, index) => {
        if (!Header) return null;
        return <Header key={key} columnWidth={headerWidths[index]} columnIndex={index} />;
      })}
    </div>
  );
};

export default TableHeaders;

import React, { useState } from 'react';
import Card from '~/components/Card';
import request from '~/utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { State } from '~/store';
import useInput from '~/components/Input/useInput';
import Button from '~/components/Button';
import Typography from '~/components/Typography';
import DepartmentListItem from './DepartmentListItem';
import CreateDepartmentModal from './CreateDepartmentModal';
import { setDepartments } from '~/store/organizationSlice';
import { refreshDepartments } from '~/utils/refreshDepartments';
import { ZNewDepartmentResponseData } from './entity/schemas';

const GroupsContainer = (): React.ReactNode => {
  const dispatch = useDispatch();
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const [showCreateDepartmentModal, setShowCreateDepartmentModal] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useInput({});
  const departments = useSelector((state: State) => state.organization.departments);

  const attemptAddDepartment = async (): Promise<void> => {
    if (newDepartmentName.valid) {
      const newDepartmentResponse = await request({
        url: `/organizations/${organizationUuid}/groups`,
        method: 'POST',
        body: {
          name: newDepartmentName.value,
        },
        params: {
          scenarioUuid: activeScenarioUuid,
        },
      });
      const newDepartment = ZNewDepartmentResponseData.parse(newDepartmentResponse.data);
      dispatch(setDepartments([...departments, newDepartment.data]));
      setNewDepartmentName({
        ...newDepartmentName,
        value: '',
      });
      setShowCreateDepartmentModal(false);
    } else {
      setNewDepartmentName({
        ...newDepartmentName,
        pristine: false,
        touched: true,
      });
    }
  };

  return (
    <Card className="w-full flex flex-col gap-5">
      <CreateDepartmentModal
        isOpen={showCreateDepartmentModal}
        onClose={() => {
          setShowCreateDepartmentModal(false);
          setNewDepartmentName({
            ...newDepartmentName,
            value: '',
          });
        }}
        newDepartmentName={newDepartmentName}
        setNewDepartmentName={setNewDepartmentName}
        attemptAddDepartment={attemptAddDepartment}
      />
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col w-full">
          <Typography size="md" weight="bold">
            Departments
          </Typography>
          <Typography color="secondary" size="xs">
            Create and manage groups
          </Typography>
        </div>
        <Button fill="outline" className="!w-auto text-nowrap" onClick={() => setShowCreateDepartmentModal(true)}>
          Add department
        </Button>
      </div>
      <div className="flex flex-col w-full">
        {departments.map((department) => (
          <DepartmentListItem key={department.uuid} department={department} refreshDepartments={refreshDepartments} />
        ))}
      </div>
    </Card>
  );
};

export default GroupsContainer;

import React, { createContext, useState, ReactNode, useEffect, useMemo } from 'react';
import * as api from '~/services/parallel';
import { IIntegrationMapping, IIntegrationSources } from '~/services/parallel/integrations.types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IContextTypeEnum } from './useFormulaContext';
import objectHash from 'object-hash';
import { IRecordTypeEnum } from '../../components/FormulasTable/TableBody';
import { IFormula, IRecipe, IUpdateSortOrderParams } from '~/services/parallel/formulas.types';
import { DragEndEvent } from '@dnd-kit/core';
import { cloneDeep } from 'lodash';
import { createMonthArrayBetweenDates } from '~/utils/dates/createMonthArrayBetweenDates';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { endOfMonth, isAfter, isSameMonth, startOfMonth } from 'date-fns';
import date from '~/utils/dates/date';
import { v4 as uuid } from 'uuid';
import { IUpdateMonthValueParams } from '~/services/parallel/formulas';
import logger from '~/utils/logger';
import { formatDateToISO } from '~/utils/dates/formatDateToISO';
import { useInput } from '~/components/Input/InputWrapper';
import { convertModelToCsvData } from './utils/convertModelToCsvData';
import { IOrganizationState } from '~/store/organizationSlice';
import { ScenarioState } from '~/store/scenarioSlice';
import { UserState } from '~/store/userSlice';

enum IFormattingEnum {
  Number = 'number',
  Currency = 'currency',
  Percent = 'percent',
}

enum IRoundDirectionEnum {
  Up = 'up',
  Down = 'down',
  Nearest = 'nearest',
}

export interface IRoundingInstructions {
  direction: IRoundDirectionEnum;
  precision: number;
}

export type IMonthlyValues = Record<
  string,
  {
    calculatedValue: number;
    overrideValue: number | null;
    actualValue: number | null;
    formatting: IFormattingEnum | null;
    roundingInstructions: IRoundingInstructions | null;
  }
>;
export interface IFormulaItemData {
  type: IRecordTypeEnum.Formula;
  formulaUuid: string;
  label: {
    name: string;
    integration: string | null;
    isIsolated: boolean;
  };
  formula: {
    recipe: IRecipe;
  };
  monthlyValues: IMonthlyValues;
}

export interface IFormulaGroupData {
  type: IRecordTypeEnum.Group;
  uuid: string;
  name: string;
  isCollapsed: boolean;
  formulas: IFormulaItemData[];
}

export interface ILoadingFormulas {
  requestUuid: string;
  formulaUuids: string[];
  months: string[];
}

export interface IModelBuilderContext {
  type: IContextTypeEnum;
  toggleGroupCollapse: (value: string | boolean) => void;
  onDragEnd: (value: DragEndEvent) => void;
  updateSortOrder: (updatedFormulasData: IFormulaGroupData[]) => Promise<void>;
  formulaDictionary: Record<string, IFormula>;
  selectedMonths: string[];
  updateFormulaMonthValue: (params: {
    formulaUuid: string;
    month: string;
    value: number | null;
    lastNeededDate: Date;
  }) => void;
  availableIntegration: IIntegrationSources | null;
  loadingFormulas: ILoadingFormulas[];
  updateFormulaName: (params: { formulaUuid: string; name: string }) => void;
  updateFormulaFormatting: (params: { formulaUuid: string; formatting: IFormattingEnum }) => void;
  updateFormulaRounding: (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }) => void;
  updateFormulaDataSource: (params: { formulaUuid: string; dataSourceUuids: string[] }) => void;
  dataSources: IIntegrationMapping[];
  allFormulasData: IFormulaGroupData[];
  filteredFormulasData: IFormulaGroupData[];
  searchFilter: Types.InputState;
  setSearchFilter: React.Dispatch<React.SetStateAction<Types.InputState>>;
  refreshData: () => Promise<void>;
  createNewAttribute: (params: { uuid: string }) => void;
  pendingAttributeData: {
    groupUuid: string;
  } | null;
  setPendingAttributeData: React.Dispatch<
    React.SetStateAction<{
      groupUuid: string;
    } | null>
  >;
  saveNewAttribute: (params: { groupIndex: number; name: string; tabIntoFormula: boolean }) => Promise<void>;
  deleteFormula: (formulaUuid: string) => Promise<void>;
  formulaUuidToFocus: string | null;
  setFormulaUuidToFocus: React.Dispatch<React.SetStateAction<string | null>>;
  pendingTabTarget: {
    columnIndex: number;
    formulaUuid: string;
  } | null;
  setPendingTabTarget: React.Dispatch<React.SetStateAction<{ columnIndex: number; formulaUuid: string } | null>>;
  csvExportData: string;
  viewOnly: boolean;
  manageGroupsModalOpen: boolean;
  setManageGroupsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ModelBuilderContext = createContext<IModelBuilderContext | undefined>(undefined);

interface ModelBuilderProviderProps {
  children: ReactNode;
  defaultData?: {
    organization: IOrganizationState;
    scenario: ScenarioState;
    user: UserState;
  };
  viewOnly?: boolean;
  startDate?: Date;
  endDate?: Date;
  scenarioUuid?: string;
}

export const ModelBuilderProvider: React.FC<ModelBuilderProviderProps> = ({
  children,
  defaultData,
  viewOnly = false,
  startDate,
  endDate,
  scenarioUuid,
}: ModelBuilderProviderProps) => {
  const state = useSelector((state: State) => state);
  const { organization, scenario, user } = defaultData ?? state;
  const { defaultGraphEndDate, defaultGraphStartDate } = user.preferences;
  const [allFormulasData, setAllFormulasData] = useState<IFormulaGroupData[]>([]);
  const [formulaDictionary, setFormulaDictionary] = useState<Record<string, IFormula>>({});
  const [loadingFormulas, setLoadingFormulas] = useState<ILoadingFormulas[]>([]);
  const [searchFilter, setSearchFilter] = useInput({ validation: /.*/ });
  const [dataSources, setDataSources] = useState<IIntegrationMapping[]>([]);
  const [availableIntegration, setAvailableIntegration] = useState<IIntegrationSources | null>(null);
  const [pendingAttributeData, setPendingAttributeData] = useState<{
    groupUuid: string;
  } | null>(null);
  const [formulaUuidToFocus, setFormulaUuidToFocus] = useState<string | null>(null);
  const [pendingTabTarget, setPendingTabTarget] = useState<{
    columnIndex: number;
    formulaUuid: string;
  } | null>(null);
  const csvExportData = useMemo(
    () =>
      convertModelToCsvData({
        modelData: allFormulasData,
        startDate: startDate ?? new Date(defaultGraphStartDate),
        endDate: endDate ?? new Date(defaultGraphEndDate),
      }),
    [allFormulasData, defaultGraphEndDate, defaultGraphStartDate, startDate, endDate],
  );
  const [manageGroupsModalOpen, setManageGroupsModalOpen] = useState(false);

  const filteredFormulasData = useMemo(() => {
    return allFormulasData
      .map((group) => ({
        ...group,
        isCollapsed: searchFilter.value ? false : group.isCollapsed,
        formulas: group.formulas.filter(
          (formula) =>
            formula.formula.recipe.name.toLowerCase().includes(searchFilter.value.toLowerCase()) ||
            Object.values(formula.formula.recipe.variables).some(
              (variable) =>
                variable.formulaUuid &&
                variable.formulaUuid in formulaDictionary &&
                formulaDictionary[variable.formulaUuid].recipe.name
                  .toLowerCase()
                  .includes(searchFilter.value.toLowerCase()),
            ),
        ),
      }))
      .filter((group) => (searchFilter.value ? group.formulas.length : true));
  }, [allFormulasData, searchFilter.value]);

  const selectedMonths = useMemo(() => {
    const startDateTime = startDate ?? user.preferences.defaultGraphStartDate;
    const endDateTime = endDate ?? user.preferences.defaultGraphEndDate;

    // Use local timezone from the browser
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert to start of day in local timezone
    const zonedStartDate = toZonedTime(startDateTime, localTimezone);
    const zonedEndDate = toZonedTime(endDateTime, localTimezone);

    return createMonthArrayBetweenDates(zonedStartDate, zonedEndDate).map((month) =>
      formatInTimeZone(month, localTimezone, 'MMM yyyy'),
    );
  }, [user.preferences.defaultGraphEndDate, user.preferences.defaultGraphStartDate]);

  const fetchData = async (): Promise<void> => {
    const formulasList = await api.formulas.list({
      organizationUuid: organization.uuid,
      startDate: startDate ?? new Date(defaultGraphStartDate),
      endDate: endDate ?? new Date(defaultGraphEndDate),
      scenarioUuid: scenarioUuid ?? scenario.activeScenarioData?.uuid ?? undefined,
    });

    const formulasDictionary = formulasList.reduce((output: Record<string, IFormula>, formula) => {
      output[formula.formulaUuid] = formula;
      return output;
    }, {});
    setFormulaDictionary(formulasDictionary);

    const sortOrder = await api.formulas.getSortOrder({
      organizationUuid: organization.uuid,
    });

    const integrationsList = !defaultData
      ? await api.integrations.list({
          organizationUuid: organization.uuid,
        })
      : [];

    if (integrationsList.length) {
      setAvailableIntegration(integrationsList[0].source?.slug as IIntegrationSources);
    }

    let integrationMappings: IIntegrationMapping[] = [];
    if (integrationsList.length) {
      integrationMappings = await api.integrations.listMappings({
        organizationUuid: organization.uuid,
        integrationUuid: integrationsList[0].uuid,
        scope: 'modelBuilder',
      });
    }

    setDataSources(integrationMappings);

    const integrationMappingsDictionary = integrationMappings.reduce(
      (output: Record<string, string>, integrationMapping) => {
        if (integrationMapping.uuid in output) return output;
        const integrationSlug = integrationsList.find(
          (integration) => integration.uuid === integrationMapping.integrationUuid,
        );
        if (integrationSlug) {
          output[integrationMapping.uuid] = integrationSlug.source?.slug ?? '';
        }
        return output;
      },
      {},
    );

    const tableData = sortOrder.groups.map((group): IFormulaGroupData => {
      const formulas = group.sortOrder
        .filter((formulaUuid) => {
          /**
           * Temporary logging to find out where the formula is not found in the dictionary
           * Long term solution is to update sort order to be on the record level.
           */
          return formulaUuid in formulasDictionary;
        })
        .map((formulaUuid): IFormulaItemData => {
          const isReferencedElsewhere = formulasList.some((f) =>
            Object.values(f.recipe.variables).some((variable) => variable.formulaUuid === formulaUuid),
          );
          const formula = formulasDictionary[formulaUuid];

          const actualsDict = formula.actuals.reduce<Record<string, number>>((output, actual) => {
            output[formatInTimeZone(actual.date, 'UTC', 'MMM yyyy')] = actual.value;
            return output;
          }, {});

          const overridesDict = formula.overrides.reduce<Record<string, number>>((output, override) => {
            output[formatInTimeZone(override.date, 'UTC', 'MMM yyyy')] = override.value;
            return output;
          }, {});

          const calculationsDict = formula.calculations.reduce<Record<string, number>>((output, calculation) => {
            output[formatInTimeZone(calculation.date, 'UTC', 'MMM yyyy')] = calculation.value ?? 0;
            return output;
          }, {});

          const monthlyValues = selectedMonths.reduce<IMonthlyValues>((output, month) => {
            output[month] = {
              calculatedValue: calculationsDict[month] ?? 0,
              overrideValue: overridesDict[month] ?? null,
              actualValue: actualsDict[month] ?? null,
              formatting: formula.formatting ?? null,
              roundingInstructions: formula.recipe.roundingInstructions ?? null,
            };
            return output;
          }, {});

          return {
            type: IRecordTypeEnum.Formula,
            formulaUuid: formula.formulaUuid,
            label: {
              name: formula.recipe.name,
              integration:
                (formula.dataSourceUuids.length && integrationMappingsDictionary[formula.dataSourceUuids[0]]) || null,
              isIsolated: !isReferencedElsewhere,
            },
            formula: {
              recipe: formula.recipe,
            },
            monthlyValues,
          };
        });

      return {
        type: IRecordTypeEnum.Group,
        uuid: objectHash(group),
        name: group.name,
        isCollapsed: false,
        formulas,
      };
    });

    setAllFormulasData(tableData);
  };

  useEffect(() => {
    if (organization.uuid) {
      fetchData();
    }
  }, [defaultGraphEndDate, defaultGraphStartDate, organization.uuid, startDate, endDate]);

  const toggleGroupCollapse = (value: string | boolean): void => {
    if (typeof value === 'string') {
      // Toggle individual group
      setAllFormulasData((prev) =>
        prev.map((group) => (group.uuid === value ? { ...group, isCollapsed: !group.isCollapsed } : group)),
      );
    } else {
      // Toggle all groups at once
      setAllFormulasData((prev) => prev.map((group) => ({ ...group, isCollapsed: value })));
    }
  };

  const onDragEnd = (value: DragEndEvent): void => {
    if (viewOnly) return;
    const { active, over } = value;
    if (!over) return;

    const clonedGroups = cloneDeep(allFormulasData);
    // Find the group and formula of the active item
    const sourceGroup = clonedGroups.find((group) =>
      group.formulas.some((formula) => formula.formulaUuid === active.id.toString()),
    );

    const targetGroup = clonedGroups.find(
      (group) =>
        group.formulas.some((formula) => formula.formulaUuid === over.id.toString()) ||
        group.uuid === over.id.toString(),
    );

    if (sourceGroup && targetGroup) {
      const sourceIndex = sourceGroup.formulas.findIndex((f) => f.formulaUuid === active.id.toString());
      const targetIndex = targetGroup.formulas.findIndex((f) => f.formulaUuid === over.id.toString());

      // If moving within the same group, reorder
      if (sourceGroup.name === targetGroup.name) {
        const [movedFormula] = sourceGroup.formulas.splice(sourceIndex, 1);

        if (sourceIndex > targetIndex) {
          // Moving down the list
          targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
        } else {
          // Moving up the list
          targetGroup.formulas.splice(targetIndex, 0, movedFormula);
        }
      } else {
        // Remove the item from its original position
        const [movedFormula] = sourceGroup.formulas.splice(
          sourceGroup.formulas.findIndex((f) => f.formulaUuid === active.id.toString()),
          1,
        );
        if (targetGroup.isCollapsed) {
          // If moving to a closed group, add it to the beginning of the target group
          targetGroup.formulas.unshift(movedFormula);
        } else {
          // Add to after the target index
          targetGroup.formulas.splice(targetIndex + 1, 0, movedFormula);
        }
      }
    }

    updateSortOrder(clonedGroups);
  };

  const updateSortOrder = async (updatedFormulasData: IFormulaGroupData[]): Promise<void> => {
    if (viewOnly) return;
    setAllFormulasData(updatedFormulasData);

    const sortOrder: IUpdateSortOrderParams = {
      organizationUuid: organization.uuid,
      scenarioUuid: scenario.activeScenarioData?.uuid ?? undefined,
      groups: updatedFormulasData.map((group) => ({
        name: group.name,
        sortOrder: group.formulas.map((formula) => formula.formulaUuid),
      })),
    };

    await api.formulas.updateSortOrder(sortOrder);
  };

  const getEffectedMonths = (month: string): string[] => {
    const months = selectedMonths.filter(
      (m) => isAfter(new Date(m), new Date(month)) || isSameMonth(new Date(m), new Date(month)),
    );
    return months;
  };

  const getEffectedFormulas = (formulaUuid: string, processedUuids: Set<string> = new Set()): string[] => {
    // If we've already processed this UUID, return empty array to prevent circular recursion
    if (processedUuids.has(formulaUuid)) {
      return [];
    }

    // Add current UUID to processed set
    processedUuids.add(formulaUuid);

    const uuids = Object.values(formulaDictionary)
      .filter((formula) => {
        const recipeVariables = formula.recipe.variables;
        const variables = Object.values(recipeVariables);
        return variables.some((variable) => variable.formulaUuid === formulaUuid);
      })
      .map((formula) => formula.formulaUuid);

    if (uuids.length) {
      const chainedFormulaUuids = uuids.flatMap((uuid) => getEffectedFormulas(uuid, processedUuids));
      return Array.from(new Set([formulaUuid, ...uuids, ...chainedFormulaUuids]));
    }
    return [formulaUuid];
  };

  const handleLoadingMonths = (params: { requestUuid: string; formulaUuid: string; month: string }): void => {
    const effectedMonths = getEffectedMonths(params.month);
    const effectedFormulas = getEffectedFormulas(params.formulaUuid);
    setLoadingFormulas((prev) => [
      ...prev,
      {
        requestUuid: params.requestUuid,
        formulaUuids: [params.formulaUuid, ...effectedFormulas],
        months: effectedMonths,
      },
    ]);
  };

  const resolveLoadingFormulas = (params: { requestUuid: string }): void => {
    setLoadingFormulas((prev) => prev.filter((request) => request.requestUuid !== params.requestUuid));
  };

  const updateFormulaMonthValue = async (params: {
    formulaUuid: string;
    month: string;
    value: number | null;
    lastNeededDate: Date;
  }): Promise<void> => {
    if (viewOnly) return;
    const requestUuid = uuid();
    handleLoadingMonths({ requestUuid, formulaUuid: params.formulaUuid, month: params.month });

    try {
      const formula = formulaDictionary[params.formulaUuid];
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const request: IUpdateMonthValueParams = {
        organizationUuid: organization.uuid,
        formulaUuid: params.formulaUuid,
        latestNeededDate: params.lastNeededDate,
        idempotencyKey: uuid(),
      };

      const formattedMonth = formatDateToISO({
        date: endOfMonth(date(formatInTimeZone(new Date(params.month), localTimezone, 'yyyy-MM-dd'))),
      });

      const startOfMonthDate = startOfMonth(new Date());
      const formattedStartOfMonthDate = formatDateToISO({ date: startOfMonthDate });

      if (formattedMonth >= formattedStartOfMonthDate) {
        // Value is an override
        const paramDate = endOfMonth(toZonedTime(new Date(params.month), localTimezone));
        const overrides: { date: string | Date; value: number | null }[] = formula.overrides
          .filter((override) => {
            const overrideDate = toZonedTime(new Date(override.date), 'UTC');
            return !isSameMonth(overrideDate, paramDate);
          })
          .map((override) => {
            // Parse the date as UTC and keep it in UTC
            return {
              date: override.date,
              value: override.value,
            };
          });

        if (params.value !== null) {
          const monthDate = new Date(params.month);
          const zonedMonthDate = toZonedTime(monthDate, localTimezone);
          overrides.push({
            date: formatDateToISO({
              date: endOfMonth(zonedMonthDate),
            }),
            value: params.value,
          });
        }
        request.overrides = overrides;
      } else {
        // Value is an actual
        const paramDate = endOfMonth(toZonedTime(new Date(params.month), localTimezone));
        const actuals = formula.actuals
          .filter((actual) => {
            const actualDate = toZonedTime(new Date(actual.date), 'UTC');
            return !isSameMonth(actualDate, paramDate);
          })
          .map((actual) => {
            // Parse the date as UTC and keep it in UTC
            const utcDate = new Date(actual.date);
            return {
              date: utcDate.toISOString(), // This maintains the exact UTC time
              value: actual.value,
            };
          });

        if (params.value !== null) {
          const monthDate = new Date(params.month);
          const zonedMonthDate = toZonedTime(monthDate, localTimezone);
          actuals.push({
            date: formatDateToISO({
              date: endOfMonth(zonedMonthDate),
            }),
            value: params.value,
          });
        }
        request.actuals = actuals;
      }

      await api.formulas.update(request);

      // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
      await fetchData();
    } catch (error) {
      if (error instanceof Error) {
        logger.error(error);
      }
    } finally {
      resolveLoadingFormulas({ requestUuid });
    }
  };

  const updateFormulaName = async (params: { formulaUuid: string; name: string }): Promise<void> => {
    if (viewOnly) return;
    const formula = formulaDictionary[params.formulaUuid];

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      recipe: {
        ...formula.recipe,
        name: params.name,
      },
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaFormatting = async (params: {
    formulaUuid: string;
    formatting: IFormattingEnum;
  }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      formatting: params.formatting,
    };
    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaRounding = async (params: {
    formulaUuid: string;
    direction?: IRoundDirectionEnum;
    roundingPrecision?: number;
  }): Promise<void> => {
    if (viewOnly) return;
    const recipe = formulaDictionary[params.formulaUuid].recipe;

    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      recipe: {
        ...recipe,
        roundingInstructions:
          params.direction && params.roundingPrecision
            ? {
                direction: params.direction,
                precision: params.roundingPrecision,
              }
            : null,
      },
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const updateFormulaDataSource = async (params: { formulaUuid: string; dataSourceUuids: string[] }): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid: params.formulaUuid,
      dataSourceUuids: params.dataSourceUuids,
    };

    await api.formulas.update(request);

    // TODO - Huge optimization. Only change data on the formulas that were affected. Not the whole model.
    await fetchData();
  };

  const createNewAttribute = ({ uuid }: { uuid: string }): void => {
    if (viewOnly) return;
    setPendingAttributeData({
      groupUuid: uuid,
    });
  };

  const saveNewAttribute = async (params: {
    groupIndex: number;
    name: string;
    tabIntoFormula: boolean;
  }): Promise<void> => {
    if (viewOnly) return;
    const createdAttribute = await api.formulas.createAttribute({
      organizationUuid: organization.uuid,
      groupIndex: params.groupIndex,
      name: params.name,
      currentSortOrder: allFormulasData.map((group) => ({
        name: group.name,
        sortOrder: group.formulas.map((formula) => formula.formulaUuid),
      })),
    });

    await fetchData();

    setPendingAttributeData(null);

    if (params.tabIntoFormula && createdAttribute.formulaUuid) {
      setFormulaUuidToFocus(createdAttribute.formulaUuid);
    }
  };

  const deleteFormula = async (formulaUuid: string): Promise<void> => {
    if (viewOnly) return;
    const request = {
      organizationUuid: organization.uuid,
      formulaUuid,
    };
    await api.formulas.remove(request);
    await fetchData();
  };

  useEffect(() => {
    const handleClick = (): void => {
      if (pendingTabTarget) setPendingTabTarget(null);
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const value = {
    type: IContextTypeEnum.ModelBuilder,
    allFormulasData,
    formulaDictionary,
    dataSources,
    availableIntegration,
    toggleGroupCollapse,
    onDragEnd,
    updateSortOrder,
    selectedMonths,
    updateFormulaMonthValue,
    loadingFormulas,
    updateFormulaName,
    updateFormulaFormatting,
    updateFormulaRounding,
    updateFormulaDataSource,
    filteredFormulasData,
    searchFilter,
    setSearchFilter,
    createNewAttribute,
    pendingAttributeData,
    setPendingAttributeData,
    saveNewAttribute,
    deleteFormula,
    formulaUuidToFocus,
    setFormulaUuidToFocus,
    refreshData: fetchData,
    pendingTabTarget,
    setPendingTabTarget,
    csvExportData,
    viewOnly,
    manageGroupsModalOpen,
    setManageGroupsModalOpen,
  };

  return <ModelBuilderContext.Provider value={value}>{children}</ModelBuilderContext.Provider>;
};

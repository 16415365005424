import request from '~/utils/request';
import { handleCreateScenario } from '~/utils/handleCreateScenario';
import { IAPIResponse } from '~/utils/types';

export const updateScenario = async ({
  scenarioUuid,
  organizationUuid,
  formulaUuid,
  overrides,
  actuals,
}: {
  scenarioUuid: string | null;
  organizationUuid: string;
  formulaUuid: string;
  overrides: {
    value: number;
    date: Date;
  }[];
  actuals: {
    value: number;
    date: Date;
  }[];
}): Promise<void> => {
  const response = (await request({
    url: `/formulas/${formulaUuid}`,
    method: 'PATCH',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
    params: {
      scenarioUuid: scenarioUuid ?? undefined,
      createScenario: scenarioUuid ? undefined : true,
    },
    body: {
      overrides,
      actuals,
    },
  })) as IAPIResponse;
  if (response.status >= 400) {
    throw new Error('Failed to add override and actual');
  }

  handleCreateScenario({
    response,
  });
};

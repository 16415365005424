import React, { useState } from 'react';
import { RadioInputState } from './RadioInput.types';

const useRadioInput = ({
  options,
  selected,
  errorMessage,
  required,
}: Partial<RadioInputState>): [
  value: RadioInputState,
  setState: React.Dispatch<React.SetStateAction<RadioInputState>>,
  resetState: () => void,
] => {
  let valid;
  if (Array.isArray(selected)) {
    valid =
      selected.every((selectedOption) => options?.some((option) => option.value === selectedOption.value)) || false;
  } else {
    valid = Boolean(options?.some((option) => option.value === selected?.value)) || false;
  }

  let selectedValue = selected;
  if (!selectedValue) {
    selectedValue = undefined;
  }

  const initialState: RadioInputState = {
    options: options ?? [],
    selected: selectedValue,
    valid,
    touched: false,
    pristine: true,
    errorMessage,
    required,
  };
  const [radioInputState, setRadioInputState] = useState<RadioInputState>(initialState);
  const resetInput = (): void => setRadioInputState(initialState);
  return [radioInputState, setRadioInputState, resetInput];
};

export default useRadioInput;

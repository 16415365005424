import { SelectMultipleState } from '~/components/SelectMultiple/SelectMultiple.types';
import React, { useState } from 'react';

const useSelectMultiple = ({
  options,
  selected,
  isNullable = false,
  isLoading = false,
}: Partial<SelectMultipleState> & {
  isNullable?: boolean;
  isLoading?: boolean;
}): [
  value: SelectMultipleState,
  setState: React.Dispatch<React.SetStateAction<SelectMultipleState>>,
  resetState: () => void,
  setLoading: (isLoading: boolean) => void,
] => {
  const valid =
    ((isNullable && selected?.length === 0) ||
      selected?.every((selectedOption) => options?.some((option) => option.value === selectedOption.value))) ??
    false;

  let selectedValue = selected;
  if (!selectedValue) {
    selectedValue = [];
  }

  const initialState: SelectMultipleState = {
    options: options ?? [],
    selected: selectedValue,
    valid,
    touched: false,
    pristine: true,
    isLoading,
  };
  const [selectMultipleState, setSelectMultipleState] = useState(initialState);
  const resetInput = (): void => setSelectMultipleState(initialState);
  const setLoading = (isLoading: boolean): void => {
    setSelectMultipleState((prevState) => ({ ...prevState, isLoading }));
  };
  return [selectMultipleState, setSelectMultipleState, resetInput, setLoading];
};

export default useSelectMultiple;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import Timeline from '~/components/Timeline';
import { HeadcountContext } from '../../context/HeadcountContext';
import { IPositionDetailsWithOrderedDates } from '../../entity/types';
import HeadcountTimelineNodeContent from './HeadcountTimelineNodeContent';
import date from '~/utils/dates/date';
import { addDays, addMonths, endOfMonth, isAfter, isBefore, startOfMonth, subMonths } from 'date-fns';
import { State } from '~/store';
import { useDispatch, useSelector } from 'react-redux';
import useQueryParams from '~/utils/hooks/useQueryParams';
import Button from '~/components/Button';
import { ZPositionDetailsWithOrderedDates } from '../../entity/schemas';
import request from '~/utils/request';
import { HttpStatusCode } from 'axios';
import toast from 'react-hot-toast';
import { useRevalidator } from 'react-router-dom';
import Typography from '~/components/Typography';
import { update, updateScenarioLoadingState, updateScenarioMode } from '~/store/scenarioSlice';
import isEqual from 'lodash.isequal';
import { IAPIResponse } from '~/utils/types';
import { fetchSpecificPositions } from '../../utils/fetchSpecificPositions';
import EditPosition from './EditPosition/EditPosition';
import { IEditPositionFormFields } from './EditPosition/EditPositionForm';
import { useFeatureFlag } from '~/utils/hooks/useFeatureFlag';

const HeadcountTimeline = ({
  addNewPosition,
  onlyNewHires,
  createScenario,
  onlyActivePositions,
  reloadDashboard,
}: {
  addNewPosition?: () => void;
  onlyNewHires?: boolean;
  createScenario?: boolean;
  onlyActivePositions?: boolean;
  reloadDashboard?: () => Promise<void>;
}): React.ReactNode => {
  const inlineHeadcount = useFeatureFlag('inlineHeadcount');
  const revalidator = useRevalidator();
  const dispatch = useDispatch();
  const {
    renderedPositions,
    addTermDateState,
    positionActiveStateDict,
    setPositions,
    setRenderedPositions,
    editPositionUuid,
    setEditPositionUuid,
    editPositionFormState,
    positions,
    clearInlineCreateForm,
    inlineCreate,
  } = useContext(HeadcountContext);
  const [draggingChanges, setDraggingChanges] = useState<{
    positionIds: string[];
    daysToAdd: number;
  }>({ positionIds: [], daysToAdd: 0 });
  const { activeScenarioUuid } = useSelector((state: State) => state.scenario);
  const scenarioState = useSelector((state: State) => state.scenario);
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const { uuid: organizationUuid } = useSelector((state: State) => state.organization);
  const [queryParams] = useQueryParams();
  const selectedDepartment = queryParams.get('departments');
  const [positionsList, setPositionsList] = useState<IPositionDetailsWithOrderedDates[]>(
    ZPositionDetailsWithOrderedDates.array().parse(renderedPositions),
  );

  if (inlineCreate && inlineHeadcount) {
    clearInlineCreateForm();
  }

  useEffect(() => {
    if (!isEqual(positionsList, renderedPositions)) {
      setPositionsList(ZPositionDetailsWithOrderedDates.array().parse(renderedPositions));
    }
  }, [renderedPositions]);

  const handleDateChange = async ({
    positionIds,
    daysToAdd,
  }: {
    positionIds: string[];
    daysToAdd: number;
  }): Promise<void> => {
    setDraggingChanges({ positionIds, daysToAdd });
  };

  const handleSave = async ({ positionIds }: { positionIds: string[] }): Promise<void> => {
    if (!activeScenarioUuid && createScenario) {
      dispatch(updateScenarioMode('creating'));
      dispatch(updateScenarioLoadingState('creating'));
    } else if (createScenario) {
      dispatch(updateScenarioLoadingState('updating'));
    }
    const { daysToAdd } = draggingChanges;

    const updates: {
      positionUuid: string;
      hireDate: string;
      termDate: string | null;
    }[] = [];

    const updatedPositions = positionsList.map((position) => {
      if (positionIds.includes(position.positionUuid)) {
        const updatedPosition = {
          ...position,
          hireDate: addDays(new Date(position.hireDate), daysToAdd).toISOString(),
        };

        if (position.termDate) {
          updatedPosition.termDate = addDays(new Date(position.termDate), daysToAdd).toISOString();
        }

        updates.push({
          positionUuid: position.positionUuid,
          hireDate: updatedPosition.hireDate,
          termDate: updatedPosition.termDate,
        });

        return updatedPosition;
      }
      return position;
    });

    if (!isEqual(updatedPositions, positionsList)) {
      setPositionsList(updatedPositions);
    }

    // Reset dragging changes
    setDraggingChanges((prevState) => ({
      ...prevState,
      positionIds: [],
      daysToAdd: 0,
    }));

    const response = (await request({
      url: `/positions`,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Organization-Uuid': organizationUuid,
      },
      params: {
        scenarioUuid: activeScenarioUuid ?? undefined,
        createScenario: !createScenario || activeScenarioUuid ? undefined : true,
      },
      body: updates,
    })) as IAPIResponse<IPositionDetailsWithOrderedDates[]>;
    if (response.status >= HttpStatusCode.BadRequest) {
      toast.error('Failed to save changes');
      revalidator.revalidate();
    }

    const scenarioUuidHeader =
      typeof response.headers['scenario-uuid'] === 'string' ? response.headers['scenario-uuid'] : null;

    if (scenarioUuidHeader && !activeScenarioUuid) {
      dispatch(
        update({
          ...scenarioState,
          activeScenarioUuid: scenarioUuidHeader,
          activeScenarioHasChanges: true,
          scenarioLoadingState: 'creating',
          activeScenarioData: {
            type: 'dynamic',
            uuid: scenarioUuidHeader,
            organizationUuid,
            changeDescription: 'Untitled Scenario',
            createdBy: userUuid,
            createdAt: new Date().toISOString(),
            updatedAt: new Date().toISOString(),
            markedAsStaleAt: null,
          },
        }),
      );
    }

    const positionsToUpdate = await fetchSpecificPositions({
      positionUuids: updates.map((update) => update.positionUuid),
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });
    setPositions((prevPositions) => {
      return prevPositions.map((position) => {
        const updatedPosition = positionsToUpdate.find(
          (updatedPosition) => updatedPosition.positionUuid === position.positionUuid,
        );
        return updatedPosition
          ? { ...position, ...updatedPosition }
          : {
              ...position,
              isActive: positionActiveStateDict[position.positionUuid],
            };
      });
    });
    setRenderedPositions((prevPositions) => {
      return prevPositions.map((position) => {
        const updatedPosition = positionsToUpdate.find(
          (updatedPosition) => updatedPosition.positionUuid === position.positionUuid,
        );
        return updatedPosition
          ? { ...position, ...updatedPosition }
          : {
              ...position,
              isActive: positionActiveStateDict[position.positionUuid],
            };
      });
    });

    if (reloadDashboard) {
      await reloadDashboard();
    }

    dispatch(updateScenarioLoadingState('idle'));
  };

  let startBoundary: Date = useMemo(() => {
    if (onlyNewHires) {
      return startOfMonth(subMonths(date(), 1)); // Use today's date as the start boundary
    }

    return positionsList.length
      ? positionsList.reduce(
          (earliestDate: Date, { hireDate }: IPositionDetailsWithOrderedDates): Date => {
            return isBefore(date(hireDate), earliestDate) ? subMonths(date(hireDate), 1) : earliestDate;
          },
          subMonths(date(), 1),
        )
      : subMonths(date(), 1);
  }, [onlyNewHires, positionsList]);
  startBoundary = startOfMonth(startBoundary);

  const endBoundary: Date = endOfMonth(addMonths(new Date(), 36));

  const timelineHeight = useMemo(() => {
    if (activeScenarioUuid) {
      return 'max-h-[calc(100vh_-_270px)]';
    } else {
      return 'max-h-[calc(100vh_-_214px)]';
    }
  }, [activeScenarioUuid]);

  const timelineNodes = positionsList
    .sort((a, b) => {
      return isBefore(date(a.orderedDate), date(b.orderedDate)) ? -1 : 1;
    })
    .filter((position) => {
      if (onlyNewHires) {
        return isAfter(position.hireDate, date());
      }
      return true;
    })
    .filter((position) => {
      return onlyActivePositions ? position.isActive : true;
    })
    .filter((position) => {
      const parsedPosition = ZPositionDetailsWithOrderedDates.parse(position);
      if (selectedDepartment && selectedDepartment !== 'all') {
        return parsedPosition.departmentUuid === selectedDepartment;
      } else {
        return true;
      }
    })
    .map((position) => {
      let alteredStartDate: string = position.hireDate;
      let alteredEndDate: string | null = position.termDate;
      if (draggingChanges.positionIds.includes(position.positionUuid)) {
        alteredStartDate = addDays(position.hireDate, draggingChanges.daysToAdd).toISOString();
        alteredEndDate = position.termDate ? addDays(position.termDate, draggingChanges.daysToAdd).toISOString() : null;
      }

      return (
        <Timeline.Node
          key={position.positionUuid}
          id={position.positionUuid}
          startDate={date(alteredStartDate)}
          endDate={alteredEndDate ? date(alteredEndDate) : undefined}
        >
          <HeadcountTimelineNodeContent
            position={position}
            createScenario={createScenario}
            reloadDashboard={reloadDashboard}
          />
        </Timeline.Node>
      );
    });

  const handleOptimisticUpdate = async ({ positionUuid }: { positionUuid: string | null }): Promise<void> => {
    if (!positionUuid) {
      return;
    }
    const [updatedPosition] = await fetchSpecificPositions({
      positionUuids: [positionUuid],
      organizationUuid,
      scenarioUuid: activeScenarioUuid ?? undefined,
    });

    const updateIndex = renderedPositions.findIndex(
      (renderedPosition) => renderedPosition.positionUuid === positionUuid,
    );

    const updatedPositionIndex = positions?.findIndex((oldPosition) => oldPosition.positionUuid === positionUuid);

    if (updatedPositionIndex !== undefined && updatedPositionIndex !== -1 && positions) {
      const updatedPositions = [...positions];
      updatedPositions[updatedPositionIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setPositions(updatedPositions);
    }
    if (updateIndex !== -1) {
      const updatedPositions = [...renderedPositions];
      updatedPositions[updateIndex] = {
        ...updatedPosition,
        orderedDate: updatedPosition.hireDate,
      };
      setRenderedPositions(updatedPositions);
    }
  };

  const emptyTimeline = onlyNewHires ? (
    <div className="w-full h-[calc(90dvh_-_374px)] left-1/2 transform translate-x-1/3 ">
      <div className="sticky top-1/2 transform -translate-y-1/2 justify-center items-center flex px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
        <Typography color="empty">No Future Hires</Typography>
      </div>
    </div>
  ) : (
    <div className="sticky top-1/2 left-1/2 transform translate-x-1/2 -translate-y-1/2 justify-center items-center flex gap-2 px-4 py-2 border border-neutral-50 rounded-lg bg-neutral-15">
      <Typography color="empty">No Positions</Typography>
    </div>
  );

  return (
    <Timeline.Container
      startDateBoundary={startBoundary}
      endDateBoundary={endBoundary}
      onChange={handleDateChange}
      onSave={handleSave}
      ref={addTermDateState.containerRef}
      className={timelineHeight}
      availableSelections={positionsList.map((position) => position.positionUuid)}
    >
      {timelineNodes.length > 0 ? timelineNodes : emptyTimeline}
      {addNewPosition && (
        <Button
          id="create-position-button"
          onClick={addNewPosition}
          fill="outlineSolid"
          className="sticky bottom-8 left-5 ml-5 !w-fit z-10"
        >
          Create Position
        </Button>
      )}
      <EditPosition
        id={`edit-position-modal-${editPositionUuid}`}
        isOpen={Boolean(editPositionUuid)}
        onClose={() => {
          editPositionFormState.resetFormState();
          setEditPositionUuid(null);
        }}
        editPositionFormState={editPositionFormState}
        reload={() => revalidator.revalidate()}
        awaitCalculations
        editPositionUuid={editPositionUuid}
        handleOptimisticUpdate={handleOptimisticUpdate}
        createScenario={createScenario}
        fieldsToEdit={[
          IEditPositionFormFields.EMPLOYEE,
          IEditPositionFormFields.TITLE,
          IEditPositionFormFields.DEPARTMENT,
        ]}
      />
    </Timeline.Container>
  );
};

export default HeadcountTimeline;

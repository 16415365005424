import { IIntegrationMapping } from '~/services/parallel/integrations.types';

export const getSelectedIntegrationMappings = ({
  expenseMappings,
  expenseIntegrationMappings,
}: {
  expenseMappings?: string[];
  expenseIntegrationMappings: IIntegrationMapping[];
}): { label: string; value: string }[] => {
  if (!expenseMappings) {
    return [];
  }

  let selected: { label: string; value: string }[] = [];
  selected = expenseIntegrationMappings.reduce(
    (acc, mapping) => {
      if (expenseMappings.includes(mapping.uuid)) {
        acc.push({
          label: mapping.name,
          value: mapping.uuid,
        });
      }
      return acc;
    },
    [] as { label: string; value: string }[],
  );

  return selected;
};

import { useEffect, useState } from 'react';
import { useAccessTokenData } from '~/utils/hooks/useAccessTokenData';
import { IPageData, pageData } from './pageData';
import { IDashboardConfiguration } from '~/store/organizationSlice';

export const useShareLinkData = (): {
  data: IPageData | null;
  dashboardConfiguration: IDashboardConfiguration;
  startDate: Date;
  endDate: Date;
  pageLoading: boolean;
  organizationUuid: string;
  permissionedFormulaUuids: string[];
  organizationName: string;
} => {
  const [data, setData] = useState<IPageData | null>(null);
  const [pageLoading, setPageLoading] = useState(false);
  const accessToken = useAccessTokenData();
  const {
    organizationUuid,
    allowedResources: { dashboardConfiguration, startDate, endDate },
  } = accessToken?.link ?? {
    organizationUuid: '',
    allowedResources: {
      dashboardConfiguration: {
        metrics: [],
        graphs: [],
        levers: [],
      },
      startDate: new Date(),
      endDate: new Date(),
    },
  };

  if (!dashboardConfiguration) throw new Error('No dashboard configuration in allowed resources');

  const getData = async ({ organizationUuid }: { organizationUuid: string }): Promise<void> => {
    setPageLoading(true);
    const reportData = await pageData({ organizationUuid });
    setData(reportData);
    setPageLoading(false);
  };

  useEffect(() => {
    if (organizationUuid) {
      getData({ organizationUuid });
    }
  }, [organizationUuid]);

  if (!accessToken?.link) {
    return {
      data: null,
      dashboardConfiguration: {
        metrics: [],
        graphs: [],
        levers: [],
      },
      startDate: new Date(),
      endDate: new Date(),
      pageLoading: false,
      organizationUuid: '',
      permissionedFormulaUuids: [],
      organizationName: '',
    };
  }

  return {
    data,
    dashboardConfiguration,
    startDate,
    endDate,
    pageLoading,
    organizationUuid,
    permissionedFormulaUuids: accessToken.link.allowedResources.formulas ?? [],
    organizationName: accessToken.link.organizationName ?? '',
  };
};

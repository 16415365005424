export const invertYValue = ({
  value,
  maxY,
  yMultiplier,
}: {
  value: number;
  maxY: number;
  yMultiplier: number;
}): number => {
  return maxY * yMultiplier - value * yMultiplier;
};

export const restoreYValue = ({
  value,
  maxY,
  yMultiplier,
}: {
  value: number;
  maxY: number;
  yMultiplier: number;
}): number => {
  return (maxY * yMultiplier - value) / yMultiplier;
};

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface SettingsState {
  showSidebar: boolean;
  isMobile: boolean;
  financialModelExpand: { name: string; expanded: boolean }[];
  hideSensitiveData: boolean;
  collapseInteractiveSection: boolean;
  isInteractiveSectionCollapsed: boolean;
}

const initialState = {
  showSidebar: false,
  isMobile: false,
  financialModelExpand: [],
  hideSensitiveData: false,
  collapseInteractiveSection: false,
  isInteractiveSectionCollapsed: false,
} as SettingsState;

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<SettingsState>>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    reset: () => initialState,
  },
});

export const { update, reset } = settingsSlice.actions;
export const settings = settingsSlice.actions;
export default settingsSlice.reducer;

import { z } from 'zod';

export const departmentSchema = z.object({
  uuid: z.string().uuid(),
  departmentUuid: z.string().uuid(),
  name: z.string(),
  organizationUuid: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
});

export const ZNewDepartmentResponseData = z.object({
  data: departmentSchema,
});

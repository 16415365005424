import { IFormulaSegment, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import shiftFormulaIndexes from '~/pages/FinancialModelDeprecated/utils/shiftFormulaIndexes';

const handleSegmentDeletion = ({
  newFormula,
  setVariables,
  setUpdatedFormula,
  setFormula,
}: {
  newFormula: IFormulaSegment[];
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
}): void => {
  if (newFormula.length === 0) {
    setVariables({});
    setUpdatedFormula([]);
    setFormula([]);
  }
};

export const commonOperations = ({
  indexToDelete,
  variableKeyToDelete,
  enteredFrom,
  updateInputPosition,
  formulaToUse,
  variablesToUse,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  setValue,
  setEnteredConstantFrom,
  setInputPosition,
  inputRef,
}: {
  indexToDelete: number;
  variableKeyToDelete: string;
  enteredFrom?: 'left' | 'right';
  updateInputPosition?: (prevPosition: number) => number;
  formulaToUse?: IFormulaSegment[];
  variablesToUse?: IVariables;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const newFormula = formulaToUse ? [...formulaToUse] : [...updatedFormula];
  newFormula.splice(indexToDelete, 1);
  const newVariables = variablesToUse ? { ...variablesToUse } : { ...variables };
  delete newVariables[variableKeyToDelete];

  const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } = shiftFormulaIndexes({
    oldFormula: newFormula,
    oldVariables: newVariables,
  });

  setFormula(formulaWithShiftedIndexes);
  setVariables(variablesWithShiftedIndexes);

  setUpdatedFormula(formulaWithShiftedIndexes);

  setValue((variables[variableKeyToDelete].constantValue ?? '').toString());
  inputRef.current?.focus();
  handleSegmentDeletion({ newFormula: formulaWithShiftedIndexes, setVariables, setUpdatedFormula, setFormula });

  if (enteredFrom) {
    setEnteredConstantFrom(enteredFrom);
  }

  if (updateInputPosition) {
    setInputPosition(updateInputPosition);
  }
};

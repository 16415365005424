import { addMonths, differenceInDays, differenceInMonths, getDaysInMonth, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export default ({ startDate, endDate }: { startDate: Date; endDate: Date }): number => {
  if (startDate > endDate) {
    [startDate, endDate] = [endDate, startDate];
  }

  startDate = startOfDay(toZonedTime(startDate, 'UTC'));
  endDate = startOfDay(toZonedTime(endDate, 'UTC'));

  const fullMonths = differenceInMonths(endDate, startDate);
  const startOfNextMonth = addMonths(startDate, fullMonths);
  const remainingDays = differenceInDays(endDate, startOfNextMonth);
  const daysInEndMonth = getDaysInMonth(endDate);

  return fullMonths + remainingDays / daysInEndMonth;
};

import { StatusCodes } from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import Modal from '~/components/Modal';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { IShareLink } from '~/pages/ShareLink/models/types';
import { State } from '~/store';
import logger from '~/utils/logger';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import Skeleton from 'react-loading-skeleton';
import EmptyShareLink from './EmptyShareLink';
import Typography from '~/components/Typography';
import Button from '~/components/Button';
import ShareLinkTable from './ShareLinkTable';
import CreateShareLinkModal from './CreateModal/CreateShareLinkModal';

const ShareLinksModal = ({
  showModal,
  setShowModal,
}: {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [shareLinks, setShareLinks] = useState<IShareLink[]>([]);
  const [scenarios, setScenarios] = useState<IScenario[]>([]);
  const [scenariosDictionary, setScenariosDictionary] = useState<Record<string, IScenario>>({});
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [shareLinkToEdit, setShareLinkToEdit] = useState<IShareLink | undefined>(undefined);

  const fetchData = async (): Promise<void> => {
    try {
      setIsLoading(true);
      const shareLinksPromise = request({
        url: '/share-link',
        method: 'GET',
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      }) as Promise<IAPIResponse<IShareLink[]>>;

      const scenariosPromise = request({
        url: `/organizations/${organizationUuid}/scenarios`,
        method: 'GET',
      }) as Promise<IAPIResponse<IScenario[]>>;

      const [shareLinks, scenarios] = await Promise.all([shareLinksPromise, scenariosPromise]);

      if (shareLinks.status === StatusCodes.OK) {
        setShareLinks(shareLinks.data.data);
      } else {
        toast.error('Failed to fetch share links data');
      }

      if (scenarios.status === StatusCodes.OK) {
        setScenarios(scenarios.data.data);
      } else {
        toast.error('Failed to fetch scenarios data');
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Failed to fetch links data');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (organizationUuid) {
      fetchData();
    }
  }, [organizationUuid, showModal]);

  useEffect(() => {
    if (scenarios.length) {
      setScenariosDictionary(scenarios.reduce((acc, scenario) => ({ ...acc, [scenario.uuid]: scenario }), {}));
    } else {
      setScenariosDictionary({});
    }
  }, [scenarios]);

  const emptyState = (
    <div className="flex flex-col justify-center items-center h-full w-full">
      <EmptyShareLink />
      <div className="flex flex-col max-w-[475px] gap-2">
        <Typography weight="bold" className="text-center">
          Share Customized Links
        </Typography>
        <Typography color="secondary" className="text-center">
          Create custom links to securely share your financial data. Control what information is shared and for how
          long.
        </Typography>
      </div>
    </div>
  );

  return (
    <div>
      <Modal isOpen={showModal} title="Share Links" size="xl" showClose onClose={() => setShowModal(false)}>
        {isLoading ? (
          <Skeleton className={'h-[50px] w-full rounded-xl'} baseColor="#F8F9F6" count={5} />
        ) : (
          <div className="flex flex-col gap-12 w-full">
            <div className="mt-4">
              {!shareLinks.length ? (
                emptyState
              ) : (
                <div className="w-full max-h-[500px] overflow-y-scroll hide-scrollbar">
                  <ShareLinkTable
                    shareLinks={shareLinks}
                    scenariosDictionary={scenariosDictionary}
                    setShareLinks={setShareLinks}
                    setShareLinkToEdit={setShareLinkToEdit}
                    setIsCreateModalOpen={setIsCreateModalOpen}
                  />
                </div>
              )}
            </div>
            <div className="flex w-full items-center justify-between">
              <Button className="!w-fit !px-0" fill="clear" onClick={() => setShowModal(false)}>
                Done
              </Button>
              <Button
                className="!w-fit"
                onClick={() => {
                  setShareLinkToEdit(undefined);
                  setIsCreateModalOpen(true);
                }}
              >
                Create New
              </Button>
            </div>
          </div>
        )}
      </Modal>
      {isCreateModalOpen && (
        <CreateShareLinkModal
          scenarios={scenarios}
          isOpen={isCreateModalOpen}
          setIsOpen={setIsCreateModalOpen}
          setShareLinks={setShareLinks}
          shareLink={shareLinkToEdit}
          shareLinks={shareLinks}
        />
      )}
    </div>
  );
};

export default ShareLinksModal;

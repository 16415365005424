import React from 'react';
import useTableContext from './hooks/useTableContext';

const ColumnResize = ({ columnIndex }: { columnIndex: number }): React.ReactNode => {
  const { handleColumnResize } = useTableContext();
  return (
    <div
      className={`absolute top-0 -right-[6px] w-3 h-full cursor-col-resize bg-transparent z-10`}
      onMouseDown={(e) => handleColumnResize(e, columnIndex)}
    />
  );
};

export default ColumnResize;

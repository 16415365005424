import React, { useEffect, useState } from 'react';
import { IColumnIdEnum, IContract } from '../entity/types';
import { useContractsData } from '../useContractsData';
import { ZContractsLoader } from '../entity/schema';
import { IFormula, IFormulaTypeEnum } from '~/services/parallel/formulas.types';
import { sortContracts } from './sortContracts';

interface IContractsContext {
  contracts: IContract[];
  setContracts: React.Dispatch<React.SetStateAction<IContract[]>>;
  cashCollectionFormulas: IFormula[];
  setCashCollectionFormulas: (formulas: IFormula[]) => void;
  revenueRecognitionFormulas: IFormula[];
  setRevenueRecognitionFormulas: (formulas: IFormula[]) => void;
  loading: boolean;
  revalidate: () => Promise<void>;
  showNewContractModal: 'new' | IContract | null;
  setShowNewContractModal: (contract: 'new' | IContract | null) => void;
  showDiscontinueContractModal: IContract | null;
  setShowDiscontinueContractModal: (show: IContract | null) => void;
  showDeleteContractModal: IContract | null;
  setShowDeleteContractModal: (show: IContract | null) => void;
  filteredContracts: IContract[];
  setFilteredContracts: React.Dispatch<React.SetStateAction<IContract[]>>;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  showPastContracts: boolean;
  setShowPastContracts: React.Dispatch<React.SetStateAction<boolean>>;
  tableSorting: { columnId: IColumnIdEnum; desc: boolean };
  setTableSorting: React.Dispatch<React.SetStateAction<{ columnId: IColumnIdEnum; desc: boolean }>>;
}

export const ContractsContext = React.createContext<IContractsContext>({
  contracts: [],
  setContracts: () => {},
  cashCollectionFormulas: [],
  setCashCollectionFormulas: () => {},
  revenueRecognitionFormulas: [],
  setRevenueRecognitionFormulas: () => {},
  loading: true,
  revalidate: async () => {},
  showNewContractModal: null,
  setShowNewContractModal: () => {},
  showDiscontinueContractModal: null,
  setShowDiscontinueContractModal: () => {},
  showDeleteContractModal: null,
  setShowDeleteContractModal: () => {},
  filteredContracts: [],
  setFilteredContracts: () => {},
  search: '',
  setSearch: () => {},
  showPastContracts: false,
  setShowPastContracts: () => {},
  tableSorting: { columnId: IColumnIdEnum.StartDate, desc: true },
  setTableSorting: () => {},
});

export const ContractsContextProvider = ({ children }: { children: React.ReactNode }): React.ReactNode => {
  const { data, loading, revalidate } = useContractsData();
  const [showNewContractModal, setShowNewContractModal] = useState<'new' | IContract | null>(null);
  const [showDiscontinueContractModal, setShowDiscontinueContractModal] = useState<IContract | null>(null);
  const [showDeleteContractModal, setShowDeleteContractModal] = useState<IContract | null>(null);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [cashCollectionFormulas, setCashCollectionFormulas] = useState<IFormula[]>([]);
  const [revenueRecognitionFormulas, setRevenueRecognitionFormulas] = useState<IFormula[]>([]);
  const [filteredContracts, setFilteredContracts] = useState<IContract[]>([]);
  const [search, setSearch] = useState<string>('');
  const [showPastContracts, setShowPastContracts] = useState<boolean>(false);
  const [tableSorting, setTableSorting] = useState<{ columnId: IColumnIdEnum; desc: boolean }>({
    columnId: IColumnIdEnum.StartDate,
    desc: true,
  });

  useEffect(() => {
    if (data) {
      const parsedData = ZContractsLoader.parse(data);
      setContracts(parsedData.contracts);
    } else {
      setContracts([]);
    }
  }, [data]);

  useEffect(() => {
    const cashCollectionFormulas = contracts.flatMap((contract) =>
      contract.formulas.filter((formula) => formula.type === IFormulaTypeEnum.ContractCashCollection),
    );
    const revenueRecognitionFormulas = contracts.flatMap((contract) =>
      contract.formulas.filter((formula) => formula.type === IFormulaTypeEnum.ContractRevenueRecognition),
    );
    setCashCollectionFormulas(cashCollectionFormulas);
    setRevenueRecognitionFormulas(revenueRecognitionFormulas);
  }, [contracts]);

  useEffect(() => {
    let filtered = contracts.filter((contract) => contract.contractTitle.toLowerCase().includes(search.toLowerCase()));
    if (!showPastContracts) {
      filtered = filtered.filter((contract) => !contract.endDate || contract.endDate >= new Date());
    }
    filtered = sortContracts({ contracts: filtered, sortingState: tableSorting });
    setFilteredContracts(filtered);
  }, [contracts, search, showPastContracts, tableSorting]);

  return (
    <ContractsContext.Provider
      value={{
        contracts,
        setContracts,
        cashCollectionFormulas,
        setCashCollectionFormulas,
        revenueRecognitionFormulas,
        setRevenueRecognitionFormulas,
        loading,
        revalidate,
        showNewContractModal,
        setShowNewContractModal,
        showDiscontinueContractModal,
        setShowDiscontinueContractModal,
        showDeleteContractModal,
        setShowDeleteContractModal,
        filteredContracts,
        setFilteredContracts,
        search,
        setSearch,
        showPastContracts,
        setShowPastContracts,
        tableSorting,
        setTableSorting,
      }}
    >
      {children}
    </ContractsContext.Provider>
  );
};

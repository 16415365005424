import { BanknotesIcon, ChartBarIcon, ListBulletIcon } from '@heroicons/react/24/outline';
import React, { useContext, useEffect } from 'react';
import Button from '~/components/Button';
import Checkbox from '~/components/Checkbox';
import InputWrapper, { useInput } from '~/components/Input/InputWrapper';
import Segment from '~/components/SegmentedControl';
import useQueryParams from '~/utils/hooks/useQueryParams';
import { ContractsContext } from '../context/ContractsContext';
import HoverPopover from '~/components/HoverPopover';
import Typography from '~/components/Typography';

const ContractsHeader = (): React.ReactNode => {
  const { setShowNewContractModal, setSearch, showPastContracts, setShowPastContracts, contracts } =
    useContext(ContractsContext);
  const [searchState, setSearchState] = useInput({
    validation: /.*/,
  });
  const [queryParams, setQueryParams] = useQueryParams();
  const contractView = queryParams.get('view');

  useEffect(() => {
    setSearch(searchState.value);
  }, [searchState]);

  const segments = [
    {
      value: 'details',
      label: (
        <HoverPopover
          buttonContent={
            <div
              onClick={() => setQueryParams({ view: 'details' })}
              className="px-4 py-2 flex items-center justify-center"
            >
              <ListBulletIcon className="size-5" />
            </div>
          }
          panelContent={
            <div className="px-3 py-2 bg-black">
              <Typography size="xs" color="white">
                Contract Details
              </Typography>
            </div>
          }
          anchor="top"
          panelClassName="shadow-md rounded-lg"
        />
      ),
      segmentClassName: '!p-0',
      labelClassName: '!p-0',
    },
    {
      value: 'collections',
      label: (
        <HoverPopover
          buttonContent={
            <div
              className="px-4 py-2 flex items-center justify-center"
              onClick={() => setQueryParams({ view: 'collections' })}
            >
              <BanknotesIcon className="size-5" />
            </div>
          }
          panelContent={
            <div className="px-3 py-2 bg-black">
              <Typography size="xs" color="white">
                Cash Collections
              </Typography>
            </div>
          }
          anchor="top"
          panelClassName="shadow-md rounded-lg"
        />
      ),
      segmentClassName: '!p-0',
      labelClassName: '!p-0',
    },
    {
      value: 'revenue',
      label: (
        <HoverPopover
          buttonContent={
            <div
              className="px-4 py-2 flex items-center justify-center"
              onClick={() => setQueryParams({ view: 'revenue' })}
            >
              <ChartBarIcon className="size-5" />
            </div>
          }
          panelContent={
            <div className="px-3 py-2 bg-black">
              <Typography size="xs" color="white">
                Revenue Recognition
              </Typography>
            </div>
          }
          anchor="top"
          panelClassName="shadow-md rounded-lg"
        />
      ),
      segmentClassName: '!p-0',
      labelClassName: '!p-0',
    },
  ];

  return (
    <div className="w-full flex items-center justify-between px-10 mt-10 mb-10">
      <div className="flex items-center gap-2">
        <Segment
          name="Contract Segment"
          segments={segments}
          value={contractView ?? 'details'}
          setValue={(value) => {
            const currentParams = Object.fromEntries(queryParams.entries());
            currentParams.view = value;
            setQueryParams(currentParams);
          }}
        />
        {contracts.some((contract) => contract.endDate && contract.endDate < new Date()) && (
          <div className="flex text-nowrap items-center gap-2 mb-1">
            <Checkbox
              checked={showPastContracts}
              toggleValue={() => setShowPastContracts(!showPastContracts)}
              label="Show Past Contracts"
              id="show-past-contracts"
            />
          </div>
        )}
      </div>
      <div className="flex items-center gap-2">
        <div className="w-[200px]">
          <InputWrapper
            id="search-contracts-input"
            type="search"
            placeholder="Search Name"
            state={searchState}
            setState={setSearchState}
          />
        </div>
        <Button
          fill="outline"
          id="new-contracts-button"
          className="max-sm:hidden !w-fit"
          onClick={() => setShowNewContractModal('new')}
        >
          Add Contract
        </Button>
      </div>
    </div>
  );
};

export default ContractsHeader;

import React from 'react';
import Skeleton from '~/components/Skeleton';

interface Props {
  colCount: number;
  widths?: number[];
}

const SkeletonRow = ({
  colCount,
  widths = Array.from({ length: colCount }).map(() => 100),
}: Props): React.ReactNode => (
  <tr data-testid="table-skeleton-row" className="border-t table-row-data bg-white ml-4">
    {Array.from({ length: colCount }).map((_, index) => (
      <td
        data-testid={`skeleton-cell-${index}`}
        key={
          /* eslint-disable-next-line react/no-array-index-key */
          `skeleton-td-row-${index}-${colCount}`
        }
        className="p-4"
        align="left"
      >
        <Skeleton
          key={
            /* eslint-disable-next-line react/no-array-index-key */
            `skeleton-component-${index}-${colCount}`
          }
          height={26}
          width={widths[index]}
          baseColor="green"
        />
      </td>
    ))}
  </tr>
);

export default SkeletonRow;

import React from 'react';
import Typography from '~/components/Typography';

const ScenarioModeToast = ({
  message,
  button,
}: {
  message: string;
  button?: {
    text: string;
    callback: () => void;
  };
}): React.ReactNode => {
  return (
    <div className="flex flex-row bg-white text-nowrap gap-2 shadow-md px-4 py-3 justify-center items-center rounded-xl !bg-[#383935]">
      <Typography size="sm" weight="normal" color="white">
        {message}
      </Typography>
      {button && (
        <button
          onClick={() => button.callback()}
          className="px-2 border border-white rounded-md"
          id="view-newly-created-scenario-button"
        >
          <Typography size="sm" weight="normal" color="white">
            {button.text}
          </Typography>
        </button>
      )}
    </div>
  );
};

export default ScenarioModeToast;

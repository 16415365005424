import React, { useContext } from 'react';
import { CellContext } from '@tanstack/react-table';
import { IContract, IContractTablularTableRow } from '../entity/types';
import EllipsisDropdown from '~/components/EllipsisDropdown';
import Typography from '~/components/Typography';
import { ContractsContext } from '../context/ContractsContext';

const ContractsTabularTableTitleCell = ({
  info,
}: {
  info: CellContext<IContractTablularTableRow, IContract>;
}): React.ReactElement => {
  const { setShowDiscontinueContractModal, setShowDeleteContractModal, setShowNewContractModal } =
    useContext(ContractsContext);
  const contract = info.getValue();
  return (
    <div className="flex w-full h-full items-center bg-white justify-between border-r border-neutral-50 -ml-2 pr-3">
      <div className="max-w-[220px] truncate ml-2">
        <Typography>{contract.contractTitle}</Typography>
      </div>
      <EllipsisDropdown
        options={[
          { label: 'Edit', onClick: () => setShowNewContractModal(contract) },
          { label: 'Discontinue', onClick: () => setShowDiscontinueContractModal(contract), className: 'text-red-500' },
          { label: 'Delete', onClick: () => setShowDeleteContractModal(contract), className: 'text-red-500' },
        ]}
      />
    </div>
  );
};

export default ContractsTabularTableTitleCell;

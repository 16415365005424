import React, { useContext, useEffect } from 'react';
import Modal from '~/components/Modal';
import Button from '~/components/Button';
import { useInput } from '~/components/Input';
import Input from '~/components/Input/InputWrapper';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';
import RadioInputTile from '~/components/RadioInputTile';
import { useRadioInput } from '~/components/RadioInput';
import { CalendarDaysIcon, CalendarIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import { ContractsContext } from '../context/ContractsContext';
import request from '~/utils/request';
import toast from 'react-hot-toast';
import logger from '~/utils/logger';
import { State } from '~/store';
import { useSelector } from 'react-redux';
import { HttpStatusCode } from 'axios';
import { ZContract } from '../entity/schema';
import { IAPIResponse } from '~/utils/types';
import { IContract } from '../entity/types';
import { CENTS_PER_DOLLAR } from '~/utils/constants/currency';

const ContractModal = (): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { showNewContractModal, setShowNewContractModal, setContracts } = useContext(ContractsContext);
  const [title, setTitle, resetTitle] = useInput({ errorMessage: 'Contract name is required' });
  const [billingSchedule, setBillingSchedule, resetBillingSchedule] = useRadioInput({
    options: [
      { label: 'Monthly', value: 'monthly', icon: <CalendarDaysIcon className="size-5" /> },
      { label: 'Quarterly', value: 'quarterly', icon: <Squares2X2Icon className="size-5" /> },
      { label: 'Annually', value: 'annual', icon: <CalendarIcon className="size-5" /> },
    ],
    selected: undefined,
    errorMessage: 'Billing schedule is required',
  });
  const [annualValue, setAnnualValue, resetAnnualValue] = useInput({ errorMessage: 'Annual value is required' });
  const [setupFee, setSetupFee, resetSetupFee] = useInput({ value: '0', errorMessage: 'Setup fee is required' });
  const [invoiceDate, setInvoiceDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
    errorMessage: 'Invoice date is required',
  });
  const [startDate, setStartDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
    errorMessage: 'Start date is required',
  });
  const [endDate, setEndDate] = usePeriodPicker({
    startDate: null,
    endDate: null,
    mode: 'month',
  });

  const resetForm = (): void => {
    resetTitle();
    resetBillingSchedule();
    resetAnnualValue();
    resetSetupFee();
    setInvoiceDate({
      startDate: null,
      endDate: null,
      mode: 'month',
    });
    setStartDate({
      startDate: null,
      endDate: null,
      mode: 'month',
    });
    setEndDate({
      startDate: null,
      endDate: null,
      mode: 'month',
    });
  };

  useEffect(() => {
    if (showNewContractModal && showNewContractModal !== 'new') {
      setTitle((prevState) => ({
        ...prevState,
        value: showNewContractModal.contractTitle,
        touched: true,
      }));
      setBillingSchedule((prevState) => ({
        ...prevState,
        selected: billingSchedule.options.find((option) => option.value === showNewContractModal.billingSchedule),
      }));
      setAnnualValue((prevState) => ({
        ...prevState,
        value: (showNewContractModal.annualValue / CENTS_PER_DOLLAR).toString(),
      }));
      setSetupFee((prevState) => ({
        ...prevState,
        value: showNewContractModal.setupFee ? (showNewContractModal.setupFee / CENTS_PER_DOLLAR).toString() : '',
      }));
      setInvoiceDate({
        startDate: showNewContractModal.invoiceDate,
        endDate: showNewContractModal.invoiceDate,
        mode: 'month',
      });
      setStartDate({
        startDate: showNewContractModal.startDate,
        endDate: showNewContractModal.startDate,
        mode: 'month',
      });
      setEndDate({
        startDate: showNewContractModal.endDate,
        endDate: showNewContractModal.endDate,
        mode: 'month',
      });
    }
  }, [showNewContractModal]);

  const validateForm = (): boolean => {
    let isValid = true;
    if (!title.value) {
      setTitle((prevState) => ({
        ...prevState,
        valid: false,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }
    if (!billingSchedule.selected) {
      setBillingSchedule((prevState) => ({
        ...prevState,
        valid: false,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }
    if (!annualValue.value) {
      setAnnualValue((prevState) => ({
        ...prevState,
        valid: false,
        touched: true,
        pristine: false,
      }));
      isValid = false;
    }
    if (!invoiceDate.startDate) {
      setInvoiceDate((prevState) => ({
        ...prevState,
        valid: false,
        errorMessage: 'Invoice date is required',
      }));
      isValid = false;
    }
    if (!startDate.startDate) {
      setStartDate((prevState) => ({
        ...prevState,
        valid: false,
        errorMessage: 'Start date is required',
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleSubmit = async ({ contract }: { contract: IContract | 'new' | null }): Promise<void> => {
    try {
      const isValid = validateForm();
      if (isValid && contract) {
        const isNew = contract === 'new';
        const response = (await request({
          url: isNew ? '/contracts' : `/contracts/${contract.contractUuid}`,
          method: isNew ? 'POST' : 'PATCH',
          headers: {
            'Organization-Uuid': organizationUuid,
          },
          params: {
            scenarioUuid: activeScenarioUuid,
          },
          body: {
            contractTitle: title.value,
            billingSchedule: billingSchedule.selected?.value,
            annualValue: parseFloat(annualValue.value) * CENTS_PER_DOLLAR,
            setupFee: parseFloat(setupFee.value) * CENTS_PER_DOLLAR,
            invoiceDate: invoiceDate.startDate,
            startDate: startDate.startDate,
            endDate: endDate.startDate,
          },
        })) as IAPIResponse<IContract>;
        if (response.status === HttpStatusCode.Created) {
          const parsedData = ZContract.parse(response.data.data);
          resetForm();
          setContracts((prevState) => [parsedData, ...prevState]);
          setShowNewContractModal(null);
        } else if (response.status === HttpStatusCode.Ok) {
          const parsedData = ZContract.parse(response.data.data);
          setContracts((prevState) =>
            prevState.map((contract) => (contract.contractUuid === parsedData.contractUuid ? parsedData : contract)),
          );
          resetForm();
          setShowNewContractModal(null);
        } else {
          toast.error('Error saving contract');
        }
      }
    } catch (error) {
      if (error instanceof Error) logger.error(error);
      toast.error('Error saving contract');
    }
  };

  return (
    <Modal isOpen={!!showNewContractModal} onClose={() => setShowNewContractModal(null)} title="New Contract" size="md">
      <div className="flex flex-col gap-4 w-full mt-2">
        <Input id="contract-name" label="Contract Name" state={title} setState={setTitle} />
        <RadioInputTile
          id="billing-schedule"
          label="Billing Schedule"
          state={billingSchedule}
          setState={(val) =>
            setBillingSchedule((prevState) => ({
              ...prevState,
              selected: val,
              touched: true,
              valid: true,
            }))
          }
          disabled={false}
          required
        />
        <CurrencyInput id="annual-value" label="Annual Value" state={annualValue} setState={setAnnualValue} />
        <CurrencyInput id="setup-fee" label="Setup Fee" state={setupFee} setState={setSetupFee} />
        <div className="w-[150px]">
          <PeriodPicker
            id="invoice-date"
            label="Invoice Date"
            state={invoiceDate}
            setState={setInvoiceDate}
            infoIcon="The date the invoice is issued, indicating when cash is expected."
          />
        </div>
        <div className="w-full flex items-start gap-2 justify-start">
          <div className="w-[150px]">
            <PeriodPicker
              id="start-date"
              label="Start Date"
              state={startDate}
              setState={setStartDate}
              infoIcon="The date when revenue is recognized, reflecting when the service or product is delivered."
            />
          </div>
          <div className="w-[150px]">
            <PeriodPicker
              id="end-date"
              label="End Date"
              state={endDate}
              setState={setEndDate}
              optional
              beAfter={startDate.startDate}
              clearable
            />
          </div>
        </div>
        <div className="flex w-full justify-between items-center">
          <Button className="!w-fit !px-0" fill="clear" onClick={() => setShowNewContractModal(null)}>
            Cancel
          </Button>
          <Button
            className="!w-fit"
            fill="solid"
            onClick={() => handleSubmit({ contract: showNewContractModal })}
            id="save-contract"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ContractModal;

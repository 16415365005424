import React from 'react';
import Button from '~/components/Button';
import InputWrapper from '~/components/Input/InputWrapper';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';

const NameScenarioModal = ({
  isOpen,
  cancel,
  confirm,
  scenarioTitle,
  setScenarioTitle,
  scenarioType,
}: {
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
  scenarioTitle: Types.InputState;
  setScenarioTitle: React.Dispatch<React.SetStateAction<Types.InputState>>;
  scenarioType: 'dynamic' | 'static';
}): React.ReactNode => {
  const titleText = scenarioType === 'static' ? 'Plan Name' : 'Scenario Name';
  const subTitleText = scenarioType === 'static' ? 'Save a snapshot of the current plan for future reference' : '';

  const placeholderText =
    scenarioType === 'static' ? 'Q1 Plan, 2025 Plan, etc.' : 'Additional Bridge, Aggressive Plan, etc.';

  return (
    <Modal isOpen={isOpen} title={titleText} size="xxs" id="scenario-tray-naming-modal">
      <div className="w-full">
        <Typography color="secondary">{subTitleText}</Typography>
        <InputWrapper
          id="new-scenario-title"
          state={scenarioTitle}
          setState={setScenarioTitle}
          placeholder={placeholderText}
          className="mt-2"
        />
        <div className="w-full flex items-center justify-between mt-4">
          <Button fill="clear" onClick={cancel} id="cancel-saving-scenario" className="!w-fit !px-0">
            Cancel
          </Button>
          <Button onClick={confirm} id="rename-and-save-scenario" className="!w-auto">
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NameScenarioModal;

import React, { useContext, useEffect } from 'react';
import { IInitialValue } from '../Cell/entities/types';
import { CellContext } from '@tanstack/react-table';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import DatePicker from '~/components/DatePicker';

const CreationDate = ({
  cellContext,
  handleBlur,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  handleBlur: () => void;
}): React.ReactNode => {
  const { inlineCreate, inlineCreateDate, setInlineCreateDate } = useContext(HeadcountContext);

  useEffect(() => {
    if (inlineCreateDate.value.startDate && inlineCreateDate.valid) {
      handleBlur();
    }
  }, [inlineCreateDate.valid]);
  return inlineCreate ? (
    <div data-testid={`create-inline-hireDate`}>
      <DatePicker
        datePickerClassName="min-w-[136px]"
        id={`create-${cellContext.column.id}`}
        state={inlineCreateDate}
        setState={setInlineCreateDate}
      />
    </div>
  ) : (
    <div />
  );
};

export default CreationDate;

import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import Typography from '~/components/Typography';
import { IScenario } from '~/pages/Dashboard/entity/types';
import { IShareLink, IShareLinkWithModel } from '~/pages/ShareLink/models/types';
import Cell from './Cells/Cell';
import BaseTable from '~/components/Table/Base/BaseTable';

const ShareLinkTable = ({
  shareLinks,
  scenariosDictionary,
  setShareLinks,
  setShareLinkToEdit,
  setIsCreateModalOpen,
}: {
  shareLinks: IShareLink[];
  scenariosDictionary: Record<string, IScenario>;
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
  setShareLinkToEdit: React.Dispatch<React.SetStateAction<IShareLink | undefined>>;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  const { tableData, tableColumns } = useMemo(() => {
    const columns = [
      { id: 'title', label: 'LINK' },
      { id: 'createdAt', label: 'CREATED' },
      { id: 'viewCount', label: 'VIEW COUNT' },
      { id: 'expirationDate', label: 'EXPIRATION' },
      { id: 'link', label: '' },
      { id: 'options', label: '' },
    ];
    const columnHelper = createColumnHelper<IShareLinkWithModel>();

    const tableColumns = columns.map((col) =>
      // @ts-expect-error - column helper wants column ids to match share link data keys exactly
      columnHelper.accessor(col.id, {
        header: () => (
          <Typography size="xs" color="empty">
            {col.label}
          </Typography>
        ),
        enableResizing: false,
        enablePinning: false,
        enableSorting: false,
        cell: (cellContext) => (
          <Cell
            shareLink={cellContext.row.original}
            columnId={cellContext.column.id}
            setShareLinks={setShareLinks}
            setShareLinkToEdit={setShareLinkToEdit}
            setIsCreateModalOpen={setIsCreateModalOpen}
          />
        ),
      }),
    );

    const tableData = shareLinks.map((shareLink) => ({
      ...shareLink,
      model: shareLink.scenarioUuid ? scenariosDictionary[shareLink.scenarioUuid].changeDescription : 'Base Model',
    }));

    return { tableData, tableColumns };
  }, [shareLinks, scenariosDictionary]);

  const table = useReactTable({
    columns: tableColumns,
    data: tableData as unknown as IShareLinkWithModel[],
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="w-full">
      <BaseTable
        id="share-link-table"
        // @ts-expect-error - BaseTable doesn't like typed rows
        table={table}
        emptyState={<></>}
        styles={{
          table: 'w-full',
          th: 'py-2 px-4 text-xs font-normal text-neutral-200 text-nowrap border-b border-neutral-50',
          td: 'h-5 px-4 text-nowrap py-2 border-b border-neutral-50',
        }}
      />
    </div>
  );
};

export default ShareLinkTable;

import { format } from 'date-fns';
import { z } from 'zod';
import request from '~/utils/request';
import { IExpense } from '../types';
import { ZExpense } from '../entity/schemas';
import { IFormula, IFormulaRecipe, IFormulaSegment, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import { ZFormulaRecipe } from '~/pages/FinancialModelDeprecated/entity/schemas';
import validateFormula from '~/pages/FinancialModelDeprecated/utils/validateFormula';

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

enum ICategoryToCalculationEnum {
  'Software' = 'softwareExpenses',
  'Other' = 'otherExpenses',
  'People & Facilities' = 'peopleAndFacilities',
  'COGS' = 'cogs',
  'Marketing' = 'marketing',
}

const createExpense = async ({
  organizationUuid,
  scenarioUuid,
  expenseUuid,
  type,
  name,
  category,
  frequency,
  amount,
  departments,
  startDate,
  endDate,
  updatedFormula,
  variables,
  dataSourceUuids,
  successCallback,
  failureCallback,
  validateFormState,
  formulaList,
  setDisplayFormulaError,
  desiredCreationStatus,
}: {
  organizationUuid: string;
  scenarioUuid: string | null;
  expenseUuid: { uuid: string; expenseUuid: string; type: 'edit' | 'create' } | undefined;
  type: string | null;
  name: string;
  category: string | null;
  frequency: string | null;
  amount: string;
  departments: (string | null | undefined)[] | null;
  startDate: Date | null;
  endDate: Date | null;
  updatedFormula: IFormulaSegment[];
  variables: IVariables;
  dataSourceUuids?: string[];
  formulaList: IFormula[];
  successCallback: ({ createdExpense }: { createdExpense: IExpense }) => void;
  failureCallback: () => void;
  validateFormState: () => boolean;
  setDisplayFormulaError?: React.Dispatch<
    React.SetStateAction<{
      isDisplayed: boolean;
      message: string;
    }>
  >;
  desiredCreationStatus?: string;
}): Promise<void> => {
  const isValid = validateFormState();

  if (!isValid) return;

  const ZValidateExpenseRequestData = z
    .object({
      organizationUuid: z.string(),
      scenarioUuid: z.string().nullable(),
      type: z.string(),
      name: z.string().min(1),
      category: z.string(),
      frequency: z.string(),
      creationStatus: z.string(),
      amountForExpense: z
        .string()
        .min(0)
        .pipe(z.coerce.number())
        .transform((val) => val * 100)
        .optional(),
      departments: z.array(z.string()),
      startDate: z
        .date()
        .transform((val) => format(val, 'yyyy-MM-dd'))
        .nullable(),
      endDate: z
        .date()
        .nullable()
        .transform((val) => {
          if (val === null) {
            return null;
          }
          return format(val, 'yyyy-MM-dd');
        })
        .nullable(),
      recipe: ZFormulaRecipe.optional(),
      dataSourceUuids: z.array(z.string()).optional(),
    })
    .refine(
      (val) => {
        if (val.type !== 'custom' && !val.amountForExpense && val.amountForExpense !== 0) {
          return false;
        }
        if (val.type === 'headcountPercentCompensation' && val.amountForExpense && val.amountForExpense > 10000) {
          return false;
        }
        return true;
      },
      {
        message: 'Amount must not exceed 10,000 for the specified type.',
        path: ['amount'],
      },
    )
    .refine((val) => {
      if (val.type === 'custom') {
        if ((!val.recipe || Object.keys(val.recipe.variables).length === 0) && expenseUuid?.type === 'create') {
          return false;
        }
      }
      return true;
    });

  let recipe;
  if (type === 'custom' && updatedFormula.length > 0) {
    const expression = updatedFormula.map((segment) => segment.textValue).join('');
    const { validated, errorMessage } = validateFormula({
      formulaToValidate: updatedFormula,
      formulaList,
      expression,
      recipeVariables: variables,
      relatedCalculationType: category
        ? ICategoryToCalculationEnum[category as keyof typeof ICategoryToCalculationEnum]
        : undefined,
    });
    if (!validated) {
      setDisplayFormulaError && setDisplayFormulaError({ isDisplayed: true, message: errorMessage });
      return;
    }
    recipe = {
      name: name,
      expression,
      variables,
    };
  }

  let amountForExpense;
  if (type !== 'custom') {
    amountForExpense = amount;
  }

  const objectToValidate = {
    organizationUuid,
    scenarioUuid,
    type,
    name,
    category,
    frequency,
    amountForExpense,
    startDate,
    endDate,
    recipe,
    departments,
    dataSourceUuids,
    creationStatus: desiredCreationStatus ?? 'created',
  };

  if (type === 'custom') {
    delete objectToValidate.amountForExpense;
  } else {
    delete objectToValidate.recipe;
  }

  const { data: parsedData, success: dataIsValid } = ZValidateExpenseRequestData.safeParse(objectToValidate);

  if (dataIsValid) {
    const updateBody: {
      name: string;
      context: {
        driver: string;
        tag: string;
        frequency: string;
        startDate: string | null;
        endDate: string | null;
        amount?: number;
        departments: string[];
      };
      dataSourceUuids?: string[];
      creationStatus: string;
      recipe?: IFormulaRecipe;
    } = {
      name: parsedData.name,
      context: {
        driver: parsedData.type,
        tag: parsedData.category,
        frequency: parsedData.frequency,
        startDate: parsedData.startDate,
        endDate: parsedData.endDate,
        departments: parsedData.departments,
      },
      creationStatus: parsedData.creationStatus,
    };

    if (parsedData.type !== 'custom') {
      updateBody.context.amount = parsedData.amountForExpense;
    }

    if (parsedData.type === 'custom' && updatedFormula.length > 0) {
      updateBody.recipe = parsedData.recipe;
    }

    if (parsedData.dataSourceUuids) {
      updateBody.dataSourceUuids = parsedData.dataSourceUuids;
    }

    let response;
    if (expenseUuid && expenseUuid.type === 'edit') {
      response = (await request({
        method: 'PATCH',
        url: `/expenses/${expenseUuid.expenseUuid}`,
        headers: { 'Organization-Uuid': organizationUuid },
        body: updateBody,
        params: { scenarioUuid: scenarioUuid, awaitCalculations: true },
      })) as IAPIResponse;
    } else {
      response = (await request({
        method: 'POST',
        url: `/expenses`,
        headers: { 'Organization-Uuid': organizationUuid },
        body: updateBody,
        params: { scenarioUuid: scenarioUuid, awaitCalculations: true },
      })) as IAPIResponse;
    }
    const parsedResponseData = ZExpense.parse(response.data.data);
    if ([200, 201].includes(response.status)) {
      successCallback({ createdExpense: parsedResponseData });
    } else {
      failureCallback();
    }
  }
};

export default createExpense;

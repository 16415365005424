import { IFormula } from '~/pages/FinancialModelDeprecated/entity/types';

export const handleTraverseFormulaList = ({
  filteredFormulaList,
  setHighlightedFormula,
  traversalDirection,
}: {
  filteredFormulaList: IFormula[];
  setHighlightedFormula: React.Dispatch<React.SetStateAction<{ formula?: IFormula; index: number }>>;
  traversalDirection: 'up' | 'down';
}): void => {
  setHighlightedFormula((prev) => {
    const delta = traversalDirection === 'up' ? -1 : 1;
    const length = filteredFormulaList.length;
    const newIndex = (prev.index + delta + length) % length;
    const newFormula = filteredFormulaList[newIndex];

    return { formula: newFormula, index: newIndex };
  });
};

import { store } from '~/store';
import serverRequest from '~/utils/request';
import { ZGraphData } from './entity/schemas';
import { dashboardConfigToFormulasToFetch } from './utils/dashboardConfigToFormulasToFetch';

interface IAPIResponse {
  data: {
    data: unknown[];
  };
  status: number;
}

export default async (signal: AbortSignal): Promise<unknown> => {
  const { defaultGraphStartDate, defaultGraphEndDate } = store.getState().user.preferences;
  const startDate = defaultGraphStartDate;
  const endDate = defaultGraphEndDate;
  const { activeScenarioUuid, selectedScenarioUuids } = store.getState().scenario;
  const {
    uuid: organizationUuid,
    configuration: { dashboardConfiguration },
  } = store.getState().organization;

  const formulasToFetch = dashboardConfigToFormulasToFetch({ dashboardConfiguration });
  const additionalReports = dashboardConfiguration.metrics.includes('Runway') ? ['Runway'] : [];

  const promises = [];
  const reportsPromises = [];

  reportsPromises.push(
    serverRequest({
      url: `/reports/consolidate`,
      method: 'GET',
      params: {
        scenarioUuid: activeScenarioUuid,
        formulaUuids: formulasToFetch,
        scenariosToInclude: selectedScenarioUuids,
        startDate,
        endDate,
        additionalReports,
      },
      headers: { 'Organization-Uuid': organizationUuid },
      signal,
    }),
  );

  promises.push(
    serverRequest({
      url: `/organizations/${organizationUuid}/scenarios`,
      method: 'GET',
      signal,
    }),
  );

  const result = await Promise.all(promises);

  const [scenariosResponse] = result as unknown as [IAPIResponse];
  const reportsResult = (await Promise.all(reportsPromises)) as unknown as [IAPIResponse];
  const parsedReportsResult = ZGraphData.parse(reportsResult[0].data.data);

  return {
    reportData: parsedReportsResult,
    scenarios: scenariosResponse.data.data,
  };
};

import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { StatusCodes } from 'http-status-codes';

const refreshAccessToken = async (): Promise<AxiosResponse> =>
  axios
    .request({
      method: 'POST',
      baseURL: `${
        !import.meta.env.VITE_PARALLEL_API_URL.includes('http') ? 'https://' : ''
      }${import.meta.env.VITE_PARALLEL_API_URL}/v1`,
      url: '/auth/tokens/refresh',
      data: {
        refreshToken: localStorage.getItem('refreshToken'),
      },
    })
    .then((tokenRefreshResponse) => {
      if (tokenRefreshResponse.status >= 400) {
        window.location.replace('/auth/logout');
        return Promise.reject();
      }

      return tokenRefreshResponse;
    });
export interface IRequest {
  url: string;
  method: Method;
  body?: object;
  headers?: object;
  params?: Record<string, string | string[] | number | boolean | Date | undefined | null>;
  signal?: AbortSignal;
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === StatusCodes.UNAUTHORIZED &&
      !originalRequest.retry &&
      localStorage.getItem('refreshToken')
    ) {
      originalRequest.retry = true;
      const tokenRefreshResponse = await refreshAccessToken();
      localStorage.setItem('accessToken', tokenRefreshResponse.data.data.accessToken);
      originalRequest.headers.Authorization = `Bearer ${tokenRefreshResponse.data.data.accessToken}`;
      return axios(originalRequest);
    } else if (error.response && error.response.status === StatusCodes.UNAUTHORIZED) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      window.location.replace('/auth/logout');
    }
    return Promise.reject(error);
  },
);

export default async ({ url, method, body, headers, params, signal }: IRequest): Promise<AxiosResponse> => {
  const accessToken = localStorage.getItem('accessToken');

  const contentType =
    headers && Object.prototype.hasOwnProperty.call(headers, 'Content-Type')
      ? headers['Content-Type']
      : 'application/json';

  const config: AxiosRequestConfig = {
    url,
    method,
    baseURL: `${
      !import.meta.env.VITE_PARALLEL_API_URL.includes('http') ? 'https://' : ''
    }${import.meta.env.VITE_PARALLEL_API_URL}/v1`,
    data: body,
    headers: {
      'Content-Type': contentType,
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      ...headers,
    },
    params,
    validateStatus: (status) => status !== 401,
    signal,
  };
  return axios.request(config);
};

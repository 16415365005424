import React, { useEffect, useRef, useState } from 'react';

const NonTimeboundGoalLabel = ({
  targetValue,
  yFormatter,
  cx,
  cy,
  payload,
  xMin,
}: {
  targetValue: number;
  yFormatter: (value: number) => string;
  cx?: number;
  cy?: number;
  payload?: { targetValue: number; date: number };
  xMin: number;
}): React.ReactElement | null => {
  const [svgSize, setSvgSize] = useState({ width: 50, height: 30 });
  const textRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (textRef.current) {
      const bbox = textRef.current.getBBox();
      const padding = 20;

      setSvgSize({
        width: bbox.width + padding,
        height: bbox.height + padding,
      });
    }
  }, [targetValue]);

  if (cx && cy && payload && payload.date === xMin) {
    return (
      <svg
        width={svgSize.width}
        height={svgSize.height}
        xmlns="http://www.w3.org/2000/svg"
        x={cx - svgSize.width * 0.1}
        y={cy - svgSize.height / 2 + 2}
      >
        <path
          d={`M4 1 h${svgSize.width - 15} a10 10 0 0 1 10 10 v10 a10 10 0 0 1 -10 10 h-${svgSize.width - 15}`}
          fill="white"
          stroke="none"
        />
        <rect
          x={4}
          y={1}
          width={svgSize.width - 10}
          height={svgSize.height - 10}
          fill="none"
          stroke="#AAB7A4"
          strokeWidth="2"
          strokeDasharray="8 8"
          rx="5"
        />
        <line x1={4} y1={1} x2={4} y2={svgSize.height - 10} stroke="white" strokeWidth="6" />
        <text
          ref={textRef as React.RefObject<SVGTextElement>}
          x={svgSize.width / 2}
          y={svgSize.height / 2 - 2}
          textAnchor="middle"
          alignmentBaseline="middle"
          fontSize={14}
          fill="#AAB7A4"
          fontFamily="Inter"
        >
          {yFormatter(targetValue)}
        </text>
      </svg>
    );
  }
  return null;
};

export default NonTimeboundGoalLabel;

import React from 'react';

const UnlockedIcon = (): React.ReactNode => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#BCBCBC" />
      <path
        d="M20 9C21.933 9 23.5 10.567 23.5 12.5V15C23.5 15.2761 23.2761 15.5 23 15.5C22.7239 15.5 22.5 15.2761 22.5 15V12.5C22.5 11.1193 21.3807 10 20 10C18.6193 10 17.5 11.1193 17.5 12.5V14.5C18.6046 14.5 19.5 15.3954 19.5 16.5V21C19.5 22.1046 18.6046 23 17.5 23H10.5C9.39543 23 8.5 22.1046 8.5 21V16.5C8.5 15.3954 9.39543 14.5 10.5 14.5H16.5V12.5C16.5 10.567 18.067 9 20 9Z"
        fill="#BCBCBC"
      />
    </svg>
  );
};

export default UnlockedIcon;

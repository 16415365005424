import { z } from 'zod';

export const ZBusinessContext = z.object({
  updatedBy: z.string().nullable(),
  updatedAt: z.date({ coerce: true }),
  context: z.object({
    summary: z.string(),
    companyUrl: z.string(),
    companyDescription: z.string(),
    keyGoals: z.string(),
    offeringDetails: z.string(),
    fundingHistory: z.string(),
  }),
});

import React from 'react';
import Button from '~/components/Button';
import Modal from '~/components/Modal';
import PeriodPicker from '~/components/PeriodPicker';
import usePeriodPicker from '~/components/PeriodPicker/usePeriodPicker';
import Typography from '~/components/Typography';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import request from '~/utils/request';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { StatusCodes } from 'http-status-codes';

const DiscontinueExpenseModal = (): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const { discontinueExpense, setDiscontinueExpense, expenses, setExpenses, revalidateFilteredExpensesReport } =
    React.useContext(ExpensesPageContext);
  const [selectedDate, setSelectedDate] = usePeriodPicker({
    mode: 'month',
    startDate: new Date(),
    endDate: new Date(),
  });
  const [error, setError] = React.useState<string>('');

  const handleDiscontinueExpense = async (): Promise<void> => {
    if (selectedDate.endDate && discontinueExpense && selectedDate.endDate >= new Date(discontinueExpense.startDate)) {
      const endDate = format(selectedDate.endDate, 'yyyy-MM-dd');
      const response = await request({
        method: 'PATCH',
        url: `/expenses/${discontinueExpense.expenseUuid}`,
        body: {
          endDate,
        },
        params: {
          scenarioUuid: activeScenarioUuid,
        },
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      });

      if (response.status === StatusCodes.OK) {
        const updatedExpenses = expenses.map((expense) =>
          expense.uuid === discontinueExpense.uuid
            ? {
                ...expense,
                context: {
                  ...expense.context,
                  endDate,
                },
              }
            : expense,
        );
        setError('');
        setDiscontinueExpense(undefined);
        setExpenses(updatedExpenses);
        revalidateFilteredExpensesReport();
      }
    } else {
      setError('This date must be after the expense start date');
    }
  };

  const onClose = (): void => {
    setError('');
    setDiscontinueExpense(undefined);
  };

  return (
    <Modal
      isOpen={!!discontinueExpense}
      onClose={onClose}
      title={`Discontinue ${discontinueExpense?.expenseTitle ?? 'Expense'}`}
      showClose={true}
      size="xs"
      id="discontinue-expense-modal"
    >
      <div className="flex flex-col gap-4">
        <div>
          <Typography color="secondary">
            Discontinue this expense will eliminate its impact on the forecast. Please specify the final month to
            include this expense.
          </Typography>
        </div>
        <div data-testid="discontinue-expense-period-picker">
          <PeriodPicker
            id="discontinue-expense-period-picker"
            label="Final Charge Date"
            state={selectedDate}
            setState={setSelectedDate}
            pickerAlignment="left"
          />
          {error && (
            <Typography size="xs" color="warning">
              {error}
            </Typography>
          )}
        </div>
        <div className="flex w-full justify-between">
          <div className="w-fit">
            <Button fill="clear" className="!w-fit !px-0" onClick={onClose}>
              Cancel
            </Button>
          </div>
          <div className="w-fit">
            <Button fill="destructive" onClick={() => handleDiscontinueExpense()}>
              Discontinue Expense
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DiscontinueExpenseModal;

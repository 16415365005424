import { IDepartmentDict } from '~/pages/Settings/Departments/entity/types';

export const convertDepartmentUuidsToDisplayName = ({
  departmentUuids,
  departmentDict,
}: {
  departmentUuids: string[];
  departmentDict: IDepartmentDict;
}): string => {
  if (departmentUuids.length === 1 && departmentUuids[0] === 'ALL') {
    return 'All';
  }

  const departmentGroupingUuids = Object.values(departmentDict).map(({ departmentUuid }) => departmentUuid);

  return departmentUuids
    .map((departmentGroupingUuid) => {
      if (departmentGroupingUuids.includes(departmentGroupingUuid)) {
        return (
          Object.values(departmentDict).find(({ departmentUuid }) => departmentGroupingUuid === departmentUuid)?.name ??
          ''
        );
      }
      return '';
    })
    .sort((a, b) => a.localeCompare(b))
    .join(', ');
};

import React, { useEffect, useRef, useCallback } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';

interface Props {
  id?: string;
  options: Option[];
  placeTop?: boolean;
  className?: string;
  color?: string;
}

export interface Option {
  label: string;
  onClick: () => void;
  className?: string;
  id?: string;
}

const EllipsisDropdown = ({ id, options, placeTop, className, color }: Props): React.ReactElement => {
  const buttonRef = useRef<HTMLDivElement | null>(null);
  const closePopoverRef = useRef<() => void>(() => {});

  const setClosePopover = useCallback((close: () => void) => {
    closePopoverRef.current = close;
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          closePopoverRef.current();
        }
      },
      { threshold: 0.1 },
    );

    if (buttonRef.current) {
      observer.observe(buttonRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Popover className={className}>
      {({ open, close }) => {
        setClosePopover(close);

        return (
          <>
            <PopoverButton
              as="div"
              ref={buttonRef}
              className={`cursor-pointer !w-fit ${color ?? 'text-neutral-400'}`}
              data-testid={id ?? ''}
            >
              <EllipsisVerticalIcon className="size-6" />
            </PopoverButton>
            <PopoverPanel
              anchor={placeTop ? 'top end' : 'bottom end'}
              as="div"
              className={`${
                open ? 'block' : 'hidden'
              } absolute bg-white shadow-md rounded-md z-60 border-none outline-none`}
            >
              {options.map((option) => (
                <button
                  key={option.label}
                  className={`
                    block px-4 py-2 w-full text-left outline-none border-none hover:bg-neutral-15
                    ${option.className} 
                  `}
                  onClick={() => {
                    close();
                    option.onClick();
                  }}
                  data-testid={id ? id + '-' + option.label : ''}
                >
                  {option.label}
                </button>
              ))}
            </PopoverPanel>
          </>
        );
      }}
    </Popover>
  );
};

export default EllipsisDropdown;

import React, { useRef, useEffect, useState } from 'react';
import useInput from '~/components/Input/useInput';
import useFormulaContext from '../useFormulaContext';
import InputWrapper from '~/components/Input/InputWrapper';
import useTableContext from '~/components/FormulasTable/hooks/useTableContext';
import ErrorPopover from './TableColumns/Label/ErrorPopover';

const PendingAttributeInput = ({ groupIndex, active }: { groupIndex: number; active: boolean }): React.ReactElement => {
  const { setPendingAttributeData, saveNewAttribute, formulaDictionary } = useFormulaContext();
  const { columnWidths } = useTableContext();
  const [pendingLabel, setPendingLabel] = useInput({
    validation: /.*/,
  });
  const pendingLabelRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);

  const onBlurPendingLabel = (tabIntoFormula = false): void => {
    if (!pendingLabel.value) {
      setPendingAttributeData(null);
    } else if (isNameUnique(pendingLabel.value)) {
      setLoading(true);
      saveNewAttribute({ name: pendingLabel.value, groupIndex, tabIntoFormula });
    }
  };

  const onKeyDownPendingLabel = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter') {
      if (pendingLabel.value && isNameUnique(pendingLabel.value)) {
        onBlurPendingLabel();
      }
    } else if (event.key === 'Tab') {
      (event.currentTarget as HTMLInputElement).blur();
      onBlurPendingLabel(true);
    }
    if (event.key === 'Escape') {
      setPendingLabel((prev) => ({ ...prev, value: '' }));
      setPendingAttributeData(null);
    }
  };

  useEffect(() => {
    if (active) {
      setPendingLabel((prev) => ({ ...prev, value: '' }));
      const element = pendingLabelRef.current;
      element?.focus();
    } else {
      setLoading(false);
      setPendingLabel((prev) => ({ ...prev, value: '' }));
    }
  }, [active]);

  const isNameUnique = (name: string): boolean => {
    if (loading) return true; // Bypass validation while saving

    const formulaWithSameName = Object.values(formulaDictionary).find((formula) => formula.recipe.name === name);
    return !formulaWithSameName;
  };

  if (!active) return <></>;

  return (
    <div className="flex border-b bg-white h-[48px] relative">
      <div
        className="flex items-center justify-start border-r pl-[25px] pr-2 sticky left-0 z-20 bg-white"
        style={{
          width: `${columnWidths[0] + columnWidths[1]}px`,
          minWidth: `${columnWidths[0] + columnWidths[1]}px`,
          boxShadow: '6px 0px 8px rgba(0, 0, 0, 0.03)',
        }}
      >
        <InputWrapper
          disabled={loading}
          placeholder="Attribute Name"
          ref={pendingLabelRef}
          id="pending-label"
          state={pendingLabel}
          setState={setPendingLabel}
          className={`h-[48px] focus-visible:!ring-0 focus-visible:!ring-offset-0 ${
            !isNameUnique(pendingLabel.value) ? '!border-red-500' : ''
          }`}
          onBlur={() => onBlurPendingLabel(false)}
          onKeyDown={onKeyDownPendingLabel}
        />
        <ErrorPopover
          visible={!isNameUnique(pendingLabel.value)}
          formulaName={pendingLabel.value}
          right={pendingLabelRef.current?.getBoundingClientRect().right ?? 0}
          top={pendingLabelRef.current?.getBoundingClientRect().top ?? 0}
        />
      </div>
    </div>
  );
};

export default PendingAttributeInput;

import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSSProperties, ReactElement } from 'react';
import { CSS } from '@dnd-kit/utilities';
import dragIcon from '~/assets/dragIcon.svg';

export const DragIconSortableItem = ({
  id,
  children,
  className,
}: {
  id: string;
  children: React.ReactNode;
  className?: string;
}): ReactElement => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id.toString() });
  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 1000 : 0, // Set a higher z-index when dragging
  };
  return (
    <tr ref={setNodeRef} style={style} {...attributes} className={className}>
      <img {...listeners} src={dragIcon} alt="drag" className="size-8" />
      {children}
    </tr>
  );
};

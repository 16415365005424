import React from 'react';
import Typography from '~/components/Typography';
import { IDataArrayDictionary, ILineProps } from '~/components/LineGraph/entity/types';
import { formatDate } from 'date-fns';

interface IProps {
  active?: boolean;
  payload?: { payload: IDataArrayDictionary }[];
  label?: number;
  lines: ILineProps[];
}

const HeadcountGraphTooltip = ({ active, payload, label, lines }: IProps): React.ReactElement | null => {
  const monthFormatter = (value: number | null): string => {
    if (!value) return '';
    return formatDate(value, 'MMM yyyy');
  };

  if (active && payload?.length) {
    return (
      <div className="bg-white ml-2 rounded-xl shadow-md py-1 px-4">
        <Typography color="empty" size="xs">
          {monthFormatter(label ?? null)}
        </Typography>
        <div className="flex flex-col items-center justify-between gap-2 min-w-[216px]">
          {lines.map((line: ILineProps, index: number) => (
            <div key={line.dataKey} className="flex items-center justify-between gap-2 w-full">
              <div className="flex items-center gap-2">
                <div className="w-2 h-2 rounded-full" style={{ backgroundColor: line.stroke }} />
                <Typography color="secondary" size="sm">
                  {line.dataKey.charAt(0).toUpperCase() + line.dataKey.slice(1)}
                </Typography>
              </div>
              <Typography size="sm" weight="bold" className="mr-1">
                {payload[index] ? payload[index].payload[line.dataKey] : '0'}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return null;
};

export default HeadcountGraphTooltip;

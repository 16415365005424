import { ICompanyGoal } from '../../Dashboard/entity/types';
import { IAPIResponse } from '~/utils/types';
import request from '~/utils/request';

export const updateGoal = async ({
  goalUuid,
  targetValue,
  targetDate,
  formulaUuid,
  organizationUuid,
}: {
  goalUuid: string;
  targetValue: number;
  targetDate: string | null;
  formulaUuid: string | null;
  organizationUuid: string;
}): Promise<IAPIResponse<ICompanyGoal>> => {
  const response = await request({
    method: 'PATCH',
    url: `/goals/${goalUuid}`,
    body: {
      updateData: {
        formulaUuid,
        targetValue,
        targetDate,
      },
    },
    headers: {
      'Organization-Uuid': organizationUuid,
    },
  });
  return response as IAPIResponse<ICompanyGoal>;
};

import { IDashboardConfiguration } from '~/store/organizationSlice';

export const dashboardConfigToFormulasToFetch = ({
  dashboardConfiguration,
}: {
  dashboardConfiguration: IDashboardConfiguration;
}): string[] => {
  const combinedSet = new Set([
    ...dashboardConfiguration.metrics,
    ...dashboardConfiguration.graphs,
    ...dashboardConfiguration.levers,
  ]);
  combinedSet.delete('Runway');
  return Array.from(combinedSet);
};

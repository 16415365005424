import React, { useContext, useMemo } from 'react';
import Button from '~/components/Button';
import Divider from '~/components/Divider';
import useInput from '~/components/Input/useInput';
import Input from '~/components/Input/InputWrapper';
import { FinancialModelContext } from '../../context/FinancialModelContext';
import Typography from '~/components/Typography';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import logger from '~/utils/logger';
import toast from 'react-hot-toast';
import { useFormulaInput } from '~/components/FormulaListInput/useFormulaListInput';
import FormulaListInput from '~/components/FormulaListInput';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';

const MarketingFunnelForm = ({ handleClose }: { handleClose: () => void }): React.ReactNode => {
  const { parsedFormulas, revalidate } = useContext(FinancialModelContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const activeScenarioUuid = useSelector((state: State) => state.scenario.activeScenarioUuid);
  const [titlePrefix, setTitlePrefix] = useInput({});
  const [attributeTitle, setAttributeTitle] = useInput({});
  const [percentOfSalesFromMarketing, setPercentOfSalesFromMarketing] = useInput({});
  const [averageCostPerLead, setAverageCostPerLead] = useInput({});
  const [percentOfLeadsToDemos, setPercentOfLeadsToDemos] = useInput({});
  const [percentOfDemosHeld, setPercentOfDemosHeld] = useInput({});
  const [percentOfDemosClosed, setPercentOfDemosClosed] = useInput({});

  const { filteredFormulaList, highlightedFormula, setHighlightedFormula, handleKeyDown, onSelectAttribute } =
    useFormulaInput({
      formulaList: parsedFormulas.list,
      inputValue: attributeTitle.value,
      onSelectAttribute: (attribute) => {
        setAttributeTitle((prev) => ({
          ...prev,
          value: attribute.recipe.name,
          valid: true,
          touched: true,
          pristine: false,
          errorMessage: '',
        }));
      },
    });

  const needTitlePrefix = useMemo(() => {
    return parsedFormulas.list.some((formula) => {
      return formula.recipe.name.includes('Marketing $ Needed');
    });
  }, []);

  const handleSave = async ({
    titlePrefix,
    attributeTitle,
    percentOfSalesFromMarketing,
    averageCostPerLead,
    percentOfLeadsToDemos,
    percentOfDemosHeld,
    percentOfDemosClosed,
  }: {
    titlePrefix: string;
    attributeTitle: string;
    percentOfSalesFromMarketing: string;
    averageCostPerLead: string;
    percentOfLeadsToDemos: string;
    percentOfDemosHeld: string;
    percentOfDemosClosed: string;
  }): Promise<void> => {
    if (
      attributeTitle &&
      (needTitlePrefix ? titlePrefix : true) &&
      percentOfSalesFromMarketing &&
      averageCostPerLead &&
      percentOfLeadsToDemos &&
      percentOfDemosHeld &&
      percentOfDemosClosed
    ) {
      const marketingFunnel = {
        titlePrefix: titlePrefix,
        attributeUuid: parsedFormulas.list.find((formula) => {
          return formula.recipe.name === attributeTitle;
        })?.formulaUuid,
        percentOfSalesFromMarketing: Number(percentOfSalesFromMarketing) / 100,
        averageCostPerLead: Number(averageCostPerLead),
        percentOfLeadsToDemos: Number(percentOfLeadsToDemos) / 100,
        percentOfDemosHeld: Number(percentOfDemosHeld) / 100,
        percentOfDemosClosed: Number(percentOfDemosClosed) / 100,
      };

      const response = await request({
        method: 'POST',
        url: `formulas/template/marketing-funnel`,
        body: marketingFunnel,
        params: {
          scenarioUuid: activeScenarioUuid,
        },
        headers: {
          'Organization-Uuid': organizationUuid,
        },
      });

      if (response.status >= 400) {
        toast.error('Failed to create rep ramping');
        logger.error(new Error(response.data));
      } else {
        handleClose();
        revalidate();
      }
    } else {
      setTitlePrefix((prev) => ({ ...prev, pristine: false, touched: true }));
      setAttributeTitle((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setPercentOfSalesFromMarketing((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setAverageCostPerLead((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setPercentOfLeadsToDemos((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setPercentOfDemosHeld((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
      setPercentOfDemosClosed((prev) => ({
        ...prev,
        pristine: false,
        touched: true,
      }));
    }
  };

  return (
    <div className="w-full flex flex-col gap-2">
      <Typography color="secondary" className="mb-2" size="xs">
        Model the customer journey from awareness to conversion, forecasting how marketing spend will translates into
        revenue
      </Typography>
      {needTitlePrefix && (
        <Input
          label="Title Prefix"
          state={titlePrefix}
          setState={setTitlePrefix}
          id="title-prefix-input"
          placeholder=""
        />
      )}
      <FormulaListInput
        attributeTitle={attributeTitle}
        setAttributeTitle={setAttributeTitle}
        handleKeyDown={handleKeyDown}
        filteredFormulaList={filteredFormulaList}
        onSelectAttribute={onSelectAttribute}
        highlightedFormula={highlightedFormula}
        setHighlightedFormula={setHighlightedFormula}
        inputLabel="New Bookings Attribute"
        inputPlaceholder="Indicate which Attribute indicates New Bookings"
        inputId="marketingFunnel"
      />
      <Divider className="my-1" />
      <div className="flex flex-col gap-2">
        <Input
          label="% of Sales from Marketing"
          state={percentOfSalesFromMarketing}
          setState={setPercentOfSalesFromMarketing}
          id="percent-of-sales-from-marketing-input"
          type="percentage"
          placeholder="%"
        />
        <CurrencyInput
          label="Average Cost Per Lead"
          state={averageCostPerLead}
          setState={setAverageCostPerLead}
          id="average-cost-per-lead-input"
        />
        <Input
          label="% of Leads to Demos"
          state={percentOfLeadsToDemos}
          setState={setPercentOfLeadsToDemos}
          id="percent-of-leads-to-demos-input"
          type="percentage"
          placeholder="%"
        />
        <Input
          label="% of Demos Held"
          state={percentOfDemosHeld}
          setState={setPercentOfDemosHeld}
          id="percent-of-demos-held-input"
          type="percentage"
          placeholder="%"
        />
        <Input
          label="% of Demos Closed"
          state={percentOfDemosClosed}
          setState={setPercentOfDemosClosed}
          id="percent-of-demos-closed-input"
          type="percentage"
          placeholder="%"
        />
      </div>
      <div className="flex items-center justify-between w-full mt-1">
        <div className="w-fit">
          <Button fill="clear" className="!w-fit !px-0" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <div className="w-fit">
          <Button
            onClick={() =>
              handleSave({
                titlePrefix: titlePrefix.value,
                attributeTitle: attributeTitle.value,
                percentOfSalesFromMarketing: percentOfSalesFromMarketing.value,
                averageCostPerLead: averageCostPerLead.value,
                percentOfLeadsToDemos: percentOfLeadsToDemos.value,
                percentOfDemosHeld: percentOfDemosHeld.value,
                percentOfDemosClosed: percentOfDemosClosed.value,
              })
            }
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MarketingFunnelForm;

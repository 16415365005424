import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useDraggableContext } from '~/components/FormulasTable/contexts/DraggableContext';

const DragHandle = (): React.ReactElement => {
  const { attributes, listeners, iconAlwaysVisible } = useDraggableContext();

  return (
    <div className={`bg-white flex items-center cursor-grab`} {...attributes} {...listeners} tabIndex={-1}>
      <div className={`flex items-center ${iconAlwaysVisible ? '' : 'group-hover:opacity-100 opacity-0'}`}>
        <EllipsisVerticalIcon className="size-5 text-neutral-200" />
        <EllipsisVerticalIcon className="size-5 -ml-[15px] text-neutral-200" />
      </div>
    </div>
  );
};

export default DragHandle;

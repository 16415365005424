import React, { createContext, useEffect, useState } from 'react';

interface IProps {
  children: React.ReactNode;
}

export enum IActiveSelectionEnum {
  INITIAL = 'initial',
  PEOPLE_AND_FACILITIES = 'peopleAndFacilities',
  COGS = 'COGS',
  SOFTWARE = 'softwareExpenses',
  MARKETING = 'marketing',
  OTHER = 'other',
  FINALIZE = 'finalize',
}

export interface ISegmentState {
  disabled: boolean;
  touched: boolean;
  selected: boolean;
}

interface IGenerateExpensesContext {
  activeSection: IActiveSelectionEnum;
  setActiveSection: React.Dispatch<React.SetStateAction<IActiveSelectionEnum>>;
  peopleAndFacilitiesSegmentState: ISegmentState;
  setPeopleAndFacilitiesSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>;
  cogsSegmentState: ISegmentState;
  setCogsSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>;
  softwareExpensesSegmentState: ISegmentState;
  setSoftwareExpensesSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>;
  marketingSegmentState: ISegmentState;
  setMarketingSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>;
  otherSegmentState: ISegmentState;
  setOtherSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>;
  resetSegmentStates: () => void;
}

const initialContext: IGenerateExpensesContext = {
  activeSection: IActiveSelectionEnum.INITIAL,
  setActiveSection: () => {},
  peopleAndFacilitiesSegmentState: {
    disabled: true,
    touched: false,
    selected: false,
  },
  setPeopleAndFacilitiesSegmentState: () => {},
  cogsSegmentState: {
    disabled: true,
    touched: false,
    selected: false,
  },
  setCogsSegmentState: () => {},
  softwareExpensesSegmentState: {
    disabled: true,
    touched: false,
    selected: false,
  },
  setSoftwareExpensesSegmentState: () => {},
  marketingSegmentState: {
    disabled: true,
    touched: false,
    selected: false,
  },
  setMarketingSegmentState: () => {},
  otherSegmentState: {
    disabled: true,
    touched: false,
    selected: false,
  },
  setOtherSegmentState: () => {},
  resetSegmentStates: () => {},
};

export const GenerateExpensesContext = createContext<IGenerateExpensesContext>(initialContext);

export const GenerateExpensesContextProvider = ({ children }: IProps): React.ReactElement => {
  const [activeSection, setActiveSection] = useState<IActiveSelectionEnum>(IActiveSelectionEnum.INITIAL);
  const initialSegmentState: ISegmentState = {
    disabled: true,
    touched: false,
    selected: false,
  };
  const [peopleAndFacilitiesSegmentState, setPeopleAndFacilitiesSegmentState] =
    useState<ISegmentState>(initialSegmentState);
  const [cogsSegmentState, setCogsSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [softwareExpensesSegmentState, setSoftwareExpensesSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [marketingSegmentState, setMarketingSegmentState] = useState<ISegmentState>(initialSegmentState);
  const [otherSegmentState, setOtherSegmentState] = useState<ISegmentState>(initialSegmentState);

  useEffect(() => {
    const updateSegmentState = (
      setSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>,
      isActive: boolean,
    ): void => {
      setSegmentState((prev) => ({
        ...prev,
        selected: isActive,
        touched: isActive || prev.touched,
      }));
    };

    updateSegmentState(
      setPeopleAndFacilitiesSegmentState,
      activeSection === IActiveSelectionEnum.PEOPLE_AND_FACILITIES,
    );
    updateSegmentState(setCogsSegmentState, activeSection === IActiveSelectionEnum.COGS);
    updateSegmentState(setSoftwareExpensesSegmentState, activeSection === IActiveSelectionEnum.SOFTWARE);
    updateSegmentState(setMarketingSegmentState, activeSection === IActiveSelectionEnum.MARKETING);
    updateSegmentState(setOtherSegmentState, activeSection === IActiveSelectionEnum.OTHER);
  }, [activeSection]);

  const resetSegmentStates = (): void => {
    const resetSegmentState = (setSegmentState: React.Dispatch<React.SetStateAction<ISegmentState>>): void => {
      setSegmentState(initialSegmentState);
    };

    resetSegmentState(setPeopleAndFacilitiesSegmentState);
    resetSegmentState(setCogsSegmentState);
    resetSegmentState(setSoftwareExpensesSegmentState);
    resetSegmentState(setMarketingSegmentState);
    resetSegmentState(setOtherSegmentState);
    setActiveSection(IActiveSelectionEnum.INITIAL);
  };

  return (
    <GenerateExpensesContext.Provider
      value={{
        activeSection,
        setActiveSection,
        peopleAndFacilitiesSegmentState,
        setPeopleAndFacilitiesSegmentState,
        cogsSegmentState,
        setCogsSegmentState,
        softwareExpensesSegmentState,
        setSoftwareExpensesSegmentState,
        marketingSegmentState,
        setMarketingSegmentState,
        otherSegmentState,
        setOtherSegmentState,
        resetSegmentStates,
      }}
    >
      {children}
    </GenerateExpensesContext.Provider>
  );
};

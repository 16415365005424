type RGB = [number, number, number];

export const generateLogoAnimationJson = (rgb: RGB): object => {
  const jsonTemplate = {
    nm: 'Option 1-fast2',
    ddd: 0,
    h: 43,
    w: 66,
    meta: { g: 'LottieFiles Figma v42' },
    layers: [
      {
        ty: 4,
        nm: 'Vector',
        sr: 1,
        st: 0,
        op: 25,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [19.62, 19.75],
                t: 0,
              },
              { s: [19.62, 19.75], t: 24 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100, 100],
                t: 0,
              },
              { s: [100, 100], t: 24 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [44.04, 32.69],
                t: 0,
              },
              { s: [21.62, 10.28], t: 24 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [0],
                t: 0,
              },
              { s: [-180], t: 24 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100],
                t: 0,
              },
              { s: [100], t: 24 },
            ],
          },
        },
        shapes: [
          {
            ty: 'sh',
            bm: 0,
            hd: false,
            nm: '',
            d: 1,
            ks: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 24,
                },
              ],
            },
          },
          {
            ty: 'fl',
            bm: 0,
            hd: false,
            nm: '',
            c: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: rgb.map((v) => v / 255),
                  t: 0,
                },
                { s: rgb.map((v) => v / 255), t: 24 },
              ],
            },
            r: 1,
            o: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [100],
                  t: 0,
                },
                { s: [100], t: 24 },
              ],
            },
          },
        ],
        ind: 1,
      },
      {
        ty: 4,
        nm: 'Vector',
        sr: 1,
        st: 0,
        op: 25,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [19.62, 19.75],
                t: 0,
              },
              { s: [19.62, 19.75], t: 24 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100, 100],
                t: 0,
              },
              { s: [100, 100], t: 24 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [21.63, 10.28],
                t: 0,
              },
              { s: [44.04, 32.69], t: 24 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [0],
                t: 0,
              },
              { s: [-180], t: 24 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100],
                t: 0,
              },
              { s: [100], t: 24 },
            ],
          },
        },
        shapes: [
          {
            ty: 'sh',
            bm: 0,
            hd: false,
            nm: '',
            d: 1,
            ks: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 24,
                },
              ],
            },
          },
          {
            ty: 'fl',
            bm: 0,
            hd: false,
            nm: '',
            c: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: rgb.map((v) => v / 255),
                  t: 0,
                },
                { s: rgb.map((v) => v / 255), t: 24 },
              ],
            },
            r: 1,
            o: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [100],
                  t: 0,
                },
                { s: [100], t: 24 },
              ],
            },
          },
        ],
        ind: 2,
      },
      {
        ty: 4,
        nm: 'Vector',
        sr: 1,
        st: 0,
        op: 25,
        ip: 0,
        hd: false,
        ddd: 0,
        bm: 0,
        hasMask: false,
        ao: 0,
        ks: {
          a: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [19.62, 19.75],
                t: 0,
              },
              { s: [19.62, 19.75], t: 24 },
            ],
          },
          s: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100, 100],
                t: 0,
              },
              { s: [100, 100], t: 24 },
            ],
          },
          sk: { a: 0, k: 0 },
          p: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [32.84, 21.49],
                t: 0,
              },
              { s: [32.83, 21.48], t: 24 },
            ],
          },
          r: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [0],
                t: 0,
              },
              { s: [-180], t: 24 },
            ],
          },
          sa: { a: 0, k: 0 },
          o: {
            a: 1,
            k: [
              {
                o: { x: 0.44999998807907104, y: 0 },
                i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                s: [100],
                t: 0,
              },
              { s: [100], t: 24 },
            ],
          },
        },
        shapes: [
          {
            ty: 'sh',
            bm: 0,
            hd: false,
            nm: '',
            d: 1,
            ks: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 0,
                },
                {
                  s: [
                    {
                      c: true,
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [31.75, 0],
                        [0, 31.96],
                        [7.48, 39.49],
                        [39.23, 7.53],
                        [31.75, 0],
                      ],
                    },
                  ],
                  t: 24,
                },
              ],
            },
          },
          {
            ty: 'fl',
            bm: 0,
            hd: false,
            nm: '',
            c: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: rgb.map((v) => v / 255),
                  t: 0,
                },
                { s: rgb.map((v) => v / 255), t: 24 },
              ],
            },
            r: 1,
            o: {
              a: 1,
              k: [
                {
                  o: { x: 0.44999998807907104, y: 0 },
                  i: { x: 0.5299999713897705, y: 0.9900000095367432 },
                  s: [100],
                  t: 0,
                },
                { s: [100], t: 24 },
              ],
            },
          },
        ],
        ind: 3,
      },
    ],
    v: '5.7.0',
    fr: 30,
    op: 24.060000360477716,
    ip: 0,
    assets: [],
  };

  return jsonTemplate;
};

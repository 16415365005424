import { endOfMonth } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const getEndOfUtcMonth = ({ date }: { date: Date }): number => {
  const originalDateUTC = toZonedTime(date, 'UTC').getTime();
  const originalDateLocal = new Date(date).getTime();
  const timeDifference = originalDateLocal - originalDateUTC;
  const endOfMonthLocal = endOfMonth(originalDateUTC);
  const endOfMonthUTC = endOfMonthLocal.getTime() + timeDifference;
  return endOfMonthUTC;
};

import React from 'react';
import TitleCell from './TitleCell';
import ViewCountCell from './ViewCountCell';
import ExpirationCell from './ExpirationCell';
import OptionsCell from './OptionsCell';
import LinkCell from './LinkCell';
import { isBefore } from 'date-fns';
import CreatedAtCell from './CreatedAtCell';
import { IShareLink, IShareLinkWithModel } from '~/pages/ShareLink/models/types';

const Cell = ({
  shareLink,
  columnId,
  setShareLinks,
  setShareLinkToEdit,
  setIsCreateModalOpen,
}: {
  shareLink: IShareLinkWithModel;
  columnId: string;
  setShareLinks: React.Dispatch<React.SetStateAction<IShareLink[]>>;
  setShareLinkToEdit: React.Dispatch<React.SetStateAction<IShareLink | undefined>>;
  setIsCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): React.ReactElement => {
  switch (columnId) {
    case 'title': {
      return <TitleCell title={shareLink.title} model={shareLink.model} />;
    }
    case 'createdAt': {
      return <CreatedAtCell createdAt={shareLink.createdAt} />;
    }
    case 'viewCount': {
      return <ViewCountCell viewCount={shareLink.viewCount} />;
    }
    case 'expirationDate': {
      return <ExpirationCell expiration={shareLink.expirationDate} />;
    }
    case 'link': {
      return <LinkCell shareLinkUuid={shareLink.uuid} isValid={isBefore(new Date(), shareLink.expirationDate)} />;
    }
    case 'options':
    default: {
      return (
        <OptionsCell
          shareLink={shareLink}
          setShareLinks={setShareLinks}
          setShareLinkToEdit={setShareLinkToEdit}
          setIsCreateModalOpen={setIsCreateModalOpen}
        />
      );
    }
  }
};

export default Cell;

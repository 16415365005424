export const turnLetterAbbreviatedIntoNumber = ({ value }: { value: string }): number => {
  let numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ''));
  const abbreviation = value.match(/[TBMK]/);
  if (abbreviation && abbreviation.length === 1) {
    switch (abbreviation[0]) {
      case 'T':
        numberValue *= 100000000000000;
        break;
      case 'B':
        numberValue *= 100000000000;
        break;
      case 'M':
        numberValue *= 100000000;
        break;
      case 'K':
        numberValue *= 100000;
        break;
    }
  } else {
    numberValue *= 100;
  }
  return numberValue;
};

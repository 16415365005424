import React from 'react';
import { CSVLink } from 'react-csv';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import sanitizeCsvData from '~/utils/exportData/sanitizeCsvData';

interface IProps {
  id: string;
  data: unknown[][];
  filename: string;
  className?: string;
  disabled?: boolean;
}

const ExportData = ({ id, data, filename, className, disabled }: IProps): React.ReactNode => {
  const sanitizedData = sanitizeCsvData(data);

  return !disabled && sanitizedData.length > 0 ? (
    <CSVLink
      data-testid={id}
      data={sanitizedData}
      filename={filename}
      className={`p-3 bg-green-50 rounded hover:bg-green-200 ${className ?? ''}`}
    >
      <ArrowDownTrayIcon className="size-5 stroke-green-400" />
    </CSVLink>
  ) : (
    <div className="p-3 bg-neutral-50 rounded">
      <ArrowDownTrayIcon className="size-5 stroke-neutral-100" />
    </div>
  );
};

export default ExportData;

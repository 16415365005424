import React, { useEffect } from 'react';
import useInput from '~/components/Input/useInput';
import Input from '~/components/Input/InputWrapper';

const PercentagePerMonthInput = ({
  label,
  sideLabel,
  value,
  onChange,
  disabled,
}: {
  label: string;
  sideLabel?: string;
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}): React.ReactNode => {
  const [percentage, setPercentage] = useInput({
    value,
    valid: false,
    touched: false,
    pristine: true,
    errorMessage: 'Please enter a valid percentage',
    disabled,
  });

  useEffect(() => {
    setPercentage((prev) => ({
      ...prev,
      value,
      valid: true,
      touched: false,
      pristine: true,
      errorMessage: '',
      disabled,
    }));
  }, [value]);

  useEffect(() => {
    if (percentage.value !== value) {
      onChange(percentage.value);
    }
  }, [percentage.value]);

  return (
    <Input
      id={`percentage-per-month-${label}`}
      label={label}
      sideLabel={sideLabel}
      state={percentage}
      setState={setPercentage}
      type="percentage"
      placeholder="%"
    />
  );
};

export default PercentagePerMonthInput;

import React from 'react';
import Card from '~/components/Card';
import Typography from '~/components/Typography';
import Divider from '~/components/Divider';
import IntegrationsList from './components/List';

const IntegrationsContainer = (): React.ReactNode => {
  return (
    <Card className="w-full flex flex-col gap-5">
      <div className="flex flex-row justify-between w-full">
        <div className="flex flex-col w-full">
          <Typography size="md" weight="bold">
            Integration
          </Typography>
          <Typography color="secondary">Automatically pull data into Parallel</Typography>
        </div>
      </div>
      <Divider className="w-full" />
      <div className="flex flex-col w-full">
        <IntegrationsList />
      </div>
    </Card>
  );
};

export default IntegrationsContainer;

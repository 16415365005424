import React from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Editor } from '@tiptap/react';

interface Props {
  editor: Editor;
  timeseriesValue?: string;
  updateTimeModifier: (modifier: string) => void;
  viewOnly?: boolean;
}

const TimeseriesModifier = ({ editor, timeseriesValue, updateTimeModifier, viewOnly }: Props): React.ReactElement => {
  return (
    <Popover>
      {({ open, close }) => (
        <>
          <PopoverButton
            as="div"
            className={`flex px-2 flex-row gap-x-1 items-center cursor-pointer !w-fit text-neutral-200 ${
              viewOnly ? '!cursor-default' : ''
            }`}
            onClick={(e) => {
              if (viewOnly) {
                e.preventDefault();
                e.stopPropagation();
                return;
              }
              if (!editor.isFocused) {
                editor.commands.focus();
              }
            }}
          >
            {timeseriesValue}
            <ChevronDownIcon className="size-3 stroke-2" />
          </PopoverButton>
          <PopoverPanel
            anchor="bottom end"
            as="div"
            className={`${
              open ? 'block' : 'hidden'
            } absolute bg-white shadow-md rounded-md z-10 border-none outline-none`}
          >
            <div className="flex flex-col" data-testid="timeseries-options">
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-0');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                This Month
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-1');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                Last Month
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-2');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                2 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-3');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                3 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-4');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                4 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-5');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                5 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-6');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                6 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-7');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                7 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-8');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                8 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-9');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                9 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-10');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                10 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-11');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                11 Months Ago
              </div>
              <div
                className="px-5 py-2 hover:bg-neutral-25 cursor-pointer"
                onClick={() => {
                  updateTimeModifier('previous-12');
                  if (!editor.isFocused) {
                    editor.commands.focus();
                  }
                  close();
                }}
              >
                12 Months Ago
              </div>
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
};

export default TimeseriesModifier;

import React from 'react';
import Typography from '~/components/Typography';

const TogglesAreDeactivated = (): React.ReactElement => {
  return (
    <div className="max-w-[194px] rounded-lg bg-white p-3 shadow-md">
      <Typography size="xs">Toggles are deactivated when a filter is applied</Typography>
    </div>
  );
};

export default TogglesAreDeactivated;

import store from '~/store';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { IContract } from './entity/types';

export default async (): Promise<unknown> => {
  const organizationUuid = store.getState().organization.uuid;
  const scenarioUuid = store.getState().scenario.activeScenarioUuid;

  const response = (await request({
    url: `/contracts`,
    method: 'GET',
    headers: {
      'Organization-Uuid': organizationUuid,
    },
    params: {
      scenarioUuid,
    },
  })) as IAPIResponse<IContract[]>;

  return {
    contracts: response.data.data,
  };
};

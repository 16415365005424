import { invertYValue, restoreYValue } from './invertYValue';
import { PERCENT_TO_SNAP_TO } from './percentToSnapTo';

export const getSnappingBoundries = ({
  graphValue,
  yMultiplier,
  maxY,
  minY,
  activeScenarioValue,
  workingModelValue,
}: {
  graphValue: number;
  yMultiplier: number;
  maxY: number;
  minY: number;
  activeScenarioValue: number;
  workingModelValue: number;
}): number | null => {
  const originalValue = restoreYValue({
    value: graphValue,
    maxY,
    yMultiplier,
  });
  const graphRange = maxY - minY;
  const snapToValue = graphRange * PERCENT_TO_SNAP_TO;
  const activeScenarioDifference = Math.abs(activeScenarioValue - originalValue);
  const workingModelDifference = Math.abs(workingModelValue - originalValue);
  const zeroDifference = Math.abs(0 - originalValue);
  if (activeScenarioDifference < snapToValue) {
    return invertYValue({
      value: activeScenarioValue,
      maxY,
      yMultiplier,
    });
  }
  if (workingModelDifference < snapToValue) {
    return invertYValue({
      value: workingModelValue,
      maxY,
      yMultiplier,
    });
  }
  if (zeroDifference < snapToValue) {
    return invertYValue({
      value: 0,
      maxY,
      yMultiplier,
    });
  }

  return null;
};

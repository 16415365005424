import { z } from 'zod';
import { IFormulaSegment, IVariables } from '~/pages/FinancialModelDeprecated/entity/types';
import updateFormulaAndVariables from '~/pages/FinancialModelDeprecated/utils/updateFormulaAndVariables';
import { commonOperations } from './commonOperations';
import shiftFormulaIndexes from '~/pages/FinancialModelDeprecated/utils/shiftFormulaIndexes';

export const handleDeleteAndBackspace = ({
  event,
  position,
  segmentToDelete,
  setSegmentToDelete,
  inputPosition,
  setInputPosition,
  updatedFormula,
  setUpdatedFormula,
  setFormula,
  variables,
  setVariables,
  value,
  setValue,
  setEnteredConstantFrom,
  inputRef,
}: {
  event: React.KeyboardEvent<HTMLDivElement>;
  position?: 'beginning' | 'end';
  segmentToDelete?: {
    segmentRef: React.RefObject<HTMLDivElement>;
    segmentIndex: number;
    segmentValue: string;
  };
  setSegmentToDelete: React.Dispatch<
    React.SetStateAction<
      | {
          segmentRef: React.RefObject<HTMLDivElement>;
          segmentIndex: number;
          segmentValue: string;
        }
      | undefined
    >
  >;
  inputPosition: number;
  setInputPosition: React.Dispatch<React.SetStateAction<number>>;
  updatedFormula: IFormulaSegment[];
  setUpdatedFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  setFormula: React.Dispatch<React.SetStateAction<IFormulaSegment[]>>;
  variables: IVariables;
  setVariables: React.Dispatch<React.SetStateAction<IVariables>>;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  setEnteredConstantFrom: React.Dispatch<React.SetStateAction<'left' | 'right' | undefined>>;
  inputRef: React.RefObject<HTMLDivElement>;
}): void => {
  const newInputPosition = event.key === 'Backspace' ? inputPosition - 1 : inputPosition;

  if (segmentToDelete) {
    if (value.length) {
      const isValidNumber = z.number().safeParse(Number(value.trim()));
      const { newFormula, newVariables } = updateFormulaAndVariables({
        valuesToAdd: [
          {
            type: isValidNumber.success ? 'constant' : 'invalid',
            value: value.trim(),
            newIndex: inputPosition,
          },
        ],
        formulaCopy: [...updatedFormula],
        variablesCopy: { ...variables },
      });
      commonOperations({
        indexToDelete: segmentToDelete.segmentIndex,
        variableKeyToDelete: segmentToDelete.segmentValue,
        updateInputPosition: () => Math.max(segmentToDelete.segmentIndex, 0),
        formulaToUse: newFormula,
        variablesToUse: newVariables,
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
      setSegmentToDelete(undefined);
    } else {
      commonOperations({
        indexToDelete: segmentToDelete.segmentIndex,
        variableKeyToDelete: segmentToDelete.segmentValue,
        updateInputPosition: () => Math.max(segmentToDelete.segmentIndex, 0),
        updatedFormula,
        setUpdatedFormula,
        setFormula,
        variables,
        setVariables,
        setValue,
        setEnteredConstantFrom,
        setInputPosition,
        inputRef,
      });
      setSegmentToDelete(undefined);
    }
  } else {
    if (position) {
      const isValidNumber = z.number().safeParse(Number(value.trim()));
      const { newFormula, newVariables } = updateFormulaAndVariables({
        valuesToAdd: [
          {
            type: isValidNumber.success ? 'constant' : 'invalid',
            value: value.trim(),
            newIndex: inputPosition,
          },
        ],
        formulaCopy: [...updatedFormula],
        variablesCopy: { ...variables },
      });
      const segmentToSelectIndex = position === 'beginning' ? inputPosition - 1 : inputPosition + 1;
      const selectedSegment = newFormula[segmentToSelectIndex];
      if (selectedSegment.type === 'operator') {
        newFormula.splice(segmentToSelectIndex, 1);
        const { formulaWithShiftedIndexes, variablesWithShiftedIndexes } = shiftFormulaIndexes({
          oldFormula: newFormula,
          oldVariables: newVariables,
        });

        setFormula(formulaWithShiftedIndexes);
        setVariables(variablesWithShiftedIndexes);

        setUpdatedFormula(formulaWithShiftedIndexes);
        setValue('');
        setInputPosition(segmentToSelectIndex);
      } else if (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid') {
        commonOperations({
          indexToDelete: segmentToSelectIndex,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: position === 'beginning' ? 'right' : 'left',
          updateInputPosition: () => segmentToSelectIndex,
          formulaToUse: newFormula,
          variablesToUse: newVariables,
          updatedFormula,
          setUpdatedFormula,
          setFormula,
          variables,
          setVariables,
          setValue,
          setEnteredConstantFrom,
          setInputPosition,
          inputRef,
        });
      } else if (selectedSegment.type === 'formula' || selectedSegment.type === 'calculated') {
        if (selectedSegment.ref.current) {
          selectedSegment.ref.current.classList.add('!bg-green-25', '!border-green-400', 'text-green-500');
        }

        setSegmentToDelete({
          segmentRef: selectedSegment.ref,
          segmentIndex: segmentToSelectIndex,
          segmentValue: selectedSegment.textValue,
        });
      }
    } else {
      const selectedSegment = updatedFormula[newInputPosition];
      if (selectedSegment.type === 'operator') {
        const newFormula = [...updatedFormula];
        newFormula.splice(newInputPosition, 1);
        setUpdatedFormula(newFormula);
        setInputPosition((prev) => Math.max(event.key === 'Backspace' ? prev - 1 : prev, 0));
      } else if (selectedSegment.type === 'constant' || selectedSegment.type === 'invalid') {
        commonOperations({
          indexToDelete: newInputPosition,
          variableKeyToDelete: selectedSegment.textValue,
          enteredFrom: event.key === 'Backspace' ? 'right' : 'left',
          updateInputPosition: (prev) => Math.max(event.key === 'Backspace' ? prev - 1 : prev, 0),
          updatedFormula,
          setUpdatedFormula,
          setFormula,
          variables,
          setVariables,
          setValue,
          setEnteredConstantFrom,
          setInputPosition,
          inputRef,
        });
      } else {
        // Handle other segment types (e.g., variables)
        if (selectedSegment.ref.current) {
          selectedSegment.ref.current.classList.add('!bg-green-25', '!border-green-400', 'text-green-500');
        }

        setSegmentToDelete({
          segmentRef: selectedSegment.ref,
          segmentIndex: newInputPosition,
          segmentValue: selectedSegment.textValue,
        });
      }
    }
  }
};

import { State } from '~/store';
import useQueryParams from '~/utils/hooks/useQueryParams';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import request from '~/utils/request';
import zod from 'zod';

const UserAlreadyCreatedErrorSchema = zod.object({
  message: zod.union([zod.literal('User already exists with this email'), zod.literal('Invitation already accepted')]),
  data: zod.string(),
});

const SignUp = (): React.ReactNode => {
  const navigate = useNavigate();
  const [queryParams] = useQueryParams();
  const invitationId = queryParams.get('invitationId');
  const { isLoggedIn } = useSelector((state: State) => state.auth);
  const { uuid: userUuid } = useSelector((state: State) => state.user);
  const hasAttemptedInvitationCreation = useRef(false);

  const attemptCreateUserFromInvite = async (invitationUuid: string): Promise<void> => {
    if (hasAttemptedInvitationCreation.current) return;
    hasAttemptedInvitationCreation.current = true;
    const createUserResponse = await request({
      url: '/users',
      method: 'POST',
      body: {
        invitationUuid,
      },
    });

    if (createUserResponse.status === 400) {
      const parseResult = UserAlreadyCreatedErrorSchema.safeParse(createUserResponse.data.errors[0]);
      if (parseResult.success) {
        navigate('/auth/login');
        return;
      }
    }

    if (createUserResponse.status === 201) {
      const { email, otp } = createUserResponse.data.data;
      navigate(`/auth/login?otp=${otp}&emailValue=${encodeURIComponent(email)}&firstLogin=true`);
    }
  };

  useEffect(() => {
    if (!invitationId || (isLoggedIn && userUuid)) return;
    attemptCreateUserFromInvite(invitationId);
  }, [invitationId, isLoggedIn, userUuid]);

  if (isLoggedIn && userUuid) return <Navigate to="/onboarding" replace />;
  return <div />;
};

export default SignUp;

import { useContext } from 'react';
import { ITableContext, TableContext } from '../contexts/TableContext';

const useTableContext = (): ITableContext => {
  const tableContext = useContext(TableContext);

  if (tableContext) {
    return tableContext;
  } else {
    throw new Error('No tableContext available. Make sure to wrap the component in a context provider.');
  }
};

export default useTableContext;

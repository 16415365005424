import React from 'react';
import { RadioInputState, RadioInputType } from '~/components/RadioInput/RadioInput.types';
import Typography from '~/components/Typography';

interface Props {
  id: string;
  state: RadioInputState;
  label: string;
  description?: string;
  disabled: boolean;
  onChange: (event: string) => void;
  option: RadioInputType;
}

const RadioInput = ({ id, state, disabled, onChange, option }: Props): React.ReactNode => (
  <div data-testid={id} className="flex flex-col justify-start w-full">
    <div className="flex flex-row gap-2">
      <input
        data-testid={option.value}
        value={option.value}
        id={option.value}
        type="radio"
        name={id}
        checked={state.selected?.value === option.value}
        onChange={() => onChange(option.value)}
        className="mt-1 hover:bg-neutral-15 hover:border-green-500 
        checked:bg-green-400 checked:hover:bg-green-500 checked:focus:bg-green-400 checked:focus:hover:bg-green-500
        focus:ring-green-400 focus:hover:ring-green-500"
        disabled={disabled}
      />
      <label htmlFor={option.value}>
        <Typography size="xs">{option.label}</Typography>
      </label>
    </div>
    <Typography size="xs" className="ml-6" color="secondary">
      {option.description}
    </Typography>
  </div>
);

export default RadioInput;

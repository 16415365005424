import React, { useState } from 'react';
import Button from '~/components/Button';
import ShareLinksModal from '~/components/ShareLinksModal/ShareLinksModal';

export const ShareButton = ({ disabled }: { disabled: boolean }): React.ReactElement => {
  const [showShareLinksModal, setShowShareLinksModal] = useState<boolean>(false);

  return (
    <>
      <Button fill="lightGreenOutlineGreenText" onClick={() => setShowShareLinksModal(true)} disabled={disabled}>
        Share
      </Button>
      <ShareLinksModal showModal={showShareLinksModal} setShowModal={setShowShareLinksModal} />
    </>
  );
};

/*
 * This hides the decimal for values that already show 3 digits so that the numbers don't overflow
 * without having to increase the width given to the yAxis
 */
export const getDecimalBasedOnValue = (value: number | null): number => {
  const ONE_HUNDRED_THOUSAND_WITH_CENTS = 10000000;
  const ONE_MILLION_WITH_CENTS = 100000000;
  const ONE_HUNDRED_MILLION_WITH_CENTS = 10000000000;
  const ONE_BILLION_WITH_CENTS = 100000000000;
  const ONE_HUNDRED_WITH_CENTS = 10000;

  if (value === null) return 0;
  const absValue = Math.abs(value);
  if (absValue >= ONE_HUNDRED_THOUSAND_WITH_CENTS && absValue < ONE_MILLION_WITH_CENTS) {
    return 0;
  }
  if (absValue >= ONE_HUNDRED_MILLION_WITH_CENTS && absValue < ONE_BILLION_WITH_CENTS) {
    return 0;
  }
  if (absValue < ONE_HUNDRED_WITH_CENTS) {
    return 0;
  }

  return 1;
};

import React, { useContext } from 'react';
import { IInitialValue } from '../Cell/entities/types';
import { CellContext } from '@tanstack/react-table';
import { HeadcountContext } from '~/pages/Headcount/context/HeadcountContext';
import CurrencyInput from '~/components/Input/currencyInput/CurrencyInput';

const CreationPayRate = ({
  cellContext,
  handleBlur,
}: {
  cellContext: CellContext<Record<string, IInitialValue>, IInitialValue>;
  handleBlur: () => void;
}): React.ReactNode => {
  const { inlineCreate, inlineCreatePayRate, setInlineCreatePayRate } = useContext(HeadcountContext);

  return inlineCreate ? (
    <div className="min-w-[130px]">
      <CurrencyInput
        id={`create-${cellContext.column.id}`}
        state={inlineCreatePayRate}
        setState={setInlineCreatePayRate}
        onBlur={handleBlur}
      />
    </div>
  ) : (
    <div />
  );
};

export default CreationPayRate;

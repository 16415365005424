import React, { useContext } from 'react';
import { GenerateExpensesContext, IActiveSelectionEnum } from './GenerateExpensesContext';
import Typography from '~/components/Typography';
import {
  ChevronRightIcon,
  ClipboardDocumentIcon,
  ForwardIcon,
  InboxIcon,
  TableCellsIcon,
} from '@heroicons/react/24/outline';
import Button from '~/components/Button';
import { ExpensesPageContext } from '~/pages/Expenses/context/ExpensesContext';
import ProposedExpenseCard from './ProposedExpenseCard';
import RecommendedExpenseCard from './RecommendedExpenseCard';
import finishGeneratingExpenses from '~/assets/finishGeneratingExpenses.svg';
import request from '~/utils/request';
import { IAPIResponse } from '~/utils/types';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { StatusCodes } from 'http-status-codes';

const titleOptions: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: 'People & Facilities',
  [IActiveSelectionEnum.COGS]: 'Cost of Goods Sold (ie. COGS)',
  [IActiveSelectionEnum.SOFTWARE]: 'Software',
  [IActiveSelectionEnum.MARKETING]: 'Marketing',
  [IActiveSelectionEnum.OTHER]: 'Other',
  [IActiveSelectionEnum.FINALIZE]: '',
};

const subTitleOptions: Record<IActiveSelectionEnum, string> = {
  [IActiveSelectionEnum.INITIAL]: '',
  [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: `These are forecasted expenses that relate to your employees and facility, often making up a large portion of your spend due to taxes, benefits, and rent.`,
  [IActiveSelectionEnum.COGS]: `These expenses are forecasted using historical spending patterns in relation to revenue. As you refine your model, the forecast can become more granular, with expenses driven by other factors like customer count.`,
  [IActiveSelectionEnum.SOFTWARE]: `To streamline your software expense forecasting, we start by forecasting it as a single category. You can expand it into more detailed projections whenever it's beneficial.`,
  [IActiveSelectionEnum.MARKETING]:
    'This category can be a lot more volatile than other expenses, make sure to add in any future conferences or campaigns that would significantly impact your budget.',
  [IActiveSelectionEnum.OTHER]: `This category includes items that don't fit into others, such as legal fees and services. Unrecognized vendors may also appear here; please reassign them to the correct category.`,
  [IActiveSelectionEnum.FINALIZE]: '',
};

const InitialPage = (): React.ReactNode => {
  const { setActiveSection } = useContext(GenerateExpensesContext);
  return (
    <div className="w-full flex justify-center items-center">
      <div className="flex flex-col gap-6 max-w-[640px] mb-24">
        <div className="flex flex-col">
          <Typography size="lg" weight="bold">
            Generate Expense Forecast
          </Typography>
          <Typography color="secondary">
            {`We've created forecasted expenses based off your actuals and headcount info. This walkthrough will verify your
        forecasted spend and display expense categories.`}
          </Typography>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <ForwardIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Effortless Data Import</Typography>
            <Typography color="secondary">Instantly pull in real data for accurate, up-to-date expense data</Typography>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <ClipboardDocumentIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Simplified Upkeep</Typography>
            <Typography color="secondary">
              With expenses linked to actuals, they can quickly pull in real data
            </Typography>
          </div>
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex justify-center items-center size-12 bg-green-25 rounded-full">
            <TableCellsIcon className="size-6 text-green-500" />
          </div>
          <div className="flex flex-col">
            <Typography weight="medium">Automatically Flows into Forecast</Typography>
            <Typography color="secondary">
              Your expenses, like salaries, will flow automatically into your model
            </Typography>
          </div>
        </div>
        <Button
          onClick={() => setActiveSection(IActiveSelectionEnum.PEOPLE_AND_FACILITIES)}
          className="!w-fit !px-4 flex flex-row"
        >
          Start
          <ChevronRightIcon className="size-4" />
        </Button>
      </div>
    </div>
  );
};

const FinalizePage = (): React.ReactNode => {
  const { setActiveSection } = useContext(GenerateExpensesContext);
  const { setAutoGeneratedExpenses, reload } = useContext(ExpensesPageContext);
  const organizationUuid = useSelector((state: State) => state.organization.uuid);

  const updateProposedToCreated = async (): Promise<void> => {
    const response = (await request({
      url: `/expenses/proposed-to-created`,
      method: 'PATCH',
      headers: {
        'Organization-Uuid': organizationUuid,
      },
    })) as IAPIResponse;

    if (response.status === StatusCodes.OK) {
      setActiveSection(IActiveSelectionEnum.INITIAL);
      setAutoGeneratedExpenses(false);
      reload();
    }
  };

  return (
    <div className="w-full flex justify-center">
      <div className="flex flex-col items-center max-w-[430px] text-center mt-[20vh]">
        <img src={finishGeneratingExpenses} alt="finalizeExpenses" className="w-[230px] h-auto mb-6 mt-14" />
        <Typography size="md" weight="bold">
          All Expenses Forecasted
        </Typography>
        <Typography>{`We'll continually refine your forecast as additional details and complexity is added`}</Typography>
        <Button onClick={updateProposedToCreated} className="!w-fit !px-4 mt-6">
          Finish
        </Button>
      </div>
    </div>
  );
};

const ExpensesPage = (): React.ReactNode => {
  const { activeSection, setActiveSection } = useContext(GenerateExpensesContext);
  const { proposedExpenses, recommendedExpenses, setExpenseModal, setDesiredCreationStatus } =
    useContext(ExpensesPageContext);

  // Map activeSection to corresponding tag
  const sectionTagMap: Record<IActiveSelectionEnum, string> = {
    [IActiveSelectionEnum.INITIAL]: '',
    [IActiveSelectionEnum.PEOPLE_AND_FACILITIES]: 'People & Facilities',
    [IActiveSelectionEnum.COGS]: 'Cost of Goods Sold',
    [IActiveSelectionEnum.SOFTWARE]: 'Software',
    [IActiveSelectionEnum.MARKETING]: 'Marketing',
    [IActiveSelectionEnum.OTHER]: 'Other',
    [IActiveSelectionEnum.FINALIZE]: '',
  };

  // Filter proposedExpenses based on activeSection
  const activeSectionProposedExpenses = proposedExpenses.filter(
    (expense) => expense.context.tag === sectionTagMap[activeSection],
  );

  const activeSectionRecommendedExpenses = recommendedExpenses.filter(
    (expense) => expense.context.tag === sectionTagMap[activeSection],
  );

  const handleNext = (): void => {
    const sections = [
      IActiveSelectionEnum.INITIAL,
      IActiveSelectionEnum.PEOPLE_AND_FACILITIES,
      IActiveSelectionEnum.COGS,
      IActiveSelectionEnum.SOFTWARE,
      IActiveSelectionEnum.MARKETING,
      IActiveSelectionEnum.OTHER,
      IActiveSelectionEnum.FINALIZE,
    ];
    const currentIndex = sections.indexOf(activeSection);
    const nextIndex = (currentIndex + 1) % sections.length;
    setActiveSection(sections[nextIndex]);
  };

  const noProposedData = activeSectionProposedExpenses.length === 0;
  const noRecommendedData = activeSectionRecommendedExpenses.length === 0;
  const noData = noProposedData && noRecommendedData;

  return (
    <div className="w-full flex justify-center mt-20">
      <div className="flex flex-col gap-4 max-w-[850px] min-w-[50vw]">
        <div className="flex flex-col">
          <Typography size="md" weight="bold">
            {titleOptions[activeSection]}
          </Typography>
          <Typography className="max-w-[600px]">{subTitleOptions[activeSection]}</Typography>
          {!noProposedData && (
            <div className="flex flex-col gap-4 mt-3">
              {activeSectionProposedExpenses.map((expense) => (
                <ProposedExpenseCard key={expense.expenseUuid} expense={expense} />
              ))}
            </div>
          )}
        </div>
        {!noRecommendedData && (
          <div>
            <Typography weight="medium" size="2xs" color="secondary" className="tracking-wider">
              {`SUGGESTED (NO DATA FOUND)`}
            </Typography>
            <div className="flex flex-col gap-4 mt-3">
              {activeSectionRecommendedExpenses.map((expense) => (
                <RecommendedExpenseCard key={expense.expenseUuid} expense={expense} />
              ))}
            </div>
          </div>
        )}
        {noData && (
          <div className="flex flex-row gap-4 my-2 items-center border border-neutral-50 rounded-lg px-8 py-6">
            <InboxIcon className="size-10 stroke-neutral-400" />
            <Typography color="secondary">
              {`No data source for ${sectionTagMap[activeSection]} was found. Please review your accounting to ensure a
            ${sectionTagMap[activeSection]} category exists, or click 'Add New' below and select the appropriate source`}
            </Typography>
          </div>
        )}
        <Button
          fill="outline"
          className="!w-fit !px-4"
          onClick={() => {
            setExpenseModal(true);
            setDesiredCreationStatus('proposed');
          }}
        >
          Add New / Upcoming Expenses
        </Button>
        <Button className="!w-fit !px-4" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};

const GeneratedExpensesPage = (): React.ReactNode => {
  const { activeSection } = useContext(GenerateExpensesContext);

  switch (activeSection) {
    case IActiveSelectionEnum.INITIAL:
      return <InitialPage />;
    case IActiveSelectionEnum.FINALIZE:
      return <FinalizePage />;
    default:
      return <ExpensesPage />;
  }
};

export default GeneratedExpensesPage;

import { format } from 'date-fns';
import React from 'react';
import Divider from '~/components/Divider';
import Modal from '~/components/Modal';
import Typography from '~/components/Typography';
import numberWithCommas from '~/utils/numberWithCommas';

const RatioDetailModal = ({
  isOpen,
  setIsOpen,
  planned,
  needed,
  actualDrivingValue,
  relevantDate,
  positionTitle,
  ratioStatus,
  drivingElementTitle,
  drivingValue,
}: {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  planned: number;
  needed: number;
  actualDrivingValue: number;
  relevantDate: string;
  positionTitle: string;
  ratioStatus: { color: string; status: React.ReactNode; stringStatus: string };
  drivingValue: number;
  drivingElementTitle: string;
}): React.ReactNode => {
  return (
    <Modal title={ratioStatus.status} size="sm" isOpen={isOpen} onClose={() => setIsOpen(false)} showClose>
      <div className="flex flex-col w-full gap-2">
        <Typography color="empty">{format(relevantDate, 'MMM yyyy')}</Typography>
        <div className="bg-neutral-15 px-6 py-3 rounded-md flex flex-col gap-1 w-full border border-neutral-50">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row gap-1">
              <Typography color="empty">Planned</Typography>
              <Typography color="secondary" className="italic">
                {drivingElementTitle}
              </Typography>
            </div>
            <Typography>{numberWithCommas(actualDrivingValue)}</Typography>
          </div>
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row gap-1">
              <Typography color="empty">Ratio</Typography>
            </div>
            <Typography>/ {numberWithCommas(drivingValue)}</Typography>
          </div>
          <Divider className="my-1" />
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row gap-1">
              <Typography color="empty">Required</Typography>
              <Typography color="secondary" className="italic">
                {positionTitle}
              </Typography>
            </div>
            <Typography weight="bold">{numberWithCommas(needed)}</Typography>
          </div>
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-row gap-1">
              <Typography color="empty">Planned</Typography>
              <Typography color="secondary" className="italic">
                {positionTitle}
              </Typography>
            </div>
            <div className="flex flex-row gap-4">
              <Typography>-</Typography>
              <Typography>{numberWithCommas(planned)}</Typography>
            </div>
          </div>
          <Divider className="my-1" />
          <div className="flex flex-row w-full justify-between">
            <Typography color="empty">{`Difference (Position Needed)`}</Typography>
            <div className={`${ratioStatus.color} font-bold`}>{numberWithCommas(needed - planned)}</div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RatioDetailModal;

import React, { useEffect, useState } from 'react';
import Typography from '~/components/Typography';
import { ICompanyGoal } from '../../Dashboard/entity/types';
import request from '~/utils/request';
import { useSelector } from 'react-redux';
import { State } from '~/store';
import { IAPIResponse } from '~/utils/types';
import { StatusCodes } from 'http-status-codes';
import GoalTile from './GoalTile';
import { ZCompanyGoal } from '../../Dashboard/entity/schemas';
import GoalModal from './GoalModal';

const CompanyGoals = (): React.ReactElement => {
  const organizationUuid = useSelector((state: State) => state.organization.uuid);
  const [createGoalModal, setCreateGoalModal] = useState<{
    open: boolean;
    goalUuid: string | null;
    formulaUuid: string | null;
    targetValue: string | null;
    targetDate: string | null;
  }>({
    open: false,
    goalUuid: null,
    formulaUuid: null,
    targetValue: null,
    targetDate: null,
  });
  const [companyGoals, setCompanyGoals] = useState<ICompanyGoal[]>([]);

  const fetchCompanyGoals = async (): Promise<void> => {
    const response = (await request({
      method: 'GET',
      url: '/goals',
      headers: {
        'Organization-Uuid': organizationUuid,
      },
    })) as IAPIResponse<ICompanyGoal[]>;
    if (response.status === StatusCodes.OK) {
      const parsedResponse = ZCompanyGoal.array().parse(response.data.data);
      setCompanyGoals(parsedResponse);
    }
  };

  useEffect(() => {
    fetchCompanyGoals();
  }, []);

  const onDeleteGoal = (goalUuid: string): void => {
    setCompanyGoals(companyGoals.filter((goal) => goal.goalUuid !== goalUuid));
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col gap-2">
        {companyGoals
          .sort((a, b) => {
            if (!a.formulaUuid) return 1;
            if (!b.formulaUuid) return -1;
            return a.formulaUuid.localeCompare(b.formulaUuid);
          })
          .map((goal) => (
            <GoalTile
              key={goal.uuid}
              goalUuid={goal.goalUuid}
              formulaUuid={goal.formulaUuid ?? ''}
              targetValue={goal.targetValue}
              targetDate={goal.targetDate ?? null}
              onDelete={onDeleteGoal}
              setCreateGoalModal={setCreateGoalModal}
            />
          ))}
        <div
          className="flex py-2 px-3 bg-green-15 border border-green-15 rounded cursor-pointer"
          onClick={() => {
            setCreateGoalModal({
              open: true,
              goalUuid: null,
              formulaUuid: null,
              targetValue: null,
              targetDate: null,
            });
          }}
        >
          <Typography color="primaryGreen" weight="medium">
            Add Goal
          </Typography>
        </div>
      </div>
      <GoalModal
        createGoalModal={createGoalModal}
        setCreateGoalModal={setCreateGoalModal}
        setTopLevelGoals={setCompanyGoals}
      />
    </div>
  );
};

export default CompanyGoals;
